import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { url } from '../../../core/utils/link';
import { WELCOME_PATH } from '../../../onboarding/constants';
import { OVERVIEW_PATHS, DocumentType } from '../../../generic_document/constants';
import { LOGIN_PATH } from '../../../login/constants';

export const Home = ({ user, loggedIn }) => {
    const navigate = useNavigate();
    const customerIsActive = user?.customer?.isActive;

    useEffect(() => {
        if (!loggedIn) {
            navigate(url(LOGIN_PATH));
        }
        if (!customerIsActive) {
            navigate(url(WELCOME_PATH));
        }

        if (user?.customer?.isOrdersEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Order]));
        } else if (user?.customer?.isOrderConfirmationsEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.OrderConfirmation]));
        } else if (user?.customer?.isInvoicesEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Invoice]));
        } else if (user?.customer?.isRfqsEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Rfq]));
        } else if (user?.customer?.isPropertyBillsEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.PropertyBill]));
        } else if (user?.customer?.isDeliveryNotesEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.DeliveryNote]));
        } else if (user?.customer?.isListOfServicesEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.ListOfServices]));
        } else if (user?.customer?.isUniversalEnabled) {
            navigate(url(OVERVIEW_PATHS[DocumentType.Universal]));
        } else {
            navigate(url(WELCOME_PATH));
        }
    }, [user, customerIsActive]);
};

export default Home;
