import * as React from 'react';
import classnames from '../utils/classnames';
import Navigation, { useNavigation } from './Navigation';
import { useApplicationContext } from '../contexts/ApplicationContext';
import SupportBar from '../../support/containers/SupportBar';
import { canUseSupportMode } from '../../users/utils';
import AnnouncementBar from '../../announcements/components/AnnouncementBar';

interface LayoutProps {
    children?: any;
    className?: string;
    supportBarControls?: any;
}

const Layout = (props: LayoutProps) => {
    const { children, className, supportBarControls } = props;
    const { user, announcements } = useApplicationContext();

    const navigationProps = useNavigation();
    const supportBarVisible = canUseSupportMode(user);

    return (
        <>
            {announcements?.length > 0 &&
                announcements.map((announcement) => (
                    <AnnouncementBar key={announcement.id} announcement={announcement} />
                ))}
            {supportBarVisible && <SupportBar user={user} controls={supportBarControls} />}
            <div className={classnames('flex h-full dark-mode-ready bg-primary relative min-h-0', className)}>
                {user?.id ? <Navigation {...navigationProps} /> : null}

                <div className="flex-1 flex h-full overflow-auto">{children}</div>
            </div>
        </>
    );
};

export default Layout;
