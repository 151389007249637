import { useEffect } from 'react';

const useShortcuts = (shortcuts, allowOnInput = false) => {
    function handleKeyDown(e) {
        // prevent broken input fields
        if (e.target.nodeName === 'INPUT' && !allowOnInput) return;

        const capsLock = e.getModifierState?.('CapsLock');

        let code = e.code;
        // support for upper/lowercase
        if (e.code.startsWith('Key')) {
            let char = String.fromCharCode(e.keyCode);
            code = (capsLock && !e.shiftKey) || (!capsLock && e.shiftKey) ? char.toUpperCase() : char.toLowerCase();
        } else if (e.code.startsWith('Digit')) {
            // handle also non alpha numeric characters
            code = (capsLock && !e.shiftKey) || (!capsLock && e.shiftKey) ? e.key : String.fromCharCode(e.keyCode);
        }

        // modifier keys (we exclude shift here as we already support upper / lowercase)
        if (e.metaKey) {
            code = `CMD+${code}`;
        }

        if (e.ctrlKey) {
            code = `CTRL+${code}`;
        }

        if (e.altKey) {
            code = `ALT+${code}`;
        }

        const action = shortcuts[code];
        if (action) {
            e.preventDefault();
            action(e);
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [shortcuts]);
};

export default useShortcuts;
