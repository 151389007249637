import Overview from './pages/Overview';
import Add from './pages/Add';
import Edit from './pages/Edit';
import Detail from './pages/Detail';

import { canManageUsers } from '../users/utils';

import { ADD_PATH, DETAIL_PATH, EDIT_PATH, OVERVIEW_PATH } from './constants';

export default [
    {
        path: OVERVIEW_PATH,
        component: Overview,
    },
    {
        path: ADD_PATH,
        condition: ({ user }) => canManageUsers(user),
        component: Add,
    },
    {
        path: DETAIL_PATH,
        component: Detail,
    },
    {
        path: EDIT_PATH,
        condition: ({ user }) => canManageUsers(user),
        component: Edit,
    },
];
