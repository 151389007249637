import React from 'react';
import { createContext, useContext } from 'react';

/**
 * In-memory store mapping all feature toggles (by their key) to the current state of the toggle
 */
const featureToggleStore = (function () {
    let featureActiveStates = {};
    const setFeatureState = (key: string, isFeatureEnabled: boolean) => {
        featureActiveStates[key] = isFeatureEnabled;
    };
    return {
        featureActiveStates,
        setFeatureState,
    };
})();

/**
 * Signature for a feature toggle config mapping each toggle's key to a function that resolves the toggle's
 * active state
 */
export interface FeatureToggleConfig {
    [key: string]: FeatureToggleStateResolver;
}
declare type FeatureToggleStateResolver = (data?: any) => Promise<boolean> | boolean;

/**
 * Context provider used for injecting the feature toggle config & store into the consuming hook
 */
type Props = React.PropsWithChildren<{
    featureConfig: FeatureToggleConfig;
}>;
export const FeatureTogglesProvider = ({ children, featureConfig }: Props) => {
    return (
        <FeatureToggleContext.Provider value={[featureConfig, featureToggleStore]}>
            {children}
        </FeatureToggleContext.Provider>
    );
};
const FeatureToggleContext = createContext([]);

/**
 * Custom hook that returns the state for the feature toggle identified by the given key.
 * If no global state for the requested toggle is available yet, the state is resolved via the
 * provided FeatureToggleConfig.
 *
 * @param key - unique identifier for the feature toggle
 * @param data - additional data passed to the resolver in the feature config to determine the toggle's state
 */
export const useFeatureToggle = (key: string, data?: object) => {
    const [featureConfig, featureToggleStore] = useContext(FeatureToggleContext);
    const { featureActiveStates, setFeatureState } = featureToggleStore;
    const resolveToggleState: FeatureToggleStateResolver = featureConfig[key];

    let isFeatureEnabled = featureActiveStates[key];
    if (resolveToggleState) {
        const newToggleState = resolveToggleState(data);
        setFeatureState(key, newToggleState);
        isFeatureEnabled ??= newToggleState;
    }
    isFeatureEnabled ??= false; // fallback to disabled

    return isFeatureEnabled;
};
