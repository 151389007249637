import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { filterValidation } from '../../utils';

import './style.scss';

const ValidationChecks = (props) => {
    const { checks = [] } = props;
    const { t, i18n } = useTranslation('assistance');

    const filteredChecks = filterValidation(checks, i18n);

    if (!filteredChecks.length) return null;

    return (
        <div className="validation-checks">
            {filteredChecks.map((result: any, key) => (
                <div className="validation-checks__check" key={key}>
                    {result.targetValue ? (
                        <>
                            <b>{t('validationChecks.targetValueLabel')}:</b> <span>{result.targetValue}</span>
                        </>
                    ) : (
                        <b>
                            {(result?.explanationDetails &&
                                (t(`validationChecks.${result.name}`, result.explanationDetails) as any)) ||
                                t(`validationChecks.${result.name}`)}
                        </b>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ValidationChecks;
