import { IModuleProps } from '../core/utils/modules';

import routes from './routes';
import locales from './locales';

export default {
    name: 'invoices',
    locales,
    routes,
} as IModuleProps;
