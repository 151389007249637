import React, { ReactNode } from 'react';
import classnames from '../../../utils/classnames';
import NavigationItemBadge from './NavigationItemBadge';
import { useNavigationContext } from './NavigationContext';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { withIcon } from '../../Icon';
import Tooltip from '../../../../core/components/Tooltip';

export interface NavigationButtonProps {
    icon?: ReactNode;
    ellipsis?: boolean; // maybe we could use children or iconAfter instead of ellipsis
    label: string;
    active?: boolean;
    disabled?: boolean;
    badgeNumber?: number;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
}

const EllipsisIcon = withIcon(faEllipsisVertical);

const NavigationButton = React.forwardRef(
    (
        {
            icon,
            ellipsis = false,
            label,
            active = false,
            badgeNumber = undefined,
            className,
            onClick,
            ...buttonProps
        }: NavigationButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
        ref: any
    ) => {
        const { collapsed } = useNavigationContext();

        return (
            <button
                ref={ref}
                className={classnames(
                    'relative flex w-full items-center gap-2 rounded-md px-3 py-2 text-sm text-primary h-9 transition-colors font-medium',
                    'cursor-pointer hover:bg-secondary transition-[padding] ease-in-out duration-200',
                    active && '!bg-brand !text-brand',
                    collapsed && 'justify-start ',
                    'whitespace-nowrap',
                    className
                )}
                data-active={active}
                onClick={(event) => {
                    onClick(event);
                }}
                {...buttonProps}
            >
                {icon ? <span className="w-4 inline-flex justify-center flex-none">{icon}</span> : null}

                {!collapsed ? (
                    <Tooltip content={label} className="truncate inline-block w-auto" placement="top" overflowOnly>
                        {label}
                    </Tooltip>
                ) : null}

                {badgeNumber && badgeNumber > 0 ? (
                    <NavigationItemBadge className="ml-auto">{badgeNumber}</NavigationItemBadge>
                ) : null}

                {ellipsis && !collapsed ? <EllipsisIcon className="ml-auto" /> : null}
            </button>
        );
    }
);

export default NavigationButton;
