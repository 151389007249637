import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isEqual, isObject } from 'lodash';
import { useConfidenceExplanationMessage, useDocumentMatchingExplanationMessage } from './useFieldExplanations';
import { DEFAULT_VALUE_KEY } from './useDocumentConfig';
import ConfidenceExplanation, { useConfidenceExplanations } from './ConfidenceExplanation';
import { AssistanceFieldContextProvider, useAssistanceFieldContext } from './AssistanceFieldContext';
import FieldWrapper from './customizable/FieldWrapper';
import DynamicField from './DynamicField';
import Field from '../../../core_updated/components/Fields/Field';
import { withIcon } from '../../../core_updated/components/Icon';
import { faExclamationCircle, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../../core_updated/utils/classnames';
import { useConditionalComponent } from '../../../customizations/ConditionalComponentContext';
import { fieldIsGreen, fieldIsRed, fieldIsYellow, isFieldValueEmpty } from './utils';
import { useAssistanceContext } from './AssistanceContext';

export interface AssistanceFieldProps {
    fieldName: string;
    valueKey: string;
    value: any;
    itemIndex?: number;
    itemType?: string;
    fieldIndex?: number;
    placeholder?: string;
    isUpdateActive?: boolean;
    isReselectActive?: boolean;
    isFocusActive?: boolean;
    data: any;
    config: any;
    generalConfig: any;

    onFocus?: (payload: any) => void;
    onBlur?: (payload: any) => void;
    onMouseEnter?: (payload: any) => void;
    onMouseLeave?: (payload: any) => void;
    onAction?: (payload: any) => void;
    onReselect?: (payload: any) => void;
    onUpdate?: (payload: any) => void;
    viewMode?: string;
    documentLoading?: boolean;
    documentReadOnly?: boolean;
    clientPartitionId?: string;
    matchingDocumentNumber?: string;
}

export const LOW_CONFIDENCE_THRESHOLD = 0.4;
export const MEDIUM_CONFIDENCE_THRESHOLD = 0.9;

const LoadingIcon = withIcon(faSpinnerThird);
const EmptyFieldIcon = withIcon(faExclamationCircle);

export const AssistanceFieldControls = ({
    confidenceExplanationButtonVisible,
    confidenceExplanationProps,
    value,
    confidence,
    severity,
    onIconMouseEnter,
    DocumentMatchingExplanationIcon,
    ConfidenceExplanationIcon,
}: {
    confidenceExplanationButtonVisible: boolean;
    confidenceExplanationProps: any;
    value: any;
    confidence: number;
    severity: string;
    onIconMouseEnter: any;
    DocumentMatchingExplanationIcon: any;
    ConfidenceExplanationIcon: any;
}) => {
    const { isRequired, isEmpty, isUpdateActive, isReselectActive, isFocusActive, config, data } =
        useAssistanceFieldContext();
    const isGreen = useMemo(() => fieldIsGreen({ config, value, data }), [config, value, data]);
    const isYellow = useMemo(() => fieldIsYellow({ config, value, data }), [config, value, data]);
    const isRed = useMemo(() => fieldIsRed({ config, value, data }), [config, value, data]);

    const showErrorColors = !isFocusActive && !isUpdateActive && !isReselectActive;

    return isUpdateActive || isReselectActive ? (
        <Field.ControlButton>
            <LoadingIcon className="text-brand text-sm animate-spin" />
        </Field.ControlButton>
    ) : confidenceExplanationButtonVisible ? (
        <Field.ControlButton
            className={classnames(
                showErrorColors && isGreen && '!text-confidence-high',
                showErrorColors && isYellow && '!text-confidence-medium',
                showErrorColors && isRed && '!text-confidence-low'
            )}
            onClick={() => confidenceExplanationProps.onIsOpenChange(!confidenceExplanationProps.isOpen)}
            onMouseEnter={onIconMouseEnter}
        >
            {isEmpty && isRequired ? (
                <EmptyFieldIcon />
            ) : DocumentMatchingExplanationIcon ? (
                <DocumentMatchingExplanationIcon />
            ) : (
                <ConfidenceExplanationIcon />
            )}
        </Field.ControlButton>
    ) : null;
};

export const AssistanceField = ({
    // data from form field
    fieldName,
    valueKey,
    value: propValue,
    itemIndex,
    itemType,
    fieldIndex, // e.g. for header fields where we have multiple fields within a field group
    // data from view state
    placeholder,
    isUpdateActive = false,
    isReselectActive = false,
    isFocusActive = false,
    // data by reference ideally only used for read-only state
    data,
    config,
    generalConfig,
    // event handlers
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    onAction,
    onReselect,
    onUpdate,
    // more stuff
    documentLoading = false,
    documentReadOnly = false,
    clientPartitionId,
    matchingDocumentNumber,
}: AssistanceFieldProps) => {
    const fullFieldName = valueKey && valueKey !== DEFAULT_VALUE_KEY ? `${fieldName}__${valueKey}` : fieldName;

    const { documentConfiguration } = useAssistanceContext();

    const inputRef = useRef(null);
    const wrapperRef = useRef(null);

    /* VALUE MANAGEMENT */

    // TODO: double check if date fields should be nullable
    const defaultValue = propValue == null ? '' : propValue; // prevent field to become uncontrolled
    const [fieldValue, setFieldValue] = useState(defaultValue);

    const [updatePayload, setUpdatePayload] = useState({});

    const [isUpdated, setIsUpdated] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const isRequired = config?.isRequired || false;
    const isEmpty = isFieldValueEmpty(fieldValue) && isFieldValueEmpty(defaultValue);

    useEffect(() => {
        // triggered by external update
        if (!isEqual(defaultValue, fieldValue)) {
            setFieldValue(defaultValue);

            if (fieldValue != null) {
                // only show updated if previous value existed
                setIsUpdated(true);
                setTimeout(() => setIsUpdated(false), 1000);
            }
        }
    }, [defaultValue]);

    const handleValueChange = (value: any) => {
        setFieldValue(value);
    };

    const handleUpdatePayloadChange = (updatePayload: any) => {
        setUpdatePayload(updatePayload);
    };

    const propagateChangedValue = () => {
        // prevent unnecessary updates
        if (fieldValue === defaultValue && isFieldValueEmpty(updatePayload)) return;

        // if value is an object we can ignore the valueKey
        const valuePayload = isObject(fieldValue) ? fieldValue : { [valueKey]: fieldValue };

        // prevent update with input value if reselect is active
        if (isReselectActive) return;

        onUpdate?.({
            fieldName: fieldName,
            itemIndex,
            itemType,
            payload: { ...updatePayload, ...valuePayload },
        });
        setUpdatePayload({});
    };

    useEffect(() => {
        if (isFocusActive) return;

        propagateChangedValue();
    }, [isFocusActive]);

    /* CONFIDENCE AND DOCUMENT MATCHING EXPLANATIONS */

    const confidence = data?.predictionConfidence || 0;
    const confidenceExplanation: any = data?.confidenceExplanation || {};
    const dateFormat = generalConfig?.dateFormat || 'L';

    const { message: confidenceExplanationMessage, Icon: ConfidenceExplanationIcon } = useConfidenceExplanationMessage(
        confidenceExplanation,
        dateFormat
    );
    const {
        message: documentMatchingExplanationMessage,
        Icon: DocumentMatchingExplanationIcon,
        acceptVisible,
        acceptAction,
        severity,
        // only consider defaultValue as this is in sync with the backend state that drives the explanation
    } = useDocumentMatchingExplanationMessage(
        confidenceExplanation,
        dateFormat,
        isRequired,
        isFieldValueEmpty(defaultValue)
    );

    /* FIELD EVENT HANDLERS */

    const confidenceExplanationProps = useConfidenceExplanations({ wrapperRef, isFocusActive });

    const confidenceExplanationButtonVisible = confidenceExplanationMessage || documentMatchingExplanationMessage;

    /* FIELD EVENT HANDLERS */

    const handleFocus = (e) => {
        confidenceExplanationProps.onFieldFocus(e);
        return onFocus?.({ fieldName: fullFieldName, itemIndex, itemType, reselectTargetRef: inputRef });
    };

    const handleBlur = (e) => {
        confidenceExplanationProps.onFieldBlur(e);
        return onBlur?.({ fieldName: fullFieldName, itemIndex, itemType, reselectTargetRef: inputRef });
    };

    const handleMouseEnter = (e) => {
        confidenceExplanationProps.onFieldMouseEnter(e);
        return onMouseEnter?.({ fieldName: fullFieldName, itemIndex, itemType, reselectTargetRef: inputRef });
    };

    const handleMouseLeave = (e) => {
        confidenceExplanationProps.onFieldMouseLeave(e);
        return onMouseLeave?.({ fieldName: fullFieldName, itemIndex, itemType, reselectTargetRef: inputRef });
    };

    const handleIconMouseEnter = (e) => {
        return confidenceExplanationProps.onIconMouseEnter(e);
    };

    const handleReselect = (e) => {
        return onReselect?.({
            fieldName: fullFieldName,
            itemIndex,
            itemType,
            bbox: e.detail.bbox,
            pageIndex: e.detail.pageIndex,
        });
    };

    const handleReset = () => {
        return onAction?.({
            fieldName: fieldName,
            itemIndex,
            itemType,
            action: 'action:reset_field',
            forceRefetchRecord: true,
        });
    };

    const handleAcceptDeviation = () => {
        return onAction?.({
            fieldName: fieldName,
            itemIndex,
            itemType,
            action: `action:${acceptAction}`,
        });
    };

    /* PROP EXTENDABILITY */

    const FieldWrapperComponent = useConditionalComponent(
        'FieldWrapper',
        {
            fieldName: fullFieldName,
            itemIndex,
            itemType,
        },
        FieldWrapper
    );

    const FieldComponent = useConditionalComponent(
        'Field',
        {
            fieldName: fullFieldName,
            itemIndex,
            itemType,
            documentTypeName: documentConfiguration?.documentTypeName,
            config,
        },
        DynamicField
    );

    return (
        <AssistanceFieldContextProvider
            fieldIndex={fieldIndex}
            fieldName={fieldName}
            fullFieldName={fullFieldName}
            value={fieldValue}
            data={data}
            config={config}
            isRequired={isRequired}
            isEmpty={isEmpty}
            isLoading={documentLoading || isUpdateActive || isReselectActive}
            isUpdated={isUpdated}
            isInvalid={isInvalid}
            isUpdateActive={isUpdateActive}
            isReselectActive={isReselectActive}
            isFocusActive={isFocusActive}
            isHoverActive={undefined}
            clientPartitionId={clientPartitionId}
            matchingDocumentNumber={matchingDocumentNumber}
        >
            <FieldWrapperComponent
                fieldName={fieldName}
                fullFieldName={fullFieldName}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                confidenceExplanationButtonVisible={!!confidenceExplanationButtonVisible}
                isConfidenceExplanationOpen={confidenceExplanationProps.isOpen}
                confidenceExplanationAlignment={confidenceExplanationProps.alignment}
                field={
                    <FieldComponent
                        placeholder={placeholder}
                        value={fieldValue}
                        onValueChange={handleValueChange}
                        onUpdatePayloadChange={handleUpdatePayloadChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onReselect={handleReselect}
                        onReset={handleReset}
                        confidence={confidence}
                        severity={severity}
                        documentLoading={documentLoading}
                        documentReadOnly={documentReadOnly}
                        inputRef={inputRef}
                        controls={
                            <AssistanceFieldControls
                                confidenceExplanationButtonVisible={!!confidenceExplanationButtonVisible}
                                confidenceExplanationProps={confidenceExplanationProps}
                                value={fieldValue}
                                confidence={confidence}
                                severity={severity}
                                onIconMouseEnter={handleIconMouseEnter}
                                DocumentMatchingExplanationIcon={DocumentMatchingExplanationIcon}
                                ConfidenceExplanationIcon={ConfidenceExplanationIcon}
                            />
                        }
                    />
                }
                confidenceExplanation={
                    <ConfidenceExplanation
                        confidenceMessage={confidenceExplanationMessage}
                        matchingMessage={fieldIndex === 0 ? documentMatchingExplanationMessage : null}
                        confidence={confidence}
                        severity={severity}
                        acceptVisible={acceptVisible}
                        acceptAction={acceptAction}
                        onAccept={handleAcceptDeviation}
                    />
                }
                ref={wrapperRef}
            />
        </AssistanceFieldContextProvider>
    );
};
