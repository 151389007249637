import { ILocalizedString, resolveLocalizedString } from '../../../utils/localization';
import { IMenuItem } from '../../../core/containers/DropDown';

export interface ISelectOption {
    label: ILocalizedString;
    description: ILocalizedString;
    value: any;
    disabled: boolean;
}

/**
 * Transforms a list of options or choices as provided by the backend into a list of menu items for use with UI
 * components such as AutocompleteField or SelectField
 *
 * Note:
 *     Options can be provided either as a list of plain strings (`choices`) or objects (`options`).
 *     When providing options as plain strings, the string is used both as a label & value for the menu item.
 *
 *     Where possible, prefer using option objects over plain string. Plain strings are still supported for
 *     backward-compatibility and will be deprecated in the future.
 *
 * @param choices
 * @param options
 */
export const toMenuItems = (choices: string[], options: Array<ISelectOption>): Array<IMenuItem> => {
    if (choices == null && options == null) {
        return null;
    }

    const menuItems = [];

    if (options != null && options.length > 0) {
        for (let option of options) {
            menuItems.push({
                ...option,
                label: resolveLocalizedString(option.label),
                description: resolveLocalizedString(option.description),
            });
        }
    } else if (choices != null && choices.length > 0) {
        for (let choice of choices) {
            if (!Array.isArray(choices[0])) {
                menuItems.push({
                    value: choice,
                    label: choice,
                    description: null,
                });
            } else {
                const [value, label, data] = choice;
                menuItems.push({
                    value,
                    label,
                    data,
                    description: null,
                });
            }
        }
    }

    return menuItems;
};
