import * as React from 'react';
import { generatePath as generateRouterPath, useLocation } from 'react-router-dom';
import { Params } from 'react-router';

export const useQueryParams = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const generatePath = (path: string, params?: Params, queryParams?: Params): string => {
    const generatedPath = generateRouterPath(path, params);
    if (queryParams == null) {
        return generatedPath;
    }
    return `${generatedPath}?${new URLSearchParams(queryParams).toString()}`;
};
