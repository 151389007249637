import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { DocumentType, getDocumentType } from '../../generic_document/constants';

type CustomAttributes = { [key: string]: any };
type ICustomEventFactory = (...args: any[]) => [IEventTelemetry, CustomAttributes];

// Shared event attributes

const userProps = (user) => ({
    userId: user?.id,
    userEmail: user?.email,
    userLanguage: user?.language,
    userJoinedAt: user?.dateJoined,
    lastLogin: user?.lastLogin,
    isStaff: user?.isStaff,
    customerId: user?.customer?.id,
    customerName: user?.customer?.shortName,
    customerCreatedAt: user?.customer?.createdAt,
    pricingPlan: user?.customer?.plan?.identifier,
});

const documentProps = (record, document) => ({
    recordId: record?.id,
    stepName: record?.stepRun?.stepName,
    stepExecutionStatus: record?.stepRun?.executionStatus,
    documentType: document != null ? DocumentType[getDocumentType(document)] : null,
    pagesCount: record?.ocr?.pagesCount,
    clientName: document?.customer?.name,
});

const channelProps = (channelId, channelName, documentType: DocumentType) => ({
    channelId,
    channelName,
    documentType: DocumentType[documentType],
});

// TODO: add URL + determine page context based on URL
const frontendProps = (pageKey: string) => ({
    pageContext: pageKey,
    pageContext2: null, // for subpages/tabs, e.g. "items" tab on assistance page
    screenResolution: `${window.screen.availWidth} x ${window.screen.availHeight}`,
});

//  Assistance events

const assistanceEvent =
    (name, staticEventProps = {}): ICustomEventFactory =>
    (user, record, document, eventProps = {}) => [
        { name },
        {
            ...frontendProps('assistance'),
            ...userProps(user),
            ...documentProps(record, document),
            ...channelProps(
                record?.channel?.id,
                record?.channel?.name,
                document != null ? getDocumentType(document) : null
            ),
            ...staticEventProps,
            ...eventProps,
        },
    ];

// Assistance / Document Events
export const openAssistanceEvent = assistanceEvent('OPEN_ASSISTANCE'); // fired when loading the assistance view
export const finishAssistanceEvent = assistanceEvent('FINISH_ASSISTANCE');
export const closeAssistanceEvent = assistanceEvent('CLOSE_ASSISTANCE');
export const discardChangesEvent = assistanceEvent('DISCARD_CHANGES');
export const removeDocumentEvent = assistanceEvent('REMOVE_DOCUMENT');
export const retryDocumentEvent = assistanceEvent('RETRY_DOCUMENT');
export const reUploadDocumentEvent = assistanceEvent('REUPLOAD_DOCUMENT');
export const deleteRecordEvent = assistanceEvent('DELETE_RECORD', { pageKey: 'overview' }); // only via overview / list of documents
export const sendToLabelingEvent = assistanceEvent('SEND_TO_LABELING');

export const toggleExtractedDocumentViewEvent = assistanceEvent('TOGGLE_EXTRACTED_DOCUMENT_VIEW', { show: null });
export const scrollToBottomEvent = assistanceEvent('SCROLL_TO_BOTTOM', { pageContext2: 'items' }); // via the down-button in the sidepanel (items tab only)

export const selectDocumentViewerRegionStartEvent = assistanceEvent('SELECT_DOCUMENT_VIEWER_REGION_START', {
    fieldName: null,
    fieldType: null,
});
export const selectDocumentViewerRegionEndEvent = assistanceEvent('SELECT_DOCUMENT_VIEWER_REGION_END', {
    fieldName: null,
    fieldType: null,
});

// Assistance / Field Events
// Note: Some interactions are currently exclusive to item or header fields (see hard-coded `fieldType`)

export const focusFieldEvent = assistanceEvent('FOCUS_FIELD', { fieldType: 'header' });
export const clearFieldEvent = assistanceEvent('CLEAR_FIELD', { fieldType: 'header' });
export const markAsCorrectEvent = assistanceEvent('MARK_AS_CORRECT', { fieldType: 'item' });
export const markAllAsCorrectEvent = assistanceEvent('MARK_ALL_AS_CORRECT'); // "continue" button for header tab

export const reselectFieldStartEvent = assistanceEvent('RESELECT_FIELD_START', { fieldType: 'header' }); // explicit reselection mode not available for item fields currently
export const reselectFieldEndEvent = assistanceEvent('RESELECT_FIELD_END'); // can be fired for item subfields (automatic on-focus reselection mode)
export const reselectFieldCancelEvent = assistanceEvent('RESELECT_FIELD_CANCEL', { fieldType: 'header' });

// Assistance / Line Item Events

export const changeItemsViewTypeEvent = assistanceEvent('CHANGE_ITEMS_VIEW_TYPE', { viewType: null });
export const changeItemsTableViewTypeEvent = assistanceEvent('CHANGE_ITEMS_TABLE_VIEW_TYPE', { viewType: null });
export const changeExplanationToggleEvent = assistanceEvent('CHANGE_EXPLANATION_TOOGLE', { explanationToggle: null });
export const addItemStartEvent = assistanceEvent('ADD_ITEM_START'); // when clicking on add-button
export const addItemEndEvent = assistanceEvent('ADD_ITEM_END'); // fired with SELECT_DOCUMENT_VIEWER_AREA_END for items
export const addItemCancelEvent = assistanceEvent('ADD_ITEM_CANCEL');
export const deleteItemEvent = assistanceEvent('DELETE_ITEM', { itemPosition: null });
export const deleteBulkItemEvent = assistanceEvent('BULK_DELETE_ITEMS', { itemPosition: null });
export const copyToAllItemsEvent = assistanceEvent('COPY_TO_ALL_ITEMS', { fieldName: null });
export const showMoreResultsEvent = assistanceEvent('SHOW_MORE_RESULTS');
export const showDefaultMDEvent = assistanceEvent('SHOW_DEFAULT_MD');

// Dashboard / Channel events

const dashboardEvent =
    (name, staticEventProps = {}): ICustomEventFactory =>
    (user, channelId, documentType, eventProps = {}) => [
        { name },
        {
            ...frontendProps('overview'),
            ...userProps(user),
            ...channelProps(channelId, null, documentType),
            ...staticEventProps,
            ...eventProps,
        },
    ];

export const showAutomationRateEvent = dashboardEvent('SHOW_AUTOMATION_RATE');
export const uploadDocumentOpenedEvent = dashboardEvent('UPLOAD_DOCUMENT_OPENED');
export const uploadDocumentCancelledEvent = dashboardEvent('UPLOAD_DOCUMENT_CANCEL');
export const uploadDocumentEvent = dashboardEvent('UPLOAD_DOCUMENT');
export const createChannelOpenedEvent = dashboardEvent('CREATE_CHANNEL_OPENED');
export const createChannelEvent = dashboardEvent('CREATE_CHANNEL');

export const filtersOpenedEvent = dashboardEvent('FILTERS_OPENED');
export const applyFiltersEvent = dashboardEvent('APPLY_FILTERS', { filters: [], filterValues: [] }); // only when applying filters manually (any filter including my tasks)
export const searchEvent = dashboardEvent('SEARCH', { searchTerm: '' }); // when applying filters manually & search term is set

export const updateChannelMetadataEvent = dashboardEvent('UPDATE_CHANNEL_METADATA', { pageKey: 'channelConfig' }); // channel name, etc
export const updateChannelConfigEvent = dashboardEvent('UPDATE_CHANNEL_CONFIG', {
    pageKey: 'channelConfig',
    configKey: null,
}); // any of the config sections
export const uploadMasterdata = dashboardEvent('UPLOAD_MASTERDATA', { pageKey: 'channelConfig', masterdataName: '' }); // manual upload

// Other events

export const loadAppEvent: ICustomEventFactory = (user) => [{ name: 'LOAD_APP' }, { ...userProps(user) }];
