import { useTranslation } from 'react-i18next';
import AssistanceSection from '../../generic_document/pages/Assistance/AssistanceSection';
import * as React from 'react';
import { withIcon } from '../../core_updated/components/Icon';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

const CompleteIcon = withIcon(faCheckCircle);

const CompleteButton = ({
    isComplete,
    onIsCompleteChange,
    disabled,
}: {
    isComplete: boolean;
    onIsCompleteChange: any;
    disabled?: boolean;
}) => {
    const { t } = useTranslation('assistance');

    return isComplete ? (
        <span className="text-sm font-medium text-primary flex gap-1.5 items-center px-2">
            <CompleteIcon className="text-success" />
            {t('itemsView.actions.sectionCompleted')}
        </span>
    ) : (
        <AssistanceSection.IconButton
            className="bg-brand-default hover:bg-brand-hover active:bg-brand-active text-inverted hover:text-inverted disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => onIsCompleteChange(true)}
            disabled={disabled}
        >
            <CompleteIcon />
        </AssistanceSection.IconButton>
    );
};

export default CompleteButton;
