import { StringField, useForm } from '../../../../assistance/containers/Fields';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../../../../core/containers/ConfirmModal';
import classnames from '../../../../core_updated/utils/classnames';
import * as React from 'react';

const AddChannelModal = (props) => {
    const { className, children, onConfirm, onCancel, initialValue, ...modalProps } = props;

    const { getFieldProps, formData } = useForm({ initialData: { name: initialValue } });
    const { t } = useTranslation('assistance');
    const handleConfirm = () => onConfirm(formData);

    return (
        <ConfirmModal
            className={classnames('add-channel-modal', className)}
            title={t('overview.addChannelModal.title')}
            onConfirm={handleConfirm}
            onCancel={onCancel}
            labelConfirm={t('overview.addChannelModal.confirm')}
            labelCancel={t('overview.addChannelModal.cancel')}
            noHeader={false}
            {...modalProps}
        >
            <div className="form">
                <StringField
                    {...getFieldProps('name')}
                    label={t('overview.addChannelModal.name')}
                    className="field--vertical"
                    initialValue={initialValue}
                />
            </div>
        </ConfirmModal>
    );
};

export default AddChannelModal;
