import { Command as CommandPrimitive } from 'cmdk';
import * as React from 'react';
import { type KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from '../../core_updated/utils/classnames';
import { withIcon } from '../../core_updated/components/Icon';
import { faCheck, faChevronDown, faEllipsis, faUser } from '@fortawesome/pro-regular-svg-icons';

export type Option = Record<'value' | 'label', string> & Record<string, string>;

type SupportUserSelectProps = {
    options: Option[];
    value?: string;
    onValueChange?: (value: string) => void;
    onSelect?: (event: any, option: Option) => void;
    isLoading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    forceSelection?: boolean;
};

const UserIcon = withIcon(faUser);
const CheckIcon = withIcon(faCheck);
const ChevronDownIcon = withIcon(faChevronDown);
const EllipsisIcon = withIcon(faEllipsis);

const CommandInput = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Input>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Input
        ref={ref}
        className={classnames(
            'flex flex-1 rounded px-8 py-1 transition-colors bg-transparent hover:bg-decorative-default focus:bg-primary focus:text-primary text-sm text-inverted font-medium',
            className
        )}
        cmdk-input-wrapper=""
        {...props}
        onFocus={(event) => {
            event.target.select();
            props.onFocus?.(event);
        }}
    />
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.List
        ref={ref}
        className={classnames('max-h-[300px] overflow-y-auto overflow-x-hidden', className)}
        {...props}
    />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandGroup = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Group>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Group
        ref={ref}
        className={classnames(
            'overflow-hidden p-1 text-slate-950 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-slate-500 dark:text-slate-50 dark:[&_[cmdk-group-heading]]:text-slate-400',
            className
        )}
        {...props}
    />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandItem = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Item
        ref={ref}
        className={classnames(
            'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none hover:bg-secondary focus:bg-secondary aria-selected:bg-secondary aria-selected:text-neutral-900 focus:text-accent-foreground data-[disabled="true"]:pointer-events-none data-[disabled="true"]:opacity-50',
            className
        )}
        {...props}
    />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const SupportUserSelect = ({
    options,
    placeholder,
    value,
    onValueChange,
    onSelect,
    disabled,
    isLoading = false,
    forceSelection = true,
}: SupportUserSelectProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const [isOpen, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string>(value || '');
    const [inputValue, setInputValue] = useState<string>(value || '');

    useEffect(() => {
        setInputValue(value || '');
        setSelectedValue(value || '');
    }, [value]);

    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLDivElement>) => {
            const input = inputRef.current;

            if (!input) {
                return;
            }

            // Keep the options displayed when the user is typing
            if (!isOpen) {
                setOpen(true);
            }

            // This is not a default behaviour of the <input /> field
            if (event.key === 'Enter' && input.value !== '') {
                const optionToSelect = options.find((option) => option.label === input.value);
                if (optionToSelect) {
                    const selectEvent = new Event('select', { cancelable: true });
                    input.dispatchEvent(selectEvent);
                    onSelect?.(selectEvent, optionToSelect);

                    if (!selectEvent.defaultPrevented) {
                        setSelectedValue(optionToSelect.value);
                        onValueChange?.(optionToSelect.value);
                    }
                }
            }

            if (event.key === 'Escape') {
                input.blur();
                event.preventDefault();
                event.stopPropagation();
            }
        },
        [isOpen, options, onValueChange]
    );

    const handleBlur = useCallback(() => {
        setOpen(false);
        if (forceSelection) setInputValue(value || '');
    }, [value]);

    const handleSelectOption = useCallback(
        (selectedOption: Option) => {
            const input = inputRef.current;

            if (!input) {
                return;
            }

            const selectEvent = new Event('select', { cancelable: true });
            input.dispatchEvent(selectEvent);
            onSelect?.(selectEvent, selectedOption);

            if (!selectEvent.defaultPrevented) {
                setInputValue(selectedOption.label);
                setSelectedValue(selectedOption.value);

                onValueChange?.(selectedOption.value);
            }

            // This is a hack to prevent the input from being focused after the user selects an option
            // We can call this hack: "The next tick"
            setTimeout(() => {
                inputRef?.current?.blur();
            }, 0);
        },
        [onValueChange, onSelect, inputRef]
    );

    const handleValueChange = useCallback(
        (value: string) => {
            setInputValue(value);

            const optionToSelect = options.find((option) => option.label === value || option.value === value);
            if (optionToSelect) {
                setSelectedValue(optionToSelect?.value || '');
            }

            if (!forceSelection) onValueChange?.(value);
        },
        [onValueChange, options]
    );

    const labelByValue = Object.fromEntries(
        options.map((option: any) => [option.value.toLowerCase(), option.label.toLowerCase()])
    );

    const filterOption = (value: string, search: string) => {
        const label = labelByValue[value.toLowerCase()];
        const isMatch =
            value.toLowerCase().includes(search.toLowerCase()) || label?.toLowerCase().includes(search.toLowerCase());

        // TODO: replace with similarity score
        return isMatch ? 1 : 0;
    };

    return (
        <CommandPrimitive onKeyDown={handleKeyDown} filter={filterOption}>
            <div className="relative flex group">
                <UserIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-inverted text-xs z-20 pointer-events-none group-focus-within:text-primary" />
                <span className="flex-1 block px-8 py-1 text-sm font-medium min-w-0 group-focus-within:min-w-[280px] transition-all max-w-[280px] overflow-hidden invisible whitespace-pre">
                    {inputValue || '.'}
                </span>
                <CommandInput
                    ref={inputRef}
                    value={inputValue}
                    onValueChange={handleValueChange}
                    onBlur={handleBlur}
                    onFocus={() => setOpen(true)}
                    placeholder={placeholder}
                    disabled={disabled}
                    className="absolute inset-0 w-full z-10 focus:border-inverted focus:border focus:border-solid focus:-mt-px group-focus-within:-ml-px"
                />
                <ChevronDownIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-inverted text-xs z-20 pointer-events-none group-focus-within:text-primary" />
            </div>

            <div className="relative z-50">
                {isOpen ? (
                    <div className="absolute top-1 z-50 min-w-full max-h-96 rounded-md border bg-primary text-popover-foreground shadow-md outline-none animate-in fade-in-0 zoom-in-95">
                        <CommandList className="ring-1 ring-slate-200 rounded-lg">
                            {isLoading ? (
                                <CommandPrimitive.Loading>
                                    <div className="p-6 text-center">
                                        <FontAwesomeIcon
                                            icon="circle-notch"
                                            spin
                                            className="text-2xl text-neutral-300"
                                        />
                                    </div>
                                </CommandPrimitive.Loading>
                            ) : null}
                            {options.length > 0 && !isLoading ? (
                                <CommandGroup>
                                    {options.map((option) => {
                                        const isSelected = selectedValue === option.value;
                                        return (
                                            <CommandItem
                                                key={option.value}
                                                value={option.value}
                                                onMouseDown={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                }}
                                                onSelect={(e) => handleSelectOption(option)}
                                                className={classnames(
                                                    'flex items-center gap-2 w-full',
                                                    !isSelected ? 'pl-8' : null
                                                )}
                                            >
                                                <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                                                    {isSelected ? <CheckIcon className="h-3 w-3" /> : null}
                                                </span>

                                                {option.label === option.value ? (
                                                    <span>{option.label}</span>
                                                ) : (
                                                    <span>
                                                        <span>{option.label}</span>
                                                        <span className="block text-xs text-neutral-400">
                                                            {option.value}
                                                        </span>
                                                    </span>
                                                )}
                                            </CommandItem>
                                        );
                                    })}
                                </CommandGroup>
                            ) : null}
                            {!isLoading ? (
                                <CommandPrimitive.Empty>
                                    <div className="p-3 text-center">
                                        <EllipsisIcon className="text-sm text-tertiary" />
                                    </div>
                                </CommandPrimitive.Empty>
                            ) : null}
                        </CommandList>
                    </div>
                ) : null}
            </div>
        </CommandPrimitive>
    );
};

export default SupportUserSelect;
