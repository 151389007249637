import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Logo from '../../../core/components/Logo';
import Card from '../../../core/components/Card';
import Input from '../../../core/components/Input';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import ProgressButton from '../../../core/containers/ProgressButton';
import LoginWrapper from '../../components/LoginWrapper';

import { RESET_PASSWORD } from '../../queries';
import { LOGIN_PATH } from '../../constants';
import { url } from '../../../core/utils/link';
import { StringField } from '../../../assistance/containers/Fields';

const ResetPassword = (props) => {
    const { loggedIn, user } = props;

    const { t } = useTranslation('user');
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn && user) {
            navigate(LOGIN_PATH);
        }
    }, [user, loggedIn]);

    const { userId, token } = useParams();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD);

    const handleSubmit = () => {
        return resetPassword({
            variables: {
                uid: userId,
                token,
                newPassword1: password,
                newPassword2: confirmPassword,
            },
        })
            .then((mutationResult: any) => {
                navigate(LOGIN_PATH);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <LoginWrapper>
            <div className="login__logo-wrapper">
                <Link to={url(LOGIN_PATH)}>
                    <Logo className="login__logo" />
                </Link>
            </div>

            <Card level={3} className="login__card login__card--wide">
                <h1 className="login__title">{t('passwordReset.headline')}</h1>

                <div className="login__form">
                    {error && (
                        <Alert className="alert--no-margin" severity="error">
                            <AlertTitle>{error?.message}</AlertTitle>
                        </Alert>
                    )}

                    <div className="login__input-group">
                        <StringField
                            label={t('profile.changePassword.newPasswordLabel')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="field--vertical"
                            inputProps={{
                                placeholder: t('profile.changePassword.newPasswordPlaceholder'),
                                type: 'password',
                                name: 'password',
                            }}
                        />
                    </div>

                    <div className="login__input-group">
                        <StringField
                            label={t('profile.changePassword.confirmPasswordLabel')}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="field--vertical"
                            inputProps={{
                                placeholder: t('profile.changePassword.confirmPasswordPlaceholder'),
                                type: 'password',
                                name: 'password',
                            }}
                        />
                    </div>

                    <ProgressButton
                        label={t('passwordReset.send')}
                        disabled={!password || password != confirmPassword}
                        onClick={handleSubmit}
                    />
                </div>

                <div className="login__below-card">
                    <Link to={LOGIN_PATH}>{t('forgotPassword.back')}</Link>
                </div>
            </Card>
        </LoginWrapper>
    );
};

export default ResetPassword;
