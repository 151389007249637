import * as React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconDefinition, IconLookup } from '@fortawesome/fontawesome-common-types';

const Icon = ({ icon, ...props }: { icon: IconDefinition | IconLookup } & Omit<FontAwesomeIconProps, 'icon'>) => {
    // force IconLookup as by default those icons are IconDefinition
    return <FontAwesomeIcon icon={icon as IconLookup} {...props} />;
};

export const withIcon = (icon: IconDefinition | IconLookup) => (props: Omit<FontAwesomeIconProps, 'icon'>) => (
    <Icon icon={icon} {...props} />
);

export default Icon;
