import * as React from 'react';

export const WorkistIcon = ({ className }: any) => (
    <svg
        width="121px"
        height="106px"
        viewBox="0 0 121 106"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={className}
    >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="workist_logo" fill="currentColor" fillRule="nonzero">
                <g id="Group">
                    <path
                        d="M119.92,50.31 L106.21,26.56 C106.21,26.56 106.2,26.55 106.2,26.55 L92.5,2.81 C91.12,0.42 88.06,-0.4 85.67,0.98 C83.28,2.36 82.46,5.42 83.84,7.81 L96.11,29.06 L83.84,50.31 C82.46,52.7 83.28,55.76 85.67,57.14 C86.46,57.59 87.32,57.81 88.16,57.81 C89.89,57.81 91.57,56.91 92.5,55.31 L101.88,39.06 L109.82,52.81 L97.55,74.06 L85.28,95.31 L63.63,95.31 L51.36,74.06 C49.98,71.67 46.92,70.85 44.53,72.23 C42.14,73.61 41.32,76.67 42.7,79.06 L56.41,102.81 C56.45,102.88 56.49,102.93 56.53,102.99 C56.57,103.06 56.62,103.13 56.67,103.2 C56.83,103.42 56.99,103.63 57.18,103.82 C57.19,103.83 57.2,103.84 57.21,103.86 C57.42,104.07 57.64,104.25 57.88,104.41 C57.94,104.45 57.99,104.48 58.05,104.52 C58.26,104.66 58.48,104.78 58.71,104.88 C58.75,104.9 58.79,104.92 58.83,104.94 C59.09,105.05 59.37,105.13 59.64,105.19 C59.71,105.21 59.78,105.22 59.85,105.23 C60.14,105.28 60.44,105.32 60.75,105.32 C60.75,105.32 60.76,105.32 60.76,105.32 L88.15,105.32 C88.16,105.32 88.16,105.32 88.16,105.32 C88.46,105.32 88.76,105.28 89.06,105.23 C89.13,105.22 89.2,105.2 89.27,105.19 C89.55,105.13 89.82,105.05 90.09,104.94 C90.13,104.92 90.16,104.9 90.2,104.89 C90.43,104.79 90.66,104.66 90.87,104.52 C90.92,104.49 90.97,104.45 91.03,104.42 C91.27,104.25 91.5,104.07 91.71,103.86 C91.71,103.86 91.72,103.85 91.72,103.85 C91.91,103.65 92.09,103.44 92.25,103.21 C92.29,103.15 92.33,103.09 92.37,103.03 C92.41,102.96 92.46,102.9 92.5,102.83 L106.21,79.08 L119.92,55.33 C120.81,53.76 120.81,51.85 119.92,50.31 Z"
                        id="Path"
                    ></path>
                    <path
                        d="M66.51,52.81 L78.78,31.56 C79.67,30.01 79.67,28.11 78.78,26.56 L65.07,2.81 C65.04,2.76 65,2.73 64.98,2.68 C64.88,2.52 64.77,2.38 64.66,2.24 C64.57,2.12 64.48,2 64.37,1.89 C64.26,1.77 64.13,1.66 64.01,1.55 C63.89,1.45 63.77,1.34 63.64,1.25 C63.51,1.15 63.36,1.07 63.22,0.99 C63.09,0.91 62.95,0.83 62.81,0.77 C62.66,0.7 62.5,0.65 62.34,0.6 C62.19,0.55 62.04,0.5 61.89,0.46 C61.74,0.42 61.58,0.4 61.43,0.38 C61.26,0.36 61.08,0.33 60.9,0.33 C60.84,0.33 60.79,0.31 60.73,0.31 L33.32,0.31 C33.27,0.31 33.22,0.32 33.16,0.33 C32.97,0.34 32.79,0.36 32.61,0.38 C32.46,0.4 32.31,0.42 32.17,0.45 C32.01,0.49 31.85,0.54 31.69,0.6 C31.54,0.65 31.39,0.7 31.25,0.77 C31.1,0.84 30.96,0.92 30.82,1 C30.68,1.08 30.55,1.15 30.42,1.24 C30.29,1.34 30.16,1.45 30.04,1.55 C29.92,1.65 29.8,1.76 29.69,1.87 C29.59,1.98 29.49,2.1 29.4,2.23 C29.29,2.37 29.18,2.51 29.08,2.67 C29.05,2.72 29.01,2.75 28.99,2.8 L15.29,26.54 C15.29,26.54 15.28,26.55 15.28,26.55 L1.56,50.31 C0.67,51.86 0.67,53.76 1.56,55.31 L15.27,79.06 L28.98,102.81 C29.91,104.41 31.59,105.31 33.31,105.31 C34.16,105.31 35.02,105.09 35.8,104.64 C38.19,103.26 39.01,100.2 37.63,97.81 L23.92,74.06 L11.67,52.81 L22.5,34.06 L47.04,34.06 C49.8,34.06 52.04,31.82 52.04,29.06 C52.04,26.3 49.8,24.06 47.04,24.06 L28.27,24.06 L36.21,10.31 L57.86,10.31 L68.69,29.06 L57.86,47.81 L33.32,47.81 C30.56,47.81 28.32,50.05 28.32,52.81 C28.32,55.57 30.56,57.81 33.32,57.81 L57.86,57.81 L70.13,79.06 C71.06,80.66 72.74,81.56 74.46,81.56 C75.31,81.56 76.17,81.34 76.96,80.89 C79.35,79.51 80.17,76.45 78.79,74.06 L66.51,52.81 Z"
                        id="Path"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

export const WorkistLogo = ({ className }: any) => (
    <svg
        width="441px"
        height="106px"
        viewBox="0 0 441 106"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={className}
    >
        <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group" fill="currentColor" fillRule="nonzero">
                <path
                    d="M119.92,50.31 L106.21,26.56 C106.21,26.56 106.2,26.55 106.2,26.55 L92.5,2.81 C91.12,0.42 88.06,-0.4 85.67,0.98 C83.28,2.36 82.46,5.42 83.84,7.81 L96.11,29.06 L83.84,50.31 C82.46,52.7 83.28,55.76 85.67,57.14 C86.46,57.59 87.32,57.81 88.16,57.81 C89.89,57.81 91.57,56.91 92.5,55.31 L101.88,39.06 L109.82,52.81 L97.55,74.06 L85.28,95.31 L63.63,95.31 L51.36,74.06 C49.98,71.67 46.92,70.85 44.53,72.23 C42.14,73.61 41.32,76.67 42.7,79.06 L56.41,102.81 C56.45,102.88 56.49,102.93 56.53,102.99 C56.57,103.06 56.62,103.13 56.67,103.2 C56.83,103.42 56.99,103.63 57.18,103.82 C57.19,103.83 57.2,103.84 57.21,103.86 C57.42,104.07 57.64,104.25 57.88,104.41 C57.94,104.45 57.99,104.48 58.05,104.52 C58.26,104.66 58.48,104.78 58.71,104.88 C58.75,104.9 58.79,104.92 58.83,104.94 C59.09,105.05 59.37,105.13 59.64,105.19 C59.71,105.21 59.78,105.22 59.85,105.23 C60.14,105.28 60.44,105.32 60.75,105.32 C60.75,105.32 60.76,105.32 60.76,105.32 L88.15,105.32 C88.16,105.32 88.16,105.32 88.16,105.32 C88.46,105.32 88.76,105.28 89.06,105.23 C89.13,105.22 89.2,105.2 89.27,105.19 C89.55,105.13 89.82,105.05 90.09,104.94 C90.13,104.92 90.16,104.9 90.2,104.89 C90.43,104.79 90.66,104.66 90.87,104.52 C90.92,104.49 90.97,104.45 91.03,104.42 C91.27,104.25 91.5,104.07 91.71,103.86 C91.71,103.86 91.72,103.85 91.72,103.85 C91.91,103.65 92.09,103.44 92.25,103.21 C92.29,103.15 92.33,103.09 92.37,103.03 C92.41,102.96 92.46,102.9 92.5,102.83 L106.21,79.08 L119.92,55.33 C120.81,53.76 120.81,51.85 119.92,50.31 Z"
                    id="Path"
                ></path>
                <path
                    d="M66.51,52.81 L78.78,31.56 C79.67,30.01 79.67,28.11 78.78,26.56 L65.07,2.81 C65.04,2.76 65,2.73 64.98,2.68 C64.88,2.52 64.77,2.38 64.66,2.24 C64.57,2.12 64.48,2 64.37,1.89 C64.26,1.77 64.13,1.66 64.01,1.55 C63.89,1.45 63.77,1.34 63.64,1.25 C63.51,1.15 63.36,1.07 63.22,0.99 C63.09,0.91 62.95,0.83 62.81,0.77 C62.66,0.7 62.5,0.65 62.34,0.6 C62.19,0.55 62.04,0.5 61.89,0.46 C61.74,0.42 61.58,0.4 61.43,0.38 C61.26,0.36 61.08,0.33 60.9,0.33 C60.84,0.33 60.79,0.31 60.73,0.31 L33.32,0.31 C33.27,0.31 33.22,0.32 33.16,0.33 C32.97,0.34 32.79,0.36 32.61,0.38 C32.46,0.4 32.31,0.42 32.17,0.45 C32.01,0.49 31.85,0.54 31.69,0.6 C31.54,0.65 31.39,0.7 31.25,0.77 C31.1,0.84 30.96,0.92 30.82,1 C30.68,1.08 30.55,1.15 30.42,1.24 C30.29,1.34 30.16,1.45 30.04,1.55 C29.92,1.65 29.8,1.76 29.69,1.87 C29.59,1.98 29.49,2.1 29.4,2.23 C29.29,2.37 29.18,2.51 29.08,2.67 C29.05,2.72 29.01,2.75 28.99,2.8 L15.29,26.54 C15.29,26.54 15.28,26.55 15.28,26.55 L1.56,50.31 C0.67,51.86 0.67,53.76 1.56,55.31 L15.27,79.06 L28.98,102.81 C29.91,104.41 31.59,105.31 33.31,105.31 C34.16,105.31 35.02,105.09 35.8,104.64 C38.19,103.26 39.01,100.2 37.63,97.81 L23.92,74.06 L11.67,52.81 L22.5,34.06 L47.04,34.06 C49.8,34.06 52.04,31.82 52.04,29.06 C52.04,26.3 49.8,24.06 47.04,24.06 L28.27,24.06 L36.21,10.31 L57.86,10.31 L68.69,29.06 L57.86,47.81 L33.32,47.81 C30.56,47.81 28.32,50.05 28.32,52.81 C28.32,55.57 30.56,57.81 33.32,57.81 L57.86,57.81 L70.13,79.06 C71.06,80.66 72.74,81.56 74.46,81.56 C75.31,81.56 76.17,81.34 76.96,80.89 C79.35,79.51 80.17,76.45 78.79,74.06 L66.51,52.81 Z"
                    id="Path"
                ></path>
            </g>
            <g id="Group" transform="translate(164.000000, 23.000000)" fill="currentColor" fillRule="nonzero">
                <path
                    d="M54.41,17.32 C51.86,17.32 50.26,18.37 49.6,20.47 L40.7,44.34 L34.32,27.03 C33.93,26.03 33.42,25.27 32.78,24.75 C32.14,24.22 31.22,23.96 30,23.96 C27.84,23.96 26.32,24.98 25.44,27.03 L18.89,44.8 L9.92,20.47 C9.59,19.36 9.01,18.56 8.18,18.06 C7.35,17.56 6.41,17.31 5.36,17.31 C3.81,17.31 2.59,17.84 1.71,18.89 C0.82,19.94 0.38,21.13 0.38,22.46 C0.38,23.12 0.49,23.76 0.71,24.37 L13.41,58.57 C13.8,59.68 14.5,60.52 15.53,61.1 C16.55,61.68 17.59,61.89 18.64,61.72 C20.52,61.61 21.93,60.56 22.87,58.57 L29.56,38.15 L36.65,58.57 C37.59,60.56 39,61.61 40.88,61.72 C41.99,61.89 43.04,61.68 44.03,61.1 C45.02,60.52 45.72,59.68 46.11,58.57 L58.81,24.37 C58.92,24.09 59,23.8 59.06,23.5 C59.11,23.2 59.14,22.85 59.14,22.46 C59.14,20.97 58.67,19.73 57.73,18.77 C56.79,17.81 55.69,17.32 54.41,17.32 Z"
                    id="Path"
                ></path>
                <path
                    d="M96.87,19.44 C93.47,17.48 89.66,16.49 85.46,16.49 C81.25,16.49 77.45,17.47 74.05,19.44 C70.65,21.4 67.94,24.12 65.92,27.57 C63.9,31.03 62.89,35.03 62.89,39.56 C62.89,44.1 63.9,48.1 65.92,51.55 C67.94,55.01 70.65,57.71 74.05,59.64 C77.45,61.58 81.26,62.55 85.46,62.55 C89.66,62.55 93.47,61.58 96.87,59.64 C100.27,57.7 102.98,55.01 105,51.55 C107.02,48.09 108.03,44.09 108.03,39.56 C108.03,35.02 107.02,31.03 105,27.57 C102.98,24.12 100.27,21.4 96.87,19.44 Z M96.37,46.83 C95.24,48.9 93.71,50.52 91.81,51.69 C89.9,52.85 87.78,53.43 85.46,53.43 C83.14,53.43 81.02,52.85 79.11,51.69 C77.2,50.53 75.68,48.91 74.55,46.83 C73.42,44.75 72.85,42.33 72.85,39.57 C72.85,36.75 73.42,34.3 74.55,32.22 C75.68,30.14 77.21,28.53 79.11,27.36 C81.02,26.2 83.14,25.62 85.46,25.62 C87.78,25.62 89.9,26.2 91.81,27.36 C93.72,28.52 95.24,30.14 96.37,32.22 C97.5,34.29 98.07,36.74 98.07,39.57 C98.07,42.33 97.5,44.75 96.37,46.83 Z"
                    id="Shape"
                ></path>
                <path
                    d="M141.94,17.9 C140.81,16.96 139.41,16.49 137.75,16.49 C136.37,16.49 134.98,16.66 133.6,16.99 C132.22,17.32 130.92,17.83 129.7,18.53 C128.48,19.22 127.39,20.08 126.42,21.1 C125.73,21.83 125.14,22.63 124.64,23.51 L124.64,22.39 C124.64,20.95 124.17,19.75 123.23,18.78 C122.29,17.81 121.1,17.33 119.66,17.33 C118.22,17.33 117.03,17.81 116.09,18.78 C115.15,19.75 114.68,20.95 114.68,22.39 L114.68,56.67 C114.68,58.11 115.15,59.31 116.09,60.28 C117.03,61.25 118.22,61.73 119.66,61.73 C121.1,61.73 122.29,61.25 123.23,60.28 C124.17,59.31 124.64,58.11 124.64,56.67 L124.64,37 C124.64,35.01 124.9,33.28 125.43,31.81 C125.96,30.34 126.65,29.11 127.51,28.12 C128.37,27.12 129.31,26.39 130.33,25.92 C131.35,25.45 132.36,25.21 133.36,25.21 C134.47,25.21 135.45,25.4 136.31,25.79 C137.17,26.18 138.12,26.37 139.17,26.37 C140.28,26.37 141.3,25.97 142.24,25.17 C143.18,24.37 143.65,23.05 143.65,21.23 C143.64,19.95 143.07,18.85 141.94,17.9 Z"
                    id="Path"
                ></path>
                <path
                    d="M171.46,37.22 L183.97,25.71 C185.3,24.55 185.96,23.3 185.96,21.98 C185.96,20.98 185.49,19.96 184.55,18.91 C183.61,17.86 182.5,17.33 181.23,17.33 C179.85,17.33 178.63,17.86 177.58,18.91 L159.82,36.05 L159.82,5.37 C159.82,3.93 159.35,2.73 158.41,1.76 C157.47,0.79 156.28,0.31 154.84,0.31 C153.4,0.31 152.21,0.79 151.27,1.76 C150.33,2.73 149.86,3.93 149.86,5.37 L149.86,56.66 C149.86,58.1 150.33,59.3 151.27,60.27 C152.21,61.24 153.4,61.72 154.84,61.72 C156.28,61.72 157.47,61.24 158.41,60.27 C159.35,59.3 159.82,58.1 159.82,56.66 L159.82,47.93 L163.9,44.17 L178.82,59.98 C179.82,61.09 181.03,61.64 182.47,61.64 C183.58,61.64 184.7,61.18 185.83,60.27 C186.96,59.36 187.53,58.18 187.53,56.74 C187.53,55.36 187,54.11 185.95,53.01 L171.46,37.22 Z"
                    id="Path"
                ></path>
                <path
                    d="M199,2.05 C197.23,2.05 195.92,2.34 195.06,2.92 C194.2,3.5 193.77,4.59 193.77,6.2 L193.77,7.78 C193.77,9.33 194.16,10.41 194.93,11.02 C195.7,11.63 197.03,11.93 198.91,11.93 C200.79,11.93 202.15,11.64 202.98,11.06 C203.81,10.48 204.23,9.39 204.23,7.78 L204.23,6.2 C204.23,4.65 203.84,3.57 203.07,2.96 C202.29,2.36 200.93,2.05 199,2.05 Z"
                    id="Path"
                ></path>
                <path
                    d="M199,17.32 C197.56,17.32 196.37,17.8 195.43,18.77 C194.49,19.74 194.02,20.94 194.02,22.38 L194.02,56.66 C194.02,58.1 194.49,59.3 195.43,60.27 C196.37,61.24 197.56,61.72 199,61.72 C200.44,61.72 201.63,61.24 202.57,60.27 C203.51,59.3 203.98,58.1 203.98,56.66 L203.98,22.39 C203.98,20.95 203.51,19.75 202.57,18.78 C201.63,17.81 200.44,17.32 199,17.32 Z"
                    id="Path"
                ></path>
                <path
                    d="M240.25,39.4 C238.81,38.4 237.23,37.6 235.52,36.99 C233.8,36.38 232.12,35.86 230.46,35.41 C228.85,34.97 227.35,34.51 225.94,34.04 C224.53,33.57 223.41,32.96 222.58,32.21 C221.75,31.46 221.33,30.45 221.33,29.18 C221.33,27.8 221.69,26.79 222.41,26.15 C223.13,25.51 223.97,25.1 224.94,24.9 C225.91,24.71 226.81,24.61 227.64,24.61 C229.69,24.61 231.5,24.97 233.08,25.69 C234.66,26.41 235.91,27.38 236.86,28.59 C237.58,29.25 238.49,29.63 239.6,29.71 C240.71,29.79 241.68,29.5 242.51,28.84 C243.67,27.9 244.2,26.85 244.09,25.69 C243.98,24.53 243.62,23.53 243.01,22.7 C241.85,21.21 240.41,20 238.69,19.09 C236.97,18.18 235.18,17.51 233.3,17.1 C231.42,16.68 229.62,16.48 227.9,16.48 C225.19,16.48 222.6,17.01 220.14,18.06 C217.68,19.11 215.67,20.61 214.12,22.54 C212.57,24.48 211.8,26.83 211.8,29.59 C211.8,32.03 212.27,34.05 213.21,35.65 C214.15,37.26 215.37,38.54 216.86,39.51 C218.35,40.48 220,41.27 221.8,41.88 C223.6,42.49 225.35,43.04 227.07,43.54 C228.73,43.98 230.24,44.45 231.59,44.95 C232.95,45.45 234.04,46.06 234.87,46.78 C235.7,47.5 236.12,48.47 236.12,49.68 C236.12,51.12 235.75,52.18 235,52.88 C234.25,53.57 233.38,54.01 232.39,54.21 C231.39,54.4 230.48,54.53 229.65,54.58 C226.99,54.58 224.78,54.14 223.01,53.25 C221.24,52.37 219.55,51.12 217.95,49.51 C217.01,48.57 216.04,48.06 215.04,47.97 C214.04,47.88 213.1,48.12 212.22,48.68 C210.84,49.57 210.12,50.62 210.06,51.83 C210,53.05 210.25,54.04 210.81,54.82 C212.97,57.7 215.68,59.7 218.94,60.84 C222.2,61.97 225.47,62.54 228.73,62.54 C231.33,62.54 233.88,62.03 236.37,61 C238.86,59.98 240.94,58.47 242.6,56.48 C244.26,54.49 245.09,52.08 245.09,49.26 C245.09,46.83 244.65,44.82 243.76,43.24 C242.85,41.68 241.69,40.4 240.25,39.4 Z"
                    id="Path"
                ></path>
                <path
                    d="M273.95,52.35 C273.23,52.35 272.59,52.47 272.04,52.72 C271.49,52.97 270.88,53.09 270.21,53.09 C269.6,53.09 269.03,52.98 268.51,52.76 C267.98,52.54 267.57,52.18 267.27,51.68 C266.96,51.18 266.81,50.55 266.81,49.77 L266.81,27.28 L272.7,27.28 C274.03,27.28 275.13,26.85 276.02,25.99 C276.9,25.13 277.35,24.07 277.35,22.79 C277.35,21.46 276.91,20.36 276.02,19.47 C275.13,18.59 274.03,18.14 272.7,18.14 L266.81,18.14 L266.81,12.83 C266.81,11.39 266.35,10.19 265.44,9.22 C264.53,8.25 263.35,7.77 261.91,7.77 C260.47,7.77 259.27,8.26 258.3,9.22 C257.33,10.19 256.85,11.39 256.85,12.83 L256.85,18.14 L253.03,18.14 C251.7,18.14 250.59,18.57 249.71,19.43 C248.82,20.29 248.38,21.35 248.38,22.63 C248.38,23.96 248.82,25.07 249.71,25.95 C250.59,26.84 251.7,27.28 253.03,27.28 L256.85,27.28 L256.85,50.19 C256.85,52.9 257.27,55.03 258.1,56.58 C258.93,58.13 259.97,59.28 261.21,60.02 C262.45,60.76 263.78,61.24 265.19,61.43 C266.6,61.62 267.89,61.72 269.05,61.72 C270.99,61.72 272.8,61.22 274.49,60.23 C276.18,59.23 277.02,57.96 277.02,56.41 C277.02,55.14 276.7,54.14 276.07,53.42 C275.43,52.71 274.72,52.35 273.95,52.35 Z"
                    id="Path"
                ></path>
            </g>
        </g>
    </svg>
);
