import * as React from 'react';

import Welcome from './pages/Welcome';
import { WELCOME_PATH } from './constants';
import MainShell from '../core/shells/MainShell';

export default [
    {
        path: WELCOME_PATH,
        component: (props) => (
            <MainShell
                documentType={null}
                activeNavTab={null}
                channelPath={null}
                overviewPath={null}
                hasChannelContext={false}
                title={' ' /* empty for spacing */}
                {...props}
            >
                <Welcome {...props} />
            </MainShell>
        ),
    },
];
