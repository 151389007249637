import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { StringField, useForm } from '../../../assistance/containers/Fields';
import ConfirmModal from '../../../core/containers/ConfirmModal';

const CustomizationConfigModal = (props) => {
    const { config, className, customer, onSubmit, onCancel, ...modalProps } = props;

    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`overview.customizations.${fieldName}`),
    });

    useEffect(() => {
        setFormData({
            customizationKey: config?.customizationKey || '',
        });
    }, [config]);

    const { t } = useTranslation('customer');

    return (
        <ConfirmModal onConfirm={() => onSubmit(formData)} onCancel={onCancel} {...modalProps}>
            <StringField {...getFieldProps('customizationKey')} className="field--vertical" />
        </ConfirmModal>
    );
};

export default CustomizationConfigModal;
