import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import UserForm from '../../containers/UserForm';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';
import Loader from '../../../core/components/Loader';

import { OVERVIEW_PATH } from '../../constants';
import { GET_USER, UPDATE_USER } from '../../queries';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const Edit = (props) => {
    const { t } = useTranslation('users');
    const navigate = useNavigate();
    const { userId } = useParams();

    const { data, error, loading, refetch } = useQuery(GET_USER, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            id: userId,
        },
    });

    const [updateUser, { loading: updateUserLoading, error: updateUserError }] = useMutation(UPDATE_USER);

    const handleSubmit = (formData) => {
        return updateUser({
            variables: {
                id: userId,
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                groups: formData.roles,
                teams: formData.teams,
                language: formData.language,
            },
        })
            .then(() => {
                navigate(url(OVERVIEW_PATH));
                window.location.reload();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Layout>
            <Page className="edit-user page--slim">
                <div className="edit-user__head">
                    <Breadcrumbs>
                        <Breadcrumb label={t('breadcrumbs.users')} linkTo={url(OVERVIEW_PATH)} />
                    </Breadcrumbs>
                    <div className="edit-user__title">{t('edit.title')}</div>
                </div>

                {error && (
                    <Alert severity="error">
                        <AlertTitle>{error?.message}</AlertTitle>
                    </Alert>
                )}

                {updateUserError && (
                    <Alert severity="error">
                        <AlertTitle>{updateUserError?.message}</AlertTitle>
                    </Alert>
                )}

                {loading ? (
                    <Loader />
                ) : (
                    <UserForm
                        user={data?.user}
                        className="edit-user__form"
                        submitLabel={t('edit.submitLabel')}
                        onSubmit={handleSubmit}
                    />
                )}
            </Page>
        </Layout>
    );
};

export default Edit;
