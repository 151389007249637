import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { canManageUsers } from '../../../users/utils';
import Page from '../../../core/components/Page';
import MenuItem from '../../../core/components/MenuItem';
import PaginatedList from '../../../core/containers/PaginatedList';
import ListItem from '../../../core/components/ListItem';
import UserListItem from '../../../users/components/UserListItem';
import Loader from '../../../core/components/Loader';
import { SecondaryButton } from '../../../core/components/Button';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import ListControl, { PAGE_PARAM, useListControl } from '../../../core/containers/ListControl';
import { url } from '../../../core/utils/link';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import Tooltip from '../../../core/components/Tooltip';

import { DELETE_TEAM, GET_TEAM } from '../../queries';
import { GET_ROLES, GET_USERS } from '../../../users/queries';
import { EDIT_PATH, OVERVIEW_PATH } from '../../constants';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const ITEMS_PER_PAGE = 10;

const Detail = (props) => {
    const { user: viewer } = props;
    const { t } = useTranslation('teams');
    const navigate = useNavigate();
    const location = useLocation();
    const { teamId } = useParams();
    const searchParams = new URLSearchParams(location.search);

    const [activePage, setActivePage] = useState(parseInt(searchParams.get(PAGE_PARAM)) || 0);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const prevSearchParams = searchParams.toString();

        if (activePage) {
            searchParams.set(PAGE_PARAM, activePage.toString());
        } else {
            searchParams.delete(PAGE_PARAM);
        }

        if (prevSearchParams != searchParams.toString()) {
            navigate({ pathname: location.pathname, search: '?' + searchParams.toString() });
        }
    }, [activePage]);

    const [roleFilter, setRoleFilter] = useListControl('role', '');
    const [sort, setSort] = useListControl('sort', 'lastName-asc');

    let [sortField, sortOrder] = sort.split('-');

    const { data, error, loading, refetch } = useQuery(GET_TEAM, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: { id: teamId },
    });

    const {
        data: userData,
        error: userError,
        loading: userLoading,
    } = useQuery(GET_USERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            from: activePage * ITEMS_PER_PAGE,
            size: ITEMS_PER_PAGE,
            groupId: roleFilter || undefined,
            teamId: teamId || '',
            sortField,
            sortOrder,
        },
    });

    const {
        data: rolesData,
        error: rolesError,
        loading: rolesLoading,
    } = useQuery(GET_ROLES, {
        notifyOnNetworkStatusChange: true,
    });

    const [isDelete, setIsDelete] = useState(false);
    const [deleteTeam, { loading: deleteTeamLoading, error: deleteTeamError }] = useMutation(DELETE_TEAM);

    const handleDeleteTeam = () => {
        return deleteTeam({
            variables: { id: teamId },
        })
            .then(() => {
                navigate(url(OVERVIEW_PATH));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const users = userData?.users?.users || [];
    const total = userData?.users?.totalCount || 0;

    if (loading) {
        return (
            <Page className="team-detail page--slim">
                <Loader />
            </Page>
        );
    }

    return (
        <Layout>
            <Page className="team-detail page--slim">
                <div className="team-detail__head">
                    <Breadcrumbs>
                        <Breadcrumb label={t('breadcrumbs.teams')} linkTo={url(OVERVIEW_PATH)} />
                    </Breadcrumbs>
                    <div className="team-detail__header">
                        <div className="team-detail__title">{data?.team?.name}</div>
                        <div className="team-detail__buttons">
                            {canManageUsers(viewer) && !data?.team?.isDefault && (
                                <SecondaryButton label={t('detail.edit')} linkTo={url(EDIT_PATH, { teamId })} />
                            )}
                            {canManageUsers(viewer) &&
                                !data?.team?.isDefault &&
                                (data?.team?.isDeletable ? (
                                    <SecondaryButton
                                        label={t('detail.delete')}
                                        className="button--danger"
                                        disabled={!data?.team?.isDeletable}
                                        onClick={() => setIsDelete(true)}
                                    />
                                ) : (
                                    <Tooltip content={t('detail.cannotDelete')} placement="bottom-end">
                                        <SecondaryButton
                                            label={t('detail.delete')}
                                            className="button--danger"
                                            disabled={true}
                                        />
                                    </Tooltip>
                                ))}
                        </div>
                    </div>
                </div>

                {data?.team?.description && <div className="team-detail__description">{data?.team?.description}</div>}

                {error && (
                    <Alert severity="error">
                        <AlertTitle>{error?.message}</AlertTitle>
                    </Alert>
                )}

                <div className="team-detail__controls list-controls">
                    <ListControl value={roleFilter} onChange={setRoleFilter}>
                        <MenuItem value="" label={t('overview.controls.role.all')} />
                        {((!rolesLoading && rolesData?.groups) || []).map((role) => (
                            <MenuItem key={role.id} value={role.id} label={role.name} />
                        ))}
                    </ListControl>

                    <ListControl className="list-control--sort" value={sort} onChange={setSort}>
                        <MenuItem value="firstName-asc" label={t('overview.controls.sort.firstNameAsc')} />
                        <MenuItem value="firstName-desc" label={t('overview.controls.sort.firstNameDesc')} />
                        <MenuItem value="lastName-asc" label={t('overview.controls.sort.lastNameAsc')} />
                        <MenuItem value="lastName-desc" label={t('overview.controls.sort.lastNameDesc')} />
                    </ListControl>
                </div>

                <div className="team-detail__list">
                    {userLoading ? (
                        <Loader />
                    ) : (
                        <PaginatedList
                            itemsPerPage={ITEMS_PER_PAGE}
                            numItems={total}
                            onPageChange={setActivePage}
                            activePage={activePage}
                        >
                            {(users || []).map((user) => {
                                return (
                                    <ListItem key={user.id} attributes={{ roles: user.groups }}>
                                        <UserListItem
                                            id={user.id}
                                            viewerId={viewer?.id}
                                            firstName={user.firstName}
                                            lastName={user.lastName}
                                            email={user.email}
                                            roles={user.groups.map((g) => g.name)}
                                            teams={user.teams?.map((t) => t.name)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </PaginatedList>
                    )}
                </div>
            </Page>
            {isDelete && (
                <ConfirmModal onConfirm={() => handleDeleteTeam()} onCancel={() => setIsDelete(false)}>
                    {t('detail.confirmDelete', { team: data?.team })}
                </ConfirmModal>
            )}
        </Layout>
    );
};

export default Detail;
