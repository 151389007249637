import * as React from 'react';

import {
    fieldConfigToLegacyConfigFormat,
    fieldToFormFieldConfig,
    getInterceptedOnReselect,
} from '../../../generic_document/pages/Assistance/utils';
import { merge } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import { DocumentContext } from '../../../stores';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';

export const documentTypeName = 'order';

const fieldNameToGroupTypeMap = {
    customer: 'CustomerField',
    invoiceAddress: 'AddressField',
    deliveryAddress: 'AddressField',
    customerContact: 'ContactField',
    dispatchContact: 'ContactField',
};

export const getHeaderFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    headerFields,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const configs = {};

    fieldConfigToLegacyConfigFormat(fieldConfigs, configs, client, recordId, documentConfig, fieldNameToGroupTypeMap);

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        customer: {
            groupFieldProps: {
                customerNumber: {
                    hidden: false,
                },
            },
        },
        invoiceAddress: {
            groupFieldProps: {
                addressId2: {
                    hidden: !headerFields.includes('invoiceAddress.addressId2'),
                },
            },
            groupInputProps: {
                addressId2: {
                    required: false,
                },
                misc: {
                    required: false,
                },
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
                name: {
                    required: false,
                },
                companyName: {
                    required: false,
                },
            },
        },
        deliveryAddress: {
            groupFieldProps: {
                addressId2: {
                    // We currently use this catch-all field to differentiate between pickup & delivery addresses
                    // If this becomes a more common use case, we should use a dedicated field instead
                    hidden: !headerFields.includes('deliveryAddress.addressId2'),
                },
            },
            groupInputProps: {
                addressId2: {
                    required: false,
                },
                misc: {
                    required: false,
                },
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
                name: {
                    required: false,
                },
                companyName: {
                    required: false,
                },
            },
        },
        customerContact: {
            groupFieldProps: {
                email: {
                    hidden: !headerFields.includes('customerContact.email'),
                },
                phone: {
                    hidden: !headerFields.includes('customerContact.phone'),
                },
            },
            groupInputProps: {
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
            },
        },
        dispatchContact: {
            groupFieldProps: {
                email: {
                    hidden: !headerFields.includes('dispatchContact.email'),
                },
                phone: {
                    hidden: !headerFields.includes('dispatchContact.phone'),
                },
            },
            groupInputProps: {
                email: {
                    required: false,
                },
                phone: {
                    required: false,
                },
            },
        },
    };

    return merge(legacyConfig, configs);
};

export const getLineItemFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    documentConfig = undefined,
    fieldConfigs = undefined,
    handlers = undefined,
    document = undefined,
    documentConfiguration = undefined
) => {
    const configs = {};

    const onReselect = getInterceptedOnReselect(client, recordId, documentConfiguration?.EXTRACT_TEXT);

    if (fieldConfigs) {
        fieldConfigs.forEach((field) => {
            configs[field.name] = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
        });
    }

    const legacyConfig = {
        articleNumber: {
            onReselect,
        },
        articleNumber2: {
            onReselect,
        },
        articleNumber3: {
            onReselect,
        },
        articlePartitionSpecificNumber: {
            onReselect,
        },
        customer: {
            onReselect,
            valueKey: 'customerNumber',
        },
        quantity: {
            fieldType: 'FloatField',
        },
        convertedQuantity: {
            fieldType: 'FloatField',
        },
        unitPrice: {
            fieldType: 'FloatField',
        },
        totalPrice: {
            fieldType: 'FloatField',
        },
    };

    return merge(legacyConfig, configs);
};
