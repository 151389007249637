import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Option, showAllFilterOption } from '../../core_updated/components/Fields/AutoCompleteField';
import { useExtractText, useLookupOptions, useMasterdataBrowser } from './useLookup';
import useForceSelection from './useForceSelection';
import useReselectEventListener, { ReselectEvent } from '../pages/Assistance/useReselectEventListener';
import { useAssistanceContext } from '../pages/Assistance/AssistanceContext';
import { useControllableState } from '../../core_updated/utils/useControllableState';

export const useForceSelectionLookupProps = ({
    lookupType,
    lookupDefinitionId,
    lookupFieldName,
    partitionId,
    allowInvalidIds,
    value,
    onValueChange,
    onUpdatePayloadChange, // TODO: maybe directly in onSelectOption
    onFocus,
    onBlur,
    isPartitionIdField = false,
    minSearchChars = 0,
    lookupMatchingProps = null,
    initialSkip = true,
    skip: propsSkip,
    onSkipChange,
}: any) => {
    const { t } = useTranslation('assistance');

    const [inputValue, setInputValue] = useState('');
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const notEnoughChars = inputValue.length < minSearchChars;
    const searchTerm = notEnoughChars ? '' : inputValue;

    const [skip, setSkip] = useControllableState(initialSkip, propsSkip, onSkipChange);

    const handleFocus = (e: any) => {
        setSkip(false);
    };

    const handleBlur = (e: any) => {
        setSkip(true);
    };

    const { options, isLoading } = useLookupOptions({
        lookupDefinitionId,
        lookupFieldName,
        partitionId,
        searchTerm,
        extraSearchTerms: lookupMatchingProps?.extraSearchTerms,
        searchLookupOnEmptyValue: lookupMatchingProps?.searchLookupOnEmptyValue,
        skip,
    });

    const forceSelectionOptions = useForceSelection({
        value,
        onValueChange,
        onFocus: onFocus,
        onBlur: onBlur,
        onSelectOption: (option: Option) => {
            onUpdatePayloadChange?.(
                option
                    ? {
                          _lookup_id: option?.id,
                          _lookupEntity: option,
                      }
                    : null
            );
        },
        forceSelection: !allowInvalidIds,
        options,
    });

    const browserOptions = useMasterdataBrowser({
        lookupType,
        lookupDefinitionId,
        lookupFieldName,
        partitionId,
        searchTerm,
        options,
        onSelectOption: forceSelectionOptions.onSelectOption,
        onBlur: forceSelectionOptions.onBlur,
        onFocus: forceSelectionOptions.onFocus,
        isPartitionIdField: isPartitionIdField,
        lookupMatchingProps: lookupMatchingProps,
    });

    return {
        value: forceSelectionOptions.value,
        onValueChange: (value: string) => {
            forceSelectionOptions.onValueChange(value);
            setInputValue(value);
        },
        onFocus: (e: any) => {
            handleFocus(e);
            browserOptions.onFocus(e);
        },
        onBlur: (e: any) => {
            handleBlur(e);
            browserOptions.onBlur();
        },

        onSelectOption: browserOptions.onSelectOption,
        onSelect: browserOptions.onSelect,

        options: browserOptions.options,
        filterOption: showAllFilterOption,

        isLoading,
        emptyMessage: notEnoughChars
            ? t('fields.autocompleteField.notEnoughChars')
            : t('fields.autocompleteField.noResults'),
    };
};

export const useForceSelectionReselectEventListener = (inputRef: any, onValueChange: any) => {
    const { documentConfiguration } = useAssistanceContext();

    const extractText = useExtractText(
        documentConfiguration.EXTRACT_TEXT,
        `${documentConfiguration?.documentTypeName}ExtractText`
    );

    const handleReselect = useCallback(
        (e: ReselectEvent) => {
            extractText(e.detail.bbox, e.detail.pageIndex).then((text) => {
                onValueChange(text);
                // reset focus to input
                inputRef.current?.focus();
                // ... and trigger keydown to start the search again
                inputRef.current?.dispatchEvent(new KeyboardEvent('keydown', { key: '', bubbles: true }));
            });
        },
        [extractText, onValueChange]
    );

    useReselectEventListener(inputRef, handleReselect);
};

export default useForceSelectionLookupProps;
