import * as React from 'react';
import { useTranslation } from 'react-i18next';

import DropDown, { DropDownMenu, DropDownToggle } from '../../../core/containers/DropDown';
import Button from '../../../core/components/Button';
import MenuItem from '../../../core/components/MenuItem';
import { useNavigate } from 'react-router-dom';

import './style.scss';

const AssistanceNextButton = (props) => {
    const { label, disabled, linkTo, onReset, onDiscard, onClick, extraItems = undefined } = props;

    const { t } = useTranslation('assistance');
    const navigate = useNavigate();

    const handleClick = (e) => {
        if (!onClick) return;
        e.preventDefault();

        const result = onClick?.(e) || Promise.resolve();
        result.then(() => {
            if (linkTo) {
                navigate(linkTo);
            }
        });
    };

    return (
        <div className="next-button">
            <Button className="next-button__button" label={label} onClick={handleClick} disabled={disabled} />

            <DropDown menuOrientation="top" className="next-button__drop-down">
                <DropDownToggle className="next-button__drop-down-handler">
                    <Button className="next-button__toggle" />
                </DropDownToggle>

                <DropDownMenu className="next-button__drop-down-menu">
                    <MenuItem label={t('nextButton.resetButton')} onClick={onReset} className="menu-item--danger" />
                    <MenuItem label={t('nextButton.discardButton')} onClick={onDiscard} className="menu-item--danger" />
                    {extraItems}
                </DropDownMenu>
            </DropDown>
        </div>
    );
};

export default AssistanceNextButton;
