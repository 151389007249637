import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

interface IProps {
    className?: string;
    label: string;
    value?: string;
    onClick?: any;
    linkTo?: any;
}

const Breadcrumb = (props: IProps) => {
    const { className, label, onClick, value, linkTo } = props;

    const handleClick = () => {
        if (onClick) onClick(value);
    };

    return (
        <Link className={classnames('breadcrumb', className)} onClick={handleClick} to={linkTo || ''}>
            {label}
        </Link>
    );
};

export default Breadcrumb;
