import { useState } from 'react';

export const useControllableState = (
    initialValue: any,
    controlledValue = undefined,
    setControlledValue = undefined
): [any, any] => {
    const [stateValue, setState] = useState(initialValue);
    const effectiveValue = controlledValue !== undefined ? controlledValue : stateValue;
    return [
        effectiveValue,
        (newValue) => {
            setState(newValue);
            if (setControlledValue) {
                if (typeof newValue === 'function') {
                    setControlledValue(newValue(effectiveValue));
                } else {
                    setControlledValue(newValue);
                }
            }
        },
    ];
};
