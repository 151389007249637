import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Modal, { IBaseModalProps } from '../../../core/components/Modal';
import { MasterdataEntity } from '../../types';
import DataGrid, { DataGridHandle } from 'react-data-grid';
import { StringField } from '../../../assistance/containers/Fields';
import { camelCase, debounce, snakeCase } from 'lodash';
import { IconButton } from '../../../core/components/IconButton';
import { AlertBar, AlertContent, AlertTitle } from '../../../core/components/Alert';
import Icon from '../../../core/components/Icon';
import { generatePath } from '../../../utils/router';
import { NavigationContext } from '../../../generic_document/routes';
import { IMenuItem } from '../../../core/containers/DropDown';
import Text from '../../../core/components/Text';

import './style.scss';

export interface IMasterdataBrowserProps {
    searchTerm?: string;
    lookupType?: string;
    targetField?: string;
    onSelectRecord?: (record: MasterdataEntity) => void;
    // Note: we are re-using the callbacks from masterdata-powered autocomplete fields here
    getItems?: (searchTerm: string) => Promise<Array<IMenuItem>>;
    dropdownMessageBuilder: (inputValue: string, resultItems: Array<IMenuItem>) => string;
}

export const RESULTS_PAGE_SIZE = 20;

export type IMasterdataBrowserModalProps = IBaseModalProps | IMasterdataBrowserProps;

// used when rendering a masterdata entity (e.g. in the masterdata browser)
export const browsableSchemaByMasterdataEntity = {
    ClientLookupType: [
        'clientId1',
        'companyName',
        'address1',
        'zipCode',
        'city',
        'country',
        'clientContactPerson1',
        'internalNote',
    ],
    AddressLookupType: [
        'addressId1',
        'name',
        'address1',
        'misc',
        'zipCode',
        'city',
        'country',
        //'email', TBD
        'phone',
        'contactPerson',
    ],
    ArticleLookupType: [
        'articleId1',
        'articleId2',
        'articleId3',
        'articlePartitionSpecificNumber',
        'description',
        'grossPrice',
        'orderUnits',
        'salesUnits',
        'salesOrganisation',
    ],
    ContactLookupType: ['contactId', 'firstName', 'lastName', 'phone', 'email'],
    FrameworkContractLookupType: ['contractId1', 'contractId2', 'contractName', 'clientId', 'clientName'],
    OrderMatchingLookupType: [
        'articleId1',
        'articleId2',
        'articleId3',
        'positionNumber',
        'articleDescription',
        'quantity',
        'unit',
    ],
};

export const MasterdataBrowser = (props) => {
    const {
        searchTerm: initialSearchTerm,
        lookupType,
        targetField,
        getItems,
        dropdownMessageBuilder,
        searchMessageBuilder,
        onSelectRecord,
        maxResultsShown = RESULTS_PAGE_SIZE,
    } = props;
    if (initialSearchTerm == null || targetField == null) {
        return;
    }

    const { t } = useTranslation('masterdata');
    const { paths, channelId } = useContext(NavigationContext);

    const [loading, setLoading] = useState(false);
    const [activeSearchTerm, setActiveSearchTerm] = useState(initialSearchTerm);
    const [searchInputText, setSearchInputText] = useState(initialSearchTerm);
    const setSearchTermDebounced = useCallback(debounce(setActiveSearchTerm, 200), []);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null); // there is no built-in single select for DataGrid (only multi-select)

    const dropdownMessage = dropdownMessageBuilder?.(activeSearchTerm, rows);
    const searchMessage = searchMessageBuilder?.(activeSearchTerm, rows);
    const gridRef = useRef<DataGridHandle>(null);

    useEffect(() => {
        // Re-fetch results when search term changes
        setLoading(true);
        getItems(activeSearchTerm, 0, RESULTS_PAGE_SIZE)
            .then((items) => items.map((item) => item.data._lookupEntity))
            .then((entities) => {
                setRows(entities);
                setLoading(false);
            })
            .then(() => {
                gridRef?.current?.scrollToCell({ rowIdx: 0 });
            });
    }, [activeSearchTerm]);

    useEffect(() => {
        // Synchronize state with external prop, i.e. re-initialize when search term prop changes
        setSearchInputText(initialSearchTerm);
        setActiveSearchTerm(initialSearchTerm);
    }, [initialSearchTerm]);

    const renderCell = ({ column, row, rowIdx, tabIndex }) => {
        // show arrays as comma-separated list
        const content = row[column.key];
        const cellText = Array.isArray(content) ? content.join(', ') : content;
        return <Text text={cellText} />;
    };

    const publicEntityKeys = rows.length > 0 ? browsableSchemaByMasterdataEntity[rows[0].__typename] : [];
    let columns = publicEntityKeys.map((key) => ({
        key,
        name: t(`schema.${lookupType}.${key}`),
        renderCell: renderCell,
        minWidth: 80,
        maxWidth: 500,
    }));

    // Ensure the target field of the search shows up as the first column
    let targetFieldColumnIdx = columns.findIndex((column) => column.key == camelCase(targetField));
    if (targetFieldColumnIdx == -1) {
        targetFieldColumnIdx = 0; // no-op, keeps the original array ordering
    }

    let actionColumn = [];
    if (onSelectRecord != null) {
        actionColumn.push({
            key: 'id',
            name: '',
            frozen: true,
            resizable: false,
            renderCell: ({ row }) => (
                <IconButton
                    onClick={() => onSelectRecord(row)}
                    icon="editDocument"
                    tooltip={t(`browser.selectRecordTooltip`)}
                />
            ),
            maxWidth: 35,
            cellClass: 'row-action-cell',
        });
    }

    columns = [
        ...actionColumn,
        {
            ...columns[targetFieldColumnIdx],
            frozen: true,
            renderCell: ({ row }) => <Text text={row[camelCase(targetField)]} highlightPattern={activeSearchTerm} />,
        },
        ...columns.slice(0, targetFieldColumnIdx),
        ...columns.slice(targetFieldColumnIdx + 1),
    ];

    const isAtBottom = ({ currentTarget }) => {
        return currentTarget.scrollTop + 10 >= currentTarget.scrollHeight - currentTarget.clientHeight;
    };

    const handleScroll = (event) => {
        if (loading || !isAtBottom(event)) return;
        setLoading(true);
        getItems(activeSearchTerm, rows.length, RESULTS_PAGE_SIZE)
            .then((items) => items.map((item) => item.data._lookupEntity))
            .then((entities) => {
                setRows([...rows, ...entities]);
                setLoading(false);
            });
    };

    return (
        <div className="masterdata-browser">
            <div className="masterdata-browser__filters">
                <span className="text-secondary">{searchMessage || ''}</span>
                <StringField
                    className="field--search"
                    inputProps={{ placeholder: t('browser.searchInputPlaceholder') }}
                    value={searchInputText}
                    setValue={setSearchInputText}
                    onChange={(e) => setSearchTermDebounced(e.target.value)}
                    onClear={() => {
                        setSearchInputText(''); // Set value to empty
                        setSearchTermDebounced(''); // Call onChange
                    }}
                    //onSubmit={setSearchTerm} // onEnter
                    //onUpdate={setSearchTerm} // onBlur
                    maxLength={50}
                />
            </div>

            <div className="masterdata-browser__content">
                {!dropdownMessage && rows.length > 0 && (
                    <DataGrid
                        defaultColumnOptions={{
                            resizable: true,
                        }}
                        ref={gridRef}
                        headerRowHeight={45}
                        rowHeight={45}
                        columns={columns}
                        rows={rows}
                        rowKeyGetter={(row) => row.id}
                        onCellClick={({ row }) => setSelectedRow(row)}
                        onScroll={handleScroll}
                    />
                )}
                {!!dropdownMessage && (
                    <AlertBar severity="info" className="masterdata-browser__content--info">
                        <span>{dropdownMessage}</span>
                    </AlertBar>
                )}
                <AlertBar severity="info" className="masterdata-browser__content--disclaimer">
                    <AlertTitle>{t('browser.disclaimer.title')}</AlertTitle>
                    <AlertContent>
                        <span>{t('browser.disclaimer.description')}</span>
                        <a href={generatePath(paths.channelMasterdata, { channelId })} target="_blank">
                            &nbsp;&nbsp;
                            <Icon icon="openExternalUrl" />
                            &nbsp;&nbsp;
                            {t('browser.disclaimer.linkText')}
                        </a>
                    </AlertContent>
                </AlertBar>
            </div>
        </div>
    );
};

export const MasterdataBrowserModal = (props) => {
    const { visible, onClose, ...browserProps } = props;
    const { t } = useTranslation('masterdata');

    const lookupTypeLocalizationKey = snakeCase(props.lookupType?.replace('Type', '') || '').toUpperCase(); // e.g. "ARTICLE_LOOKUP"
    return (
        <Modal
            className="masterdata-browser-modal"
            visible={visible}
            onClose={onClose}
            title={
                <Trans
                    t={t}
                    i18nKey={'browser.title'}
                    values={{ lookupType: t(`importRun.lookupType.${lookupTypeLocalizationKey}`) }}
                />
            }
            allowMaximize
        >
            <MasterdataBrowser {...browserProps} />
        </Modal>
    );
};
