import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanField, IntegerField, StringField, TextField, useForm } from '../Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import ConfigurationCard from '../ConfigurationCard';

const UploadConfiguration = ({ config, onActivate, onDeactivate = undefined, onSubmit }) => {
    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`uploadConfiguration.fieldNames.${fieldName}`),
    });
    useEffect(() => {
        setFormData({
            enabled: config?.enabled,
            targetPathFileExport: config?.targetPathFileExport || '',
            targetPathOriginalDocument: config?.targetPathOriginalDocument || '',
            sendOriginalDocument: config?.sendOriginalDocument,
            sendOriginalEmail: config?.sendOriginalEmail,
            deliveryMethod: config?.deliveryMethod || '',
        });
    }, [config]);

    const handleSubmit = () => onSubmit(formData);

    const { t } = useTranslation('config');

    return (
        <ConfigurationCard
            title={t('uploadConfiguration.title')}
            description={t('uploadConfiguration.description')}
            active={!!config}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
        >
            <div className="form">
                <BooleanField {...getFieldProps('enabled')} className="field--vertical" />
                <StringField {...getFieldProps('targetPathFileExport')} className="field--vertical" />
                <StringField {...getFieldProps('targetPathOriginalDocument')} className="field--vertical" />
                <BooleanField {...getFieldProps('sendOriginalDocument')} className="field--vertical" />
                <BooleanField {...getFieldProps('sendOriginalEmail')} className="field--vertical" />
                <StringField {...getFieldProps('deliveryMethod')} className="field--vertical" />

                <div>
                    <ProgressButton label={t('card.save')} onClick={handleSubmit} />
                </div>
            </div>
        </ConfigurationCard>
    );
};

export default UploadConfiguration;
