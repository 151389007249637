import classnames from 'classnames';
import { snakeCase } from 'lodash';
import pick from 'lodash/pick';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { SecondaryButton } from '../../../core/components/Button';
import { getConfidenceClass } from '../../../core/components/ConfidenceIndicator';
import Loader from '../../../core/components/Loader';
import { MenuDivider } from '../../../core/components/Menu';
import MenuItem from '../../../core/components/MenuItem';
import DropDown, { DropDownMenu, DropDownToggle } from '../../../core/containers/DropDown';
import SplitPanes, { Pane } from '../../../core/containers/SplitPanes';
import { Area, SelectionImageMap } from '../ImageMap';
import ConfirmModal from '../../../core/containers/ConfirmModal';

import { Spacer } from '../../../core/components/Spacer';
import { useFeatureToggle } from '../../../core/utils/hooks/useFeatureToggle';
import useShortcuts from '../../../core/utils/hooks/useShortcuts';
import { useChannelStore } from '../../../stores';
import ERPLockedField from '../ERPLockedField';
import { renderField } from '../Fields';
import ValidationChecks from '../ValidationChecks';
import { appInsights } from '../../../core/analytics/applicationInsights';
import {
    addItemCancelEvent,
    addItemEndEvent,
    addItemStartEvent,
    changeItemsTableViewTypeEvent,
    changeItemsViewTypeEvent,
    copyToAllItemsEvent,
    deleteBulkItemEvent,
    deleteItemEvent,
    markAllAsCorrectEvent,
    markAsCorrectEvent,
    selectDocumentViewerRegionEndEvent,
    selectDocumentViewerRegionStartEvent,
} from '../../../core/analytics/customEvents';
import AssistanceCanvas from '../../components/AssistanceCanvas';

import Icon from '../../../core/components/Icon';

export const ITEMS_DISPLAY_AS_STORAGE_KEY = 'itemsDisplayAs';
export const ITEMS_TABLE_VIEW_TOGGLE_STORAGE_KEY = 'itemsTableViewDisplayAs';
const DISPLAY_AS = {
    LIST: 'list',
    TABLE: 'table',
};
export const TABLE_DISPLAY_AS = {
    HORIZONTAL_VIEW: 'horizontal',
    VERTICAL_SPLIT_VIEW: 'vertical',
};

const SCROLL_TRIGGER_OFFSET = 10;
const SCROLL_OFFSET = -20;
const SCROLL_DISPLAY_AS_BUTTONS_OFFSET = -62;
const ADD_LINE_ITEM_INDEX = -1;
export const TABLE_HEADER_SPLIT_PANEL_ID = 'assistance-items-table-header'; // used for column width localStorage

// TROX CUSTOMIOZATION
const ARTICLE_FIELD_NAMES = ['orderCode', 'articleNumber'];

const ItemFields = (props) => {
    const {
        itemIndex,
        item,
        onUpdate,
        handlerOnUpdate,
        setFocusItemField,
        fieldConfigs = {},
        fieldNames = [],
        groupFieldProps = {},
        groupInputProps = {},
        activeFieldId,
        setActiveFieldId,
        explanationToggle,
        loading,
        formDisplayAs,
    } = props;

    const { t } = useTranslation('assistance');
    const channelId = useChannelStore((state) => state.channelId);

    if (fieldNames.length === 0) {
        return <span className="field-group__note">{t('itemsView.noFields')}</span>;
    }

    return fieldNames.map((fieldName, index) => {
        const field = item?.[fieldName];
        // Customization that will be only applied for item24 (TODO delete after poc and build something that makes sense)
        const unit = item?.['unit'];
        if (unit?.value == 'm' && (fieldName === 'articleCutLength' || fieldName === 'articleCutPieces')) {
            groupInputProps[fieldName].required = true;
        }
        if (
            unit?.value == 'qm' &&
            (fieldName === 'articleCutLength' || fieldName === 'articleCutPieces' || fieldName === 'articleCutWidth')
        ) {
            groupInputProps[fieldName].required = true;
        }

        if (formDisplayAs == DISPLAY_AS.TABLE) {
            groupFieldProps[fieldName] = {
                ...(groupFieldProps[fieldName] || {}),
                tooltipPlacement: 'bottom-start',
            };
        }

        const onMouseEnter = () => setFocusItemField({ itemIndex, fieldName });
        const onMouseLeave = () => setFocusItemField({ itemIndex });

        return renderField({
            t,
            field,
            fieldName,
            fieldConfigs,
            channelId,
            groupFieldProps,
            groupInputProps,
            onUpdate,
            handlerOnUpdate,
            onMouseEnter,
            onMouseLeave,
            activeFieldId,
            setActiveFieldId,
            explanationToggle,
            loading,
        });
    });
};

const ItemMoreActionsButton = (props) => {
    const { onActionClick, item, itemIndex, fieldNames, formDisplayAs, showOrderCodeToggle, record } = props;
    const { t } = useTranslation('assistance');

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [ignoreMatchingModalVisible, setIgnoreMatchingModalVisible] = useState(false);

    const handleConfirmIgnoreMatching = () => {
        onActionClick('document_matching_ignore_matching');
        setIgnoreMatchingModalVisible(false);
    };
    return (
        <div>
            <DropDown menuOrientation={formDisplayAs === DISPLAY_AS.LIST ? 'right' : 'left'}>
                <DropDownToggle>
                    <SecondaryButton
                        label=""
                        className="field-group__action-button field-group__action-button--more-icon-only"
                    />
                </DropDownToggle>

                <DropDownMenu onChange={() => {}} className="field-group__action-menu">
                    {showOrderCodeToggle && (
                        <MenuItem
                            label={t('itemsView.actions.selectOrderCode')}
                            onClick={() => onActionClick('selectOrderCode')}
                        />
                    )}
                    {showOrderCodeToggle && (
                        <MenuItem
                            label={t('itemsView.actions.selectArticleNumber')}
                            onClick={() => onActionClick('selectArticleNumber')}
                        />
                    )}
                    {showOrderCodeToggle && <MenuDivider />}
                    <MenuItem label={t('itemsView.actions.correct')} onClick={() => onActionClick('correct')} />
                    <MenuItem
                        label={t('itemsView.actions.duplicate')}
                        onClick={() => onActionClick('duplicate', { itemIndex })}
                    />
                    {record?.isMatchingEnabled && item?.isMatchingIgnored && (
                        <MenuItem
                            label={t('itemsView.actions.document_matching_restore_matching')}
                            onClick={() => onActionClick('document_matching_restore_matching')}
                        />
                    )}
                    <MenuDivider />
                    {fieldNames
                        ?.filter((fieldName) => !fieldName.startsWith('articleNumber'))
                        .map((fieldName) => {
                            // action:copy does not make sense for article numbers due to the dependencies to allowed units, allowed variants, etc.
                            const label = t(`itemsView.fieldNames.${snakeCase(fieldName)}`);
                            return (
                                <MenuItem
                                    key={fieldName}
                                    label={t('itemsView.actions.copy', { fieldName: label })}
                                    onClick={() => onActionClick('copy', { fieldName })}
                                />
                            );
                        })}
                    {fieldNames.length > 0 ? <MenuDivider /> : null}
                    {record?.isMatchingEnabled && !item?.isMatchingIgnored && (
                        <MenuItem
                            label={t('itemsView.actions.document_matching_ignore_matching')}
                            className="menu-item--danger"
                            onClick={() => setIgnoreMatchingModalVisible(true)}
                        />
                    )}
                    <MenuItem
                        label={t('itemsView.actions.delete')}
                        className="menu-item--danger"
                        onClick={() => onActionClick('delete')}
                    />
                    <MenuItem
                        label={t('itemsView.actions.bulk_delete')}
                        className="menu-item--danger"
                        onClick={() => setDeleteModalVisible(true)}
                    />
                </DropDownMenu>
            </DropDown>
            <ConfirmModal
                title={t('itemsView.deleteModal.title')}
                labelConfirm={t('itemsView.deleteModal.confirmButton')}
                labelCancel={t('itemsView.deleteModal.cancelButton')}
                onConfirm={() => onActionClick('bulk_delete')} // Bulk delete
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                danger
            >
                <p>{t('itemsView.deleteModal.message')}</p>
            </ConfirmModal>
            <ConfirmModal
                title={t('itemsView.ignoreMatchingModal.title')}
                labelConfirm={t('itemsView.ignoreMatchingModal.confirmButton')}
                labelCancel={t('itemsView.ignoreMatchingModal.cancelButton')}
                onConfirm={handleConfirmIgnoreMatching}
                onCancel={() => setIgnoreMatchingModalVisible(false)}
                visible={ignoreMatchingModalVisible}
                danger
            >
                <p>{t('itemsView.ignoreMatchingModal.message')}</p>
            </ConfirmModal>
        </div>
    );
};

const ItemFieldGroup = (props) => {
    const {
        item,
        itemIndex,

        onUpdate,
        setFocusItemField,
        setReselectItemField,

        fieldConfigs,
        fieldNames,
        optionalFieldNames,

        readOnly,
        formDisplayAs,
        loading,

        hasOrderCode,

        user,
        record,
        document,

        activeFieldId,
        setActiveFieldId,
        explanationToggle,

        widths,
    } = props;

    const { t } = useTranslation('assistance');

    // TROX CUSTOMIZATION: This is used for customers that have more than one article identifier
    // The initial value for selectedArticleField is determined in the backend and stored on the orderCode field
    const [selectedArticleField, setSelectedArticleField] = useState(
        item?.orderCode?.isDisplayed === false ? 'articleNumber' : 'orderCode'
    );

    const handleUpdate = (fieldName, value, options = {}) => {
        onUpdate({ itemIndex, fieldName, payload: value, ...options });
    };

    const onItemUpdate = (props) => onUpdate({ itemIndex, ...props }); // this provides the regular useAssistanceHandlers.handleUpdate signature, but adds the itemIndex

    const handleActionClick = (action, payload = undefined) => {
        let forceRefetchRecord = false;

        // For selectOrderCode and selectArticleNumber actions we update SelectedArticleField before calling the backend
        // TODO: this shouldn't be a separate command for each field, but field name in payload
        if (action === 'selectOrderCode') {
            setSelectedArticleField('orderCode');
            forceRefetchRecord = true;
        } else if (action === 'selectArticleNumber') {
            setSelectedArticleField('articleNumber');
            forceRefetchRecord = true;
        }

        onUpdate({ itemIndex, action: `action:${action}`, payload, forceRefetchRecord });

        // Track analytics event
        switch (action) {
            case 'delete':
                appInsights?.trackEvent(...deleteItemEvent(user, record, document, { itemPosition: itemIndex }));
                break;
            case 'bulk_delete':
                appInsights?.trackEvent(...deleteBulkItemEvent(user, record, document));
                break;
            case 'copy':
                appInsights?.trackEvent(
                    ...copyToAllItemsEvent(user, record, document, { fieldName: payload?.fieldName })
                );
                break;
            case 'correct':
                appInsights?.trackEvent(...markAsCorrectEvent(user, record, document, { fieldType: 'item' }));
                break;
        }
    };
    const handleReset = (fieldName) => {
        onUpdate({ itemIndex, fieldName, action: `action:reset_field`, forceRefetchRecord: true });
    };

    const handleFocus = (fieldName, event, ...eventProps) => {
        setReselectItemField({ itemIndex, fieldName });
    };
    const handleBlur = (fieldName, event, ...eventProps) => {
        setReselectItemField(undefined);
    };

    const groupInputProps = Object.fromEntries(
        fieldNames.map((fieldName) => [
            fieldName,
            {
                onFocus: (event, ...eventProps) => handleFocus(fieldName, event, ...eventProps),
                onBlur: (event, ...eventProps) => handleBlur(fieldName, event, ...eventProps),
                onReset: () => handleReset(fieldName),
                required: !optionalFieldNames.includes(fieldName),
                disabled:
                    readOnly ||
                    (hasOrderCode &&
                        selectedArticleField &&
                        ARTICLE_FIELD_NAMES.includes(fieldName) &&
                        fieldName !== selectedArticleField),
            },
        ])
    );

    const groupFieldProps =
        widths && formDisplayAs === DISPLAY_AS.TABLE
            ? Object.fromEntries(
                  fieldNames.map((fieldName, index) => [
                      fieldName,
                      {
                          style: { width: `${widths?.[index] || 100}px` },
                      },
                  ])
              )
            : {};

    // we display the minimal field confidence as this is what we use to decide if assistance is needed
    const minConfidence = Math.min(
        ...fieldNames
            .filter(
                (fieldName) =>
                    !optionalFieldNames.includes(fieldName) ||
                    (item?.[fieldName]?.value && item[fieldName].value !== '[]')
            )
            .map((fieldName) => item?.[fieldName]?.predictionConfidence)
            .filter((c) => c !== undefined),
        1
    );
    const confidence = Math.floor(minConfidence * 100);

    return (
        <div
            key={`item-${itemIndex}`}
            className={classnames('field-group', `item-${itemIndex}`)}
            onMouseEnter={() => setFocusItemField({ itemIndex })}
            onMouseLeave={() => setFocusItemField(undefined)}
        >
            <div className="field-group__header">
                <div className="field-group__title">
                    {t('itemsView.groupTitle')} #{itemIndex + 1}{' '}
                    <span
                        className={classnames(
                            'field-group__confidence',
                            `field-group__confidence--${getConfidenceClass(minConfidence)}`
                        )}
                    >
                        {confidence}%
                    </span>
                    {item?.isMatchingIgnored && (
                        <span className={classnames('field-group__ignore-matching')}>
                            <Icon icon="warning" />
                            <span>{t('itemsView.matchingIgnored')}</span>
                        </span>
                    )}
                </div>
                {!readOnly && (
                    <div
                        className={classnames(
                            'field-group__actions',
                            explanationToggle && 'field-group__show-explanations'
                        )}
                    >
                        {minConfidence === 1 && (
                            <div className="field-group__all-correct">{t('itemsView.allCorrect')}</div>
                        )}
                        <ItemMoreActionsButton
                            item={item}
                            itemIndex={itemIndex}
                            fieldNames={fieldNames}
                            onActionClick={handleActionClick}
                            formDisplayAs={formDisplayAs}
                            showOrderCodeToggle={hasOrderCode}
                            record={record}
                        />
                    </div>
                )}
            </div>

            <div className="js-scroll-trigger" />
            <ItemFields
                onUpdate={handleUpdate}
                handlerOnUpdate={onItemUpdate}
                item={item}
                fieldConfigs={fieldConfigs}
                itemIndex={itemIndex}
                setFocusItemField={setFocusItemField}
                groupInputProps={groupInputProps}
                groupFieldProps={groupFieldProps}
                fieldNames={fieldNames}
                activeFieldId={activeFieldId}
                setActiveFieldId={setActiveFieldId}
                explanationToggle={explanationToggle}
                loading={loading}
                formDisplayAs={formDisplayAs}
            />
        </div>
    );
};

const MemoizedItemFieldGroup = React.memo(ItemFieldGroup);

const SelectionImageMapMemo = React.memo(SelectionImageMap);

const ItemsView = (props) => {
    const {
        user,
        record,
        ocr,

        document,
        items,

        onUpdate,
        onReselect,
        loading,
        readOnly,

        itemFields: fieldNames = [],
        optionalItemFields: optionalFieldNames = [],
        itemValidationChecks,
        explanationToggle,

        fieldConfigs = {},
        enableAddItem = true,
        onAddItemClick = null,
    } = props;

    const { t } = useTranslation('assistance');

    const pagesCount = ocr?.pagesCount;

    const getTop = (bbox, pageIndex, pagesCount) => (bbox[0][1] + pageIndex) / pagesCount;
    const [activeFieldId, setActiveFieldId] = useState(null);

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    const [dragColumnActive, setDragColumnActive] = useState(false);
    const [columnWidths, setColumnWidths] = useState(null);
    const handleDragColumnStart = () => setDragColumnActive(true);
    const handleDragColumnEnd = () => setDragColumnActive(false);
    const handleColumnWidthsChange = (widths) => {
        setColumnWidths(widths);
    };

    const scrollPreviewToItem = (itemIndex) => {
        const item = items?.[itemIndex];
        if (!item) return;

        const svg = previewRef.current?.querySelector('svg');
        const svgHeight = svg?.getBoundingClientRect().height || 0;

        const scrollTop = item?.bbox ? getTop(item.bbox, item.pageIndex, pagesCount) : 0;
        const spacingTop =
            svg.getBoundingClientRect().top -
            previewRef.current?.getBoundingClientRect().top +
            previewRef.current?.scrollTop;

        previewRef.current.scrollTo({
            top: scrollTop * svgHeight + spacingTop + SCROLL_OFFSET,
            behavior: 'smooth',
        });
    };

    const scrollFormToItem = (itemIndex) => {
        const triggers = formRef?.current.querySelectorAll('.js-scroll-trigger');
        const trigger = triggers?.[itemIndex];
        if (!trigger) return;

        const offsetTop = formRef?.current.offsetTop + SCROLL_DISPLAY_AS_BUTTONS_OFFSET;
        formRef?.current.scrollTo({
            top: trigger.offsetTop - offsetTop,
            behavior: 'smooth',
        });
    };

    const [displayAs, setDisplayAs] = useState(localStorage.getItem(ITEMS_DISPLAY_AS_STORAGE_KEY) || DISPLAY_AS.LIST);
    const handleChangeDisplayAs = (val) => {
        localStorage.setItem(ITEMS_DISPLAY_AS_STORAGE_KEY, val);
        setDisplayAs(val);
        appInsights?.trackEvent(...changeItemsViewTypeEvent(user, record, document, { viewType: val }));
    };

    const [tableViewToggle, setTableViewToggle] = useState(
        localStorage.getItem(ITEMS_TABLE_VIEW_TOGGLE_STORAGE_KEY) || TABLE_DISPLAY_AS.HORIZONTAL_VIEW
    );
    const handleTableViewToggleChange = () => {
        if (tableViewToggle == TABLE_DISPLAY_AS.HORIZONTAL_VIEW) {
            localStorage.setItem(ITEMS_TABLE_VIEW_TOGGLE_STORAGE_KEY, TABLE_DISPLAY_AS.VERTICAL_SPLIT_VIEW);
            setTableViewToggle(TABLE_DISPLAY_AS.VERTICAL_SPLIT_VIEW);
        } else {
            localStorage.setItem(ITEMS_TABLE_VIEW_TOGGLE_STORAGE_KEY, TABLE_DISPLAY_AS.HORIZONTAL_VIEW);
            setTableViewToggle(TABLE_DISPLAY_AS.HORIZONTAL_VIEW);
        }

        appInsights?.trackEvent(
            ...changeItemsTableViewTypeEvent(user, record, document, { viewType: tableViewToggle })
        );
    };

    const [scrollItemIndex, setScrollItemIndex] = useState(undefined);

    // {itemIndex, fieldName}
    const [focusItemField, setFocusItemField] = useState(undefined);
    const [reselectItemField, setReselectItemField] = useState(undefined);
    const [activeReselectItemField, setActiveReselectItemField] = useState(undefined);

    // we are storing the imageSrc because everytime we do an update a new token is added to the image url
    // which triggers a rerender and image reload in the image map - and we don't want that
    const [imageSrc, setImageSrc] = useState(ocr?.processedFile.url);
    useEffect(() => {
        if (imageSrc === undefined) setImageSrc(ocr?.processedFile.url);
    }, [ocr?.processedFile.url]);

    const handleAddItem = useCallback(() => {
        setReselectItemField({ itemIndex: ADD_LINE_ITEM_INDEX });
        appInsights?.trackEvent(...addItemStartEvent(user, record, document));
    }, [user, document, record]);

    const handleSelectAreaStart = useCallback(
        (bbox, pageIndex) => {
            setActiveReselectItemField({ ...reselectItemField, bbox, pageIndex });
            appInsights?.trackEvent(
                ...selectDocumentViewerRegionStartEvent(user, record, document, {
                    fieldName: reselectItemField?.fieldName,
                    fieldType: 'item',
                })
            );
        },
        [reselectItemField, user, document, record]
    );

    const handleUpdate = useCallback(
        (props) => {
            // This will prevent onUpdate from triggering onBlur when we actually started to reselect in the ImageMap
            if (!activeReselectItemField) {
                onUpdate(props); // we are passing props to the onUpdate method? itemType should be passed as well, but how can we access it for the update query?
            }
        },
        [activeReselectItemField, record, document]
    );

    // we only show the button if something is not already at 100% confidence
    const handleAllCorrect = useCallback(() => {
        onUpdate({ action: 'action:mark_items_correct' });
        appInsights?.trackEvent(...markAllAsCorrectEvent(user, record, document, { fieldType: 'item' }));
    }, [user, document, record, onUpdate]);
    const allCorrectButtonVisible =
        items &&
        Math.min(
            ...items.flatMap((item) => fieldNames.map((fieldName) => item?.[fieldName]?.predictionConfidence || 0))
        ) < 1;

    const handleSelectAreaEnd = useCallback(
        (bbox, pageIndex) => {
            const { itemIndex, fieldName } = activeReselectItemField;

            const fieldConfig = fieldConfigs[fieldName];

            if (fieldConfig?.onReselect) {
                fieldConfig.onReselect({ fieldName, bbox, pageIndex, itemIndex });
            } else {
                onReselect({ fieldName, bbox, pageIndex, itemIndex });
            }

            setReselectItemField(undefined);
            setActiveReselectItemField(undefined);

            appInsights?.trackEvent(
                ...selectDocumentViewerRegionEndEvent(user, record, document, { fieldName, fieldType: 'item' })
            );
            if (itemIndex == ADD_LINE_ITEM_INDEX) {
                appInsights?.trackEvent(...addItemEndEvent(user, record, document));
            }
        },
        [activeReselectItemField, fieldConfigs, items, user, document, record]
    );

    const handleCancelReselection = useCallback(() => {
        if (reselectItemField?.itemIndex == ADD_LINE_ITEM_INDEX) {
            appInsights?.trackEvent(
                ...addItemCancelEvent(user, record, document, {
                    fieldName: reselectItemField?.fieldName,
                    fieldType: 'item',
                })
            );
        }
        setReselectItemField(undefined);
    }, [reselectItemField, user, document, record]);

    const previewRef = useRef(undefined);
    const formRef = useRef(undefined);

    const handleImageMapLoad = useCallback((evt) => {
        const { width, height } = evt.currentTarget;
        setImageDimensions({ width, height });
    }, []);

    useEffect(() => {
        if (!imageDimensions?.height || !imageDimensions?.width) return;
        // TODO: this is a hack to delay the scroll one render
        setTimeout(() => setScrollItemIndex(0), 100);
    }, [imageDimensions]);

    useEffect(() => {
        if (scrollItemIndex === undefined || scrollItemIndex < 0) return;
        scrollPreviewToItem(scrollItemIndex);
    }, [scrollItemIndex]);

    const handleFormScroll = useCallback(
        (event) => {
            const scrollTop = event.target.scrollTop;
            const itemFormTops = Array.from(formRef.current.querySelectorAll('.js-scroll-trigger')).map(
                (el: any) => el.offsetTop
            );
            const scrollToIndex = itemFormTops.findIndex((trigger) => trigger + SCROLL_TRIGGER_OFFSET > scrollTop);
            setScrollItemIndex(scrollToIndex);
        },
        [formRef?.current]
    );

    const moveFocusToSiblingRow = useCallback((e, prev = false) => {
        const item = e.target.closest('.field-group');
        if (!item) return;

        const field = e.target.closest('.field');

        const itemFields = item?.querySelectorAll('.field');
        const fieldIndex = Array.from(itemFields).findIndex((el) => el === field);

        const nextItem = prev ? item.previousSibling : item.nextSibling;
        if (!nextItem) return;

        const nextItemField = nextItem?.querySelectorAll('.field')[fieldIndex];
        if (!nextItemField) return;

        nextItemField.querySelector('input')?.focus();
    }, []);

    const moveFocusToSiblingField = useCallback((e, prev = false) => {
        const item = e.target.closest('.field-group');
        if (!item) return;

        const field = e.target.closest('.field');

        const itemFields = item?.querySelectorAll('.field');
        const fieldIndex = Array.from(itemFields).findIndex((el) => el === field);

        const nextField = prev
            ? item?.querySelectorAll('.field')[fieldIndex - 1]
            : item?.querySelectorAll('.field')[fieldIndex + 1];
        if (!nextField) return;

        nextField.querySelector('input')?.focus();
    }, []);

    useShortcuts(
        {
            'ALT+Tab': (e) => moveFocusToSiblingRow(e, e.shiftKey),

            'ALT+ArrowDown': (e) => moveFocusToSiblingRow(e, false),
            'ALT+ArrowUp': (e) => moveFocusToSiblingRow(e, true),
            'ALT+ArrowRight': (e) => moveFocusToSiblingField(e, false),
            'ALT+ArrowLeft': (e) => moveFocusToSiblingField(e, true),

            'CTRL+ArrowDown': (e) => moveFocusToSiblingRow(e, false),
            'CTRL+ArrowUp': (e) => moveFocusToSiblingRow(e, true),
            'CTRL+ArrowRight': (e) => moveFocusToSiblingField(e, false),
            'CTRL+ArrowLeft': (e) => moveFocusToSiblingField(e, true),

            'CMD+ArrowDown': (e) => moveFocusToSiblingRow(e, false),
            'CMD+ArrowUp': (e) => moveFocusToSiblingRow(e, true),
            'CMD+ArrowRight': (e) => moveFocusToSiblingField(e, false),
            'CMD+ArrowLeft': (e) => moveFocusToSiblingField(e, true),
        },
        true
    );

    const fields = items?.flatMap((item, itemIndex) =>
        Object.entries(pick(item, fieldNames))
            .filter((item) => item[1])
            .map((item) => [...item, itemIndex])
    );

    const isERPLockEnabled = useFeatureToggle('ERPLock', { channel: record?.channel });

    // TROX customization: if orderCodes are being used, hasOrderCode is passed down and activates a showOrderCodeToggle
    // To keep the UI stable when toggling, we reorder the headerFields (for table view)
    // and fieldnames so that we always show the article identifier as the first field
    const hasOrderCode = fieldNames.includes('orderCode');
    const actionButtonOrientation = displayAs === DISPLAY_AS.LIST ? 'vertical' : tableViewToggle;

    return (
        <div className="line-items-view assistance-view__inner">
            <SplitPanes
                id="assistance-items"
                initialWidths={[66, 34]}
                initialHeights={[50, 50]}
                orientation={displayAs === DISPLAY_AS.TABLE ? tableViewToggle : TABLE_DISPLAY_AS.VERTICAL_SPLIT_VIEW}
            >
                <Pane className="bg-secondary">
                    <AssistanceCanvas
                        artboardWidth={imageDimensions.width}
                        artboardHeight={imageDimensions.height}
                        viewportRef={previewRef}
                    >
                        <SelectionImageMapMemo
                            src={imageSrc}
                            numPages={ocr?.pagesCount}
                            height="100%"
                            width="100%"
                            onLoad={handleImageMapLoad}
                            canSelect={reselectItemField !== undefined || activeReselectItemField !== undefined}
                            onSelectionStart={handleSelectAreaStart}
                            onSelectionEnd={handleSelectAreaEnd}
                        >
                            {items?.map((item: any, itemIndex) => (
                                <Area
                                    key={itemIndex}
                                    data={item}
                                    bbox={item?.bbox}
                                    pageIndex={item?.pageIndex}
                                    onClick={() => scrollFormToItem(itemIndex)}
                                    className={classnames(
                                        'image-map__area--row image-map__area--link',
                                        focusItemField &&
                                            focusItemField.itemIndex === itemIndex &&
                                            'image-map__area--hover'
                                    )}
                                />
                            ))}

                            {reselectItemField === undefined &&
                                activeReselectItemField === undefined &&
                                fields?.map(([fieldName, field, itemIndex]: [string, any, number], fieldIndex) => (
                                    <Area
                                        key={fieldIndex}
                                        data={field}
                                        bbox={field?.bbox}
                                        pageIndex={field?.pageIndex}
                                        className={classnames(
                                            'image-map__area--cell',
                                            focusItemField &&
                                                (focusItemField.fieldName !== fieldName ||
                                                    focusItemField.itemIndex !== itemIndex) &&
                                                'image-map__area--cell-no-focus'
                                        )}
                                    />
                                ))}
                        </SelectionImageMapMemo>
                    </AssistanceCanvas>

                    {!readOnly && (
                        <div className="assistance-view__preview-buttons">
                            {reselectItemField === undefined ? (
                                enableAddItem && (
                                    <Button
                                        label={t('itemsView.addItemButton')}
                                        className="assistance-view__button-add"
                                        onClick={onAddItemClick ? () => onAddItemClick() : handleAddItem}
                                    />
                                )
                            ) : (
                                <Button
                                    label={t('itemsView.cancelSelectionButton')}
                                    className="button--danger"
                                    onClick={handleCancelReselection}
                                />
                            )}
                        </div>
                    )}
                </Pane>
                <Pane className="assistance-view__form-modal-root">
                    <div className="assistance-view__display-as-buttons">
                        {displayAs === DISPLAY_AS.TABLE && (
                            <div className="assistance-view__table-view-toggle">
                                <label className="assistance-view__table-view-toggle__note">
                                    {t('itemsView.tableToggle.message')}
                                </label>
                                <a
                                    className="assistance-view__table-view-toggle__button"
                                    onClick={() => handleTableViewToggleChange()}
                                >
                                    {tableViewToggle === TABLE_DISPLAY_AS.VERTICAL_SPLIT_VIEW
                                        ? t('itemsView.tableToggle.newVersion')
                                        : t('itemsView.tableToggle.oldVersion')}
                                </a>
                            </div>
                        )}
                        <SecondaryButton
                            className={classnames(
                                'assistance-view__display-as-button assistance-view__display-as-list',
                                displayAs === DISPLAY_AS.LIST && 'button--active'
                            )}
                            onClick={() => handleChangeDisplayAs(DISPLAY_AS.LIST)}
                            label={t('itemsView.displayAs.list')}
                        />
                        <SecondaryButton
                            className={classnames(
                                'assistance-view__display-as-button assistance-view__display-as-table',
                                displayAs === DISPLAY_AS.TABLE && 'button--active'
                            )}
                            onClick={() => handleChangeDisplayAs(DISPLAY_AS.TABLE)}
                            label={t('itemsView.displayAs.table')}
                        />
                    </div>

                    <div
                        className="assistance-view__table assistance-view__form-wrapper"
                        ref={formRef}
                        onScroll={handleFormScroll}
                    >
                        <div
                            className={classnames(
                                `assistance-view__form--${displayAs}`,
                                'assistance-view-table-header',
                                `assistance-view-table-header__display-as-${displayAs}`,
                                loading && 'assistance-view__form--locked'
                            )}
                        >
                            <div className="assistance-view__field-groups">
                                <div
                                    className={classnames(
                                        `assistance-view-table-header__display-as-${displayAs}`,
                                        'field-group',
                                        readOnly && `assistance-view-table-header--read-only`
                                    )}
                                >
                                    <SplitPanes
                                        id={TABLE_HEADER_SPLIT_PANEL_ID}
                                        shrinkPanels={false}
                                        onDragStart={handleDragColumnStart}
                                        onDragEnd={handleDragColumnEnd}
                                        onUpdate={handleColumnWidthsChange}
                                    >
                                        {fieldNames.map((fieldName) => (
                                            <Pane
                                                key={fieldName}
                                                className={classnames(
                                                    'field header-field',
                                                    !optionalFieldNames.includes(fieldName) && 'header-field--required',
                                                    `field--${snakeCase(fieldName)}`
                                                )}
                                            >
                                                {t(`itemsView.fieldNames.${snakeCase(fieldName)}`)}
                                            </Pane>
                                        ))}
                                        <Pane></Pane>
                                    </SplitPanes>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                className={classnames(
                                    'assistance-view__form',
                                    `assistance-view__form--${displayAs}`,
                                    (loading || dragColumnActive) && 'assistance-view__form--locked'
                                )}
                            >
                                <div className="assistance-view__field-groups">
                                    <ValidationChecks checks={itemValidationChecks} />

                                    {!items || items.length === 0 ? (
                                        <div className="field-group__note">{t('itemsView.noItems')}</div>
                                    ) : null}

                                    {!items ? (
                                        <Loader className="assistance-view__form-loader" />
                                    ) : (
                                        // split into smaller components for caching
                                        items?.map((item, itemIndex) => (
                                            <MemoizedItemFieldGroup
                                                key={itemIndex}
                                                item={item}
                                                itemIndex={itemIndex}
                                                onUpdate={handleUpdate}
                                                setFocusItemField={setFocusItemField}
                                                setReselectItemField={setReselectItemField}
                                                fieldConfigs={fieldConfigs}
                                                fieldNames={fieldNames}
                                                optionalFieldNames={optionalFieldNames}
                                                readOnly={readOnly}
                                                loading={loading}
                                                formDisplayAs={displayAs}
                                                hasOrderCode={hasOrderCode}
                                                user={user}
                                                record={record}
                                                document={document}
                                                activeFieldId={activeFieldId}
                                                setActiveFieldId={setActiveFieldId}
                                                explanationToggle={displayAs === DISPLAY_AS.LIST && explanationToggle} // only show explanation toggle in list view
                                                widths={columnWidths}
                                            />
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="assistance-view__completion_buttons">
                        {readOnly && items && (
                            <div className={`assistance-view__completion_buttons__${actionButtonOrientation}`}>
                                {isERPLockEnabled && (
                                    <ERPLockedField record={record} readOnly={readOnly} handleUpdate={handleUpdate} />
                                )}
                            </div>
                        )}

                        {!readOnly && items && (
                            <div className={`assistance-view__completion_buttons__${actionButtonOrientation}`}>
                                {isERPLockEnabled && (
                                    <>
                                        <ERPLockedField
                                            record={record}
                                            readOnly={readOnly}
                                            handleUpdate={handleUpdate}
                                        />
                                        <Spacer />
                                    </>
                                )}
                                {allCorrectButtonVisible && (
                                    <SecondaryButton
                                        label={t('itemsView.allCorrectButton')}
                                        onClick={handleAllCorrect}
                                    />
                                )}
                                {props.formButtons}
                            </div>
                        )}
                    </div>
                </Pane>
            </SplitPanes>
        </div>
    );
};

export default ItemsView;
