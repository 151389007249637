import React, { CSSProperties, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './InlineSelect.scss';
import classnames from '../../../core_updated/utils/classnames';

const InlineSelect = ({
    onUpdate,
    initialValue,
    allowCustomValue = false,
    customSample = undefined,
    choices,
    className,
}: any) => {
    const { t } = useTranslation();
    const allowedValues = choices.map((choice: any) => choice.value);
    const [customValue, setCustomValue] = useState(allowedValues.includes(initialValue) ? '' : initialValue);
    useEffect(() => {
        setCustomValue(allowedValues.includes(initialValue) ? '' : initialValue);
    }, [initialValue]);

    if (customSample && typeof customSample === 'function') {
        customSample = customSample(customValue);
    }

    const handleUpdate = (value: any) => {
        if (allowedValues && allowedValues.includes(value)) {
            setCustomValue('');
        }
        if (value) {
            onUpdate(value);
        } else {
            onUpdate(choices[0].value);
        }
    };

    const containerStyle: CSSProperties = className ? { overflowY: 'auto' } : {};

    return (
        <div className={classnames('inline-select', className)} style={containerStyle}>
            {choices.map((choice: any) => (
                <div
                    className={classNames(
                        'inline-select__item',
                        !customValue && choice.value === initialValue && 'inline-select__item--active'
                    )}
                    onClick={() => {
                        handleUpdate(choice.value);
                    }}
                >
                    <label className="inline-select__label">{choice.label}</label>
                    {choice.sample && <div className="inline-select__sample">{choice.sample}</div>}
                </div>
            ))}
            {allowCustomValue && (
                <div
                    className={classNames(
                        'inline-select__item',
                        customValue === initialValue && 'inline-select__item--active'
                    )}
                >
                    <input
                        className="inline-select__input"
                        placeholder={t('config:documentConfig.customValuePlaceholder')}
                        value={customValue}
                        onChange={(e) => {
                            setCustomValue(e.target.value);
                            // handleUpdate(e.target.value);
                        }}
                        onBlur={(e) => {
                            handleUpdate(e.target.value);
                        }}
                    />
                    {customSample && <div className="inline-select__sample">{customSample}</div>}
                </div>
            )}
        </div>
    );
};

export default InlineSelect;
