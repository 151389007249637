export const unpackAndMergeDynamicSchemaFields = <T>(
    objectWithDynamicSchemaFields: T,
    dynamicSchemaFieldName = 'dynamicSchema',
    dynamicSchemaSerializedFieldName = 'dynamicSchemaSerialized'
): T => {
    function _traverseObject(obj) {
        for (const key in obj) {
            // Determine if there is a dynamic schema field at the current level of the object tree
            // (either a polymorphic type or embedded as a serialized string)
            let dynamicSchema;
            if (obj.hasOwnProperty(dynamicSchemaFieldName)) {
                // polymorphic type
                dynamicSchema = obj[dynamicSchemaFieldName];
            }
            if (obj.hasOwnProperty(dynamicSchemaSerializedFieldName)) {
                // embedded string
                let dynamicSchemaString = obj[dynamicSchemaSerializedFieldName];
                dynamicSchema ??= JSON.parse(dynamicSchemaString);
                // make sure also polymorphic field exists
                if (!obj.hasOwnProperty(dynamicSchemaFieldName)) {
                    obj[dynamicSchemaFieldName] = dynamicSchema;
                }
            }

            // Recurse all the way to object leaf nodes
            let value = obj[key];
            if (value != undefined) {
                if (value && typeof value === 'object') {
                    _traverseObject(value);
                }
            }

            // Make sure to traverse nested dynamic schemas
            if (dynamicSchema && typeof dynamicSchema === 'object') {
                _traverseObject(dynamicSchema);
            }

            // Revert changes to the object if we have already merged dynamic schema fields
            if ('__dynamicSchemaKeys' in obj) {
                for (const dynamicSchemaKey of obj['__dynamicSchemaKeys']) {
                    delete obj[dynamicSchemaKey];
                }
                obj['__dynamicSchemaKeys'] = [];
            }

            // Merge dynamic schema into the current level of the object tree as we unwind the recursion
            if (dynamicSchema != null) {
                obj['__dynamicSchemaKeys'] = [];

                for (const dynamicSchemaKey in dynamicSchema) {
                    // Skip protected fields
                    if (dynamicSchemaKey.startsWith('__')) {
                        continue;
                    }
                    // Copy over the key/value pair from inside the dynamic schema
                    obj[dynamicSchemaKey] = dynamicSchema[dynamicSchemaKey];
                    obj['__dynamicSchemaKeys'].push(dynamicSchemaKey); // this is so we can revert changes when needed
                }
            }
        }
    }

    _traverseObject(objectWithDynamicSchemaFields);
    return objectWithDynamicSchemaFields; // original object mutated in-place
};
