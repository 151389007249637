import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Text from '../Text';

import './style.scss';

interface IProps {
    active?: boolean;
    className?: string;
    label?: any;
    description?: string;
    highlightPattern?: string; // applies to both label & description
    labelHighlightPattern?: string;
    descriptionHighlightPattern?: string;
    onClick?: any;
    // dirty hack because the implementation of select and autocomplete fields sucks e.g. it is dependent on triggering
    // onMouseDown instead of onClick so this component just changes it which leads to a lot of debugging problems
    forceOnClick?: any;
    value?: any;
    linkTo?: string;
    linkToExternal?: boolean;
    disabled?: boolean;
    preventDefault?: boolean;
    tooltip?: string;
    showTooltip?: boolean;
}

export const MenuItemWithTooltip = (props) => {
    return (
        <>
            <span
                data-tooltip={props.tooltip}
                className={classnames('menu-item-tooltip-flex', props.showTooltip && 'menu-item-tooltip')}
            >
                <MenuItem {...props} />
            </span>
        </>
    );
};

MenuItemWithTooltip.displayName = 'MenuItemWithTooltip';

const MenuItem = (props: IProps) => {
    const {
        active,
        className,
        label,
        description,
        highlightPattern,
        labelHighlightPattern,
        descriptionHighlightPattern,
        onClick,
        forceOnClick,
        value,
        linkTo,
        disabled,
        preventDefault = false,
        linkToExternal = false,
    } = props;

    const handleClick = (e) => {
        if (preventDefault) e.preventDefault();
        if (onClick) onClick(value);
    };

    const classNames = classnames(
        'menu-item',
        active && !disabled ? 'menu-item--active' : '',
        disabled && 'menu-item--disabled',
        className
    );
    const Base = linkToExternal ? 'a' : linkTo ? Link : 'a';

    // onMouseDown would overwrite "to" so links wouldn't work
    const clickProp = linkTo || forceOnClick ? { onClick: handleClick } : { onMouseDown: handleClick };

    const linkProps = linkToExternal ? { to: linkTo, href: linkTo, target: '_blank' } : { to: linkTo };

    return (
        <Base className={classNames} {...clickProp} {...linkProps}>
            <>
                <Text
                    className="menu-item__label"
                    text={label || value}
                    highlightPattern={labelHighlightPattern ?? highlightPattern}
                    truncate={false}
                />
                {description && (
                    <Text
                        className="menu-item__description"
                        text={description}
                        highlightPattern={descriptionHighlightPattern ?? highlightPattern}
                        truncate={false}
                    />
                )}
            </>
        </Base>
    );
};

MenuItem.displayName = 'MenuItem';

export default MenuItem;
