import React from 'react';

const DefaultLoader = (props: any) => {
    return <span className="h-5 w-full flex-auto animate-pulse items-center gap-2 rounded-md bg-gray-200" />;
};

const withLoader = (WrappedComponent: React.ComponentType<any>, Loader: React.ComponentType<any> = DefaultLoader) => {
    return <T,>({ loading, ...props }: T & { loading: boolean }) => {
        if (loading) {
            return <Loader {...props} />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withLoader;
