import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import TeamForm from '../../containers/TeamForm';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';
import Loader from '../../../core/components/Loader';

import { DETAIL_PATH, OVERVIEW_PATH } from '../../constants';
import { GET_TEAM, UPDATE_TEAM } from '../../queries';
import { GET_USERS } from '../../../users/queries';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const Edit = (props) => {
    const { user: viewer } = props;

    const { t } = useTranslation('teams');
    const navigate = useNavigate();
    const { teamId } = useParams();

    const { data, error, loading, refetch } = useQuery(GET_TEAM, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            id: teamId,
        },
    });

    const {
        data: usersData,
        error: usersErrors,
        loading: usersLoading,
    } = useQuery(GET_USERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            size: 1000, // we just want to show all users
        },
    });

    const [updateTeam, { loading: updateTeamLoading, error: updateTeamError }] = useMutation(UPDATE_TEAM);

    const handleSubmit = (formData) => {
        return updateTeam({
            variables: {
                id: teamId,
                name: formData.name,
                description: formData.description,
                users: formData.users,
            },
        })
            .then(() => {
                navigate(url(DETAIL_PATH, { teamId }));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Layout>
            <Page className="edit-team page--slim">
                <div className="edit-team__head">
                    <Breadcrumbs>
                        <Breadcrumb label={t('breadcrumbs.teams')} linkTo={url(OVERVIEW_PATH)} />
                        <Breadcrumb label={data?.team?.name} linkTo={url(DETAIL_PATH, { teamId })} />
                    </Breadcrumbs>
                    <div className="edit-team__title">{t('edit.title')}</div>
                </div>

                {updateTeamError && (
                    <Alert severity="error">
                        <AlertTitle>{updateTeamError?.message}</AlertTitle>
                    </Alert>
                )}

                {loading || usersLoading ? (
                    <Loader />
                ) : (
                    <TeamForm
                        viewer={viewer}
                        team={data?.team}
                        selectableUsers={usersData?.users?.users}
                        className="edit-team__form"
                        submitLabel={t('edit.submitLabel')}
                        onSubmit={handleSubmit}
                    />
                )}
            </Page>
        </Layout>
    );
};

export default Edit;
