import React from 'react';
import { fetchLookupOptions, MASTERDATA_DEFAULT_BROWSER_KEY, renderArticleIdOption } from './useLookup';
import { useTranslation } from 'react-i18next';
import AutoCompleteField from '../../core_updated/components/Fields/AutoCompleteField';
import { snakeCase } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { createDropdownMessageBuilder } from '../../masterdata/containers/MasterdataBoundField';
import moment from 'moment';
import useForceSelectionLookupProps, { useForceSelectionReselectEventListener } from './useForceSelectionLookupProps';
import { FieldProps, useFieldProps } from '../pages/Assistance/customizable/Field';
import { useAssistanceFieldContext } from '../pages/Assistance/AssistanceFieldContext';
import { FormFieldConfig, MatchingLookupProps } from '../pages/Assistance/useDocumentConfig';

const useLookupMatchingProps = ({
    config,
    lookupType,
    partitionId,
    matchingDocumentNumber,
}: {
    config: FormFieldConfig;
    lookupType: string;
    partitionId: string;
    matchingDocumentNumber: string;
}): Partial<MatchingLookupProps> => {
    let { lookupDefinitionIdDefault, lookupFieldNameDefault, lookupLastSuccessfulImportDate, documentNumberFieldName } =
        config;

    const extraSearchTerms = {
        [snakeCase(documentNumberFieldName)]: matchingDocumentNumber,
    };

    lookupLastSuccessfulImportDate = moment(lookupLastSuccessfulImportDate).format(
        localStorage.getItem('i18nextLng') === 'de' ? 'Do MMMM, HH:mm' : 'MMMM Do, HH:mm'
    );

    const fetchOptions = fetchLookupOptions({
        client: useApolloClient(),
        lookupDefinitionId: lookupDefinitionIdDefault,
        lookupFieldName: lookupFieldNameDefault,
        partitionId: partitionId,
    });

    const renderOptions = (items, searchTerm) => {
        /*
        Render the options to display in the dropdown list for matching use case:
          - Add a menu item indicating the lookup name where the data comes from
          - Add the MD items to display if any, otherwise add a dropdown message
          - Add a button to open up and browse in the entire MD
         */
        const { t } = useTranslation('masterdata');
        const lookupTypeLocalizationKey = snakeCase(lookupType?.replace('Type', '') || '').toUpperCase();
        const labelName = t(`masterdata:browser.dropDownTitle.` + lookupTypeLocalizationKey);

        const dropdownMessage = createDropdownMessageBuilder({
            partitionId: partitionId,
            isPartitionIdField: false,
            hasMatching: true,
            matchingDocumentNumber: matchingDocumentNumber,
            lookupLastSuccessfulImportDate: lookupLastSuccessfulImportDate,
        })(searchTerm, items);

        const options = [
            {
                role: 'message',
                label: labelName,
            },
            ...items,
        ];

        if (dropdownMessage) {
            options.push({
                role: 'message',
                label: dropdownMessage,
            });
        }

        options.push({
            role: 'separator',
        });

        options.push({
            role: 'button',
            label: t('browser.searchEntireData'),
            value: MASTERDATA_DEFAULT_BROWSER_KEY,
        });

        return options;
    };

    return {
        hasMatching: true,
        extraSearchTerms,
        matchingDocumentNumber,
        lookupLastSuccessfulImportDate,
        fetchOptions,
        renderOptions,
        minSearchChars: 0,
    };
};

export const ArticleNumberField = (props: FieldProps) => {
    const { clientPartitionId, config, matchingDocumentNumber } = useAssistanceFieldContext();
    const { lookupDefinitionId, lookupFieldName, allowInvalidIds } = config;

    const fieldProps = useFieldProps(props);
    const { value, onValueChange, onFocus, onBlur } = fieldProps;

    const hasMatching = !!config?.documentNumberFieldName;

    const matchingLookupType = 'OrderMatchingLookupType';
    const lookupMatchingProps = useLookupMatchingProps({
        config,
        lookupType: matchingLookupType, // TODO: configurable ?
        partitionId: clientPartitionId,
        matchingDocumentNumber,
    });

    const forceSelectionLookupProps = useForceSelectionLookupProps({
        lookupType: hasMatching ? matchingLookupType : 'ArticleLookupType',
        lookupDefinitionId,
        lookupFieldName,
        partitionId: clientPartitionId,
        allowInvalidIds,
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus,
        onBlur,
        isPartitionIdField: config?.isPartitionIdField === true,
        // only apply if matching is enabled
        lookupMatchingProps: hasMatching ? { ...config, ...lookupMatchingProps } : undefined,
    });

    useForceSelectionReselectEventListener(props.inputRef, forceSelectionLookupProps.onValueChange);

    return (
        <AutoCompleteField
            {...fieldProps}
            {...forceSelectionLookupProps}
            renderOption={renderArticleIdOption(lookupFieldName)}
        />
    );
};

const articleNumberFieldNames = [
    'article_number',
    'article_number_2',
    'article_number_3',
    'article_partition_specific_number',
    'article_description',
    'number', // used by ListOfServices
    'description', // used by ListOfServices
];

export const ArticleNumberFieldConditionalComponents = [
    {
        // article number field is only a lookup field if it has a lookupFieldName and lookupDefinitionId
        condition: ({ fieldName, config }) =>
            articleNumberFieldNames.includes(fieldName) && config.lookupFieldName && config.lookupDefinitionId,
        component: ArticleNumberField,
    },
];
