import * as React from 'react';
import classnames from 'classnames';

import logoSvg from '!!raw-loader!../../../../../static/images/workist_logo.svg';

interface IProps {
    className?: string;
}

const Logo = (props: IProps) => {
    const { className } = props;
    return <div className={classnames('logo', className)} dangerouslySetInnerHTML={{ __html: logoSvg }} />;
};

export default Logo;
