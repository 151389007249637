import * as React from 'react';
import { useEffect, useState } from 'react';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';

export interface ArrayFieldProps
    extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'defaultValue'>,
        FieldProps {}

const ArrayField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            onBlur,
            controls,
            className,
            readOnly,
            disabled,
            inputRef = undefined,
            ...props
        }: ArrayFieldProps,
        ref: any
    ) => {
        /*
         * ArrayField expects the initialValue on the record to be a string that is a JSON array e.g.: '["a", "b", "c"]'
         * If this is not the case, there will be no parsing and the raw value will be displayed.
         *
         * The field returns a string that is a JSON array e.g.: '["a", "b", "c"]'
         *
         * When the user changes or adds an element, each element of the elements are expected to be on separate lines and separated by a semicolon
         * (tailing semicolon will be added automatically to the last element).
         * This means in particular that an element can span multiple lines if the individual lines are not separated by a semicolon.
         */

        const arrayToString = (s) => {
            try {
                s = JSON.parse(s);
                if (s.length === 0 || (s.length === 1 && s[0] === '')) {
                    // We want to show an empty field if the array is empty
                    return '';
                }
                s = s.join(';\n') + ';';
                return s;
            } catch (SyntaxError) {
                // Not a JSON array - continue with initial value
                return s;
            }
        };

        const stringToArray = (s) => {
            // Remove trailing semicolon
            if (s.slice(-1) === ';') s = s.slice(0, -1);

            return JSON.stringify(s.split(';\n'));
        };

        // value is always in the form of a JSON array: '["a", "b", "c"]'
        const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);
        // inputValue is always in the form of a string: 'a;\nb;\nc;'
        const [inputValue, setInputValue] = useState<string>(arrayToString(defaultValue));

        useEffect(() => {
            setInputValue(arrayToString(value));
        }, [value]);

        const handleChange = (e: any) => {
            setInputValue(e.target.value);
            onChange?.(e);
        };

        const handleBlur = (e: any) => {
            const nextValue = stringToArray(inputValue);
            if (value !== nextValue) setValue(nextValue);
            onBlur?.(e);
        };

        return (
            <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
                <Field.Input>
                    <textarea
                        className="px-2 py-1.5 flex-1 min-w-0 h-24 resize-none bg-transparent"
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={inputValue || ''}
                        {...props}
                        ref={inputRef}
                    />
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        );
    }
);

export default ArrayField;
