import { gql } from '@apollo/client';

export const GET_RFQ_EXTRACTION_RATIO = gql`
    query ($channelIds: [UUID], $startDate: Date!, $endDate: Date!) {
        rfqsUploadedAndAutomated(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            uploadedCount
            automatedCount
        }

        rfqChannelsExtractionRatio(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            extractionDays {
                date
                extractionRate
            }
        }

        rfqChannelsTopAssistanceReasons(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceReasons {
                reason
                fieldName
                percentage
            }
        }

        rfqChannelsAssistanceTimeAndTimeSaved(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceTimes {
                date
                assistanceTime
                assistedDocs
            }
            totalTimeSaved
            timeSavedPerDocument
        }
    }
`;

export const GET_ORDER_EXTRACTION_RATIO = gql`
    query ($channelIds: [UUID], $startDate: Date!, $endDate: Date!) {
        ordersUploadedAndAutomated(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            uploadedCount
            automatedCount
        }

        orderChannelsExtractionRatio(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            extractionDays {
                date
                extractionRate
            }
        }

        orderChannelsTopAssistanceReasons(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceReasons {
                reason
                fieldName
                percentage
            }
        }

        orderChannelsAssistanceTimeAndTimeSaved(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceTimes {
                date
                assistanceTime
                assistedDocs
            }
            totalTimeSaved
            timeSavedPerDocument
        }
    }
`;

export const GET_ORDER_CONFIRMATION_EXTRACTION_RATIO = gql`
    query ($channelIds: [UUID], $startDate: Date!, $endDate: Date!) {
        orderConfirmationsUploadedAndAutomated(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            uploadedCount
            automatedCount
        }

        orderConfirmationChannelsExtractionRatio(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            extractionDays {
                date
                extractionRate
            }
        }

        orderConfirmationChannelsTopAssistanceReasons(
            channelIds: $channelIds
            startDate: $startDate
            endDate: $endDate
        ) {
            assistanceReasons {
                reason
                fieldName
                percentage
            }
        }

        orderConfirmationChannelsAssistanceTimeAndTimeSaved(
            channelIds: $channelIds
            startDate: $startDate
            endDate: $endDate
        ) {
            assistanceTimes {
                date
                assistanceTime
                assistedDocs
            }
            totalTimeSaved
            timeSavedPerDocument
        }
    }
`;

export const GET_INVOICE_EXTRACTION_RATIO = gql`
    query ($channelIds: [UUID], $startDate: Date!, $endDate: Date!) {
        invoicesUploadedAndAutomated(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            uploadedCount
            automatedCount
        }

        invoiceChannelsExtractionRatio(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            extractionDays {
                date
                extractionRate
            }
        }

        invoiceChannelsTopAssistanceReasons(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceReasons {
                reason
                fieldName
                percentage
            }
        }

        invoiceChannelsAssistanceTimeAndTimeSaved(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceTimes {
                date
                assistanceTime
                assistedDocs
            }
            totalTimeSaved
            timeSavedPerDocument
        }
    }
`;

export const GET_DELIVERY_NOTE_EXTRACTION_RATIO = gql`
    query ($channelIds: [UUID], $startDate: Date!, $endDate: Date!) {
        deliveryNotesUploadedAndAutomated(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            uploadedCount
            automatedCount
        }

        deliveryNoteChannelsExtractionRatio(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            extractionDays {
                date
                extractionRate
            }
        }

        deliveryNoteChannelsTopAssistanceReasons(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceReasons {
                reason
                fieldName
                percentage
            }
        }

        deliveryNoteChannelsAssistanceTimeAndTimeSaved(
            channelIds: $channelIds
            startDate: $startDate
            endDate: $endDate
        ) {
            assistanceTimes {
                date
                assistanceTime
                assistedDocs
            }
            totalTimeSaved
            timeSavedPerDocument
        }
    }
`;

export const GET_UNIVERSAL_EXTRACTION_RATIO = gql`
    query ($channelIds: [UUID], $startDate: Date!, $endDate: Date!) {
        universalUploadedAndAutomated(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            uploadedCount
            automatedCount
        }

        universalChannelsExtractionRatio(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            extractionDays {
                date
                extractionRate
            }
        }

        universalChannelsTopAssistanceReasons(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceReasons {
                reason
                fieldName
                percentage
            }
        }

        universalChannelsAssistanceTimeAndTimeSaved(channelIds: $channelIds, startDate: $startDate, endDate: $endDate) {
            assistanceTimes {
                date
                assistanceTime
                assistedDocs
            }
            totalTimeSaved
            timeSavedPerDocument
        }
    }
`;

export const GET_FILTER_OPTION_DATA = gql`
    query {
        rfqProcessingChannels {
            id
            name
        }

        orderProcessingChannels {
            id
            name
        }

        orderConfirmationProcessingChannels {
            id
            name
        }

        invoiceProcessingChannels {
            id
            name
        }

        deliveryNoteProcessingChannels {
            id
            name
        }

        listOfServicesProcessingChannels {
            id
            name
        }

        universalProcessingChannels {
            id
            name
        }

        viewer {
            customer {
                isRfqsEnabled
                isOrdersEnabled
                isOrderConfirmationsEnabled
                isInvoicesEnabled
                isDeliveryNotesEnabled
                isUniversalEnabled
            }
        }

        users(From: 0, size: 1000) {
            users {
                id
                email
                firstName
                lastName
                groups {
                    id
                    name
                }
                teams {
                    id
                    name
                }
            }
            totalCount
        }
    }
`;
