import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';
import { DECIMAL_VALIDATION_REGEXP } from '../../../assistance/containers/Fields';

export interface IntegerFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {}

const IntegerField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            controls,
            className,
            readOnly,
            disabled,
            inputRef = undefined,
            ...props
        }: IntegerFieldProps,
        ref: any
    ) => {
        const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

        const handleChange = (e: any) => {
            // last char if still typing
            if (e.target.value && !DECIMAL_VALIDATION_REGEXP.test(e.target.value)) {
                e.preventDefault();
                return;
            }
            setValue(e.target.value);
            onChange?.(e);
        };

        return (
            <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
                <Field.Input>
                    <input
                        type="text" // type number doesn't trigger change on invalid input
                        value={value || ''}
                        onChange={handleChange}
                        className="px-2 py-1.5 flex-1 min-w-0 bg-transparent tabular-nums"
                        inputMode="numeric"
                        readOnly={readOnly}
                        disabled={disabled}
                        {...props}
                        ref={inputRef}
                    />
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        );
    }
);

export default IntegerField;
