import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanField, IntegerField, StringField, TextField, useForm } from '../Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import ConfigurationCard from '../ConfigurationCard';

const AS2Configuration = ({ config, onActivate, onDeactivate = undefined, onSubmit }) => {
    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`as2Configuration.fieldNames.${fieldName}`),
    });
    useEffect(() => {
        setFormData({
            senderAs2Name: config?.senderAs2Name || '',
            receiverUrl: config?.receiverUrl || '',
            receiverAs2Name: config?.receiverAs2Name || '',
            receiverVerifyCert: config?.receiverVerifyCert || '',
            receiverEncryptCert: config?.receiverEncryptCert || '',
            verifyReceiverCertificates: config?.verifyReceiverCertificates,
        });
    }, [config]);

    const handleSubmit = () => onSubmit(formData);

    const { t } = useTranslation('config');

    return (
        <ConfigurationCard
            title={t('as2Configuration.title')}
            description={t('as2Configuration.description')}
            active={!!config}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
        >
            <div className="form">
                <StringField {...getFieldProps('senderAs2Name')} className="field--vertical" />
                <StringField {...getFieldProps('receiverUrl')} className="field--vertical" />
                <StringField {...getFieldProps('receiverAs2Name')} className="field--vertical" />
                <TextField {...getFieldProps('receiverVerifyCert')} className="field--vertical" />
                <TextField {...getFieldProps('receiverEncryptCert')} className="field--vertical" />
                <BooleanField {...getFieldProps('verifyReceiverCertificates')} className="field--vertical" />

                <div>
                    <ProgressButton label={t('card.save')} onClick={handleSubmit} />
                </div>
            </div>
        </ConfigurationCard>
    );
};

export default AS2Configuration;
