import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { GET_SUPPORT_USERS, SWITCH_SUPPORT_USER } from '../queries';
import { useMutation, useQuery } from '@apollo/client';
import { DocumentType } from '../../generic_document/constants';
import { withIcon } from '../../core_updated/components/Icon';
import {
    faBuilding,
    faCheck,
    faChevronDown,
    faChevronUp,
    faCube,
    faSpinnerThird,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../core_updated/utils/classnames';
import { capitalize } from 'lodash';
import SupportUserSelect from '../components/SupportUserSelect';
import { useLocalStorage } from '../../core/utils/hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../core/components/Tooltip';
import { canUseSupportMode } from '../../users/utils';

const CubeIcon = withIcon(faCube);
const ChevronDownIcon = withIcon(faChevronDown);
const ChevronUpIcon = withIcon(faChevronUp);
const BuildingIcon = withIcon(faBuilding);
const LoadingIcon = withIcon(faSpinnerThird);
const SuccessIcon = withIcon(faCheck);
const ErrorIcon = withIcon(faXmark);

const SupportBar = ({ user, controls }: any) => {
    const { t } = useTranslation('support');
    const [collapsed, setCollapsed] = useLocalStorage('SUPPORT_BAR_COLLAPSED', true);
    let { supportUsers, switchSupportUser, isSessionUserOutOfSync } = useSupportMode(user);
    const isCollapsed = collapsed && !isSessionUserOutOfSync;

    return (
        <div
            className={classnames(
                'bg-decorative-hover w-full flex-none relative flex z-[100] justify-center items-center h-full min-h-0 transition-[max-height]',
                isCollapsed ? 'max-h-1' : 'max-h-12',
                isSessionUserOutOfSync && 'bg-decorative-active'
            )}
        >
            <div
                className={classnames(
                    'flex-1 flex justify-start px-5 gap-4 transition-opacity',
                    isCollapsed ? 'height-0 opacity-0 overflow-hidden pointer-events-none' : 'delay-100 opacity-100'
                )}
            >
                <SupportUserSelect
                    value={user?.email}
                    options={supportUsers?.map((supportUser) => ({
                        label: supportUser.email,
                        value: supportUser.email,
                    }))}
                    onValueChange={(supportUserId) => switchSupportUser({ supportUserId })}
                />

                <div className="flex text-sm text-inverted items-center">
                    {!isSessionUserOutOfSync ? (
                        <span className="font-medium flex gap-2 items-center">
                            <BuildingIcon className="text-xs" /> {user?.customer?.name}
                        </span>
                    ) : (
                        <span className="font-medium">
                            <Tooltip long content={t('supportBar.expiredUserTooltip')}>
                                <span className="opacity-50 flex gap-2 items-center">
                                    <BuildingIcon className="text-xs" /> {user?.customer?.name}
                                </span>
                                <span> {t('supportBar.expiredUser')}</span>
                            </Tooltip>
                        </span>
                    )}
                </div>
            </div>

            <button
                className={classnames(
                    'rounded px-3 py-1 text-inverted gap-2 flex items-center text-sm font-medium hover:bg-decorative-default transition-colors disabled:opacity-50',
                    collapsed &&
                        'absolute left-1/2 top-full transform -translate-x-1/2 -translate-y-1 bg-decorative-hover z-10'
                )}
                onClick={() => setCollapsed(!collapsed)}
                disabled={isSessionUserOutOfSync}
            >
                <CubeIcon className="text-xs" /> {capitalize(process.env.RUN_ENV)}{' '}
                {collapsed ? <ChevronUpIcon className="text-xs" /> : <ChevronDownIcon className="text-xs" />}
            </button>

            <div
                className={classnames(
                    'flex-1 flex justify-end px-5 gap-4 transition-opacity',
                    isCollapsed ? 'height-0 opacity-0 overflow-hidden pointer-events-none' : 'delay-100 opacity-100',
                    isSessionUserOutOfSync && 'invisible'
                )}
            >
                {controls}
            </div>
        </div>
    );
};

export const SupportBarButton = ({
    className,
    onClick,
    children,
    ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const [state, setState] = useState<string | null>(null);
    const [width, setWidth] = useState(null);

    const ref = useRef(null);

    const handleClick = (e) => {
        setState('loading');
        setWidth(ref.current.offsetWidth);
        Promise.resolve(onClick?.(e))
            .finally(() =>
                setTimeout(() => {
                    setState(null);
                    setWidth(null);
                }, 1000)
            )
            .then(() => setState('success'))
            .catch(() => setState('error'));
    };
    return (
        <button
            ref={ref}
            className={classnames(
                'rounded px-3 py-1 text-inverted gap-3 flex items-center text-sm font-medium hover:bg-decorative-default transition-colors disabled:opacity-50 text-center justify-center',
                state !== null && 'bg-decorative-default',
                className
            )}
            {...props}
            style={width ? { width } : null}
            onClick={handleClick}
        >
            {state === 'loading' && <LoadingIcon className="text-xs animate-spin" />}
            {state === 'success' && <SuccessIcon className="text-xs" />}
            {state === 'error' && <ErrorIcon className="text-xs" />}
            {state === null && children}
        </button>
    );
};

export const useSupportMode = (user) => {
    if (!canUseSupportMode(user)) {
        return { canUseSupportMode: false, supportUsers: null, switchSupportUser: null, isSessionUserOutOfSync: null };
    }

    const { data } = useQuery(GET_SUPPORT_USERS);
    let supportUsers = data?.supportUsers || [];
    if (!supportUsers.find((u) => u.id === user.id)) {
        supportUsers = [user, ...supportUsers];
    }

    let [isSessionUserOutOfSync, setIsSessionUserOutOfSync] = useState(false);

    const onWindowFocus = () => {
        const refreshedSessionUserId = JSON.parse(sessionStorage.getItem('sessionUserId'));
        const refreshedGlobalUserId = JSON.parse(localStorage.getItem('globalUserId'));
        if (refreshedGlobalUserId != null && refreshedGlobalUserId != refreshedSessionUserId) {
            setIsSessionUserOutOfSync(true);
        } else {
            setIsSessionUserOutOfSync(false);
        }
    };
    useEffect(() => {
        window.addEventListener('focus', onWindowFocus);
        if (!isSessionUserOutOfSync) {
            localStorage.setItem('globalUserId', user.id);
        }
        sessionStorage.setItem('sessionUserId', user.id);
        onWindowFocus();
        return () => {
            window.removeEventListener('focus', onWindowFocus);
        };
    }, [user.id]);

    const [switchSupportUserMutation] = useMutation(SWITCH_SUPPORT_USER);
    const switchSupportUser = ({ supportUserId = null, recordId = null, documentType = null }) => {
        // May provide either support user or record
        if (supportUserId != null) {
            supportUserId = parseInt(supportUsers.find((u) => u.email === supportUserId)?.id);
        }
        if (documentType != null) {
            documentType = DocumentType[documentType].toString();
        }
        if (!supportUserId && !recordId) return;
        if (supportUserId != null && supportUserId === parseInt(user.id)) return;

        return switchSupportUserMutation({ variables: { supportUserId, recordId, documentType } }).then((res) => {
            let targetUser = res?.data?.switchSupportUser?.supportUser;
            if (targetUser && targetUser.id != user.id) {
                localStorage.setItem('globalUserId', user.id);
                sessionStorage.setItem('sessionUserId', user.id);
                // start fresh
                window.location.reload();
            }
        });
    };

    return { canUseSupportMode: canUseSupportMode(user), supportUsers, switchSupportUser, isSessionUserOutOfSync };
};

export default SupportBar;
