import * as React from 'react';
import { useContext } from 'react';
import { DocumentContext } from '../../../stores';
import { camelCase, snakeCase } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { MasterdataEntity } from '../../types';
import { IMenuItem } from '../../../core/containers/DropDown';
import { SEARCH_MASTERDATA_ENTITY_FIELD } from '../../queries';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';
import { RESULTS_PAGE_SIZE } from '../MasterdataBrowser';
import moment from 'moment/moment';

// We don't have a dedicated component here, this just contains all the logic to generate the props that
// are passed into the rendered field component

export const buildMasterdataFieldPropsFromConfig = (client, fieldConfig, document, handlers) => {
    const { lookupType, lookupDefinitionId } = fieldConfig;
    const targetField = fieldConfig.lookupFieldName;
    const searchLookupOnEmptyValue = fieldConfig?.searchLookupOnEmptyValue;
    const { clientPartitionId: partitionId, clientPartitionFieldName: partitionFieldName } = document;

    // Document matching information
    const documentNumberFieldName = camelCase(fieldConfig?.documentNumberFieldName || '');
    const matchingDocumentNumber = document ? document[documentNumberFieldName]?.value : undefined;
    const extraSearchTerms = {
        [snakeCase(documentNumberFieldName)]: matchingDocumentNumber,
    };

    // Shared callbacks for masterdata field & browser
    const getItems = createItemSource({
        apolloClient: client,
        partitionId: partitionId,
        lookupDefinitionId: lookupDefinitionId,
        targetField: targetField,
        extraSearchTerms: extraSearchTerms,
        searchOnEmptyValue: searchLookupOnEmptyValue,
    });

    // Default lookup information and master data  callback when document matching is enabled
    const hasMatching = !!(fieldConfig?.performLookups && documentNumberFieldName);
    const lookupTypeDefault = fieldConfig?.lookupTypeDefault;
    const lookupDefinitionIdDefault = fieldConfig?.lookupDefinitionIdDefault;
    const targetFieldDefault = fieldConfig?.lookupFieldNameDefault;
    const getItemsDefault = createItemSource({
        apolloClient: client,
        partitionId: partitionId,
        lookupDefinitionId: lookupDefinitionIdDefault,
        targetField: targetFieldDefault,
    });
    const lookupLastSuccessfulImportDate = moment(fieldConfig?.lookupLastSuccessfulImportDate).format(
        localStorage.getItem('i18nextLng') === 'de' ? 'Do MMMM, HH:mm' : 'MMMM Do, HH:mm'
    );

    const dropdownMessageBuilder = createDropdownMessageBuilder({
        partitionId: partitionId,
        isPartitionIdField: camelCase(partitionFieldName) == fieldConfig?.groupName,
        hasMatching: hasMatching,
        matchingDocumentNumber: matchingDocumentNumber,
        lookupLastSuccessfulImportDate: lookupLastSuccessfulImportDate,
    });

    return {
        getItems: !!fieldConfig.performLookups ? getItems : null,
        dropdownMessageBuilder: !!fieldConfig.performLookups ? dropdownMessageBuilder : null,
        forceSelection: fieldConfig.allowNonLookupValue != null ? !fieldConfig.allowNonLookupValue : null,
        maxItemsShown: 5,
        // Lots of callbacks here - definitely not ideal, but better than hard-coupling the masterdata browser
        // to the field component. Probably worth rethinking our fields architecture a bit to avoid these callbacks
        onShowMore: ({ value, ...fieldProps }) => {
            handleOpenMasterdataBrowser({
                handlers: handlers,
                partitionId: partitionId,
                isPartitionIdField: camelCase(partitionFieldName) == fieldConfig?.groupName,
                searchTerm: value,
                lookupType: lookupType,
                getItems: getItems,
                targetField: targetField,
                fieldProps: fieldProps,
                hasMatching: hasMatching,
                matchingDocumentNumber: matchingDocumentNumber,
                lookupLastSuccessfulImportDate: lookupLastSuccessfulImportDate,
            });
        },
        lookupType: lookupType,
        searchOnEmptyValue: hasMatching,
        getItemsDefault: hasMatching ? getItemsDefault : null,
        onShowMoreDefault: hasMatching
            ? ({ value, ...fieldProps }) => {
                  handleOpenMasterdataBrowser({
                      handlers: handlers,
                      partitionId: partitionId,
                      isPartitionIdField: camelCase(partitionFieldName) == fieldConfig?.groupName,
                      searchTerm: value,
                      lookupType: lookupTypeDefault,
                      getItems: getItemsDefault,
                      targetField: targetFieldDefault,
                      fieldProps: fieldProps,
                  });
              }
            : null,
    };
};

export const handleOpenMasterdataBrowser = ({
    handlers,
    partitionId,
    isPartitionIdField,
    searchTerm,
    lookupType,
    getItems,
    targetField,
    fieldProps = null,
    onSelectRecord = null,
    hasMatching = false,
    matchingDocumentNumber = null,
    lookupLastSuccessfulImportDate = null,
}) => {
    const { openMasterdataBrowser, closeMasterdataBrowser } = handlers;

    openMasterdataBrowser({
        searchTerm: searchTerm,
        lookupType: lookupType,
        getItems: getItems,
        targetField: targetField,
        dropdownMessageBuilder: createDropdownMessageBuilder({
            partitionId: partitionId,
            isPartitionIdField: isPartitionIdField,
            hasMatching: hasMatching,
            matchingDocumentNumber: matchingDocumentNumber,
            lookupLastSuccessfulImportDate: lookupLastSuccessfulImportDate,
        }),
        searchMessageBuilder: createSearchMessageBuilder({
            targetField: targetField,
            lookupType: lookupType,
            hasMatching: hasMatching,
            matchingDocumentNumber: matchingDocumentNumber,
        }),
        onSelectRecord:
            onSelectRecord ||
            ((record) => {
                const targetFieldValue = record[camelCase(targetField)];
                // Call the field's update handler & optimistically close the modal (since we don't have a promise
                // to attach to)
                fieldProps.onUpdate?.(targetFieldValue, {
                    _lookup_id: record?.id,
                    _lookupEntity: record,
                });
                closeMasterdataBrowser();
            }),
    });
};

const createMatchingDropdownMessage = ({
    inputValue,
    resultItems,
    matchingDocumentNumber,
    lookupLastSuccessfulImportDate,
}) => {
    const { t } = useTranslation();

    if (!matchingDocumentNumber) {
        return <Trans t={t} i18nKey={`masterdata:assistance.fields.autocompleteField.missingDocumentNumber`} />;
    } else if (resultItems.length == 0) {
        return (
            <Trans
                t={t}
                i18nKey={`masterdata:assistance.fields.autocompleteField.noResultsMatching`}
                values={{ searchValue: inputValue, updatedAt: lookupLastSuccessfulImportDate }}
                count={inputValue ? inputValue.length : 0}
            />
        );
    }
};

export const createDropdownMessageBuilder =
    ({
        isPartitionIdField,
        partitionId,
        hasMatching = false,
        matchingDocumentNumber = null,
        lookupLastSuccessfulImportDate = null,
    }) =>
    (inputValue, resultItems) => {
        const { t } = useTranslation();
        // Create dropdown message when document matching is enabled
        if (hasMatching) {
            return createMatchingDropdownMessage({
                inputValue: inputValue,
                resultItems: resultItems,
                matchingDocumentNumber: matchingDocumentNumber,
                lookupLastSuccessfulImportDate: lookupLastSuccessfulImportDate,
            });
        }

        // TODO: show a warning here if no lookup_definition_id is configured for the field

        if (!partitionId && !isPartitionIdField) {
            return <Trans t={t} i18nKey={`masterdata:assistance.fields.autocompleteField.missingPartitionId`} />;
        }
        if (resultItems.length == 0) {
            return <Trans t={t} i18nKey={`masterdata:assistance.fields.autocompleteField.noResults`} />;
        }
    };

const createSearchMessageBuilder =
    ({ targetField, lookupType, hasMatching = false, matchingDocumentNumber = null }) =>
    (inputValue, resultItems, maxResultsShown) => {
        const { t } = useTranslation('masterdata');

        let searchTranslation = 'searchFilterInfo';
        if (hasMatching) {
            searchTranslation += 'Matching';
        }

        return (
            <Trans
                t={t}
                i18nKey={'browser.' + searchTranslation + (resultItems.length >= maxResultsShown ? '_max' : '')}
                values={{
                    numResults: resultItems.length,
                    searchTerm: inputValue,
                    fieldName: t(`schema.${lookupType}.${camelCase(targetField)}`),
                    documentNumber: matchingDocumentNumber,
                }}
                count={resultItems.length}
            />
        );
    };

const createItemSource =
    ({
        apolloClient,
        partitionId,
        lookupDefinitionId,
        targetField,
        extraSearchTerms = {},
        searchOnEmptyValue = false,
        minChars = 0,
    }) =>
    (searchTerm: string, from: number = 0, size: number = RESULTS_PAGE_SIZE) => {
        const toMenuItem = (entity: MasterdataEntity): IMenuItem => {
            const attributeValue = entity[camelCase(targetField)];
            // Filter out the matched value from the content summary & convert to one-line CSV string
            const descriptionLines = entity.contentSummaryMultiline?.split('\n');
            const description = descriptionLines?.filter((line) => line != attributeValue).join(' | ');
            return {
                value: attributeValue,
                label: attributeValue,
                description: description,
                labelHighlightPattern: searchTerm,
                data: {
                    _lookup_id: entity?.id,
                    _lookupEntity: entity,
                },
            };
        };

        // Condition to determine if an empty value should fetch new results or not.
        const doNothingOnEmptyValue = !searchTerm && !searchOnEmptyValue;

        if (doNothingOnEmptyValue || searchTerm.length < minChars) {
            return Promise.resolve([]);
        }

        return apolloClient
            .query({
                query: SEARCH_MASTERDATA_ENTITY_FIELD,
                variables: {
                    lookupDefinitionId,
                    targetField,
                    searchTerm,
                    partitionId: partitionId || null, // prevent sending empty value
                    from,
                    size,
                    kwargs: extraSearchTerms,
                },
                fetchPolicy: 'no-cache',
            })
            .then((res) => res?.data?.assistanceSearchByField.edges.map((node) => node.node).map(toMenuItem) || [])
            .catch((err) => {
                console.error(err);
                return [];
            });
    };
