import * as React from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { camelCase } from 'lodash';

interface AssistanceViewContextProviderProps {
    onUpdate: (field: IEventField & { action?: string; payload?: any }) => Promise<any>;
    onReselect: (field: IEventField & { bbox: any; pageIndex: number }) => Promise<any>;
    onFocusFieldChange?: (field: IEventField) => void;
    onHoverFieldChange?: (field: IEventField) => void;
    onUpdateFieldChange?: (field: IEventField) => void;
    onReselectFieldChange?: (field: IEventField) => void;
    onHoverItemIndexChange?: (itemIndex: number) => void;
    onFastTrackChange?: (isFastTrackEnabled: boolean) => void;
    isFastTrackEnabled?: boolean;
    generalConfig?: any;
    previewRef?: any;
}

interface AssistanceViewContextProps {
    onFieldUpdate: (field: IEventField & { payload: any }) => Promise<any>;
    onFieldAction: (field: IEventField & { action: string; payload?: any }) => Promise<any>;
    onFieldReselect: (field: IEventField & { bbox: any; pageIndex: number }) => Promise<any>;
    onFieldReselectStart: (field: IEventField) => void;
    onFieldReselectEnd: (field: IEventField) => void;
    onFieldFocus: (field: IEventField) => void;
    onFieldBlur: (field: IEventField) => void;
    onFieldMouseEnter: (field: IEventField) => void;
    onFieldMouseLeave: (field: IEventField) => void;
    onItemMouseEnter: (itemIndex: number) => void;
    onItemMouseLeave: (itemIndex: number) => void;
    onFastTrackChange?: (isFastTrackEnabled: boolean) => void;
    isFastTrackEnabled?: boolean;
    generalConfig?: any;
    previewRef?: any;
}

const AssistanceViewContext = createContext<AssistanceViewContextProps>({} as AssistanceViewContextProps);

export interface IEventField {
    // locator
    fieldName?: string;
    itemIndex?: number;
    itemType?: string;
    // view control
    reselectTargetRef?: any;
}

export const AssistanceViewContextProvider = ({
    onUpdate,
    onReselect,
    onFocusFieldChange,
    onHoverFieldChange,
    onUpdateFieldChange,
    onReselectFieldChange,
    onHoverItemIndexChange,
    onFastTrackChange,
    isFastTrackEnabled,
    generalConfig,
    children,
    previewRef,
}: AssistanceViewContextProviderProps & { children: any }) => {
    const handleFieldUpdate = useCallback(
        ({ payload, ...field }: { payload: any } & IEventField) => {
            onUpdateFieldChange(field);
            return Promise.resolve(onUpdate({ payload, ...field })).finally(() => {
                onUpdateFieldChange(null);
            });
        },
        [onUpdate]
    );

    const handleFieldAction = useCallback(
        ({
            action,
            payload,
            ...field
        }: {
            action: string;
            payload?: any;
        } & IEventField) => {
            onUpdateFieldChange(field);
            return Promise.resolve(onUpdate({ ...field, action, payload })).finally(() => {
                onUpdateFieldChange(null);
            });
        },
        [onUpdate]
    );

    const handleFieldReselectStart = useCallback((field: IEventField) => {
        onReselectFieldChange(field);
    }, []);

    const handleFieldReselectEnd = useCallback((field: IEventField) => {
        onReselectFieldChange(null);
    }, []);

    const handleFieldReselect = useCallback(
        ({
            bbox,
            pageIndex,
            ...field
        }: {
            bbox: any;
            pageIndex: number;
        } & IEventField) => {
            const reselectFieldName = field?.fieldName?.split('__').map(camelCase).join('.');

            onUpdateFieldChange(field);
            return Promise.resolve(onReselect({ ...field, fieldName: reselectFieldName, bbox, pageIndex })).finally(
                () => {
                    onUpdateFieldChange(null);
                }
            );
        },
        [onReselect]
    );

    const handleFieldFocus = useCallback((field: IEventField) => {
        onFocusFieldChange(field);
    }, []);

    const handleFieldBlur = useCallback((field: IEventField) => {
        onFocusFieldChange(null);
    }, []);

    const handleFieldMouseEnter = useCallback((field: IEventField) => {
        onHoverFieldChange(field);
    }, []);

    const handleFieldMouseLeave = useCallback((field: IEventField) => {
        onHoverFieldChange(null);
    }, []);

    const handleItemMouseEnter = useCallback((itemIndex: number) => {
        onHoverItemIndexChange(itemIndex);
    }, []);

    const handleItemMouseLeave = useCallback((itemIndex: number) => {
        onHoverItemIndexChange(null);
    }, []);

    const contextValue = useMemo(
        () => ({
            onFieldUpdate: handleFieldUpdate,
            onFieldAction: handleFieldAction,
            onFieldReselect: handleFieldReselect,
            onFieldReselectStart: handleFieldReselectStart,
            onFieldReselectEnd: handleFieldReselectEnd,
            onFieldFocus: handleFieldFocus,
            onFieldBlur: handleFieldBlur,
            onFieldMouseEnter: handleFieldMouseEnter,
            onFieldMouseLeave: handleFieldMouseLeave,
            onItemMouseEnter: handleItemMouseEnter,
            onItemMouseLeave: handleItemMouseLeave,
            onFastTrackChange,
            isFastTrackEnabled,
            generalConfig,
            previewRef,
        }),
        [
            handleFieldUpdate,
            handleFieldAction,
            handleFieldReselect,
            handleFieldReselectStart,
            handleFieldReselectEnd,
            handleFieldFocus,
            handleFieldBlur,
            handleFieldMouseEnter,
            handleFieldMouseLeave,
            handleItemMouseEnter,
            handleItemMouseLeave,
            onFastTrackChange,
            isFastTrackEnabled,
            generalConfig,
            previewRef,
        ]
    );

    return <AssistanceViewContext.Provider value={contextValue}>{children}</AssistanceViewContext.Provider>;
};

export const useAssistanceViewContext = () => useContext(AssistanceViewContext);
