import * as React from 'react';

export interface IHighlightedText extends React.HTMLAttributes<HTMLDivElement> {
    text: string | React.ReactNode;
    highlightPattern?: string;
}

export const HighlightedText = ({ text, pattern }) => {
    if (pattern == null || text == null) {
        return text;
    }
    const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedPattern})`, 'gi'));
    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part.toLowerCase() === pattern.toLowerCase() ? (
                <b style={{ fontWeight: 700, background: 'rgba(0,0,0,0.05)' }}>{part}</b>
            ) : (
                part
            )}
        </React.Fragment>
    ));
};
