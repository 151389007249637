import * as React from 'react';

interface AssistanceFieldContext {
    fieldIndex: number;
    fieldName: string;
    fullFieldName: string;
    value: any;
    data: any;
    config: any;
    isRequired: boolean;
    isEmpty: boolean;
    isLoading: boolean;
    isUpdated?: boolean;
    isInvalid?: boolean;
    isUpdateActive?: boolean;
    isReselectActive?: boolean;
    isFocusActive?: boolean;
    isHoverActive?: boolean;
    clientPartitionId?: string;
    matchingDocumentNumber?: string;
}

const AssistanceFieldContext = React.createContext<AssistanceFieldContext>({} as AssistanceFieldContext);
export const AssistanceFieldContextProvider = ({
    children,
    ...props
}: AssistanceFieldContext & { children: React.ReactNode }) => {
    return <AssistanceFieldContext.Provider value={props}>{children}</AssistanceFieldContext.Provider>;
};
export const useAssistanceFieldContext = () => React.useContext(AssistanceFieldContext);
