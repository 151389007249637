import * as React from 'react';
import { HeaderDataFieldGroupControls } from '../../../generic_document/pages/Assistance/HeaderDataSection';
import {
    FocusActionClickButton,
    ItemMoreActionsButton,
} from '../../../generic_document/pages/Assistance/ItemDataSection';

export const UniversalHeaderDataFieldGroupControls = ({
    fields,
    fieldName,
    onFieldFocus,
    onFieldReselect,
    focusField,
}: any) => {
    return (
        <HeaderDataFieldGroupControls
            fields={fields}
            fieldName={fieldName}
            onFieldFocus={onFieldFocus}
            onFieldReselect={onFieldReselect}
            focusField={focusField}
            // Not supported for Universal product
            onClear={null}
            onFocus={null}
        />
    );
};

export const UniversalFocusActionClickButton = ({ previewRef, viewMode, itemIndex }: any) => {
    return (
        <FocusActionClickButton
            previewRef={previewRef}
            viewMode={viewMode}
            itemIndex={itemIndex}
            // Not supported for Universal product
            isButtonEnabled={false}
        />
    );
};

export const UniversalItemMoreActionsButton = ({
    item,
    onActionClick,
    itemIndex,
    fieldNames,
    children,
    className,
    ...props
}: any) => {
    return (
        <ItemMoreActionsButton
            item={item}
            onActionClick={onActionClick}
            itemIndex={itemIndex}
            // Not supported for Universal product
            fieldNames={null}
            isCorrectActionDisabled={true}
            {...props}
        />
    );
};
