import * as React from 'react';

import { ANALYTICS_DASHBOARD_PATH } from './constants';
import Dashboard from './pages/Dashboard';
import { canSeeAnalytics } from '../users/utils';

export default [
    {
        path: ANALYTICS_DASHBOARD_PATH,
        component: Dashboard,
        condition: ({ user }) => canSeeAnalytics(user),
    },
];
