import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';

export interface StringFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {}

const StringField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            controls,
            className,
            readOnly,
            disabled,
            inputRef = undefined,
            ...props
        }: StringFieldProps,
        ref: any
    ) => {
        const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

        const handleChange = (e: any) => {
            setValue(e.target.value);
            onChange?.(e);
        };

        return (
            <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
                <Field.Input>
                    <input
                        type="text"
                        value={value || ''}
                        onChange={handleChange}
                        className="px-2 py-1.5 flex-1 min-w-0 bg-transparent"
                        readOnly={readOnly}
                        disabled={disabled}
                        {...props}
                        ref={inputRef}
                    />
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        );
    }
);

export default StringField;
