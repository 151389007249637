import * as React from 'react';
import moment from 'moment/moment';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

import { withIcon } from '../../core_updated/components/Icon';
import classnames from '../../core_updated/utils/classnames';
import { capitalizeFirstLetter } from '../../utils/string';
import { useLocalStorage } from '../../core/utils/hooks/useLocalStorage';

export interface Announcement {
    id: string;
    announcementType: 'info' | 'issue' | 'maintenance';
    title: string;
    message: string;
    titleDe?: string;
    messageDe?: string;
    externalLink?: string;
    startedAt: string;
    resolvedAt?: string;
}

const CloseIcon = withIcon(faXmark);

const useAnnouncementBar = (id: string) => {
    const [closedAnnouncements, setClosedAnnouncements] = useLocalStorage('closedAnnouncements', []);

    const isClosed = closedAnnouncements.includes(id);
    const close = () => {
        setClosedAnnouncements([...closedAnnouncements, id]);
    };

    return { isClosed, close };
};

const AnnouncementBar = ({ announcement }: { announcement: Announcement }) => {
    const { t } = useTranslation('announcements');

    const { isClosed, close } = useAnnouncementBar(announcement.id);

    let title = announcement.title;
    if (announcement[`title${capitalizeFirstLetter(i18n.language)}`]) {
        title = announcement[`title${capitalizeFirstLetter(i18n.language)}`];
    }

    let message = announcement.message;
    if (announcement[`message${capitalizeFirstLetter(i18n.language)}`]) {
        message = announcement[`message${capitalizeFirstLetter(i18n.language)}`];
    }

    const startedAt = announcement.startedAt ? moment(announcement.startedAt) : null;
    const announcementType = announcement.announcementType.toLowerCase();

    if (isClosed) {
        return null;
    }

    return (
        <div
            className={classnames(
                'w-full px-7 pr-16 py-4 text-sm flex flex-wrap justify-center items-center gap-4 bg-secondary border-b border-solid border-primary relative',
                announcementType == 'info' && 'bg-success text-success',
                announcementType == 'maintenance' && 'bg-warning text-warning',
                announcementType == 'issue' && 'bg-error text-error'
            )}
        >
            <div className="justify-center">
                <span className="font-medium inline-block mr-2">{title}</span>
                <span>{message}</span>
                {announcement.externalLink && (
                    <a
                        className="underline !text-brand-default hover:!text-brand-hover ml-2"
                        target="_blank"
                        href={announcement.externalLink}
                    >
                        {t('announcementBar.learnMore')}
                    </a>
                )}
                {startedAt && <span className="text-xs text-secondary inline-block ml-2">{startedAt.fromNow()}</span>}
            </div>

            <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                <button
                    className="flex items-center justify-center w-8 h-8 hover:bg-tertiary rounded transition-colors duration-200"
                    onClick={close}
                >
                    <CloseIcon />
                </button>
            </div>
        </div>
    );
};

export default AnnouncementBar;
