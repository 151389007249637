import DocumentAssistance from '../../../generic_document/pages/Assistance';
import {
    DELETE_ORDER_CONFIRMATION,
    DISCARD_ORDER_CONFIRMATION,
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    ORDER_CONFIRMATION_EXTRACT_TEXT,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../../queries';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../../constants';
import { getHeaderFieldConfigs, getLineItemFieldConfigs } from './utils';
import { DocumentType } from '../../../generic_document/constants';
import {
    getCustomerFieldConditionalComponents,
    getCustomerFieldGroupConditionalComponents,
} from '../../../generic_document/components/CustomerField';
import { getAddressFieldConditionalComponents } from '../../../generic_document/components/AddressField';
import { ArticleNumberFieldConditionalComponents } from '../../../generic_document/components/ArticleNumberField';
import * as React from 'react';

const OrderConfirmationAssistance = (props) => {
    const documentConfiguration = {
        documentType: DocumentType.OrderConfirmation,
        documentTypeName: 'orderConfirmation',
        GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
        GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
        RETRY_STEP: RETRY_STEP,
        DISCARD: DISCARD_ORDER_CONFIRMATION,
        DELETE: DELETE_ORDER_CONFIRMATION,
        REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
        ASSISTANCE_PATH: ASSISTANCE_PATH,
        ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
        OVERVIEW_PATH: OVERVIEW_PATH,
        OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
        OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
        CHANNEL_PATH: CHANNEL_PATH,
        CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
        CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
        RE_UPLOAD_FILE: RE_UPLOAD_FILE,
        EXTRACT_TEXT: ORDER_CONFIRMATION_EXTRACT_TEXT,
        SEND_TO_LABELING: SEND_TO_LABELING,
        getHeaderFieldConfigs: getHeaderFieldConfigs,
        getLineItemFieldConfigs: getLineItemFieldConfigs,
        components: {
            Field: [
                ...getCustomerFieldConditionalComponents('supplier'),
                ...getCustomerFieldConditionalComponents('buyer'),
                ...getAddressFieldConditionalComponents('delivery_address'),
                ...ArticleNumberFieldConditionalComponents,
            ],
            HeaderDataFieldGroup: [
                ...getCustomerFieldGroupConditionalComponents('supplier'),
                ...getCustomerFieldGroupConditionalComponents('buyer'),
            ],
        },
    };

    return <DocumentAssistance documentConfiguration={documentConfiguration} props={props} />;
};

export default OrderConfirmationAssistance;
