import * as React from 'react';

import Loader from '../../../core/components/Loader';
import InputDataPreview from '../InputDataPreview';

import './style.scss';

const SourceFileView = (props) => {
    const { file, formButtons, readOnly, loading } = props;
    return (
        <div className="source-file-view assistance-view__inner">
            {file === undefined ? (
                <Loader className="source-file-view__loader" />
            ) : (
                <div className="source-file-view__preview-wrapper">
                    <InputDataPreview data={file} className="source-file-view__preview" />
                </div>
            )}
            {!readOnly && !loading && <div className="assistance-view__preview-buttons">{formButtons}</div>}
        </div>
    );
};

export default SourceFileView;
