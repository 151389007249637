import { useCallback } from 'react';
import debounce from 'lodash.debounce';

export const useDebounce = (callback, delay, options = {}) => {
    const debouncedFn = useCallback(
        debounce((...args) => callback(...args), delay, options),
        [callback, delay] // will recreate if delay changes
    );
    return debouncedFn;
};
