import * as React from 'react';
import classnames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

import './style.scss';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import { mergeRefs } from '../../../core_updated/utils/mergeRefs';

interface IProps {
    content?: any;
    children: any;
    placement?: Placement;
    className?: string;
    long?: boolean;
    triggerRef?: any;
    overflowOnly?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
}

const Tooltip = React.forwardRef((props: IProps, ref: any) => {
    const { content, children, placement = 'bottom', long = false, className, triggerRef: propsTriggerRef } = props;
    const placementClass = placement.split(' ').map((p) => `tooltip--${p.replace('-start', '').replace('-end', '')}`);

    const [isOverflowing, setIsOverflowing] = useState(false);

    const wrapperRef = useRef(null);
    const triggerRef = propsTriggerRef || wrapperRef;
    const [tooltipElement, setTooltipElement] = useState(null);
    const [isTooltipVisible, setIsTooltipVisible] = useControllableState(false, props.open, props.onOpenChange);
    const showTooltip = () => setIsTooltipVisible(true);
    const hideTooltip = () => setIsTooltipVisible(false);

    useEffect(() => {
        if (!triggerRef || !triggerRef.current) return;

        triggerRef.current?.addEventListener('mouseenter', showTooltip);
        triggerRef.current?.addEventListener('mouseleave', hideTooltip);
        triggerRef.current?.addEventListener('focus', hideTooltip);
        triggerRef.current?.addEventListener('click', hideTooltip);

        return () => {
            triggerRef.current?.removeEventListener('mouseenter', showTooltip);
            triggerRef.current?.removeEventListener('mouseleave', hideTooltip);
            triggerRef.current?.removeEventListener('focus', hideTooltip);
            triggerRef.current?.removeEventListener('click', hideTooltip);
        };
    }, [triggerRef?.current]);

    const popperOptions = {
        placement,
        modifiers: [{ name: 'preventOverflow', enabled: true, options: { altAxis: true } }],
    } as any;

    const popper = usePopper(wrapperRef.current, tooltipElement, popperOptions);
    // needed when mounting tooltips via portal to make sure their position stays updated when the trigger element moves
    const refreshPopperPosition = () => popper.update();

    useEffect(() => {
        if (!triggerRef?.current) return;
        triggerRef.current.addEventListener('mouseenter', () => {
            setIsOverflowing(triggerRef.current.scrollWidth > triggerRef.current.offsetWidth);
        });
    }, [triggerRef?.current]);

    if (props.overflowOnly && !isOverflowing) {
        return (
            <span ref={mergeRefs(wrapperRef, ref)} className={className}>
                {children}
            </span>
        );
    }

    if (content == null) {
        return children;
    }

    return (
        <>
            <span
                ref={mergeRefs(wrapperRef, ref)}
                onMouseEnter={() => {
                    refreshPopperPosition();
                }}
                onMouseLeave={() => {
                    refreshPopperPosition();
                }}
                className={classnames('tooltip-trigger', className)}
            >
                {children}
            </span>
            {createPortal(
                <span
                    role="tooltip"
                    ref={setTooltipElement}
                    data-show={isTooltipVisible}
                    // Inject popper's positioning & styling
                    style={popper.styles.popper}
                    {...popper.attributes.popper}
                    className={classnames('tooltip', placementClass, long && 'tooltip--long')}
                >
                    <p>{content}</p>
                </span>,
                document.getElementById('modal-root')
            )}
        </>
    );
});

export default Tooltip;
