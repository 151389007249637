const alphaNumericSorter = (a, b) => {
    /*
    This method can be used to sort a list of strings in a way that the numbers in the string are sorted in a numeric way.

    For example, the following array:
    ['Werk 123 Berlin', 'Werk 23 Mitte', 'Bla', '123']

    will be sorted to:
    ['123', 'Bla', 'Werk 23 Mitte', 'Werk 123 Berlin']
    */

    const isNumber = (v) => (+v).toString() === v;
    const aPart = a.match(/\d+|\D+/g) || [];
    const bPart = b.match(/\d+|\D+/g) || [];
    let i = 0;
    let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) {
        i++;
    }
    if (i === len) {
        return aPart.length - bPart.length;
    }
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
    }
    return aPart[i].localeCompare(bPart[i]);
};

export default alphaNumericSorter;
