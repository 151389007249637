import { gql } from '@apollo/client';

export const GET_TEAMS = gql`
    query {
        teams {
            id
            name
            description
            users {
                id
                email
                firstName
                lastName
            }
            isDefault
        }
    }
`;

export const GET_TEAM = gql`
    query Team($id: UUID!) {
        team(id: $id) {
            id
            name
            description
            users {
                id
                email
                firstName
                lastName
            }
            isDefault
            isDeletable
        }
    }
`;

export const ADD_TEAM = gql`
    mutation AddTeam($name: String!, $description: String, $users: [String]) {
        addTeam(name: $name, description: $description, users: $users) {
            success
            team {
                id
            }
        }
    }
`;

export const UPDATE_TEAM = gql`
    mutation UpdateTeam($id: UUID!, $name: String, $description: String, $users: [String]) {
        updateTeam(id: $id, name: $name, description: $description, users: $users) {
            success
            team {
                id
            }
        }
    }
`;

export const DELETE_TEAM = gql`
    mutation DeleteTeam($id: UUID!) {
        deleteTeam(id: $id) {
            success
        }
    }
`;
