import { gql } from '@apollo/client';

export const GET_NAVIGATION_CHANNELS = gql`
    query {
        rfqProcessingChannels {
            id
            name
            openDocumentsCount
        }

        orderProcessingChannels {
            id
            name
            openDocumentsCount
        }

        orderConfirmationProcessingChannels {
            id
            name
            openDocumentsCount
        }

        invoiceProcessingChannels {
            id
            name
            openDocumentsCount
        }

        propertyBillProcessingChannels {
            id
            name
            openDocumentsCount
        }

        deliveryNoteProcessingChannels {
            id
            name
            openDocumentsCount
        }

        listOfServicesProcessingChannels {
            id
            name
            openDocumentsCount
        }

        universalProcessingChannels {
            id
            name
            openDocumentsCount
        }
    }
`;
