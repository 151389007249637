import React from 'react';
import AutoCompleteField from '../../core_updated/components/Fields/AutoCompleteField';
import { useAssistanceFieldContext } from '../pages/Assistance/AssistanceFieldContext';
import { renderAddressOption } from './useLookup';
import { FieldProps, useFieldProps } from '../pages/Assistance/customizable/Field';
import { IConditionalComponent } from '../../customizations/ConditionalComponentContext';
import useForceSelectionLookupProps, { useForceSelectionReselectEventListener } from './useForceSelectionLookupProps';
import { COUNTRY_CODES } from '../../utils';
import SelectField from '../../core_updated/components/Fields/SelectField';

export const AddressIdField = (props: FieldProps) => {
    const { clientPartitionId, config } = useAssistanceFieldContext();
    const { lookupDefinitionId, lookupFieldName, allowInvalidIds } = config;

    const fieldProps = useFieldProps(props);
    const { value, onValueChange, onFocus, onBlur } = fieldProps;

    const forceSelectionLookupProps = useForceSelectionLookupProps({
        lookupType: 'AddressLookupType',
        lookupDefinitionId,
        lookupFieldName,
        partitionId: clientPartitionId,
        allowInvalidIds,
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus,
        onBlur,
    });

    useForceSelectionReselectEventListener(props.inputRef, forceSelectionLookupProps.onValueChange);

    return <AutoCompleteField {...fieldProps} {...forceSelectionLookupProps} renderOption={renderAddressOption} />;
};

const CountryField = (props: FieldProps) => {
    const fieldProps = useFieldProps(props);

    return (
        <SelectField options={COUNTRY_CODES.map((country) => ({ value: country, label: country }))} {...fieldProps} />
    );
};

export const getAddressFieldConditionalComponents = (addressFieldKey: string): IConditionalComponent[] => [
    {
        condition: ({ fieldName }) => fieldName === `${addressFieldKey}__address_id`,
        component: AddressIdField,
    },
    {
        condition: ({ fieldName }) => fieldName === `${addressFieldKey}__country`,
        component: CountryField,
    },
];
