import * as React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import type { ICustomer } from '../../interfaces';
import { IBaseModalProps } from '../../../core/components/Modal';

import ConfirmModal from '../../../core/containers/ConfirmModal';
import Select from '../../../core/containers/Select';
import MenuItem from '../../../core/components/MenuItem';
import { parseCron, makeCron, getRange } from '../../services';

import './style.scss';

interface IProps extends IBaseModalProps {
    className?: string;
    customer: ICustomer;
    onSubmit: (object) => void;
    onCancel: () => void;
}

const EmailNotificationsModal = (props: IProps) => {
    const { className, customer, onSubmit, onCancel, ...modalProps } = props;

    const { t } = useTranslation('customer');

    const [option, setOption] = useState('always');
    const [day, setDay] = useState('1');
    const [time, setTime] = useState('');
    const [additionalTime, setAdditionalTime] = useState('');

    useEffect(() => {
        const enabled = customer?.sendEmailNotification || true;
        const cron = customer?.emailNotificationCron || '';
        const cronParts = parseCron(cron);
        setOption(!enabled ? 'never' : cronParts.option ? cronParts.option : 'always');
        setDay(cronParts.day || '1');
        setTime(cronParts.time || '8');
        setAdditionalTime(cronParts.additionalTime || '');
    }, [customer]);

    const options = {
        never: t('emailNotificationModal.never'),
        always: t('emailNotificationModal.always'),
        daily: t('emailNotificationModal.daily'),
        weekly: t('emailNotificationModal.weekly'),
    };

    const timeOptions = getRange(6, 21).map((hour) => [hour.toString(), `${hour.toString().padStart(2, '0')}:00`]);
    const dayOptions = getRange(1, 6).map((day) => [day.toString(), moment().day(day).format('dddd')]);

    const handleSubmit = () =>
        onSubmit({
            sendEmailNotification: option != 'never',
            emailNotificationCron: ['daily', 'weekly'].includes(option)
                ? makeCron(option, day, time, additionalTime)
                : '',
        });

    return (
        <ConfirmModal
            className={classnames('confirm-modal', className)}
            onConfirm={handleSubmit}
            onCancel={onCancel}
            labelCancel={t('emailNotificationModal.cancel')}
            labelConfirm={t('emailNotificationModal.ok')}
            {...modalProps}
        >
            <div className="email-notifications-modal__message email-notifications-modal__form">
                <div className="email-notifications-modal__group">
                    <label className="email-notifications-modal__label">{t('emailNotificationModal.label')}</label>
                    <Select className="email-notifications-modal__input" value={option} onChange={setOption}>
                        {Object.entries(options).map(([key, value]) => (
                            <MenuItem label={value} value={key} key={key} />
                        ))}
                    </Select>
                </div>

                {option == 'weekly' && (
                    <div className="email-notifications-modal__group">
                        <label className="email-notifications-modal__label">{t('emailNotificationModal.day')}</label>
                        <Select className="email-notifications-modal__input" value={day} onChange={setDay}>
                            {dayOptions.map(([key, value]) => (
                                <MenuItem label={value} value={key} key={key} />
                            ))}
                        </Select>
                    </div>
                )}

                {['daily', 'weekly'].includes(option) && (
                    <div className="email-notifications-modal__group">
                        <label className="email-notifications-modal__label">{t('emailNotificationModal.time')}</label>
                        <Select className="email-notifications-modal__input" value={time} onChange={setTime}>
                            {timeOptions.map(([key, value]) => (
                                <MenuItem label={value} value={key} key={key} />
                            ))}
                        </Select>
                    </div>
                )}

                {option == 'daily' && (
                    <div className="email-notifications-modal__group">
                        <label className="email-notifications-modal__label">
                            {t('emailNotificationModal.additionalTime')}
                        </label>
                        <Select
                            className="email-notifications-modal__input"
                            value={additionalTime}
                            onChange={setAdditionalTime}
                        >
                            <MenuItem label="------" value="" key="empty" />
                            {timeOptions.map(([key, value]) => (
                                <MenuItem label={value} value={key} key={key} />
                            ))}
                        </Select>
                    </div>
                )}
            </div>
        </ConfirmModal>
    );
};

export default EmailNotificationsModal;
