import * as React from 'react';
import { createContext } from 'react';
import MainShell, { useChannels } from '../core/shells/MainShell';
import { DocumentType } from './constants';
import { canManageAllDocumentTypes, canSeeTestingDocuments } from '../users/utils';
import { IRouteProps } from '../core/utils/modules';
import MasterdataImportLog from '../masterdata/containers/MasterdataImportLog';
import { ChannelContext } from '../stores';
import Layout from '../core_updated/components/Layout';

interface RouteFactoryProps {
    paths: RouteFactoryPathProps;
    pageComponents: RouteFactoryPageComponentProps;
    documentType: DocumentType;
    documentTypeNavTabKey: string;
    documentTypeEnabled: (customer: any) => boolean;
}

interface RouteFactoryPathProps {
    allDocuments: string;
    allDocumentsFinished: string;
    allDocumentsTesting: string;
    channelDocuments: string;
    channelDocumentsFinished: string;
    channelDocumentsTesting: string;
    assistance: string;
    assistanceTab: string;
    channelConfig: string;
    channelMasterdata: string;
}

interface RouteFactoryPageComponentProps {
    Overview: any;
    Configuration: any;
    Assistance: any;
}

interface INavigationContext {
    paths?: RouteFactoryPathProps;
    channelId?: string;
    recordId?: string;
}

export const NavigationContext = createContext<INavigationContext>({ paths: null, channelId: null, recordId: null });

export const buildRoutes = ({
    paths,
    pageComponents,
    documentType,
    documentTypeNavTabKey,
    documentTypeEnabled,
}: RouteFactoryProps): Array<IRouteProps> => {
    const { Overview, Configuration, Assistance } = pageComponents;
    return [
        {
            path: paths.allDocuments,
            component: (props) => {
                const { activeChannel } = useChannels(documentType);
                return (
                    <Layout>
                        <ChannelContext.Provider value={{ activeChannel, documentType }}>
                            <Overview finished={false} {...props} />
                        </ChannelContext.Provider>
                    </Layout>
                );
            },
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
        {
            path: paths.allDocumentsFinished,
            component: (props) => {
                const { activeChannel } = useChannels(documentType);
                return (
                    <Layout>
                        <ChannelContext.Provider value={{ activeChannel, documentType }}>
                            <Overview finished={true} {...props} />
                        </ChannelContext.Provider>
                    </Layout>
                );
            },
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
        {
            path: paths.channelDocuments,
            component: (props) => {
                const { activeChannel } = useChannels(documentType);
                return (
                    <Layout>
                        <ChannelContext.Provider value={{ activeChannel, documentType }}>
                            <Overview finished={false} {...props} key={props?.params?.channelId} />
                        </ChannelContext.Provider>
                    </Layout>
                );
            },
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
        {
            path: paths.channelDocumentsFinished,
            component: (props) => {
                const { activeChannel } = useChannels(documentType);
                return (
                    <Layout>
                        <ChannelContext.Provider value={{ activeChannel, documentType }}>
                            <Overview finished={true} {...props} key={props?.params?.channelId} />
                        </ChannelContext.Provider>
                    </Layout>
                );
            },
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
        {
            path: paths.allDocumentsTesting,
            component: (props) => {
                const { activeChannel } = useChannels(documentType);
                return (
                    <Layout>
                        <ChannelContext.Provider value={{ activeChannel, documentType }}>
                            <Overview showTestingDocuments={true} {...props} />
                        </ChannelContext.Provider>
                    </Layout>
                );
            },
            condition: ({ user }) => canSeeTestingDocuments(user),
        },
        {
            path: paths.channelDocumentsTesting,
            component: (props) => {
                const { activeChannel } = useChannels(documentType);
                return (
                    <Layout>
                        <ChannelContext.Provider value={{ activeChannel, documentType }}>
                            <Overview showTestingDocuments={true} {...props} key={props?.params?.channelId} />
                        </ChannelContext.Provider>
                    </Layout>
                );
            },
            condition: ({ user }) => canSeeTestingDocuments(user),
        },
        {
            path: paths.channelConfig,
            component: (props) => (
                <MainShell
                    documentType={documentType}
                    activeNavTab={documentTypeNavTabKey}
                    channelPath={paths.channelDocuments}
                    overviewPath={paths.allDocuments}
                    title="config:configPage.manageChannel"
                    {...props}
                >
                    <Configuration {...props} />
                </MainShell>
            ),
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
        {
            path: paths.channelMasterdata,
            component: (props) => (
                <MainShell
                    documentType={documentType}
                    activeNavTab={documentTypeNavTabKey}
                    channelPath={paths.channelDocuments}
                    overviewPath={paths.allDocuments}
                    title="assistance:overview.header.actions.manageMasterdata"
                    className="main-shell--full"
                    {...props}
                >
                    <MasterdataImportLog {...props} />
                </MainShell>
            ),
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
        {
            path: paths.assistance,
            component: (props) => <Assistance key={props?.params?.recordId} {...props} />,
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
        {
            path: paths.assistanceTab,
            component: (props) => <Assistance key={props?.params?.recordId} {...props} />,
            condition: ({ user }) =>
                (user?.customer && documentTypeEnabled(user?.customer)) || canManageAllDocumentTypes(user),
        },
    ].map(({ component: WrappedComponent, ...rest }) => ({
        ...rest,
        // Inject navigation context for each page
        component: (props) => {
            return (
                <NavigationContext.Provider value={{ paths }}>
                    <WrappedComponent {...props} />
                </NavigationContext.Provider>
            );
        },
    }));
};
