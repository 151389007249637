import { useParams as useRouterParams } from 'react-router-dom';
import { useChannelStore } from '../../../stores';
import { useEffect } from 'react';

/**
 * Custom hook for retrieving the current channel from the URL params & syncing it to the store.
 */
export const useCurrentChannel = () => {
    const { channelId } = useRouterParams();
    const setActiveChannel = useChannelStore((state) => state.setActiveChannel);

    useEffect(
        function _syncActiveChannelToStore() {
            setActiveChannel(channelId);
        },
        [channelId]
    );

    return { channelId };
};
