import { camelCase, snakeCase } from 'lodash';

/**
 * Recursively converts all keys with their camelCase version in a given object.
 * Taken from https://stackoverflow.com/a/50620653
 *
 * @param inputObject The object of which the keys (and sub-keys, recursively) should be made camelCase.
 */
export function objectKeysToCamelCase(inputObject: any): object {
    if (Array.isArray(inputObject)) {
        return inputObject.map((v) => objectKeysToCamelCase(v));
    } else if (inputObject !== null && inputObject.constructor === Object) {
        return Object.keys(inputObject).reduce(
            (result: object, key: string) => ({
                ...result,
                [camelCase(key)]: objectKeysToCamelCase(inputObject[key]),
            }),
            {}
        );
    }
    return inputObject;
}

export function objectKeysToSnakeCase(inputObject: any): object {
    if (Array.isArray(inputObject)) {
        return inputObject.map((v) => objectKeysToSnakeCase(v));
    } else if (inputObject !== null && inputObject.constructor === Object) {
        return Object.keys(inputObject).reduce(
            (result: object, key: string) => ({
                ...result,
                [snakeCase(key)]: objectKeysToSnakeCase(inputObject[key]),
            }),
            {}
        );
    }
    return inputObject;
}
