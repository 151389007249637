import * as React from 'react';
import classnames from '../../core_updated/utils/classnames';
import DropdownMenu from '../../core_updated/components/DropdownMenu';
import { withIcon } from '../../core_updated/components/Icon';
import { faChevronDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../core_updated/components/Button';

export interface FilterDropdownOption {
    label: string;
    value: string;
}

interface FilterDropdownProps {
    allLabel?: string;
    label?: string;

    options: FilterDropdownOption[];
    renderOption?: (option: FilterDropdownOption) => React.ReactNode; // TODO maybe this is not the cleanest way to do this

    selected?: string[];
    onSelectedChange?: (selected: string[]) => void;
    multiple?: boolean;

    required?: boolean;
    className?: string;
}

const ChevronDownIcon = withIcon(faChevronDown);
const ClearIcon = withIcon(faXmark);

const FilterDropdown = ({
    allLabel,
    label: defaultLabel,

    options,
    renderOption,

    selected = [],
    onSelectedChange,
    multiple = false,

    required = false,
    className,
}: FilterDropdownProps) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const handleCheckedChange = (value) => (checked: boolean) => {
        if (checked) {
            onSelectedChange?.(multiple ? [...selected, value] : [value]);
        } else {
            onSelectedChange?.(multiple ? selected.filter((selectedValue) => selectedValue !== value) : []);
        }
    };

    const handleClear = () => {
        onSelectedChange?.([]);
    };

    // in case no item is selected we show the default label
    let label: string = defaultLabel;
    if (!multiple || options.length === 1 || (multiple && selected.length === 1)) {
        // in case only one option is allowed or only one option given we either show that option or the default label
        // OR in case only one option is selected we show that option
        label = options.find((option) => option.value === selected[0])?.label ?? selected[0] ?? defaultLabel;
        if (label !== defaultLabel) {
            label = `${defaultLabel} ${label}`;
        }
    } else if (selected.length === options.length) {
        // in case all options are selected we show the all label
        label = allLabel;
    }

    return (
        <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
            <div className="relative text-sm">
                <DropdownMenu.Trigger
                    onPointerDown={(e) => e.preventDefault()}
                    onClick={() => setIsOpen((prev) => !prev)}
                    asChild
                >
                    <Button className={classnames('pr-8', className)} active={selected.length > 0}>
                        <span>{label}</span>
                        {multiple && selected.length > 1 && selected.length != options.length ? (
                            <span className="ml-1.5">({selected.length})</span>
                        ) : null}
                    </Button>
                </DropdownMenu.Trigger>

                {!required && selected.length > 0 ? (
                    <button
                        onClick={handleClear}
                        className={classnames(
                            'absolute right-0 top-0 transform flex items-center justify-center h-full px-3 text-primary',
                            (selected.length > 0 || isOpen) && 'text-brand'
                        )}
                    >
                        <ClearIcon />
                    </button>
                ) : (
                    <ChevronDownIcon
                        className={classnames(
                            'absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-primary',
                            (selected.length > 0 || isOpen) && 'text-brand'
                        )}
                    />
                )}
            </div>
            <DropdownMenu.Portal>
                <DropdownMenu.Content align="start" className="max-h-[260px] overflow-y-auto">
                    {multiple ? (
                        options?.map((option) => (
                            <DropdownMenu.CheckboxItem
                                key={option.value}
                                onCheckedChange={handleCheckedChange(option.value)}
                                checked={selected.includes(option.value)}
                                onSelect={(e) => e.preventDefault()}
                                className="outline-none"
                            >
                                {renderOption ? renderOption(option) : option.label}
                            </DropdownMenu.CheckboxItem>
                        ))
                    ) : (
                        <DropdownMenu.RadioGroup
                            value={selected[0]}
                            onValueChange={(value) => onSelectedChange?.([value])}
                        >
                            {options?.map((option) => (
                                <DropdownMenu.RadioItem
                                    key={option.value}
                                    value={option.value}
                                    className="outline-none"
                                >
                                    {renderOption ? renderOption(option) : option.label}
                                </DropdownMenu.RadioItem>
                            ))}
                        </DropdownMenu.RadioGroup>
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu>
    );
};

export default FilterDropdown;
