import * as React from 'react';
import { useMemo } from 'react';
import classnames from '../../../core_updated/utils/classnames';
import { withIcon } from '../../../core_updated/components/Icon';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import { BaseButton } from '../../../core_updated/components/Button';

interface AssistanceSectionContextProps {
    viewMode: 'list' | 'table';
}
const AssistanceSectionContext = React.createContext<AssistanceSectionContextProps>({
    viewMode: 'list',
});
export const useAssistanceSectionContext = () => React.useContext(AssistanceSectionContext);

const ToggleOpenIcon = withIcon(faChevronUp);
const ToggleCloseIcon = withIcon(faChevronDown);

const AssistanceSection = ({
    title,
    defaultOpen = true,
    open: controlledOpen,
    onOpenChange,
    controls,
    viewMode = 'list',
    children,
}: any) => {
    const [isOpen, setIsOpen] = useControllableState(defaultOpen, controlledOpen, onOpenChange);

    const contextValue = useMemo(
        () => ({
            viewMode,
        }),
        [viewMode]
    );

    return (
        <AssistanceSectionContext.Provider value={contextValue}>
            <div
                className={classnames(
                    'flex flex-col w-full border-b border-solid border-primary last:-mb-px relative',
                    isOpen && 'last:border-b-0'
                )}
            >
                <div className="flex justify-between gap-4 items-center sticky top-0 z-20 bg-primary p-6">
                    <button
                        className="text-sm flex gap-4 items-center rounded transition-colors duration-200 hover:bg-secondary h-8 px-2.5 -ml-2.5 text-primary"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <h3 className="text-sm font-medium">{title}</h3>
                        {isOpen ? <ToggleOpenIcon /> : <ToggleCloseIcon />}
                    </button>

                    {controls && <div className="flex gap-2 items-center">{controls}</div>}
                </div>
                <div className={classnames('p-6 pt-0', !isOpen && 'hidden')}>{children}</div>
            </div>
        </AssistanceSectionContext.Provider>
    );
};

const AssistanceSectionButtonSeparator = ({ className, ...props }: any) => {
    return <div className={classnames('h-6 border-l border-solid border-primary', className)} {...props} />;
};

const AssistanceSectionIconButton = React.forwardRef(({ className, active = false, ...props }: any, ref) => {
    return (
        <BaseButton
            className={classnames(
                'flex items-center justify-center gap-2 rounded transition-colors duration-200 hover:bg-secondary w-8 h-8 cursor-pointer text-sm font-medium text-primary hover:text-primary disabled:opacity-40 disabled:pointer-events-none data-[state=open]:bg-brand data-[state=open]:text-brand',
                active && '!bg-brand !text-brand',
                className
            )}
            {...props}
            ref={ref}
        />
    );
});

const AssistanceFieldGroups = ({ className, children, viewMode = 'list' }: any) => {
    return { children };
};

const AssistanceFieldGroup = ({ className, title, required = false, controls, children, ...props }: any) => {
    const { viewMode } = useAssistanceSectionContext();

    return (
        <div
            className={classnames('flex group', viewMode === 'list' ? 'flex-col gap-2' : 'gap-0.5', className)}
            {...props}
        >
            <span
                className={classnames(
                    'flex gap-2 items-center justify-between',
                    viewMode !== 'list' && 'sticky left-0 z-10 bg-primary p-px pr-2'
                )}
            >
                {viewMode === 'list' && (
                    <label className="text-sm font-medium text-primary">
                        {title} {required && <span className="text-error">*</span>}
                    </label>
                )}

                {controls && (
                    <span className={classnames('flex gap-1', viewMode === 'list' && 'mr-1')}>{controls}</span>
                )}
            </span>
            <div className={classnames('flex gap-2', viewMode === 'list' && 'flex-col')}>{children}</div>
        </div>
    );
};

const AssistanceFieldGroupIconButton = React.forwardRef(({ className, active = false, ...props }: any, ref) => {
    return (
        <BaseButton
            className={classnames(
                'flex h-8 w-8 items-center justify-center gap-1 rounded transition-colors hover:bg-secondary cursor-pointer text-sm font-medium text-primary hover:text-primary disabled:opacity-40 disabled:pointer-events-none',
                active && '!bg-brand !text-brand',
                className
            )}
            type="button"
            {...props}
            ref={ref}
        />
    );
});

const AssistanceFieldGroupLoadingSkeleton = ({ className, lines = 3, ...props }: any) => {
    const { viewMode } = useAssistanceSectionContext();

    return (
        <div
            className={classnames('flex gap-3 animate-pulse', viewMode === 'list' && 'flex-col', className)}
            {...props}
        >
            {viewMode === 'list' && (
                <span className="text-sm font-medium flex-none flex gap-2 items-center justify-between">
                    <label className="rounded-full bg-text-secondary h-2 w-32 block opacity-80" />
                </span>
            )}

            <div className={classnames('flex gap-2 flex-1', viewMode === 'list' && 'flex-col')}>
                {[...Array(lines)].map((_, i) => (
                    <div key={i} className="rounded-md h-8 w-full bg-secondary opacity-80" />
                ))}
            </div>
        </div>
    );
};

const AssistanceFieldLabel = ({ className, label, required = false, children, ...props }: any) => {
    const { viewMode } = useAssistanceSectionContext();

    return (
        <div className={classnames('flex gap-1', className)} {...props}>
            {viewMode === 'list' && (
                <label className="text-xs text-secondary w-36 flex-none flex justify-start gap-1 py-2">
                    <span className="truncate">{label}</span> {required && <span className="text-error">*</span>}
                </label>
            )}
            <div className={classnames('flex gap-1 flex-1 min-w-0', viewMode === 'list' && 'flex-col')}>{children}</div>
        </div>
    );
};

export default Object.assign(AssistanceSection, {
    ButtonSeparator: AssistanceSectionButtonSeparator,
    IconButton: AssistanceSectionIconButton,
    FieldGroup: AssistanceFieldGroup,
    FieldGroups: AssistanceFieldGroups,
    FieldGroupLoadingSkeleton: AssistanceFieldGroupLoadingSkeleton,
    FieldLabel: AssistanceFieldLabel,
    FieldGroupIconButton: AssistanceFieldGroupIconButton,
});
