import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegerField, StringField, TextField, useForm } from '../Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import ConfigurationCard from '../ConfigurationCard';

const SFTPConfiguration = ({ config, onActivate, onDeactivate = undefined, onSubmit }) => {
    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`sftpConfiguration.fieldNames.${fieldName}`),
    });
    useEffect(() => {
        setFormData({
            sftpHost: config?.sftpHost || '',
            sftpHostKey: config?.sftpHostKey || '',
            sftpPort: config?.sftpPort || null,
            sftpUser: config?.sftpUser || '',
            sftpPassword: config?.sftpPassword || '',
        });
    }, [config]);

    const handleSubmit = () => onSubmit(formData);

    const { t } = useTranslation('config');

    return (
        <ConfigurationCard
            title={t('sftpConfiguration.title')}
            description={t('sftpConfiguration.description')}
            active={!!config}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
        >
            <div className="form">
                <StringField {...getFieldProps('sftpHost')} className="field--vertical" />
                <TextField {...getFieldProps('sftpHostKey')} className="field--vertical" />
                <IntegerField {...getFieldProps('sftpPort')} className="field--vertical" />
                <StringField {...getFieldProps('sftpUser')} className="field--vertical" />
                <StringField {...getFieldProps('sftpPassword')} className="field--vertical" />
                <div>
                    <ProgressButton label={t('card.save')} onClick={handleSubmit} />
                </div>
            </div>
        </ConfigurationCard>
    );
};

export default SFTPConfiguration;
