import * as React from 'react';
import * as SwitchPrimitive from '@radix-ui/react-switch';

import './style.scss';

const Switch = ({ value = undefined, onValueChange = undefined, ...switchProps }) => {
    return (
        <div className="switch">
            <SwitchPrimitive.Root
                className="switch__root"
                checked={value}
                onCheckedChange={onValueChange}
                {...switchProps}
            >
                <SwitchPrimitive.Thumb className="switch__thumb" />
            </SwitchPrimitive.Root>
        </div>
    );
};

export default Switch;
