import { IModuleProps } from '../../core/utils/modules';

import locales from './locales';

export default {
    name: 'wilhelm_boellhoff_group',
    locales,
    featureFlags: {
        assistanceEmailThreads: true,
    },
} satisfies IModuleProps;
