import * as React from 'react';

import {
    OVERVIEW_PATH,
    OVERVIEW_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_FINISHED_PATH,
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_CONFIG_PATH,
    CHANNEL_MASTERDATA_PATH,
    OVERVIEW_TESTING_PATH,
    CHANNEL_TESTING_PATH,
} from './constants';
import Configuration from './pages/Configuration';
import Overview from './pages/Overview';
import RfqAssistance from './pages/RfqAssistance';

import { DocumentType } from '../generic_document/constants';
import { buildRoutes } from '../generic_document/routes';

export default buildRoutes({
    paths: {
        allDocuments: OVERVIEW_PATH,
        allDocumentsFinished: OVERVIEW_FINISHED_PATH,
        allDocumentsTesting: OVERVIEW_TESTING_PATH,
        channelDocuments: CHANNEL_PATH,
        channelDocumentsFinished: CHANNEL_FINISHED_PATH,
        channelDocumentsTesting: CHANNEL_TESTING_PATH,
        assistance: ASSISTANCE_PATH,
        assistanceTab: ASSISTANCE_TAB_PATH,
        channelConfig: CHANNEL_CONFIG_PATH,
        channelMasterdata: CHANNEL_MASTERDATA_PATH,
    },
    pageComponents: {
        Overview,
        Configuration,
        Assistance: RfqAssistance,
    },
    documentType: DocumentType.Rfq,
    documentTypeNavTabKey: 'rfq',
    documentTypeEnabled: (customer) => customer?.isRfqsEnabled,
});
