import * as React from 'react';

const resolveChildren = (children: React.ReactNode): React.ReactNode[] => {
    let resolvedChildren = [];

    React.Children.forEach(children, (child) => {
        if (React.isValidElement(child)) {
            if (child.type !== React.Fragment) {
                resolvedChildren.push(child);
            } else if (child.props.children) {
                resolvedChildren = resolvedChildren.concat(resolveChildren(child.props.children));
            }
        }
    });

    return resolvedChildren;
};

export default resolveChildren;
