import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { url } from '../../../core/utils/link';
import { PROFILE_PATH } from '../../../users/constants';
import { EDIT_PATH as ORGANIZATION_PATH } from '../../../customer/constants';
import { OVERVIEW_PATH as USERS_PATH } from '../../../users/constants';
import { GET_USER_FIRST_STEPS } from '../../queries';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const FirstStepItem = (props) => {
    const { title, description, linkTo, completed, disabled } = props;
    const Base = linkTo ? Link : 'div';
    return (
        <Base
            className={classnames(
                'first-step',
                completed && 'first-step--completed',
                disabled && 'first-step--disabled'
            )}
            to={linkTo}
        >
            <div className="first-step__text">
                <div className="first-step__title">{title}</div>
                <div className="first-step__description">{description}</div>
            </div>
            <div
                className={classnames(
                    'first-step__icon',
                    linkTo && 'first-step__icon--link',
                    completed && 'first-step__icon--completed'
                )}
            />
        </Base>
    );
};

const Welcome = (props) => {
    const { user, onDarkMode, isDarkMode, onLogout, children, channelMenuItems } = props;

    const { t } = useTranslation('welcome');

    const { data, error, loading } = useQuery(GET_USER_FIRST_STEPS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });

    const FIRST_STEPS = [
        {
            title: t('steps.email.title'),
            description: t('steps.email.description'),
            linkTo: url(PROFILE_PATH),
            completed: true, // otherwise you wouldn't see this page
            disabled: false,
        },
        {
            title: t('steps.organization.title'),
            description: t('steps.organization.description'),
            linkTo: url(ORGANIZATION_PATH),
            completed: [
                data?.viewer?.customer.name,
                data?.viewer?.customer.streetAddress,
                data?.viewer?.customer.postalCode,
                data?.viewer?.customer.city,
                data?.viewer?.customer.invoiceEmail,
            ].every((i) => i),
        },
        // TODO Check what onboarding looks like atm & re-enable this functionality
        /**
        {
            title: t('steps.channel.title'),
            description: t('steps.channel.description'),
            linkTo: url(CREATE_CHANNEL_PATH),
            completed: false, // we cannot access the channels here
        },
        */
        {
            title: t('steps.invite.title'),
            description: t('steps.invite.description'),
            linkTo: url(USERS_PATH),
            completed: false, // we cannot access the users here
        },
        {
            title: t('steps.activate.title'),
            description: t('steps.activate.description'),
            linkTo: undefined,
            completed: data?.viewer?.customer?.isActive,
            disabled:
                ![
                    data?.viewer?.customer.name,
                    data?.viewer?.customer.streetAddress,
                    data?.viewer?.customer.postalCode,
                    data?.viewer?.customer.city,
                    data?.viewer?.customer.invoiceEmail,
                    data?.viewer?.customer?.processSet?.length,
                ].every((i) => i) && !data?.viewer?.customer?.isActive,
        },
    ];

    return (
        <Layout>
            <div className="welcome">
                <div className="welcome__header">
                    <span>{t('hello.firstSteps')}</span>
                </div>
                <div className="welcome__greeting">
                    <span>{t('hello.greeting', { name: user.firstName })}</span>
                </div>
                <div className="welcome__intro">
                    <span>{t('hello.intro')}</span>
                </div>
                <div className="steps">
                    <div className="overview_steps">
                        {FIRST_STEPS.map((step, i) => (
                            <FirstStepItem key={i} {...step} />
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Welcome;
