import moment from 'moment/moment';

export const isoDateFormat = 'YYYY-MM-DD';
export const isoDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
export const isoDateTimeFormatWithTimezone = 'YYYY-MM-DDTHH:mm:ssZ';
export const isoDateTimeFormatWithMs = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const isoDateTimeFormatWithMsAndTimezone = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const isoDateTimeFormatWithMcs = 'YYYY-MM-DDTHH:mm:ss.SSSSSS';
export const isoDateTimeFormatWithMcsAndTimezone = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ';

export const isoTimeFormat = 'HH:mm:ss';
export const isoTimeFormatWithMs = 'HH:mm:ss.SSS';
export const isoTimeFormatWithMcs = 'HH:mm:ss.SSSSSS';

export const isoDateRegex = /\d{4}-[01]\d-[0-3]\d/;

/**
 * Converts the input date (given as a JS Date object, or a plain string) into an ISO date string. If the input
 * string is not an ISO-formatted date yet, try to convert to ISO format based on the candidate input formats.
 */
export const toISODateString = (date: Date | string, candidateFormats: [string]) => {
    if (date == null || date == '') {
        return date;
    }
    if (typeof date === 'string') {
        if (isoDateRegex.test(date)) {
            return date;
        } else {
            for (let candidateFormat of candidateFormats) {
                if (moment(date, candidateFormat).isValid()) {
                    return moment(date, candidateFormat).format(isoDateFormat);
                }
            }
        }
    }
    return null;
};

export const isDateInRange = (date: Date | string, minDate: Date, maxDate: Date, dateFormat = 'YYYY-MM-DD') => {
    if (date == null || date == '') {
        return false;
    }
    if (typeof date === 'string') {
        if (!moment(date, dateFormat).isValid()) {
            return false;
        }
        date = moment(date, dateFormat).toDate();
    }
    return (
        date >= minDate &&
        date <= maxDate &&
        date.getMonth() >= 0 &&
        date.getMonth() <= 11 &&
        date.getDay() >= 0 &&
        date.getDay() <= 30
    );
};

const PYTHON_TO_JS_DATE_VARIABLE_MAPPING = Object.freeze({
    '%A': 'dddd', //Weekday as locale’s full name: (In English: Sunday, .., Saturday)(Auf Deutsch: Sonntag, .., Samstag)
    '%a': 'ddd', //Weekday abbreivated: (In English: Sun, .., Sat)(Auf Deutsch: So, .., Sa)
    '%B': 'MMMM', //Month name: (In English: January, .., December)(Auf Deutsch: Januar, .., Dezember)
    '%b': 'MMM', //Month name abbreviated: (In English: Jan, .., Dec)(Auf Deutsch: Jan, .., Dez)
    '%c': 'ddd MMM DD HH:mm:ss YYYY', //Locale’s appropriate date and time representation: (English: Sun Oct 13 23:30:00 1996)(Deutsch: So 13 Oct 22:30:00 1996)
    '%d': 'DD', //Day 0 padded: (01, .., 31)
    '%f': 'SSS', //Microseconds 0 padded: (000000, .., 999999)
    '%H': 'HH', //Hour (24-Hour) 0 padded: (00, .., 23)
    '%I': 'hh', //Hour (12-Hour) 0 padded: (01, .., 12)
    '%j': 'DDDD', //Day of Year 0 padded: (001, .., 366)
    '%M': 'mm', //Minute 0 padded: (01, .. 59)
    '%m': 'MM', //Month 0 padded: (01, .., 12)
    '%p': 'A', //Locale equivalent of AM/PM: (EN: AM, PM)(DE: am, pm)
    '%S': 'ss', //Second 0 padded: (00, .., 59)
    '%U': 'ww', //Week # of Year (Sunday): (00, .., 53)  All days in a new year preceding the first Sunday are considered to be in week 0.
    '%W': 'ww', //Week # of Year (Monday): (00, .., 53)  All days in a new year preceding the first Monday are considered to be in week 0.
    '%w': 'd', //Weekday as #: (0, 6)
    '%X': 'HH:mm:ss', //Locale's appropriate time representation: (EN: 23:30:00)(DE: 23:30:00)
    '%x': 'MM/DD/YYYY', //Locale's appropriate date representation: (None: 02/14/16)(EN: 02/14/16)(DE: 14.02.16)
    '%Y': 'YYYY', //Year as #: (1970, 2000, 2038, 292,277,026,596)
    '%y': 'YY', //Year without century 0 padded: (00, .., 99)
    '%Z': 'z', //Time zone name: ((empty), UTC, EST, CST) (empty string if the object is naive).
    '%z': 'ZZ', //UTC offset in the form +HHMM or -HHMM: ((empty), +0000, -0400, +1030) Empty string if the the object is naive.
    '%%': '%', //A literal '%' character: (%)
});

export const convertPythonDateTimeFormat = (formatStr) => {
    for (let key in PYTHON_TO_JS_DATE_VARIABLE_MAPPING) {
        formatStr = formatStr.split(key).join(PYTHON_TO_JS_DATE_VARIABLE_MAPPING[key]);
    }
    return formatStr;
};
