import * as React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from 'i18next';

import Page from '../../../core/components/Page';
import PaginatedList from '../../../core/containers/PaginatedList';
import ListItem from '../../../core/components/ListItem';
import Loader from '../../../core/components/Loader';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';
import { PAGE_PARAM } from '../../../core/containers/ListControl';
import { GET_INVOICES } from '../../queries';
import { OVERVIEW_PATH } from '../../constants';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const ITEMS_PER_PAGE = 10;

const InvoiceItem = (props) => {
    const { className, linkTo, invoiceNumber, invoiceDate, total, currencyCode } = props;

    const Base = linkTo ? 'a' : 'div';
    return (
        <Base className={classnames('invoice-item', className)} href={linkTo} target="_blank">
            <div className="invoice-item__section invoice-item__section--invoice-number">{invoiceNumber}</div>
            <div className="invoice-item__section invoice-item__section--date">{invoiceDate.toLocaleDateString()}</div>
            <div className="invoice-item__section invoice-item__section--total">
                {total} {currencyCode}
            </div>
        </Base>
    );
};

const Invoices = (props) => {
    const { user } = props;

    const { t } = useTranslation('customer');
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    moment.locale(user?.language || i18n.language);

    const [activePage, setActivePage] = useState(parseInt(searchParams.get(PAGE_PARAM)) || 0);

    useEffect(() => {
        if (activePage) {
            searchParams.set(PAGE_PARAM, activePage.toString());
        } else {
            searchParams.delete(PAGE_PARAM);
        }

        navigate({ pathname: location.pathname, search: '?' + searchParams });
    }, [activePage]);

    const { data, error, loading, refetch } = useQuery(GET_INVOICES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            input: {
                from: activePage * ITEMS_PER_PAGE,
                size: ITEMS_PER_PAGE,
            },
        },
    });

    const invoices = data?.viewer?.customer?.invoices || [];

    return (
        <Layout>
            <Page className="invoices page--slim">
                <div className="page__head">
                    <div className="page__title-group">
                        <Breadcrumbs className="page__breadcrumbs">
                            <Breadcrumb label={t('breadcrumbs.customer')} linkTo={url(OVERVIEW_PATH)} />
                        </Breadcrumbs>
                        <div className="page__title">{t('invoices.title')}</div>
                    </div>
                </div>

                <div className="invoices__list">
                    {loading ? (
                        <Loader />
                    ) : (
                        <PaginatedList
                            itemsPerPage={ITEMS_PER_PAGE}
                            numItems={invoices.length} // TODO: fix me
                            onPageChange={setActivePage}
                            activePage={activePage}
                        >
                            {invoices.map((item) => (
                                <ListItem key={item.id}>
                                    <InvoiceItem
                                        invoiceNumber={item.invoiceNumber}
                                        invoiceDate={new Date(Date.parse(item.invoiceDate))}
                                        total={item.total}
                                        currencyCode={item.currencyCode}
                                        id={item.id}
                                        linkTo={item.signedInvoiceFileUrl}
                                    />
                                </ListItem>
                            ))}
                        </PaginatedList>
                    )}
                </div>
            </Page>
        </Layout>
    );
};

export default Invoices;
