import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ApolloClient, ApolloLink, InMemoryCache, useMutation, useQuery } from '@apollo/client';
import i18n from 'i18next';

import Page from '../../../core/components/Page';
import Loader from '../../../core/components/Loader';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';
import { canManageIntegrations } from '../../../users/utils';
import {
    CREATE_CUSTOMER_SFTP_PUBLIC_KEY,
    CREATE_CUSTOMER_WEBHOOK_TOKEN,
    DELETE_CUSTOMER_SFTP_PUBLIC_KEY,
    DELETE_CUSTOMER_WEBHOOK_TOKEN,
    GET_CUSTOMER_WEBHOOK_TOKENS,
    GET_SFTP_PUBLIC_KEYS,
    INTEGRATIONS_SAGE100_OAUTH2_COMPLETE,
    INTEGRATIONS_SAGE200PROFESSIONAL_OAUTH2_COMPLETE,
    REFRESH_CUSTOMER_WEBHOOK_TOKEN,
} from '../../queries';
import { httpLink } from '../../../core/utils/authentication';
import { OVERVIEW_PATH } from '../../constants';
import ProgressButton from '../../../core/containers/ProgressButton';
import Input from '../../../core/components/Input';
import Alert, { AlertTitle } from '../../../core/components/Alert';

import './style.scss';
import OutlookAddIn from '../OutlookAddIn';
import Layout from '../../../core_updated/components/Layout';
import WeClappIntegration from '../WeClappIntegration';
import Sage100Integration from '../Sage100Integration';
import { useParams } from 'react-router-dom';
import { useToaster } from '../../../core/components/Toast';
import Sage200ProfessionalIntegration from '../Sage200ProfessionalIntegration';

const integrationAuthorized = (integration, payload = {}) => {
    // client without authLink
    const publicClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    const oauth2CompleteMutation =
        {
            sage100: INTEGRATIONS_SAGE100_OAUTH2_COMPLETE,
            sage200professional: INTEGRATIONS_SAGE200PROFESSIONAL_OAUTH2_COMPLETE,
        }[integration] || INTEGRATIONS_SAGE100_OAUTH2_COMPLETE;

    return publicClient
        .mutate({
            mutation: oauth2CompleteMutation,
            variables: {
                input: {
                    integration,
                    payload: JSON.stringify(payload),
                },
            },
        })
        .then((res) => {
            if (integration === 'sage200professional')
                return res.data?.triggerSage200professionalOauthFlowComplete?.success;
            else return res.data?.triggerSage100OauthFlowComplete?.success;
        });
};

const Integrations = (props) => {
    const { user, providerAuthorized: isProviderAuthorized } = props;

    const { publishToast } = useToaster();
    const { t } = useTranslation('customer');
    const { integration } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const payload = Object.fromEntries(searchParams.entries());

    const [name, setName] = useState('');

    const [publicKeyBody, setPublicKeyBody] = useState('');

    moment.locale(user?.language || i18n.language);

    const {
        data: tokenData,
        error: tokenError,
        loading: tokenLoading,
        refetch: tokenRefetch,
    } = useQuery(GET_CUSTOMER_WEBHOOK_TOKENS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (!integration || error) return;

        if (isProviderAuthorized) {
            integrationAuthorized(integration, payload)
                .then((data) => {
                    if (data) {
                        publishToast({ description: t(`Success!!!`) });
                    } else {
                        publishToast({ description: t(`Error!`) });
                    }
                })
                .catch((err) => {
                    publishToast({ description: t(`Error: ` + err.message) });
                    console.error(err);
                    //setError(err.message);
                });
        }
    }, []);

    const tokens = tokenData?.customerWebhookTokens || [];

    const [refreshToken, { loading: refreshTokenLoading, error: refreshTokenError }] =
        useMutation(REFRESH_CUSTOMER_WEBHOOK_TOKEN);
    const handleRefreshToken = (tokenId) => {
        return refreshToken({ variables: { tokenId } })
            .then((res) => void tokenRefetch())
            .catch((error) => {
                console.error(error);
            });
    };

    const [createToken, { loading: createTokenLoading, error: createTokenError }] =
        useMutation(CREATE_CUSTOMER_WEBHOOK_TOKEN);
    const handleCreateToken = () => {
        return createToken({ variables: { name } })
            .then((res) => {
                tokenRefetch();
                setName('');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [deleteToken, { loading: deleteTokenLoading, error: deleteTokenError }] =
        useMutation(DELETE_CUSTOMER_WEBHOOK_TOKEN);
    const handleDeleteToken = (tokenId) => {
        return deleteToken({ variables: { tokenId } })
            .then((res) => void tokenRefetch())
            .catch((error) => {
                console.error(error);
            });
    };

    const {
        data: sftpPublicKeyData,
        error: sftpPublicKeyError,
        loading: sftpPublicKeyLoading,
        refetch: sftpPublicKeyRefetch,
    } = useQuery(GET_SFTP_PUBLIC_KEYS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    const sftpPublicKeys = sftpPublicKeyData?.viewer?.customer?.sftpPublicKeys || [];

    const [createSftpPublicKey, { loading: createSftpPublicKeyLoading, error: createSftpPublicKeyError }] = useMutation(
        CREATE_CUSTOMER_SFTP_PUBLIC_KEY
    );
    const handleCreateSftpPublicKey = (publicKeyBody) => {
        return createSftpPublicKey({ variables: { publicKeyBody } })
            .then((res) => {
                sftpPublicKeyRefetch();
                setPublicKeyBody('');
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [deleteSftpPublicKey, { loading: deleteSftpPublicKeyLoading, error: deleteSftpPublicKeyError }] = useMutation(
        DELETE_CUSTOMER_SFTP_PUBLIC_KEY
    );
    const handleDeleteSftpPublicKey = (publicKeyId) => {
        return deleteSftpPublicKey({ variables: { publicKeyId } })
            .then((res) => void sftpPublicKeyRefetch())
            .catch((error) => {
                console.error(error);
            });
    };
    const error = [
        tokenError,
        createTokenError,
        deleteTokenError,
        refreshTokenError,
        sftpPublicKeyError,
        createSftpPublicKeyError,
        deleteSftpPublicKeyError,
    ].find((value) => value);

    return (
        <Layout>
            <Page className="integrations page--sli">
                <div className="page__head">
                    <div className="page__title-group">
                        <Breadcrumbs className="page__breadcrumbs">
                            <Breadcrumb label={t('breadcrumbs.customer')} linkTo={url(OVERVIEW_PATH)} />
                        </Breadcrumbs>
                        <div className="page__title">{t('integrations.title')}</div>
                    </div>
                </div>

                {error && (
                    <Alert severity="error">
                        <AlertTitle>{error?.message}</AlertTitle>
                    </Alert>
                )}

                {[tokenLoading, sftpPublicKeyLoading].some((value) => value) ? (
                    <Loader />
                ) : (
                    <div className={'integrations__page-container'}>
                        <div className="integrations__tokens-section">
                            <div className="integrations__section-title">{t('integrations.token.title')}</div>

                            <div className="integrations__tokens">
                                {tokens.length ? (
                                    tokens.map((token) => {
                                        return (
                                            <div className="integrations__token">
                                                <div className="integrations__token-key-wrapper">
                                                    <div className="integrations__token-key">{token.key}</div>
                                                    <ProgressButton
                                                        className=" button--secondary"
                                                        onClick={() => handleRefreshToken(token.id)}
                                                        label={t('integrations.token.refreshButton')}
                                                    />
                                                    <ProgressButton
                                                        className="button--danger button--secondary"
                                                        onClick={() => handleDeleteToken(token.id)}
                                                        label={t('integrations.token.deleteButton')}
                                                    />
                                                </div>
                                                {t('integrations.token.note', {
                                                    date: new Date(token.created).toLocaleString(),
                                                    user: `${token.user?.firstName} ${token.user?.lastName}`,
                                                    name: token.name,
                                                })}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="integrations__no-tokens">{t('integrations.token.noTokens')}</div>
                                )}
                            </div>

                            {tokens.length < 2 && (
                                <div className="integrations__create-token form">
                                    <div className="form__group">
                                        <div className="form__label">{t('integrations.createToken.title')}</div>
                                        <div className="form__group form__group--horizontal">
                                            <Input
                                                className="integrations__create-token-input"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                placeholder={t('integrations.createToken.placeholder')}
                                            />
                                            <ProgressButton
                                                label={t('integrations.createToken.createButton')}
                                                onClick={handleCreateToken}
                                            />
                                        </div>
                                        <div className="form__note">{t('integrations.createToken.note')}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="integrations__sftpKey-section">
                            <div className="integrations__section-title">{t('integrations.sftpKey.title')}</div>
                            <div className="integrations__sftpKeys">
                                <div className="integrations__sftpKeys-note">{t('integrations.sftpKey.note')}</div>
                                {sftpPublicKeys.length ? (
                                    sftpPublicKeys.map((sftpPublicKey) => {
                                        return (
                                            <div className="integrations__sftpKey">
                                                <div className="integrations__sftpKey-body-wrapper">
                                                    <div className="integrations__sftpKey-body">
                                                        {sftpPublicKey.SshPublicKeyBody}
                                                    </div>
                                                    <ProgressButton
                                                        className="button--danger button--secondary"
                                                        onClick={() =>
                                                            handleDeleteSftpPublicKey(sftpPublicKey.SshPublicKeyId)
                                                        }
                                                        label={t('integrations.sftpKey.deleteButton')}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="integrations__no-sftpKeys">{t('integrations.sftpKey.noKeys')}</div>
                                )}
                            </div>
                            <div className="integrations__create-sftpKey form">
                                <div className="form__group">
                                    <div className="form__label">{t('integrations.createSftpKey.title')}</div>
                                    <div className="form__group form__group--horizontal">
                                        <Input
                                            className="integrations__create-sftpKey-input"
                                            value={publicKeyBody}
                                            onChange={(e) => setPublicKeyBody(e.target.value)}
                                            placeholder={t('integrations.createSftpKey.placeholder')}
                                        />
                                        <ProgressButton
                                            label={t('integrations.createSftpKey.createButton')}
                                            onClick={() => handleCreateSftpPublicKey(publicKeyBody)}
                                            className={!publicKeyBody && 'button--disabled'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {canManageIntegrations(user) && (
                    <>
                        <div className="page__head">
                            <div className="page__title-group">
                                <div className="page__title">{t('integrations.apps.title')}</div>
                            </div>
                        </div>
                        <OutlookAddIn />
                        <WeClappIntegration />
                        <Sage100Integration />
                        <Sage200ProfessionalIntegration />
                    </>
                )}
            </Page>
        </Layout>
    );
};

export default Integrations;
