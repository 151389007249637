import * as React from 'react';
import { highlight, languages } from 'prismjs/components/prism-core';
import Editor from 'react-simple-code-editor';
import classnames from 'classnames';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import './style.scss';

const DataViewer = (props) => {
    const { data, className, language = languages.json } = props;
    const parsed = data ? JSON.stringify(data, null, 2) : '{}';
    return (
        <div>
            <Editor
                className={classnames('data-viewer', className)}
                value={parsed}
                onValueChange={(data: any) => {}}
                highlight={(code: any) =>
                    highlight(code, language)
                        .split('\n')
                        .map((line) => `<span class="data-viewer__line-number">${line}</span>`)
                        .join('\n')
                }
                disabled={true}
                textareaClassName="data-viewer__editor"
                preClassName="data-viewer__highlighted"
            />
        </div>
    );
};

export default DataViewer;
