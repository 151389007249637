import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';
import classnames from '../../utils/classnames';
import { withIcon } from '../Icon';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useCallback, useMemo } from 'react';

const RemoveIcon = withIcon(faXmark);

interface TagProps extends React.ComponentPropsWithRef<'div'> {
    isRemovable?: boolean;
    onRemove?: () => void;
}

const Tag = ({ className, isRemovable = true, onRemove, children, ...props }: TagProps) => {
    return (
        <div
            className={classnames(
                'border-secondary border border-solid bg-secondary flex gap-2 items-center px-1 rounded',
                className
            )}
            {...props}
        >
            <span>{children}</span>
            {isRemovable && (
                <button type="button" onClick={onRemove} className="flex items-center justify-center">
                    <RemoveIcon />
                </button>
            )}
        </div>
    );
};

export interface TagsFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {
    submitKeys?: string[];
    separator?: string;
    renderTag?: (props: TagProps) => React.ReactNode;
}

const TagsField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            controls,
            className,
            readOnly,
            disabled,
            inputRef = undefined,
            onBlur,
            onFocus,
            required,

            submitKeys = ['Enter', 'Tab'],
            separator = ',',

            renderTag,

            ...props
        }: TagsFieldProps,
        ref: any
    ) => {
        const [inputValue, setInputValue] = React.useState('');

        // value stored as string with separator
        const [stringValue, setStringValue] = useControllableState(defaultValue, propsValue, onValueChange);

        const value = useMemo(
            () => (stringValue ? stringValue.split(separator).filter(Boolean) : []),
            [stringValue, separator]
        );
        const setValue = useCallback(
            (value) => {
                setStringValue(value.join(separator));
            },
            [setStringValue, separator]
        );

        const handleKeyDown = (event) => {
            // Prevent default behavior for submit or separator keys
            if (![...submitKeys, separator].includes(event.key) || !inputValue) return;
            event.preventDefault();

            setValue([...value, inputValue]);
            setInputValue('');

            onBlur?.(null);
        };

        const handleBlur = (event) => {
            if (inputValue) {
                setValue([...value, inputValue]);
                setInputValue('');
            }

            onBlur?.(event);
        };

        const handlePaste = (event) => {
            const pasteValue = (event.clipboardData || (window as any).clipboardData).getData('text');
            if (pasteValue.includes(separator)) {
                event.preventDefault();

                const newValues = pasteValue.split(separator).map((v) => v.trim());
                setValue([...value, ...newValues]);
                setInputValue('');

                onBlur?.(null);
            }
        };

        const handleChange = (event) => {
            setInputValue(event.target.value);
        };

        const TagComponent = useMemo(() => renderTag || Tag, [renderTag]);

        return (
            <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
                <Field.Input>
                    <div className="flex flex-wrap gap-1.5 p-1.5 w-full min-w-0">
                        {value.map((tag, index) => (
                            <TagComponent
                                key={index}
                                isRemovable={!readOnly}
                                onRemove={() => {
                                    setValue(value.filter((_, i) => i !== index));
                                    onBlur?.(null);
                                }}
                            >
                                {tag}
                            </TagComponent>
                        ))}

                        <input
                            type="text"
                            value={inputValue}
                            className={classnames('flex-1 min-w-20 bg-transparent pl-1')}
                            readOnly={readOnly}
                            disabled={disabled}
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            onPaste={handlePaste}
                            onBlur={handleBlur}
                            onFocus={onFocus}
                            required={required ? value.length === 0 : false}
                            {...props}
                            ref={inputRef}
                        />
                    </div>
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        );
    }
);

export default TagsField;
