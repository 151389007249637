import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import classnames from 'classnames';
import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';
import { ApolloLink } from '@apollo/client';

import Card from '../../../core/components/Card';
import Form from '../../../core/components/Form';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import { url } from '../../../core/utils/link';
import Logo from '../../../core/components/Logo';
import LoginWrapper from '../../components/LoginWrapper';
import { LOGIN_PATH, PROVIDER_LOGIN_PATH } from '../../constants';
import { PROVIDER_AUTHORIZED, PROVIDER_LOGIN, PROVIDER_PROVIDER_LOGIN } from '../../queries';
import { httpLink } from '../../../core/utils/authentication';

import './style.scss';
import { StringField } from '../../../assistance/containers/Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import { SecondaryButton } from '../../../core/components/Button';

const providerLogin = (email, payload = {}) => {
    // client without authLink
    const publicClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    return publicClient
        .mutate({
            mutation: PROVIDER_LOGIN,
            variables: {
                input: {
                    email,
                    payload: JSON.stringify(payload),
                },
            },
        })
        .then((res) => {
            return res.data?.oauthLogin?.redirectUrl;
        });
};

const providerProviderLogin = (provider, payload = {}) => {
    // client without authLink
    const publicClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    return publicClient
        .mutate({
            mutation: PROVIDER_PROVIDER_LOGIN,
            variables: {
                input: {
                    provider,
                    payload: JSON.stringify(payload),
                },
            },
        })
        .then((res) => {
            return res.data?.oauthProviderLogin?.redirectUrl;
        });
};

const providerAuthorized = (provider, payload = {}) => {
    // client without authLink
    const publicClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([httpLink]),
    });

    return publicClient
        .mutate({
            mutation: PROVIDER_AUTHORIZED,
            variables: {
                input: {
                    provider,
                    payload: JSON.stringify(payload),
                },
            },
        })
        .then((res) => {
            return res?.data?.oauthAuthorized;
        });
};

const ProviderLogin = (props) => {
    const { loggedIn, user, providerAuthorized: isProviderAuthorized } = props;

    const { t } = useTranslation('user');
    const { provider } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const payload = Object.fromEntries(searchParams.entries());
    const nextLocation = searchParams.get('next') || '/';

    const [error, setError] = useState(undefined);
    const [username, setUsername] = useState('');

    useEffect(() => {
        if (loggedIn && user) {
            navigate(nextLocation);
        }
    }, [user, loggedIn]);

    useEffect(() => {
        if (!provider || error) return;

        if (isProviderAuthorized) {
            providerAuthorized(provider, payload)
                .then((data) => {
                    if (data) {
                        window.location.href = data.redirectUrl || nextLocation;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    setError(err.message);
                });
        } else {
            providerProviderLogin(provider, payload)
                .then((redirectUrl) => {
                    if (redirectUrl) window.location.href = redirectUrl;
                })
                .catch((err) => {
                    console.error(err);
                    setError(t('login.providers.loginError'));
                });
        }
    }, []);

    const handleLogin = () =>
        providerLogin(username, payload)
            .then((redirectUrl) => {
                if (redirectUrl) window.location.href = redirectUrl;
            })
            .catch((err) => {
                console.error(err);
                setError(t('login.providers.loginError'));
            });

    return (
        <LoginWrapper className="provider-login">
            <div className="login__logo-wrapper">
                <Link to={url(LOGIN_PATH)}>
                    <Logo className="login__logo" />
                </Link>
            </div>

            <Card level={3} className={classnames('login__card', error && 'login__card--wide')}>
                {error ? (
                    <Form className="login__form">
                        <Alert severity="error" className="alert--no-margin">
                            <AlertTitle>{error}</AlertTitle>
                        </Alert>

                        <SecondaryButton label={t('login.providers.goBack')} linkTo={url(LOGIN_PATH)} />
                    </Form>
                ) : isProviderAuthorized ? (
                    <>
                        <div className="provider-login__loading-wrapper">
                            <div className="provider-login__loading" />
                        </div>
                        <div className="provider-login__label">{t('login.providers.login')}</div>
                        <div className="provider-login__loading-note">{t('login.providers.pleaseWait')}</div>
                    </>
                ) : (
                    <>
                        <h1 className="login__title">{t('login.title')}</h1>

                        <Form className="login__form" onSubmit={handleLogin}>
                            <StringField
                                label={t('login.form.email')}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="field--vertical"
                                inputProps={{
                                    placeholder: t('login.form.emailPlaceholder'),
                                    name: 'username',
                                    type: 'email',
                                }}
                            />

                            <ProgressButton label={t('login.form.submitButton')} onClick={handleLogin} type="submit" />

                            <div className="provider-login__note">{t('login.providers.redirectNote')}</div>
                        </Form>

                        <div className="login__below-card">
                            <Link to={`${LOGIN_PATH}?next=${nextLocation}`}>{t('login.withPassword')}</Link>
                        </div>
                    </>
                )}
            </Card>
        </LoginWrapper>
    );
};

export default ProviderLogin;
