import { useEffect, useState } from 'react';

const useConfirmValueUpdate = ({ value, onValueChange, onUpdatePayloadChange, onFocus, onBlur }: any) => {
    // Basically value gets updated all the time, the update only gets propagated on blur
    // Here we intercept blur and decide whether to update the value or not

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [unconfirmedValue, setUnconfirmedValue] = useState<string>(value);
    const [waitingForConfirm, setWaitingForConfirm] = useState<boolean>(false);

    useEffect(() => {
        // only allow external updates when the input is not focused
        if (!isFocused && !waitingForConfirm) {
            setUnconfirmedValue(value);
        }
    }, [value]);

    const handleFocus = (e) => {
        if (!waitingForConfirm) {
            setUnconfirmedValue(value);
        }
        setIsFocused(true);
        onFocus?.(e);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        if (unconfirmedValue !== value) {
            setWaitingForConfirm(true);
        } else {
            onBlur?.(e);
        }
    };

    const handleConfirm = () => {
        setWaitingForConfirm(false);
        onBlur?.(null);
    };

    const handleCancel = () => {
        setWaitingForConfirm(false);

        // reset to the previous value
        onValueChange?.(unconfirmedValue);
        onUpdatePayloadChange?.({});

        onBlur?.(null);
    };

    return {
        onFocus: handleFocus,
        onBlur: handleBlur,

        waitingForConfirm,
        onConfirm: handleConfirm,
        onCancel: handleCancel,
    };
};

export default useConfirmValueUpdate;
