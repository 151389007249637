import React, { ReactNode } from 'react';
import classnames from '../../../utils/classnames';
import NavigationButton from './NavigationButton';

export interface NavigationItemProps {
    icon?: ReactNode;
    label: string;
    disabled?: boolean;
    badgeNumber?: number;
    className?: string;
    active?: boolean;
    onSelect?: (event: React.MouseEvent | null, item: [string, string | null]) => void;
}

const NavigationItem = React.forwardRef(
    ({ icon, label, badgeNumber = undefined, className, active, onSelect }: NavigationItemProps, ref: any) => {
        return (
            <li className={classnames('flex flex-none flex-col gap-1 rounded-md', className)} ref={ref}>
                <span className="flex-none px-4 py-1">
                    <NavigationButton
                        active={active}
                        onClick={(event) => onSelect?.(event, null)}
                        label={label}
                        badgeNumber={badgeNumber}
                        icon={icon}
                    />
                </span>
            </li>
        );
    }
);

export default NavigationItem;
