import React, { ReactNode } from 'react';
import classnames from '../../../utils/classnames';
import withLoader from '../../../utils/withLoader';
import { NavigationChannelItemLoader } from './NavigationChannelItem';
import { useTranslation } from 'react-i18next';
import { useNavigationContext } from './NavigationContext';

interface NavigationItemListProps {
    className?: string;
    children?: ReactNode;
}

const NavigationItemList = ({ children, className }: NavigationItemListProps) => {
    const { t } = useTranslation('assistance');

    const { collapsed } = useNavigationContext();
    const hasChildren = React.Children.toArray(children)?.filter((c) => c).length !== 0;

    return (
        <div
            className={classnames(
                'relative mb-1 flex min-h-0 flex-col opacity-100 duration-200 ease-in-out transition-[opacity]',
                collapsed && 'h-0 p-0 m-0 opacity-0 delay-0 duration-0',
                className
            )}
        >
            {!hasChildren ? (
                <span className="pb-4 pl-14 pr-4 pt-3 text-sm text-tertiary">
                    {t('overview.navigation.noChannels')}
                </span>
            ) : (
                <ul
                    className={classnames(
                        'relative flex min-h-0 flex-auto flex-col gap-1.5 py-1 pl-10 pr-4 transition-opacity ease-in-out duration-300 opacity-100 overflow-x-hidden',
                        collapsed && 'opacity-0'
                    )}
                >
                    {children}
                </ul>
            )}
        </div>
    );
};

export const NavigationItemListLoader = () => {
    return (
        <div className="relative mb-1 flex min-h-0 flex-col">
            <ul className="relative flex min-h-0 flex-auto flex-col gap-1.5 py-1 pl-12 pr-4">
                <NavigationChannelItemLoader />
                <NavigationChannelItemLoader />
                <NavigationChannelItemLoader />
            </ul>
        </div>
    );
};

export const NavigationItemListWithLoader = withLoader(NavigationItemList, NavigationItemListLoader);

export default NavigationItemList;
