import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import Menu, { IProps as IMenuProps } from '../../components/Menu';
import MenuItem from '../../components/MenuItem';

import './style.scss';

interface IProps {
    className?: string;
    children?: any;
    activeValue?: string;
    placeholder?: string;
    onChange?: any;
    menuOrientation?: string;
    onCloseMenu?: any;
    onOpenMenu?: any;
    menuVisible?: any;
}

interface IToggleProps {
    className?: string;
    children?: any;
    onClick?: any;
}

export interface IMenuItem {
    label: string;
    description: string;
    value: string | number;
    data: any;
    disabled?: boolean;
    onClick?: () => void;
    highlightPattern?: string; // applies to both label & description
    labelHighlightPattern?: string;
    descriptionHighlightPattern?: string;
}

export const DropDownToggle = React.forwardRef((props: IToggleProps, ref: any) => {
    const { className, children, onClick } = props;
    return (
        <div ref={ref} {...props} onClick={onClick} className={classnames('drop-down__handler', className)}>
            {children}
        </div>
    );
});

DropDownToggle.displayName = 'DropDownToggle';

interface IDropDownMenuProps extends IMenuProps {
    items?: Array<IMenuItem>;
    onItemClick?: (item: IMenuItem) => void;
    loading?: boolean;
    emptyItemsContent?: React.ReactElement | Array<IMenuItem>;
}

export const DropDownMenu = React.forwardRef((props: IDropDownMenuProps, ref: any) => {
    let { className, items, children, onItemClick, loading, emptyItemsContent } = props;

    if (children != null && items != null) {
        throw new Error('Must not provide both items and children to DropDownMenu');
    }

    // Override default children with items (if any)
    if (items != null) {
        children = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            let { value, label, description, disabled, onClick, ...menuItemProps } = item;
            children.push(
                <MenuItem
                    key={i}
                    value={value}
                    label={label}
                    description={description}
                    onClick={onClick ?? (() => onItemClick(item))}
                    disabled={disabled ?? false}
                    {...menuItemProps}
                />
            );
        }
    }

    const content =
        children != null && (!Array.isArray(children) || (Array.isArray(children) && children.length > 0))
            ? children
            : emptyItemsContent;

    return (
        <Menu ref={ref} {...props} className={classnames('drop-down__menu', 'drop-down__menu--positioned', className)}>
            {!loading && content}
            {loading && <MenuItem className="menu-item__loading" value="" disabled={true} />}
        </Menu>
    );
});

DropDownMenu.displayName = 'DropDownMenu';

const DropDown = (props: IProps) => {
    const {
        className,
        children,
        onChange,
        menuOrientation,
        onCloseMenu,
        onOpenMenu,
        menuVisible: menuVisibleProp = undefined,
    } = props;

    const [menuVisible, setMenuVisible] = useState(menuVisibleProp !== undefined ? menuVisibleProp : false);

    useEffect(() => {
        setMenuVisible(menuVisibleProp);
    }, [menuVisibleProp]);
    const menuRef = useRef(null);
    const handlerRef = useRef(null);

    useEffect(() => {
        const handleClick = (e) => {
            // if menu is open and you click outside of menu it should close
            if (!menuRef?.current?.contains(e.target) && !handlerRef?.current.contains(e.target)) {
                setMenuVisible(false);
            }
        };

        if (menuVisible) document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [menuRef, menuVisible]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'Escape') {
                event.preventDefault();
                setMenuVisible(false);
                if (onCloseMenu) onCloseMenu();
            }
        };

        if (menuVisible) window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [menuVisible]);

    useEffect(() => {
        if (menuVisible && onOpenMenu) onOpenMenu();
        else if (!menuVisible && onCloseMenu) onCloseMenu();
    }, [menuVisible]);

    const toggleVisible = () => setMenuVisible(!menuVisible);

    // align right if select is more to the right
    let menuClassNames = classnames('drop-down__menu');
    if (handlerRef.current) {
        const referenceNode = handlerRef.current.closest('.js-menu-reference') || document.body;

        const menuLeft = handlerRef.current.getBoundingClientRect().left - referenceNode.getBoundingClientRect().left;
        const menuRight =
            referenceNode.getBoundingClientRect().right - handlerRef.current.getBoundingClientRect().right;

        const orientation = menuOrientation || (menuRight < menuLeft ? 'right' : 'left');
        menuClassNames = classnames('drop-down__menu', `drop-down__menu--${orientation}`);
    }

    return (
        <div ref={handlerRef} className={classnames('drop-down', menuVisible && 'drop-down--visible', className)}>
            {React.Children.map(children, (child, i) => {
                if (child == null) {
                    return null;
                }
                if (child.type.displayName == 'DropDownToggle') {
                    // if menuVisibleProp != undefined the dropdown is externally controlled
                    return (
                        <child.type
                            onClick={menuVisibleProp === undefined ? toggleVisible : undefined}
                            {...child.props}
                            ref={handlerRef}
                            key={i}
                        />
                    );
                } else if (child.type.displayName == 'DropDownMenu') {
                    const handleChange = (value) => {
                        if (
                            // close the menu upon clicking, unless the menu item has the key MenuItemDoNotCloseOnClick
                            !(
                                child.props?.children?.props?.children?.length >= value &&
                                child.props.children.props.children[value]?.key === 'MenuItemDoNotCloseOnClick'
                            )
                        ) {
                            setMenuVisible(false);
                        }
                        if (onChange) return onChange(value);
                        if (child.props.onChange) return child.props.onChange(value);
                    };

                    const loading = child.props.loading ?? false;
                    return (
                        <child.type
                            {...child.props}
                            ref={menuRef}
                            visible={loading || menuVisible}
                            onChange={handleChange}
                            className={classnames(child.props.className, menuClassNames)}
                            key={i}
                        />
                    );
                } else {
                    console.warn(`Invalid child type: ${child.type.displayName}`, child);
                }
            })}
        </div>
    );
};

DropDown.displayName = 'DropDownMenu';

export default DropDown;
