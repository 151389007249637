import * as React from 'react';
import { useState, useEffect } from 'react';

import classnames from 'classnames';

import List, { IProps as IListProps } from '../../components/List';

import './style.scss';
import Pagination from '../../../core_updated/components/Pagination';
import { useTranslation } from 'react-i18next';

export interface IProps extends IListProps {
    className?: string;
    itemsPerPage: number;
    activePage?: number;
    numItems?: number; // force overwrite  -> if set we expect manual pagination / slicing
    onPageChange?: (page) => void;
}

const PaginatedList = (props: IProps) => {
    const { className, onChildrenReady, itemsPerPage = 10, activePage = 0, onPageChange, ...listProps } = props;

    const { t } = useTranslation('core');

    const [page, setPage] = useState(activePage);

    useEffect(() => {
        setPage(activePage);
    }, [activePage]);

    const [numItems, setNumItems] = useState(props.numItems);

    useEffect(() => {
        setNumItems(props.numItems);
    }, [props.numItems]);

    /*
    props.numItems is the implicit switch between manual pagination and automated pagination.
    So if that is set we expect the wrapper to always include the updated children
    and don't do automatic slicing.
     */
    const begin = !props.numItems ? page * itemsPerPage : undefined;
    const end = !props.numItems ? (page + 1) * itemsPerPage : undefined;

    const handleChildrenReady = (children) => {
        if (!props.numItems) setNumItems(children.length);
        if (onChildrenReady) onChildrenReady(children);
    };

    const handlePageChange = (page) => {
        setPage(page);
        if (onPageChange) onPageChange(page);
    };

    const showFrom = page * itemsPerPage + 1;
    const showTo = Math.min((page + 1) * itemsPerPage, numItems);

    return (
        <div className={classnames('paginated-list', className)}>
            <List
                {...listProps}
                className="paginated-list__list"
                onChildrenReady={handleChildrenReady}
                begin={begin}
                end={end}
            />

            {numItems > 0 ? (
                <div className="paginated-list__from-to">
                    {t('components.paginatedList.show')} {showFrom} - {showTo} {t('components.paginatedList.of')}{' '}
                    {numItems}
                </div>
            ) : (
                ''
            )}

            <Pagination
                className="paginated-list__pagination"
                onChange={handlePageChange}
                numItems={numItems}
                itemsPerPage={itemsPerPage}
                activePage={page}
            />
        </div>
    );
};

PaginatedList.displayName = 'PaginatedList';

export default PaginatedList;
