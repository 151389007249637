import { FeatureToggleConfig } from './utils/hooks/useFeatureToggle';

/**
 * List of features toggles for conditional or customer-specific functionality in the frontend
 *
 * Each toggle is identified by a unique key and maps to a function that resolves the toggle's current state,
 * with optional data passed via the consuming hook to help determine the current state.
 *
 * Example:
 *   const isFeatureEnabled = useFeatureToggle('FeatureName', { currentUser });
 */
const featureToggles: FeatureToggleConfig = {
    ERPLock: ({ channel }) => {
        const outputConfig = channel?.xmlSerializerConfig;
        const outputHeaderFields = outputConfig?.headerFields ?? [];
        return outputHeaderFields.includes('erp_locked');
    },
};

export default featureToggles;
