import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import classnames from 'classnames/dedupe';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18n from 'i18next';
import { posthog } from 'posthog-js';
import { useQuery } from '@apollo/client';

import { useAuth } from './modules/core/utils/authentication';
import { modules } from './modules/core/utils/modules';
import NotFound from './modules/core/containers/NotFound';
import Loader from './modules/core/components/Loader';
import Page from './modules/core/components/Page';
import { GET_ANNOUNCEMENTS } from './modules/announcements/queries';
import { FeatureTogglesProvider } from './modules/core/utils/hooks/useFeatureToggle';
import featureConfig from './modules/core/featureToggles';
import { appInsights } from './modules/core/analytics/applicationInsights';
import { loadAppEvent } from './modules/core/analytics/customEvents';

import { ApplicationContextProvider } from './modules/core_updated/contexts/ApplicationContext';
import useDarkMode from './modules/core_updated/utils/useDarkMode';

// order is important here
import '../stylesheets/tailwind.css';
import './modules/core/app.scss';

export const DARK_MODE_LOCAL_STORAGE_KEY = 'darkMode';

const App = ({ browserName }) => {
    const { t } = useTranslation();
    moment.locale(i18n.language);

    const { user, loggedIn, loading } = useAuth();
    // polling once a minute for new announcements should be enough
    const { data: announcementsData } = useQuery(GET_ANNOUNCEMENTS, { pollInterval: 60000 });

    // this helps the navigation to know when it should be collapsed
    const [activeChannelId, setActiveChannelId] = useState(null);

    useEffect(() => {
        appInsights?.trackEvent(...loadAppEvent(user));

        if (!loggedIn) return;

        if (user.language) {
            void i18n.changeLanguage(user.language.toLowerCase());
            moment.locale(user.language);
        }

        user.groups.some(function (group) {
            if (group.name == 'Clerk' && group.memberCount > 1) {
                posthog.capture('identify_customer', { $set_once: { customer: user.customer.shortName } });
                return true;
            }
        });
    }, [loggedIn]);

    const { isActive: darkMode } = useDarkMode();

    return (
        <ApplicationContextProvider
            user={user}
            loading={loading}
            channelId={activeChannelId}
            setChannelId={setActiveChannelId}
            announcements={announcementsData?.announcements || []}
        >
            <FeatureTogglesProvider featureConfig={featureConfig}>
                <>
                    <Helmet>
                        <title>{t('app.title')}</title>
                        <body className={classnames(darkMode ? 'dark' : '', browserName && `browser-${browserName}`)} />
                    </Helmet>

                    {loading ? ( // TODO remove loading state handling here & leave it up to shells
                        <Page className="page--slim">
                            <Loader />
                        </Page>
                    ) : (
                        <Router key="router">
                            <Routes>
                                {modules.renderRoutes({
                                    user,
                                    loggedIn,
                                })}
                                <Route element={<NotFound />} path="*" />
                            </Routes>
                        </Router>
                    )}
                </>
            </FeatureTogglesProvider>
        </ApplicationContextProvider>
    );
};

export default App;
