import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { ACQUIRE_LOCK, RELEASE_LOCK } from '../queries';

const useLock = (groupName, identifier, userId) => {
    const [locked, setLocked] = useState(undefined);
    const [lockedBy, setLockedBy] = useState(undefined);
    const [lockJob] = useMutation(ACQUIRE_LOCK);

    userId = userId.toString();

    const lock = useCallback(() => {
        lockJob({
            variables: {
                groupName: groupName,
                identifier: identifier,
                userId: userId,
            },
        }).then((res: any) => {
            const lockedBy = res?.data?.acquireLock?.lockedBy;
            const locked = res?.data?.acquireLock?.isLocked;

            if (locked && lockedBy?.id.toString() !== userId) {
                setLocked(true);
                setLockedBy(lockedBy);
            } else {
                setLocked(false);
                setLockedBy(undefined);
            }
        });
    }, [groupName, identifier, userId, lockedBy]);

    const cleanLock = useCallback(() => {
        // only clear lock if not locked by someone else
        if (lockedBy) return;

        // call beacon api https://developer.mozilla.org/en-US/docs/Web/API/Beacon_API
        const url = process.env.GRAPHQL_URL;
        const query = RELEASE_LOCK.loc.source.body;
        const data = new FormData();
        data.append('operations', JSON.stringify({ query: query, variables: { groupName, identifier, userId } }));
        navigator.sendBeacon(url, data);
    }, [locked, lockedBy, groupName, identifier]);

    useEffect(() => {
        if (!groupName || !identifier || !userId) return;

        lock();
        const lockInterval = 40000;
        const interval = setInterval(lock, lockInterval);
        window.addEventListener('beforeunload', cleanLock);

        return () => {
            cleanLock();
            clearInterval(interval);
            window.removeEventListener('beforeunload', cleanLock);
        };
    }, [groupName, identifier, userId]);

    return { locked, lockedBy, lock, cleanLock };
};

export default useLock;
