import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

interface IProps {
    className?: string;
    children?: any;
    activeValue?: string;
    onChange?: any;
}

const Tabs = (props: IProps) => {
    const { className, children, activeValue, onChange } = props;

    return (
        <nav className={classnames('tabs', className)}>
            {React.Children.map(children, (child, i) => {
                const value = child.props.value || i;
                const active = child.props.active || value == activeValue;

                const handleClick = (event) => {
                    if (onChange) onChange(event);
                    if (child.onClick) child.onClick(event);
                    if (child.props.onClick) child.props.onClick(event);
                };

                return <child.type {...child.props} key={value} value={value} active={active} onClick={handleClick} />;
            })}
        </nav>
    );
};

export default Tabs;
