import Page from './internal/Page';
import PageContent from './internal/PageContent';
import PageHeader from './internal/PageHeader';
import PageHeaderTitle from './internal/PageHeaderTitle';
import PageHeaderButton, { PageHeaderIconButton } from './internal/PageHeaderButton';

export default Object.assign(Page, {
    Content: PageContent,
    Header: PageHeader,
    HeaderTitle: PageHeaderTitle,
    HeaderButton: PageHeaderButton,
    HeaderIconButton: PageHeaderIconButton,
});
