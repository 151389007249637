import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../core_updated/components/Button';
import { ORDER_BY_FILTER_KEY, SEARCH_FILTER_KEY } from '../../../../core/utils/filterQuery';

const EmptyState = ({ filters, onFiltersChange }) => {
    const { t } = useTranslation('assistance');

    const isFiltersActive =
        filters && filters.filter((f) => f.name !== ORDER_BY_FILTER_KEY && f.name !== SEARCH_FILTER_KEY).length > 0;
    const isSearchActive = filters && filters.filter((f) => f.name === SEARCH_FILTER_KEY).length > 0;

    const handleClearFilters = () => {
        // keep orderBy filter
        onFiltersChange?.(filters.filter((f) => f.name === ORDER_BY_FILTER_KEY));
    };

    return (
        <div className="flex flex-col gap-10 items-center justify-center h-full">
            <div className="text-center flex flex-col gap-1 text-base">
                <h2 className="font-medium text-primary">
                    {t(
                        isFiltersActive || isSearchActive
                            ? 'overview.emptyState.filters.title'
                            : 'overview.emptyState.finished.title'
                    )}
                </h2>
                <p className="text-gray-600 mt-2 text-secondary text-base">
                    {t(
                        isFiltersActive || isSearchActive
                            ? 'overview.emptyState.filters.description'
                            : 'overview.emptyState.finished.description'
                    )}
                </p>
            </div>
            {(isFiltersActive || isSearchActive) && !(isSearchActive && isFiltersActive) && (
                <Button onClick={handleClearFilters}>{t('overview.filters.clearAll')}</Button>
            )}
        </div>
    );
};

export default EmptyState;
