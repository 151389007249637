import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';
import classnames from '../../utils/classnames';

export interface TextFieldProps
    extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value' | 'defaultValue'>,
        FieldProps {
    inputClassName?: string;
}

const TextField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            readOnly,
            disabled,
            className,
            inputClassName,
            controls,
            inputRef = undefined,
            ...props
        }: TextFieldProps,
        ref: any
    ) => {
        const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

        const handleChange = (e: any) => {
            setValue(e.target.value);
            onChange?.(e);
        };

        return (
            <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
                <Field.Input>
                    <textarea
                        className={classnames(
                            'px-2 py-1.5 flex-1 min-w-0 h-24 resize-none bg-transparent',
                            inputClassName
                        )}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={handleChange}
                        value={value || ''}
                        {...props}
                        ref={inputRef}
                    />
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        );
    }
);

export default TextField;
