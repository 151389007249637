import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

interface IProps {
    level: Number;
    className?: String;
    children?: any;
    onClick?: any;
    linkTo?: string;
}

export const Card = (props: IProps) => {
    const {
        level,
        className,
        children,
        onClick,
        linkTo, // TODO: think about making this explicit with a CardLink component
    } = props;

    const Base = linkTo ? Link : 'div';
    return (
        <Base className={classnames('card', `card--level-${level}`, className)} onClick={onClick} to={linkTo}>
            {children}
        </Base>
    );
};

Card.defaultProps = { level: 0 };

export default Card;
