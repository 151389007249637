import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import Logo from '../../../core/components/Logo';
import Card from '../../../core/components/Card';
import ProgressButton from '../../../core/containers/ProgressButton';
import Form from '../../../core/components/Form';
import { url } from '../../../core/utils/link';
import LoginWrapper from '../../components/LoginWrapper';
import { login } from '../../../core/utils/authentication';
import { FORGOT_PATH, LOGIN_PATH, PROVIDER_LOGIN_PATH } from '../../constants';
import { StringField } from '../../../assistance/containers/Fields';

const Login = (props) => {
    const { loggedIn, user } = props;

    const { t } = useTranslation('user');
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [wrongCredentials, setWrongCredentials] = useState(false);

    const nextLocation = searchParams.get('next') || '/';

    const handleSubmit = () => {
        setWrongCredentials(false);
        return login(username, password).then((success) => {
            if (success) {
                // force hard reload
                window.location.href = nextLocation;
            } else {
                setWrongCredentials(true);
            }
        });
    };

    useEffect(() => {
        if (!loggedIn || !user) return;
        navigate(nextLocation);
    }, [loggedIn, user]);

    return (
        <LoginWrapper>
            <div className="login__logo-wrapper">
                <Link to={url(LOGIN_PATH)}>
                    <Logo className="login__logo" />
                </Link>
            </div>

            <Card level={3} className={classnames('login__card', wrongCredentials ? 'login__card--shake' : '')}>
                <h1 className="login__title">{t('login.title')}</h1>

                <Form className="login__form" onSubmit={handleSubmit}>
                    {wrongCredentials && <div className="login__error">{t('login.wrongCredentialsText')}</div>}

                    <div className="login__input-group">
                        <StringField
                            label={t('login.form.email')}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="field--vertical"
                            inputProps={{
                                placeholder: t('login.form.emailPlaceholder'),
                                name: 'username',
                                type: 'email',
                            }}
                        />
                    </div>

                    <div className="login__input-group">
                        <StringField
                            label={t('login.form.password')}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="field--vertical"
                            inputProps={{
                                placeholder: t('login.form.passwordPlaceholder'),
                                type: 'password',
                                name: 'password',
                            }}
                        />
                        <div className="login__forgot-password">
                            <Link to={FORGOT_PATH}>{t('login.forgotLink')}</Link>
                        </div>
                    </div>

                    <ProgressButton label={t('login.form.submitButton')} onClick={handleSubmit} type="submit" />
                </Form>

                <div className="login__below-card">
                    <Link to={`${PROVIDER_LOGIN_PATH}?next=${nextLocation}`}>{t('login.withSSO')}</Link>
                </div>
            </Card>
        </LoginWrapper>
    );
};

export default Login;
