import React, { useEffect, useRef } from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import uniqueId from 'lodash/uniqueId';
import { DECIMAL_VALIDATION_REGEXP } from '../Fields';

import './Slider.scss';
import { useControllableState } from '../../../core_updated/utils/useControllableState';

const Slider = React.forwardRef(
    (
        { showInput = true, value, onChange, onUpdate, onBlur, min = 0, max = 100, step = 1, ...props }: any,
        ref: any
    ) => {
        return (
            <div className="slider">
                <SliderPrimitive.Root
                    className="slider__slider"
                    defaultValue={[min]}
                    min={min}
                    max={max}
                    step={step}
                    value={value != undefined ? [value] : undefined}
                    onValueChange={(value) => onChange?.({ target: { value: value[0] } })}
                    onBlur={(e) => onBlur?.(e)}
                    {...props}
                    ref={ref}
                >
                    <SliderPrimitive.Track className="slider__track">
                        <SliderPrimitive.Range className="slider__range" />
                    </SliderPrimitive.Track>
                    <SliderPrimitive.Thumb className="slider__thumb" aria-label="Volume" />
                </SliderPrimitive.Root>

                {showInput && (
                    <input
                        className="slider__input field"
                        type="number"
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={(e) => onChange?.(e)}
                        onBlur={(e) => onBlur?.(e)}
                    />
                )}
            </div>
        );
    }
);

export const SliderField = ({
    label,
    value: controlledValue,
    setValue: setControlledValue,
    initialValue = undefined,
    onUpdate,
    inputProps = {},
    locale = 'en_US',
    onClear = undefined,
    onChange = undefined,
    ...sliderProps
}: any) => {
    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        if (event.target.value != undefined && !DECIMAL_VALIDATION_REGEXP.test(event.target.value)) {
            event.preventDefault();
            return;
        }

        const value = event.target.value !== '' ? parseFloat(event.target.value) : undefined;

        setValue(value);
        onChange?.(value);
        if (inputProps?.onChange) inputProps?.onChange(value);
    };

    const handleBlur = (event, ...eventProps) => {
        if (inputProps?.onBlur) inputProps?.onBlur(event, ...eventProps);
        if (onUpdate && initialValue !== value) onUpdate(value !== undefined && value !== '' ? value : null);
    };
    const formatDecimal = (value, locale) => {
        if (locale && value) {
            const newValue = value.toString();
            // In case the number is still being written, just return it
            if (newValue.slice(-1) == '.' || newValue.slice(-1) == ',') {
                return value;
            }
            locale = locale.replace('_', '-');
            value = new Number(newValue.replace(',', '.'));
            value = value.toLocaleString(locale, { useGrouping: false });
        }

        return value;
    };

    return (
        <Slider
            id={fieldId}
            {...inputProps}
            value={formatDecimal(value, locale) == undefined ? '' : formatDecimal(value, locale)}
            onChange={handleChange}
            onUpdate={handleChange}
            onBlur={handleBlur}
            {...sliderProps}
        />
    );
};

export default Slider;
