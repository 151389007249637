import * as React from 'react';
import { BooleanField } from '../Fields';
import { useTranslation } from 'react-i18next';

interface IProps {
    record?: any;
    readOnly: boolean;
    handleUpdate: (payload: any) => void;
}

export const ERPLockedField = (props: IProps) => {
    const { record, readOnly, handleUpdate } = props;
    const { t } = useTranslation('assistance');

    const isERPLocked = record?.orderUnsaved?.isErpLocked ?? false;

    return (
        <BooleanField
            label={t(`fields.ERPLock.${readOnly ? 'read' : 'edit'}`)}
            value={isERPLocked ?? false}
            onUpdate={() =>
                handleUpdate({
                    fieldName: 'isErpLocked',
                    payload: { value: !isERPLocked ?? true },
                })
            }
            inputProps={{ disabled: readOnly }}
            isWrapped={false}
        />
    );
};

export default ERPLockedField;
