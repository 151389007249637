import * as React from 'react';

export const documentTypeName = 'propertyBill';

export const getHeaderFieldConfigs = (client, recordId, masterDataConfig, headerFields) => {
    return {
        kreditor: {
            groupFieldProps: {
                customerNumber: {
                    hidden: false,
                },
            },
        },
    };
};

export const getLineItemFieldConfigs = (client, recordId, masterDataConfig) => {
    return {};
};
