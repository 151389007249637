import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from '../../../../core_updated/utils/classnames';
import { useTranslation } from 'react-i18next';
import Page from '../../../../core_updated/components/Page';
import OrderByDropdown from './OrderByDropdown';
import { IFilter, ORDER_BY_FILTER_KEY, SEARCH_FILTER_KEY } from '../../../../core/utils/filterQuery';
import { withIcon } from '../../../../core_updated/components/Icon';
import { faArchive, faBarsFilter, faEllipsisVertical, faFlask, faInbox } from '@fortawesome/pro-regular-svg-icons';
import { useOverviewContext } from './OverviewContext';
import { useCallback, useEffect, useState } from 'react';
import SearchField from './SearchField';
import StatusFilter from './StatusFilter';
import CreatedByFilter from './CreatedByFilter';
import CreatedAtFilter from './CreatedAtFilter';
import { useApplicationContext } from '../../../../core_updated/contexts/ApplicationContext';
import { canSeeTestingDocuments } from '../../../../users/utils';
import { useDebounce } from '../../../../core/utils/hooks/useDebounce';

const FilterIcon = withIcon(faBarsFilter);
const InboxIcon = withIcon(faInbox);
const ArchiveIcon = withIcon(faArchive);
const TestingIcon = withIcon(faFlask);

interface FilterBarProps {
    filters: IFilter[];
    onFiltersChange: (filters: IFilter[]) => void;
    // used for db_immo external status right now
    renderExtraFilters?: (props: {
        filters: IFilter[];
        onFiltersChange: (filters: IFilter[]) => void;
    }) => React.ReactNode;
    activeChannel: any;
}

const TabFilter = ({
    active = false,
    className,
    ...props
}: React.ComponentPropsWithoutRef<typeof Link> & { active?: boolean }) => (
    <Link
        className={classnames(
            'border-b-2 border-solid border-transparent flex items-center justify-center gap-2 px-3 font-medium text-sm text-primary hover:text-brand transition-colors',
            'hover:border-secondary hover:text-primary',
            active && '!border-brand !text-brand',
            className
        )}
        {...props}
    />
);

const DropdownFilterBar = ({ filters, onFiltersChange, renderExtraFilters, activeChannel }: FilterBarProps) => {
    const { t } = useTranslation('assistance');

    // keep only the order_by and search filters
    const handleClearAll = () =>
        onFiltersChange(filters.filter((f) => f.name === ORDER_BY_FILTER_KEY || f.name === SEARCH_FILTER_KEY));

    const listFilters = filters.filter((f) => f.name !== ORDER_BY_FILTER_KEY && f.name !== SEARCH_FILTER_KEY);

    return (
        <div className="px-6 py-2 flex gap-2 bg-secondary-light border-b border-solid border-secondary">
            <StatusFilter filters={filters} onFiltersChange={onFiltersChange} />
            <CreatedByFilter filters={filters} onFiltersChange={onFiltersChange} activeChannel={activeChannel} />
            <CreatedAtFilter filters={filters} onFiltersChange={onFiltersChange} />

            {renderExtraFilters && renderExtraFilters({ filters, onFiltersChange })}

            {listFilters.length > 0 && (
                <button
                    className="text-primary px-3 text-sm font-medium hover:text-primary hover:bg-secondary rounded transition-colors"
                    onClick={handleClearAll}
                >
                    {t('overview.filters.clearAll')}
                </button>
            )}
        </div>
    );
};

const FilterBar = ({ filters, onFiltersChange, renderExtraFilters, activeChannel }: FilterBarProps) => {
    const { t } = useTranslation('assistance');

    const { user } = useApplicationContext();
    const { finished, testing, openUrl, finishedUrl, testingUrl } = useOverviewContext();

    const listFilters = filters.filter((f) => f.name !== ORDER_BY_FILTER_KEY && f.name !== SEARCH_FILTER_KEY);
    const [dropdownFilterBarVisible, setDropdownFilterBarVisible] = useState(listFilters.length > 0);
    const uniqueListFiltersCount = new Set(listFilters.map((f) => f.name)).size;

    let orderByValue = filters.find((f) => f.name === ORDER_BY_FILTER_KEY)?.value;
    if (orderByValue && Array.isArray(orderByValue)) orderByValue = orderByValue[0];

    const handleOrderByChange = (orderBy) => {
        // replace the current order_by filter with the new one
        const newFilters = filters.filter((f) => f.name !== ORDER_BY_FILTER_KEY);
        newFilters.push({ name: ORDER_BY_FILTER_KEY, value: orderBy, exclude: false, expression: 'eq' });
        onFiltersChange(newFilters);
    };

    let searchTerm = filters.find((f) => f.name === SEARCH_FILTER_KEY)?.value || '';
    if (searchTerm && Array.isArray(searchTerm)) searchTerm = searchTerm[0];

    const [searchInputValue, setSearchInputValue] = useState(searchTerm);

    const handleSearchTermChange = (searchTerm) => {
        // only really trigger search after the user has stopped typing for 500ms
        setSearchInputValue(searchTerm);
        debouncedPropagateSearchFilter(searchTerm);
    };

    const propagateSearchFilter = useCallback(
        (searchTerm) => {
            // replace the current search filter with the new one
            const newFilters = filters.filter((f) => f.name !== SEARCH_FILTER_KEY);
            if (searchTerm) {
                newFilters.push({ name: SEARCH_FILTER_KEY, value: searchTerm, exclude: false, expression: 'eq' });
            }
            onFiltersChange(newFilters);
        },
        [filters, onFiltersChange]
    );

    const debouncedPropagateSearchFilter = useDebounce(propagateSearchFilter, 500);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const orderByOptions = [
        {
            value: 'created_at',
            label: t('overview.filters.orderBy.options.createdAt'),
            isDate: true,
        },
        {
            value: 'created_by__email',
            label: t('overview.filters.orderBy.options.createdBy'),
        },
    ];

    return (
        <>
            <Page.Header className="py-0">
                <div className="flex flex-1 h-full">
                    <TabFilter to={`${openUrl}?${searchParams.toString()}`} active={!finished && !testing}>
                        <InboxIcon /> {t('overview.header.open')}
                    </TabFilter>

                    <TabFilter to={`${finishedUrl}?${searchParams.toString()}`} active={finished}>
                        <ArchiveIcon /> {t('overview.header.finished')}
                    </TabFilter>

                    {testingUrl && canSeeTestingDocuments(user) && (
                        <TabFilter to={`${testingUrl}?${searchParams.toString()}`} active={testing}>
                            <TestingIcon /> {t('overview.header.testing')}
                        </TabFilter>
                    )}
                </div>

                <div className="py-2.5 flex gap-2">
                    <SearchField
                        placeholder={t('overview.filters.search.placeholder')}
                        value={searchInputValue}
                        onValueChange={handleSearchTermChange}
                    />
                    <Page.HeaderButton
                        active={dropdownFilterBarVisible}
                        onClick={() => setDropdownFilterBarVisible(!dropdownFilterBarVisible)}
                        variant="ghost"
                    >
                        <FilterIcon /> {t('overview.filters.toggleLabel')}{' '}
                        {uniqueListFiltersCount > 0 && `(${uniqueListFiltersCount})`}
                    </Page.HeaderButton>

                    <OrderByDropdown
                        label={t('overview.filters.orderBy.label')}
                        options={orderByOptions}
                        selected={orderByValue}
                        onSelectedChange={handleOrderByChange}
                    />
                </div>
            </Page.Header>

            {dropdownFilterBarVisible && (
                <DropdownFilterBar
                    filters={filters}
                    onFiltersChange={onFiltersChange}
                    renderExtraFilters={renderExtraFilters}
                    activeChannel={activeChannel}
                />
            )}
        </>
    );
};

export default FilterBar;
