import * as React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Modal, { IBaseModalProps as ModalProps } from '../../components/Modal';
import { SecondaryButton } from '../../components/Button';
import ProgressButton from '../ProgressButton';

import './style.scss';

export interface IProps extends ModalProps {
    onConfirm: any;
    onCancel: any;
    confirmDisabled?: boolean;
    labelConfirm?: string;
    labelCancel?: string;
    buttons?: Array<React.ReactNode>;
    danger?: boolean;
}

const ConfirmModal = (props: IProps) => {
    let {
        className,
        children,
        onConfirm,
        onCancel,
        buttons,
        labelConfirm,
        labelCancel,
        danger = false,
        confirmDisabled,
        ...modalProps
    } = props;

    const { t } = useTranslation('core');

    buttons = buttons || [
        // default buttons
        <SecondaryButton
            key="cancel"
            label={labelCancel ?? t('components.confirmModal.cancel')}
            onClick={() => onCancel()}
        />,
        <ProgressButton
            key="confirm"
            label={labelConfirm ?? t('components.confirmModal.ok')}
            onClick={() => onConfirm()}
            className={danger && 'button--danger'}
            disabled={confirmDisabled}
        />,
    ];

    return (
        <Modal
            className={classnames('confirm-modal', className)}
            buttons={buttons}
            onClose={onCancel}
            noHeader
            {...modalProps}
        >
            {children}
        </Modal>
    );
};

export default ConfirmModal;
