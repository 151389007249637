import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { snakeCase } from 'lodash';
import classnames from 'classnames';
import pick from 'lodash/pick';
import { useTranslation } from 'react-i18next';
import Button, { SecondaryButton } from '../../../core/components/Button';
import Loader from '../../../core/components/Loader';
import { getConfidenceClass } from '../../../core/components/ConfidenceIndicator';
import SplitPanes, { Pane } from '../../../core/containers/SplitPanes';
import { Area, SelectionImageMap } from '../ImageMap';
import ValidationChecks from '../ValidationChecks';
import { FIELDS, isEmptyValue } from '../Fields';
import ERPLockedField from '../ERPLockedField';
import { Spacer } from '../../../core/components/Spacer';
import { useFeatureToggle } from '../../../core/utils/hooks/useFeatureToggle';
import { appInsights } from '../../../core/analytics/applicationInsights';
import {
    clearFieldEvent,
    focusFieldEvent,
    reselectFieldCancelEvent,
    reselectFieldEndEvent,
    reselectFieldStartEvent,
    selectDocumentViewerRegionEndEvent,
    selectDocumentViewerRegionStartEvent,
} from '../../../core/analytics/customEvents';
import AssistanceCanvas from '../../components/AssistanceCanvas';

const SCROLL_OFFSET = -20;
const FOCUS_DURATION = 3000;

const getFieldTransKey = (name, key = undefined, prefix = undefined) => {
    prefix = prefix || 'headerView.fieldNames.';
    const legacyFieldName = snakeCase(name);
    const legacyKey = snakeCase(key);
    return key ? `${prefix}${legacyFieldName}__${legacyKey}` : `${prefix}${legacyFieldName}`;
};

const HeaderField = (props) => {
    const {
        user,
        record,
        document,
        fieldName,
        optionalFieldNames,
        readOnly,
        loading,

        fieldConfigs,

        onUpdate,
        handleClickFieldFocus,
        handleFieldReselect,

        setHoverField,
        activeFieldId,
        setActiveFieldId,
        explanationToggle,
    } = props;

    const { t } = useTranslation('assistance');
    const field = document?.[fieldName];

    let fieldConfig = fieldConfigs?.[fieldName];
    let fieldTypeName = fieldConfig?.fieldType || field?.__typename;
    if (field?.['choices'] != null || field?.['options'] != null) {
        fieldTypeName = 'SelectField';
    }
    const Field = FIELDS[fieldTypeName];
    const fieldTypeConfig = fieldConfigs?.[fieldTypeName] || {};

    fieldConfig = Object.assign(fieldTypeConfig, fieldConfig);
    if (!field || !Field) return null;

    const mapKeys = (keys, fn) => Object.fromEntries(keys.map((key) => [key, fn(key)]));
    const fieldGroupFieldNames = {
        AddressField: [
            'addressId',
            'addressId2',
            'name',
            'companyName',
            'streetAndNr',
            'postcode',
            'city',
            'country',
            'email',
            'phone',
            'misc',
        ],
        CustomerField: ['customerNumber', 'name', 'address', 'internalNote'],
        ContactField: ['contactId', 'name', 'email', 'phone'],
    };
    const isFieldGroup = fieldGroupFieldNames[fieldTypeName] !== undefined;
    const value = isFieldGroup ? pick(field, fieldGroupFieldNames[fieldTypeName]) : field.value;

    const handleUpdate = (value, data = {}) =>
        onUpdate({ fieldName, payload: isFieldGroup ? { ...value, ...data } : { value, ...data } });

    const handleAcceptDeviation = (value) => {
        onUpdate({
            fieldName,
            action: 'action:document_matching_accept_deviation',
        });
    };

    const handleClear = (fieldName) => {
        const clearedValue = isFieldGroup ? mapKeys(fieldGroupFieldNames[fieldTypeName], () => '') : '';
        onUpdate({ fieldName, payload: isFieldGroup ? { ...clearedValue } : { value: clearedValue } });
        appInsights?.trackEvent(...clearFieldEvent(user, record, document, { fieldType: 'header' }));
    };

    const tooltip = field?.confidenceExplanation;
    const fieldProps = {
        ...fieldConfig?.fieldProps,
        className: `field--confidence-${getConfidenceClass(field.predictionConfidence, false)}`,
        label: t(getFieldTransKey(fieldName)),
        tooltip,
        activeFieldId: activeFieldId,
        setActiveFieldId: setActiveFieldId,
        explanationToggle: explanationToggle,
        loading: loading,
        handleMatchingAction: handleAcceptDeviation,
    };

    const inputProps = {
        ...fieldConfig?.inputProps,
        onFocus: () => {
            // if the field only allows a list of given values (e.g. from lookup), don't allow nested reselection
            const forceSelection = fieldConfig?.fieldProps?.forceSelection;
            return !forceSelection && handleFieldReselect(fieldName);
        },
        onBlur: () => handleFieldReselect(undefined),
        placeholder: t(getFieldTransKey(fieldName)),
        required:
            fieldConfig?.inputProps?.required == null
                ? !optionalFieldNames.includes(fieldName)
                : fieldConfig?.inputProps.required,
        disabled: readOnly,
    };

    let fieldGroupProps;
    if (isFieldGroup) {
        // in case we have a field group, we need to prepare props to pass to subfields
        const groupFieldProps = mapKeys(fieldGroupFieldNames[fieldTypeName], (key) => ({
            ...fieldProps,
            ...fieldConfig?.groupFieldProps?.[key],
            label: t(getFieldTransKey(fieldName, key)),
        }));

        const allValuesEmpty = Object.entries(value).every(([k, v]) => isEmptyValue(v));
        const groupInputProps = mapKeys(fieldGroupFieldNames[fieldTypeName], (key) => ({
            ...inputProps,
            ...fieldConfig?.groupInputProps?.[key],
            onFocus: () => {
                // if the field only allows a list of given values (e.g. from lookup), don't allow nested reselection
                const forceSelection = fieldConfig?.groupFieldProps?.[key]?.forceSelection;
                return !forceSelection && handleFieldReselect(`${fieldName}.${key}`);
            },
            onBlur: () => handleFieldReselect(undefined),
            placeholder: t(getFieldTransKey(fieldName, key)),
            required:
                fieldConfig?.groupInputProps?.[key]?.required == null
                    ? !(
                          // if explicitly not required then it's never required
                          (
                              optionalFieldNames.includes(`${fieldName}.${key}`) ||
                              // if we fall back to the full field being optional, all values need to be empty
                              (optionalFieldNames.includes(fieldName) && allValuesEmpty)
                          )
                      )
                    : fieldConfig?.groupInputProps?.[key]?.required,
        }));

        fieldGroupProps = {
            groupInputProps,
            groupFieldProps,
        };
    }

    const confidence = Math.floor(field.predictionConfidence * 100);

    return (
        <div
            key={fieldName}
            className="field-group"
            onMouseEnter={() => setHoverField(fieldName)}
            onMouseLeave={() => setHoverField(undefined)}
        >
            <div className="field-group__header">
                <div className="field-group__title">
                    {t(getFieldTransKey(fieldName))}{' '}
                    <span
                        className={classnames(
                            'field-group__confidence',
                            `field-group__confidence--${getConfidenceClass(field.predictionConfidence)}`
                        )}
                    >
                        {confidence}%
                    </span>
                </div>
                {!readOnly && (
                    <div
                        className={classnames(
                            'field-group__actions',
                            explanationToggle && 'field-group__show-explanations'
                        )}
                    >
                        <SecondaryButton
                            label={t('headerView.actions.focus')}
                            className="field-group__action-button field-group__action-button--focus"
                            onClick={() => handleClickFieldFocus(fieldName)}
                        />

                        {fieldTypeName === 'AddressField' && (
                            <SecondaryButton
                                label={t('headerView.actions.clear')}
                                className="field-group__action-button field-group__action-button--clear"
                                onClick={() => handleClear(fieldName)}
                            />
                        )}

                        <SecondaryButton
                            label={t('headerView.actions.reselect')}
                            className="field-group__action-button field-group__action-button--reselect"
                            onClick={() => {
                                handleFieldReselect(fieldName);
                                appInsights?.trackEvent(
                                    ...reselectFieldStartEvent(user, record, document, {
                                        fieldName,
                                        fieldType: 'header',
                                    })
                                );
                            }}
                        />
                    </div>
                )}
            </div>

            <Field
                onUpdate={handleUpdate}
                label={fieldName}
                initialValue={value}
                choices={field['choices']} // for SelectField
                options={field['options']} // for SelectField
                inputProps={inputProps}
                {...fieldGroupProps}
                {...fieldProps}
            />

            <ValidationChecks checks={field?.validationChecks} />
        </div>
    );
};

const MemoizedHeaderField = React.memo(HeaderField);

const HeaderView = (props) => {
    const {
        user,
        record,
        ocr,
        document,
        onUpdate,
        onReselect,
        loading,
        readOnly,
        headerFields: fieldNames = [],
        optionalHeaderFields: optionalFieldNames = [],
        headerValidationChecks = [],
        formButtons,
        fieldConfigs = {},
        explanationToggle,
    } = props;

    const { t } = useTranslation('assistance');

    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

    const [activeFieldId, setActiveFieldId] = useState(null);
    const pagesCount = ocr?.pagesCount;

    const getTop = (bbox, pageIndex, pagesCount) => (bbox[0][1] + pageIndex) / pagesCount;

    const scrollToField = (fieldName) => {
        const field = document?.[fieldName];

        const viewportWidth = previewRef.current?.getBoundingClientRect().width || 0;

        const svg = previewRef.current?.querySelector('svg');
        const svgHeight = svg?.getBoundingClientRect().height || 0;
        const svgWidth = svg?.getBoundingClientRect().width || 0;

        const scrollTop = field?.bbox ? getTop(field.bbox, field.pageIndex, pagesCount) : 0;
        const spacingTop =
            svg.getBoundingClientRect().top -
            previewRef.current?.getBoundingClientRect().top +
            previewRef.current?.scrollTop;

        const spacingLeft =
            svg.getBoundingClientRect().left -
            previewRef.current?.getBoundingClientRect().left +
            previewRef.current?.scrollLeft;

        const relativeWordCenterX = field?.bbox ? field.bbox[0][0] + (field.bbox[1][0] - field.bbox[0][0]) / 2 : 0;
        const alignLeft = relativeWordCenterX <= 0.5;

        // if word is on the right side of the screen, scroll to the right

        previewRef.current.scrollTo({
            top: scrollTop * svgHeight + spacingTop + SCROLL_OFFSET,
            left:
                svgWidth > viewportWidth
                    ? alignLeft
                        ? spacingLeft + SCROLL_OFFSET
                        : spacingLeft + svgWidth - viewportWidth - SCROLL_OFFSET
                    : undefined,
            behavior: 'smooth',
        });
    };

    const previewRef = useRef(undefined);
    const formRef = useRef(undefined);

    const [reselectField, setReselectField] = useState(undefined);
    const [activeReselectField, setActiveReselectField] = useState(undefined);
    const [hoverField, setHoverField] = useState(undefined);
    const [focusField, setFocusField] = useState(undefined);

    // we are storing the imageSrc because everytime we do an update a new token is added to the image url
    // which triggers a rerender and image reload in the image map - and we don't want that
    const [imageSrc, setImageSrc] = useState(ocr?.processedFile.url);
    useEffect(() => {
        if (imageSrc === undefined) setImageSrc(ocr?.processedFile.url);
    }, [ocr?.processedFile.url]);

    const handleSelectAreaStart = useCallback(
        (bbox, pageIndex) => {
            setActiveReselectField(reselectField);
            appInsights?.trackEvent(
                ...selectDocumentViewerRegionStartEvent(user, record, document, {
                    fieldName: reselectField,
                    fieldType: 'header',
                })
            );
        },
        [reselectField, document, user, record]
    );

    const handleSelectAreaEnd = useCallback(
        (bbox, pageIndex) => {
            onReselect({ fieldName: activeReselectField, bbox, pageIndex });
            setActiveReselectField(undefined);
            setReselectField(undefined);
            appInsights?.trackEvent(
                ...selectDocumentViewerRegionEndEvent(user, record, document, {
                    fieldName: activeReselectField,
                    fieldType: 'header',
                })
            );
            appInsights?.trackEvent(
                ...reselectFieldEndEvent(user, record, document, {
                    fieldName: activeReselectField,
                    fieldType: 'header',
                })
            );
        },
        [reselectField, activeReselectField, document, user, record]
    );

    const handleCancelReselection = useCallback(() => {
        setReselectField(undefined);
        appInsights?.trackEvent(
            ...reselectFieldCancelEvent(user, record, document, { fieldName: reselectField, fieldType: 'header' })
        );
    }, [reselectField, document, user, record]);

    const handleFieldReselect = useCallback((fieldName) => {
        setReselectField(fieldName);
    }, []);

    const handleClickFieldFocus = useCallback(
        (fieldName) => {
            scrollToField(fieldName);
            setFocusField(fieldName);
            setTimeout(() => {
                setFocusField(() => undefined);
            }, FOCUS_DURATION);
            appInsights?.trackEvent(...focusFieldEvent(user, record, document, { fieldType: 'header' }));
        },
        [document, previewRef?.current, user, record]
    );

    const handleClickFieldCorrect = useCallback(
        (fieldName) => {
            onUpdate({ fieldName, action: `action:correct` });
        },
        [document, previewRef?.current, user, record, onUpdate]
    );
    const handleUpdate = useCallback((props) => onUpdate(props), [record, onUpdate]);

    const fields = Object.entries(pick(document, fieldNames));

    const isERPLockEnabled = useFeatureToggle('ERPLock', { channel: record?.channel });

    const handleImageLoad = (evt) => {
        const { width, height } = evt.currentTarget;
        setImageDimensions({ width, height });
    };

    return (
        <div className="header-view assistance-view__inner">
            <SplitPanes id="assistance-header" initialWidths={[66, 34]} initialHeights={[66, 34]}>
                <Pane className="bg-secondary">
                    <AssistanceCanvas
                        artboardWidth={imageDimensions.width}
                        artboardHeight={imageDimensions.height}
                        viewportRef={previewRef}
                    >
                        <SelectionImageMap
                            src={imageSrc}
                            numPages={ocr?.pagesCount}
                            canSelect={reselectField !== undefined || activeReselectField !== undefined}
                            onSelectionStart={handleSelectAreaStart}
                            onSelectionEnd={handleSelectAreaEnd}
                            onLoad={handleImageLoad}
                        >
                            {reselectField === undefined &&
                                fields.map(([fieldName, field]: [string, any], fieldIndex) => (
                                    <Area
                                        key={fieldIndex}
                                        data={field}
                                        bbox={field?.bbox}
                                        pageIndex={field?.pageIndex}
                                        className={classnames(
                                            'image-map__area--cell',
                                            ((!focusField && hoverField && hoverField !== fieldName) ||
                                                (focusField && focusField !== fieldName)) &&
                                                'image-map__area--cell-no-focus'
                                        )}
                                    />
                                ))}
                        </SelectionImageMap>
                    </AssistanceCanvas>

                    {!readOnly && (
                        <div className="assistance-view__preview-buttons">
                            {reselectField !== undefined && (
                                <Button
                                    label={t('headerView.cancelSelectionButton')}
                                    className="button--danger"
                                    onClick={handleCancelReselection}
                                />
                            )}
                        </div>
                    )}
                </Pane>

                <Pane className="assistance-view__form-modal-root">
                    <div className="assistance-view__form-wrapper" ref={formRef}>
                        <div
                            className={classnames('assistance-view__form', loading && 'assistance-view__form--locked')}
                        >
                            <ValidationChecks checks={headerValidationChecks} />

                            {!document ? (
                                <Loader className="assistance-view__form-loader" />
                            ) : (
                                fieldNames.map((fieldName) => (
                                    <MemoizedHeaderField
                                        key={fieldName}
                                        user={user}
                                        record={record}
                                        document={document}
                                        fieldName={fieldName}
                                        fieldConfigs={fieldConfigs}
                                        readOnly={readOnly}
                                        optionalFieldNames={optionalFieldNames}
                                        onUpdate={onUpdate}
                                        handleClickFieldFocus={handleClickFieldFocus}
                                        handleFieldReselect={handleFieldReselect}
                                        handleClickFieldCorrect={handleClickFieldCorrect}
                                        setHoverField={setHoverField}
                                        activeFieldId={activeFieldId}
                                        setActiveFieldId={setActiveFieldId}
                                        explanationToggle={explanationToggle}
                                        loading={loading}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                    <div className="assistance-view__completion_buttons">
                        {readOnly && document && (
                            <div className="assistance-view__completion_buttons__vertical">
                                {isERPLockEnabled && (
                                    <ERPLockedField record={record} readOnly={readOnly} handleUpdate={handleUpdate} />
                                )}
                            </div>
                        )}

                        {!readOnly && document && (
                            <div className="assistance-view__completion_buttons__vertical">
                                {isERPLockEnabled && (
                                    <>
                                        <ERPLockedField
                                            record={record}
                                            readOnly={readOnly}
                                            handleUpdate={handleUpdate}
                                        />
                                        <Spacer />
                                    </>
                                )}
                                {formButtons}
                            </div>
                        )}
                    </div>
                </Pane>
            </SplitPanes>
        </div>
    );
};

export default HeaderView;
