import * as React from 'react';
import { Navigate, Route, useParams } from 'react-router-dom';
import { useTrackedLocation } from './hooks/useLocation';

import NotFound from '../containers/NotFound';
import { IConditionalComponents } from '../../customizations/ConditionalComponentContext';

export interface IFeatureFlags {
    assistanceEmailThreads?: boolean;
}

export interface IModuleProps {
    name: string;
    routes?: IRouteProps[];
    locales?: any;
    components?: IConditionalComponents;
    featureFlags?: IFeatureFlags;
}

export interface IRouteProps {
    path: any;
    loginRequired?: boolean;
    condition?: any;
    component: any;
}

const CustomRoute = (props) => {
    const { component: Component, condition, loginRequired = true, loggedIn, ...renderProps } = props;

    const params = useParams();
    const location = useTrackedLocation();

    if (!loginRequired || loggedIn === true) {
        return !condition || condition(renderProps) ? (
            <Component loggedIn={loggedIn} params={params} {...renderProps} />
        ) : (
            <NotFound />
        );
    }

    const redirectUrl = location.search ? `${location.pathname}${location.search}` : location.pathname;
    const search = redirectUrl && redirectUrl !== '/' ? `?next=${encodeURIComponent(redirectUrl)}` : '';
    return <Navigate to={{ pathname: '/login', search }} />;
};

class Modules {
    private modules = {};

    public populate(modules: IModuleProps[]) {
        modules.forEach(({ name, ...options }) => this.register(name, options));
    }

    public register(name, options) {
        const { routes = [], locales = {} } = options;

        this.modules[name] = {
            name,
            routes,
            locales,
        } as IModuleProps;
    }

    public getRoutes() {
        return Object.values(this.modules).flatMap((mod: IModuleProps) => mod.routes);
    }

    public renderRoutes(props) {
        // Specific output for ReactRouter
        return this.getRoutes().map((route: IRouteProps) => {
            // @ts-ignore
            const { path, ...routeProps } = route;
            return <Route key={path} path={path} element={<CustomRoute {...props} {...routeProps} />} />;
        });
    }

    public getLocales() {
        // Specific output for i18n-next resource bundles
        const locales = {};

        Object.values(this.modules).forEach((mod: IModuleProps) => {
            Object.entries(mod.locales).forEach(([locale, translations]) => {
                if (!(locale in locales)) locales[locale] = {};
                locales[locale][mod.name] = translations;
            });
        });

        return locales;
    }
}

// create default module registry
export const modules = new Modules();
