export const CONFIDENCE_THRESHOLD_HIGH_STRICT = 1.0;
export const CONFIDENCE_THRESHOLD_HIGH = 0.9;
export const CONFIDENCE_THRESHOLD_MEDIUM = 0.4;

export const getConfidenceClass = (confidence: number, strict: boolean = false): string => {
    if (strict && confidence >= CONFIDENCE_THRESHOLD_HIGH_STRICT) return 'high';
    else if (!strict && confidence >= CONFIDENCE_THRESHOLD_HIGH) return 'high';
    else if (confidence >= CONFIDENCE_THRESHOLD_MEDIUM) return 'medium';
    return 'low';
};
