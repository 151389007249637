import { useEffect } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useApplicationContext } from '../../../core_updated/contexts/ApplicationContext';

/**
 * Custom hook that builds up a history of location changes from navigating across the app
 *
 * Note: Ideally use only once somewhere at the top of the component hierarchy
 */
export const useTrackedLocation = () => {
    const { navigationHistory } = useApplicationContext();
    const location = useLocation();
    const numLocationsKept = 2;

    // Update navigation history with every location change
    useEffect(() => {
        const lastLocation = navigationHistory[navigationHistory.length - 1];
        if (!isEqual(lastLocation, location)) {
            navigationHistory.push(location);
        }
    }, [location.pathname, location.state, location.search]);

    // Clear history when unmounting
    useEffect(() => {
        return () => {
            // only keep most recent locations on stack when unmounting
            navigationHistory.splice(navigationHistory.length - numLocationsKept);
        };
    }, []);

    return location;
};

export const useNavigationHistory = () => {
    const { navigationHistory } = useApplicationContext();
    return navigationHistory;
};

/**
 * For global focus management - restoreFocus will restore the last trapped focus from anywhere within the app
 * TODO use a proper focus trap library
 */
let trappedFocusRef = null;

export const useFocusTrap = () => {
    const trapFocus = (ref) => {
        trappedFocusRef = ref;
    };
    const restoreFocus = () => {
        setTimeout(() => {
            trappedFocusRef?.current?.focus();
        }, 0);
    };

    return { trapFocus, restoreFocus, trappedFocusRef };
};
