import { useControllableState } from '../../../../core_updated/utils/useControllableState';
import { useState } from 'react';
import classnames from '../../../../core_updated/utils/classnames';
import * as React from 'react';
import { withIcon } from '../../../../core_updated/components/Icon';
import { faMagnifyingGlass, faTimes } from '@fortawesome/pro-regular-svg-icons';

const SearchIcon = withIcon(faMagnifyingGlass);
const ClearIcon = withIcon(faTimes);

const SearchField = ({ defaultValue = '', value: propsValue, onValueChange, placeholder }: any) => {
    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

    const [isFocused, setIsFocused] = useState(false);

    return (
        <div
            // if it is empty and not focused it acts like a button otherwise like a field
            className={classnames(
                'text-sm relative h-8 border border-transparent outline-transparent border-solid rounded transition-all min-w-[80px]',
                value && 'border-secondary min-w-[300px] hover:border-brand',
                isFocused &&
                    'border-brand outline outline-surface-brand outline-2 !bg-primary min-w-[300px] hover:border-brand',
                !isFocused && !value && 'hover:bg-secondary'
            )}
        >
            <SearchIcon className="absolute pointer-events-none top-1/2 left-3 transform -translate-y-1/2 z-10 text-sm text-primary" />

            <button
                className={classnames(
                    'absolute h-full w-8 right-0 z-10 text-sm hover:bg-secondary hover:text-primary rounded-full transition-all',
                    value ? 'opacity-100' : 'opacity-0 pointer-events-none'
                )}
                onClick={() => setValue('')}
            >
                <ClearIcon />
            </button>

            <input
                className={classnames(
                    'absolute inset-0 w-full h-full pl-8 pr-3 cursor-pointer placeholder-primary font-medium rounded bg-transparent text-primary',
                    isFocused && !value && 'font-normal placeholder-secondary',
                    value && 'pr-10 font-normal'
                )}
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
            />
            <div
                className={classnames(
                    'pl-8 pr-3 font-medium text-sm pointer-events-none border border-transparent bg-transparent opacity-0',
                    value && 'pr-10'
                )}
            >
                {value || placeholder}
            </div>
        </div>
    );
};

export default SearchField;
