import * as React from 'react';
import { FieldWrapper } from '../Fields';
import { SimpleChipInput } from '../../../core/containers/ChipInput';

const ListField = ({ label, initialValue, onUpdate, inputProps = undefined, ...props }) => {
    inputProps = inputProps || {};
    return (
        <FieldWrapper
            label={label}
            className="field--vertical"
            {...props}
            required={inputProps?.required}
            disabled={inputProps?.disabled || inputProps?.readOnly}
        >
            <SimpleChipInput
                className="list-field field__input"
                {...inputProps}
                value={initialValue}
                setValue={onUpdate}
            />
        </FieldWrapper>
    );
};
export default ListField;
