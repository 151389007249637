import * as React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Announcement } from '../../announcements/components/AnnouncementBar';

interface ApplicationContextProps {
    loading?: boolean;
    channelId?: any;
    setChannelId?: (channel: any) => void;
    user: any;
    announcements?: Announcement[];
    navigationHistory?: any;
    setNavigationHistory?: (history: any) => void;
    children: any;
}

const ApplicationContext = createContext<Omit<ApplicationContextProps, 'children'>>({
    loading: false,
    channelId: null,
    setChannelId: () => {},
    user: null,
    announcements: [],
    navigationHistory: [],
});

export const ApplicationContextProvider = (props: Omit<ApplicationContextProps, 'navigationHistory'>) => {
    const { children, ...value } = props;
    const [navigationHistory, setNavigationHistory] = useState<any[]>([]);
    return (
        <ApplicationContext.Provider value={{ ...value, navigationHistory }}>{children}</ApplicationContext.Provider>
    );
};

export const useApplicationContext = () => useContext(ApplicationContext);
