import { ValidationCheckResultStatus } from './constants';
import { uniqWith, isEqual } from 'lodash';

export const filterValidation = (validationChecks, i18n = undefined) => {
    const filteredValidation = (validationChecks || []).filter(
        (check) => check.result === ValidationCheckResultStatus.FAILED
    );

    // If there is no translation key, we also don't want to show the check (e.g. document matching check)
    // Note: this seems like a pretty bad idea and I just copied in from its original place - we should rather
    //  add the validation key to the i18n file or come up with a better solution
    return uniqWith(
        (filteredValidation || []).filter((check) => {
            if (check.targetValue || !i18n || (check.name && i18n.exists(`assistance:validationChecks.${check.name}`)))
                return check;
        }),
        isEqual
    );
};
