import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../../../../core_updated/contexts/ApplicationContext';
import Filter from '../../../../analytics/components/Filter';
import * as React from 'react';

const CreatedByFilter = ({ filters, onFiltersChange, activeChannel }) => {
    const { t } = useTranslation('assistance');

    // TODO: maybe move activeChannel to context
    const { user } = useApplicationContext();

    const filterName = 'created_by';
    const filter = filters.find((f) => f.name === filterName);

    const selected = filter ? (Array.isArray(filter.value) ? filter.value : [filter.value]) : [];
    const handleSelectedChange = (selected: string[]) => {
        const newFilters = filters.filter((f) => f.name !== filterName);
        if (selected.length) {
            newFilters.push({ name: filterName, value: selected, exclude: false, expression: 'eq' });
        }
        onFiltersChange(newFilters);
    };

    // Sort users by first name, but put the current user on top
    const channelUsers = activeChannel?.team?.users
        ? [...activeChannel.team.users.filter((u) => u.email !== user.email)]
        : [];
    channelUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));
    channelUsers.unshift(user);

    const createdByOptions = channelUsers.map((user) => ({
        value: user.email,
        label: `${user.firstName} ${user.lastName}`,
    }));

    return (
        <Filter
            allLabel={t('overview.filters.createdBy.all')}
            label={t('overview.filters.createdBy.label')}
            options={createdByOptions}
            selected={selected}
            onSelectedChange={handleSelectedChange}
            multiple
        />
    );
};

export default CreatedByFilter;
