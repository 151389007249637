import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

export interface IProps {
    id?: string;
    name?: string;
    label?: string;
    className?: string;
    disabled?: boolean;
    onChange?: any;
    onClick?: any;
    onBlur?: any;
    checked?: boolean;
    required?: boolean;
    readOnly?: boolean;
    alignRight?: boolean;
}

const Checkbox = React.forwardRef(({ id, name, label = '', className, alignRight, ...props }: IProps, ref: any) => {
    return (
        <label className={classnames('checkbox', className)}>
            {alignRight && label && <span className="checkbox__label__left">{label}</span>}
            <input ref={ref} id={id} className="checkbox__input" name={name} type="checkbox" {...props} />
            <div className="checkbox__box" />
            {!alignRight && label && <span className="checkbox__label">{label}</span>}
        </label>
    );
});

Checkbox.defaultProps = {
    disabled: false,
    checked: false,
    required: false,
    readOnly: false,
};

export default Checkbox;
