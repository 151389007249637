import * as React from 'react';
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import { SecondaryButton } from '../../components/Button';
import Card from '../../components/Card';

import './style.scss';

interface IProps {
    className?: string;
    placeholder: string;
    placeholderActive?: string;
    placeholderInvalid?: string;
    maxFiles: number;
    onFilesChange?: Function;
    accept?: { [key: string]: string[] };
    files?: any[];
}

interface IPropsFile {
    file: any;
    onRemove?: Function;
}

const DropAreaFile = (props: IPropsFile) => {
    const { file, onRemove } = props;
    const { t } = useTranslation('core');
    return (
        <Card level={1} className="drop-area__file">
            <span className="drop-area__file-name">{file.path}</span>
            <SecondaryButton label={t('components.dropArea.remove')} onClick={onRemove.bind(this, file)} />
        </Card>
    );
};

const DropArea = (props: IProps) => {
    let { className, placeholder, placeholderActive, placeholderInvalid, maxFiles, onFilesChange, accept } = props;

    const [files, setFiles] = useState(props.files || []);

    // update state if props change
    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    useEffect(() => {
        // prevent infinite loop
        files != props.files && onFilesChange && onFilesChange(files);
    }, [files]);

    const onDrop = (acceptedFiles) => {
        setFiles((files) => files.concat(...acceptedFiles));
    };
    const onRemoveFile = (file) => {
        setFiles(files.filter((f) => f != file));
    };

    const multiple = maxFiles !== 1;
    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        /* maxFiles, */ multiple,
        accept,
    });

    let currentPlaceholder = placeholder;
    if (isDragReject && placeholderInvalid) currentPlaceholder = placeholderInvalid;
    else if (isDragActive && placeholderActive) currentPlaceholder = placeholderActive;

    const uploadClassNames = classnames(
        'drop-area__upload',
        isDragActive && !isDragReject ? 'drop-area__upload--active' : ''
    );

    return (
        <div className={classnames('drop-area', className)}>
            {!files.length || multiple ? (
                <div className={uploadClassNames} {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span className="drop-area__placeholder">{currentPlaceholder}</span>
                </div>
            ) : (
                ''
            )}

            {files.length ? (
                <div className="drop-area__files">
                    {files.map((file, i) => (
                        <DropAreaFile key={i} file={file} onRemove={onRemoveFile} />
                    ))}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

DropArea.defaultProps = {
    maxFiles: 0,
    accept: {
        'application/pdf': ['.pdf'],
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/tiff': ['.tiff', '.tif'],
        'application/vnd.ms-excel': ['.xls', '.csv'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'text/csv': ['.csv'],
        'message/rfc822': ['.eml', '.msg'],
        'text/html': ['.html', '.htm'],
        'text/xml': ['.x83'],
    },
};

export default DropArea;
