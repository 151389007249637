import { gql } from '@apollo/client';

export const BASE_FIELD = `
    bbox
    pageIndex
    predictionConfidence
    confidenceExplanation {
        translationKey
        explanationDetails
    }
    validationChecks {
        name
        result
        targetValue
    }
`;

export const BASE_DOCUMENT = `
    clientPartitionId
    clientPartitionFieldName
    validationChecks {
            name
            result
            targetValue
            reference
            explanationDetails
        }
`;

export const PRIMITIVE_FIELD = `
    ${BASE_FIELD}
    value
`;

export const SELECT_FIELD_FRAGMENT = gql`
    fragment FullSelectField on SelectField {
        ${PRIMITIVE_FIELD}
        choices
        options {
            value
            label {
                locale
                string
            }
            description {
                locale
                string
            }
        }
    }
`;

export const ACQUIRE_LOCK = gql`
    mutation ($groupName: String!, $identifier: UUID!, $userId: String!) {
        acquireLock(groupName: $groupName, identifier: $identifier, userId: $userId) {
            isLocked
            lockedAt
            lockedBy {
                id
                firstName
                lastName
                email
            }
            success
        }
    }
`;

export const RELEASE_LOCK = gql`
    mutation ($groupName: String!, $identifier: UUID!, $userId: String!) {
        releaseLock(groupName: $groupName, identifier: $identifier, userId: $userId) {
            success
        }
    }
`;
