import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';

import Page from '../../../core/components/Page';
import MenuItem from '../../../core/components/MenuItem';
import PaginatedList from '../../../core/containers/PaginatedList';
import ListItem from '../../../core/components/ListItem';
import UserListItem from '../../components/UserListItem';
import Loader from '../../../core/components/Loader';
import { SecondaryButton } from '../../../core/components/Button';
import { canManageUsers } from '../../utils';
import { MenuDivider } from '../../../core/components/Menu';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import ListControl, { PAGE_PARAM, useListControl } from '../../../core/containers/ListControl';
import { url } from '../../../core/utils/link';
import DropDown, { DropDownMenu, DropDownToggle } from '../../../core/containers/DropDown';

import { DELETE_USER, GET_ROLES, GET_TEAMS, GET_USERS, RESET_PASSWORD } from '../../queries';
import { ADD_PATH, EDIT_PATH } from '../../constants';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const ITEMS_PER_PAGE = 10;

const Overview = (props) => {
    const { user: viewer } = props;
    const { t } = useTranslation('users');
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [activePage, setActivePage] = useState(parseInt(searchParams.get(PAGE_PARAM)) || 0);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const prevSearchParams = searchParams.toString();

        if (activePage) {
            searchParams.set(PAGE_PARAM, activePage.toString());
        } else {
            searchParams.delete(PAGE_PARAM);
        }

        if (prevSearchParams != searchParams.toString()) {
            navigate({ pathname: location.pathname, search: '?' + searchParams.toString() });
        }
    }, [activePage]);

    const [roleFilter, setRoleFilter] = useListControl('role', '');
    const [teamFilter, setTeamFilter] = useListControl('team', '');
    const [sort, setSort] = useListControl('sort', 'lastName-asc');

    let [sortField, sortOrder] = sort.split('-');

    const { data, error, loading, refetch } = useQuery(GET_USERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            from: activePage * ITEMS_PER_PAGE,
            size: ITEMS_PER_PAGE,
            groupId: roleFilter || undefined,
            teamId: teamFilter || undefined,
            sortField,
            sortOrder,
        },
    });

    const {
        data: rolesData,
        error: rolesError,
        loading: rolesLoading,
    } = useQuery(GET_ROLES, {
        notifyOnNetworkStatusChange: true,
    });
    const {
        data: teamsData,
        error: teamsError,
        loading: teamsLoading,
    } = useQuery(GET_TEAMS, {
        notifyOnNetworkStatusChange: true,
    });

    const [selectedDeleteUser, setSelectedDeleteUser] = useState(undefined);
    const [selectedResetUser, setSelectedResetUser] = useState(undefined);

    const [deleteUser, { loading: deleteUserLoading, error: deleteUserError }] = useMutation(DELETE_USER);
    const [resetPassword, { loading: resetPasswordLoading, error: resetPasswordError }] = useMutation(RESET_PASSWORD);

    const users = data?.users?.users || [];
    const total = data?.users?.totalCount || 0;

    const handleDeleteUser = (user) => {
        return deleteUser({
            variables: { id: user.id },
        })
            .then((mutationResult: any) => {
                // reload user list after deleting one
                setSelectedDeleteUser(undefined);
                refetch();
            })
            .catch((error) => {
                setSelectedDeleteUser(undefined);
                console.error(error);
            });
    };

    const handleResetPassword = (user) => {
        return resetPassword({
            variables: { email: user.email },
        })
            .then((mutationResult: any) => {
                setSelectedResetUser(undefined);
            })
            .catch((error) => {
                setSelectedResetUser(undefined);
                console.error(error);
            });
    };

    return (
        <Layout>
            <Page className="users page--slim">
                <div className="users__header">
                    <div className="users__title">{t('overview.title')}</div>
                    {canManageUsers(viewer) && (
                        <SecondaryButton label={t('overview.inviteCoworker')} linkTo={url(ADD_PATH)} />
                    )}
                </div>

                {error && (
                    <Alert severity="error">
                        <AlertTitle>{error?.message}</AlertTitle>
                    </Alert>
                )}

                {deleteUserError && (
                    <Alert severity="error">
                        <AlertTitle>{deleteUserError?.message}</AlertTitle>
                    </Alert>
                )}

                {resetPasswordError && (
                    <Alert severity="error">
                        <AlertTitle>{resetPasswordError?.message}</AlertTitle>
                    </Alert>
                )}

                <div className="users__controls list-controls">
                    <ListControl value={roleFilter} onChange={setRoleFilter}>
                        <MenuItem value="" label={t('overview.controls.role.all')} />
                        {((!rolesLoading && rolesData?.groups) || []).map((role) => (
                            <MenuItem key={role.id} value={role.id} label={role.name} />
                        ))}
                    </ListControl>
                    <ListControl value={teamFilter} onChange={setTeamFilter}>
                        <MenuItem value="" label={t('overview.controls.team.all')} />
                        {((!teamsLoading && teamsData?.teams) || []).map((team) => (
                            <MenuItem key={team.id} value={team.id} label={team.name} />
                        ))}
                    </ListControl>

                    <ListControl className="list-control--sort" value={sort} onChange={setSort}>
                        <MenuItem value="firstName-asc" label={t('overview.controls.sort.firstNameAsc')} />
                        <MenuItem value="firstName-desc" label={t('overview.controls.sort.firstNameDesc')} />
                        <MenuItem value="lastName-asc" label={t('overview.controls.sort.lastNameAsc')} />
                        <MenuItem value="lastName-desc" label={t('overview.controls.sort.lastNameDesc')} />
                    </ListControl>
                </div>

                <div className="users__list">
                    {loading ? (
                        <Loader />
                    ) : (
                        <PaginatedList
                            itemsPerPage={ITEMS_PER_PAGE}
                            numItems={total}
                            onPageChange={setActivePage}
                            activePage={activePage}
                        >
                            {(users || []).map((user) => {
                                const menu = canManageUsers(viewer) && (
                                    <DropDown>
                                        <DropDownToggle>
                                            <SecondaryButton className="user-list-item__menu-button" label="" />
                                        </DropDownToggle>
                                        <DropDownMenu>
                                            <MenuItem
                                                key="edit"
                                                label={t('overview.menu.edit')}
                                                linkTo={url(EDIT_PATH, { userId: user.id })}
                                            />
                                            <MenuItem
                                                key="reset"
                                                label={t('overview.menu.resetPassword')}
                                                onClick={() => setSelectedResetUser(user)}
                                            />
                                            {viewer.id != user.id && <MenuDivider key="divider" />}
                                            {viewer.id != user.id && (
                                                <MenuItem
                                                    key="delete"
                                                    label={t('overview.menu.delete')}
                                                    className="menu-item--danger"
                                                    onClick={() => setSelectedDeleteUser(user)}
                                                />
                                            )}
                                        </DropDownMenu>
                                    </DropDown>
                                );

                                return (
                                    <ListItem key={user.id} attributes={{ roles: user.groups }}>
                                        <UserListItem
                                            id={user.id}
                                            viewerId={viewer?.id}
                                            firstName={user.firstName}
                                            lastName={user.lastName}
                                            email={user.email}
                                            roles={user.groups.map((g) => g.name)}
                                            teams={user.teams?.map((t) => t.name)}
                                            buttons={menu}
                                        />
                                    </ListItem>
                                );
                            })}
                        </PaginatedList>
                    )}
                </div>
            </Page>
            {selectedDeleteUser && (
                <ConfirmModal
                    onConfirm={() => handleDeleteUser(selectedDeleteUser)}
                    onCancel={() => setSelectedDeleteUser(undefined)}
                >
                    {t('overview.confirmDelete', { user: selectedDeleteUser })}
                </ConfirmModal>
            )}
            {selectedResetUser && (
                <ConfirmModal
                    onConfirm={() => handleResetPassword(selectedResetUser)}
                    onCancel={() => setSelectedResetUser(undefined)}
                >
                    {t('overview.confirmReset', { user: selectedResetUser })}
                </ConfirmModal>
            )}
        </Layout>
    );
};

export default Overview;
