import * as React from 'react';
import { useEffect, useState } from 'react';
import Page from '../../core_updated/components/Page';
import ChartCard from '../components/ChartCard';
import TextChart from '../components/TextChart';
import axios from 'axios';
import {
    Bar,
    BarChart,
    CartesianGrid,
    LabelList,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import ChartTooltip from '../components/ChartTooltip';
import FilterDropdown from '../components/Filter';
import { useExtractionRatioQuery, useFilterOptions } from '../utils/dataByDocumentType';
import { useTranslation } from 'react-i18next';
import Layout from '../../core_updated/components/Layout';
import { SupportBarButton } from '../../support/containers/SupportBar';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPANY_DETAILS, UPDATE_CUSTOMER } from '../../customer/queries';
import Alert, { AlertTitle } from '../../core/components/Alert';
import { withIcon } from '../../core_updated/components/Icon';
import { faArrowDownToLine, faEye, faEyeSlash, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import DateRangeFilterDropdown from '../components/DateRangeFilter';
import moment from 'moment';
import classnames from '../../core_updated/utils/classnames';
import { useApplicationContext } from '../../core_updated/contexts/ApplicationContext';
import { canDownloadAnalytics } from '../../users/utils';
import { DOWNLOAD_KPI_DATA_URL } from '../constants';
import { isoDateFormat } from '../../utils/dates';
import Button from '../../core_updated/components/Button';

interface ChartProps {
    data: any[];
    [key: string]: any;
}

const secondsToMinutes = (seconds: number) => seconds / 60;
const secondsToHours = (seconds: number) => seconds / 60 / 60;

const getEqualIntervalNumberTicks = (values: number[], maxSteps = 4) => {
    if (values.length === 0) return [];

    const maxVal = Math.ceil(Math.max(...values));
    const minVal = Math.floor(Math.min(...values));

    const range = maxVal - minVal;
    const step = Math.ceil(range / (maxSteps - 1));

    // if we would calculate ticks from the top by subtracting step from maxVal
    // and the last tick would be less than 0 we should start from the bottom
    const shouldStartFromBottom = maxVal - (maxSteps - 1) * step < 0;

    let ticks = [];
    for (let i = 0; i < maxSteps; i++) {
        ticks.push(shouldStartFromBottom ? minVal + i * step : maxVal - i * step);
    }

    // ensure no duplicates
    ticks = Array.from(new Set(ticks));

    // sort in ascending order
    ticks.sort((a, b) => a - b);

    return ticks;
};

const getEqualIntervalCategoryTicks = (values: string[], maxSteps = 4) => {
    if (values.length === 0) return [];

    const ticks = [];
    const step = Math.floor(values.length / (maxSteps - 1));

    for (let i = 0; i < maxSteps; i++) {
        ticks.push(values[i * step]);
    }

    return ticks;
};

const FieldExtractionRateChart = ({ data, dateRange }: ChartProps) => {
    const { t, i18n } = useTranslation('analytics');

    const formatPercentage = (value: number) => `${Math.round(value * 100)}%`;
    const formatDate = (date: string) => {
        // format to locale like Jan 6
        const dateObj = new Date(date);
        return dateObj.toLocaleString(i18n.language, { month: '2-digit', day: '2-digit' });
    };
    const formatDateFull = (date: string) => {
        // format to locale like Jan 6
        const dateObj = new Date(date);
        return dateObj.toLocaleString(i18n.language, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            weekday: 'short',
        });
    };

    const yTicks = getEqualIntervalNumberTicks(
        data.map((item) => item.value * 100),
        5
    ).map((tick) => tick / 100);

    const rangeDays = dateRange && dateRange.length === 2 ? moment(dateRange[1]).diff(moment(dateRange[0]), 'days') : 0;
    const xTicks = getEqualIntervalCategoryTicks(
        data.map((item) => item.date),
        // one per week, one per month or one per day (+ 1 for having a tick at the end of scale)
        rangeDays === 30 ? 5 : rangeDays === 90 ? 4 : rangeDays === 7 ? 7 : null
    );

    return (
        <ResponsiveContainer width="100%" height={400} className="overflow-visible">
            <LineChart data={data} className="overflow-visible">
                <CartesianGrid vertical={false} stroke="inherit" className="stroke-primary" />
                <Tooltip
                    content={({ active, payload }) => {
                        const date = payload?.[0]?.payload.date;
                        const formattedDate = date && formatDateFull(date);

                        const value = payload?.[0]?.payload.value;
                        const formattedValue = value && formatPercentage(value);

                        return (
                            <ChartTooltip
                                content={
                                    <span className="flex flex-col">
                                        <span className="opacity-80 text-sm">{formattedDate}</span>
                                        <span className="text-sm">
                                            <strong className="font-medium">{formattedValue}</strong>{' '}
                                            {t('dashboard.charts.fieldExtractionRate.tooltip')}
                                        </span>
                                    </span>
                                }
                            />
                        );
                    }}
                />

                <XAxis
                    type="category"
                    ticks={xTicks}
                    domain={[xTicks[0], xTicks[xTicks.length - 1]]}
                    dataKey="date"
                    tick={{ fill: 'inherit' }}
                    className="text-xs fill-text-secondary"
                    tickFormatter={formatDate}
                    tickMargin={20}
                    minTickGap={20}
                    tickLine={false}
                    axisLine={false}
                    padding={{ left: 20, right: 20 }}
                    dy={-5}
                />
                <YAxis
                    type="number"
                    ticks={yTicks}
                    domain={[yTicks[0], yTicks[yTicks.length - 1]]}
                    axisLine={false}
                    tickLine={false}
                    tickMargin={10}
                    width={60}
                    tick={{ fill: 'inherit' }}
                    className="text-xs fill-text-secondary"
                    tickFormatter={formatPercentage}
                />
                <Line dataKey="value" className="stroke-data-1" strokeWidth={3} dot={false} stroke="inherit" />
            </LineChart>
        </ResponsiveContainer>
    );
};

const DocumentAssistanceTimeChart = ({ data, dateRange }: ChartProps) => {
    const { t, i18n } = useTranslation('analytics');

    const formatTime = (value: number) => `${Math.round(value)}s`;
    const formatDate = (date: string) => {
        // format to locale like Jan 6
        const dateObj = new Date(date);
        return dateObj.toLocaleString(i18n.language, { month: '2-digit', day: '2-digit' });
    };
    const formatDateFull = (date: string) => {
        // format to locale like Jan 6
        const dateObj = new Date(date);
        return dateObj.toLocaleString(i18n.language, {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            weekday: 'short',
        });
    };

    const ticks = getEqualIntervalNumberTicks(
        data.map((item) => item.value),
        5
    );

    const rangeDays = dateRange && dateRange.length === 2 ? moment(dateRange[1]).diff(moment(dateRange[0]), 'days') : 0;
    const xTicks = getEqualIntervalCategoryTicks(
        data.map((item) => item.date),
        // one per week, one per month or one per day (+ 1 for having a tick at the end of scale)
        rangeDays === 30 ? 5 : rangeDays === 90 ? 4 : rangeDays === 7 ? 7 : null
    );

    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data?.map((item) => ({ ...item, value: Math.round(item.value) }))}>
                <CartesianGrid vertical={false} stroke="inherit" className="stroke-primary" />
                <Tooltip
                    content={({ active, payload }) => {
                        const date = payload?.[0]?.payload.date;
                        const formattedDate = date && formatDateFull(date);

                        const value = payload?.[0]?.payload.value;
                        const formattedValue = value && formatTime(value);

                        const docsCount = payload?.[0]?.payload.docsCount;

                        return (
                            <ChartTooltip
                                content={
                                    <span className="flex flex-col">
                                        <span className="opacity-80 text-sm">{formattedDate}</span>
                                        <span className="text-sm">
                                            <strong className="font-medium">{formattedValue}</strong>{' '}
                                            {t('dashboard.charts.documentAssistanceTime.tooltip')}
                                        </span>
                                        <span className="text-sm">
                                            <strong className="font-medium">{docsCount}</strong>{' '}
                                            {t('dashboard.charts.documentAssistanceTime.tooltipDocsCount')}
                                        </span>
                                    </span>
                                }
                            />
                        );
                    }}
                />

                <XAxis
                    type="category"
                    ticks={xTicks}
                    domain={[xTicks[0], xTicks[xTicks.length - 1]]}
                    dataKey="date"
                    className="text-xs fill-text-secondary"
                    tickFormatter={formatDate}
                    tickMargin={20}
                    minTickGap={20}
                    tickLine={false}
                    axisLine={false}
                    padding={{ left: 20, right: 20 }}
                    dy={-5}
                />
                <YAxis
                    type="number"
                    ticks={ticks}
                    domain={[ticks[0], ticks[ticks.length - 1]]}
                    axisLine={false}
                    tickLine={false}
                    tickMargin={10}
                    width={60}
                    tick={{ fill: 'inherit' }}
                    className="text-xs fill-text-secondary"
                    tickFormatter={formatTime}
                />
                <Line dataKey="value" className="stroke-data-1" strokeWidth={3} dot={false} stroke="inherit" />
            </LineChart>
        </ResponsiveContainer>
    );
};

const AssistanceReasonsChart = ({ data }: ChartProps) => {
    const gap = 10;
    const barSize = 40;
    const height = data.length * (barSize + gap) + gap;

    const maxLabelLength = data.reduce((acc, item) => {
        return Math.max(acc, item.label.length);
    }, 0);
    const yAxisWidth = maxLabelLength * 7 + 20;

    const unit = '%';
    const labelFormatter = (value: number) => `${value}${unit}`;

    return (
        <ResponsiveContainer width="100%" height={height}>
            <BarChart data={data} layout="vertical" margin={{ right: 30 }}>
                <Tooltip
                    cursor={false}
                    content={({ active, payload }) => {
                        return <ChartTooltip content={labelFormatter(payload?.[0]?.payload?.value)} />;
                    }}
                />
                <XAxis type="number" hide width={100} />
                <YAxis
                    dataKey="label"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    tickMargin={10}
                    width={yAxisWidth}
                    tick={{ fill: 'inherit' }}
                    className="text-xs fill-text-primary"
                />
                <Bar dataKey="value" className="fill-data-1" layout="vertical" barSize={barSize}>
                    <LabelList dataKey="value" position="right" formatter={labelFormatter} className="text-xs" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

const Dashboard = () => {
    const { t } = useTranslation('analytics');

    const [documentType, setDocumentType] = useState<string[]>([]);
    const [channels, setChannels] = useState<string[]>([]);

    // use one month by default
    const [dateRange, setDateRange] = useState<string[]>([
        moment().subtract(1, 'month').format(isoDateFormat),
        moment().format(isoDateFormat),
    ]);
    const [users, setUsers] = useState<string[]>([]);

    const filterOptions = useFilterOptions();
    const channelOptions = filterOptions?.channelsByDocumentType?.[documentType?.[0]];

    const handleDocumentTypeChange = (value: string[]) => {
        setDocumentType(value);
        // select all by default
        const channelOptions = filterOptions?.channelsByDocumentType?.[value[0]];
        if (channelOptions) setChannels(channelOptions?.map((option) => option.value));
        if (filterOptions?.user) setUsers(filterOptions?.user.map((option) => option.value));
    };

    useEffect(() => {
        if (filterOptions?.documentType?.length > 0) handleDocumentTypeChange([filterOptions?.documentType[0]?.value]);
    }, [filterOptions.loading]);

    const { loading, error, ...data } = useExtractionRatioQuery({
        documentType: documentType?.[0],
        dateRange,
        channels,
        users,
    });

    const ArrowDownToLineIcon = withIcon(faArrowDownToLine);
    const LoadingIcon = withIcon(faSpinnerThird);

    const { user } = useApplicationContext();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const handleDownloadClick = () => {
        setIsLoading(true);
        const [startDate, endDate] = dateRange;
        const chosenChannelNames =
            channels.length == channelOptions?.length
                ? ['all-channels']
                : channels.map((channelValue) => {
                      const channel = channelOptions?.find((channel) => channel.value === channelValue);
                      return channel ? channel.label : '';
                  });
        let formattedDateRange: string = dateRange
            .map((date) => {
                const [year, month, day] = date.split('-');
                return day + month + year;
            })
            .join('-');
        const concatenatedNames = chosenChannelNames.join('_').replace(' ', '-');
        const fileName = `${documentType}s_${concatenatedNames}_${formattedDateRange}.xlsx`;

        axios
            .get(DOWNLOAD_KPI_DATA_URL, {
                responseType: 'blob',
                withCredentials: true,
                params: {
                    channel_ids: channels,
                    start_date: startDate,
                    end_date: endDate,
                    document_type: documentType?.[0],
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // Set the filename for the downloaded file
                document.body.appendChild(link);

                // Trigger the click event on the link to start the download
                link.click();

                // Clean up
                setIsLoading(false);
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error(error.message);
                setIsLoading(false);
                setIsError(true);
                setTimeout(() => {
                    setTimeout(() => {
                        setIsError(false);
                    }, 2000);
                });
            });
    };

    return (
        <Layout>
            <Page>
                <Page.Header>
                    <Page.HeaderTitle>{t('dashboard.title')}</Page.HeaderTitle>
                    {canDownloadAnalytics(user) && (
                        <Button onClick={handleDownloadClick} disabled={isLoading || isError} className="ml-auto">
                            {isLoading ? (
                                <LoadingIcon className="text-brand text-sm animate-spin" />
                            ) : isError ? (
                                t('dashboard.error')
                            ) : (
                                <>
                                    {<ArrowDownToLineIcon className="text-primary text-sm mr-2" />}
                                    {t('dashboard.export')}
                                </>
                            )}
                        </Button>
                    )}
                </Page.Header>
                <Page.Content lowered>
                    <div className="flex flex-col gap-4 justify-start overflow-x-hidden">
                        <div className="flex gap-2 justify-start">
                            <FilterDropdown
                                allLabel={t('dashboard.filters.documentType.all')}
                                label={t('dashboard.filters.documentType.some')}
                                options={filterOptions.documentType}
                                selected={documentType}
                                onSelectedChange={handleDocumentTypeChange}
                                required
                            />

                            <FilterDropdown
                                allLabel={t('dashboard.filters.channel.all')}
                                label={t('dashboard.filters.channel.some')}
                                options={filterOptions.channelsByDocumentType[documentType?.[0]] || []}
                                selected={channels}
                                onSelectedChange={setChannels}
                                multiple
                            />

                            <DateRangeFilterDropdown
                                label={t('dashboard.filters.dateRange.some')}
                                options={filterOptions.dateRange}
                                range={{
                                    from: dateRange && dateRange[0] ? moment(dateRange[0]).toDate() : null,
                                    to: dateRange && dateRange[1] ? moment(dateRange[1]).toDate() : null,
                                }}
                                onRangeChange={(range) =>
                                    setDateRange([
                                        range.from ? moment(range.from).format('YYYY-MM-DD') : null,
                                        range.to ? moment(range.to).format('YYYY-MM-DD') : null,
                                    ])
                                }
                                required={true}
                            />
                        </div>

                        <div className="flex gap-4 w-full min-h-[12rem]">
                            <ChartCard
                                title={t('dashboard.charts.documentsReceived.title')}
                                helpText={t('dashboard.charts.documentsReceived.helpText')}
                                loading={loading}
                                error={error}
                            >
                                {data.documentsReceived != null && <TextChart value={data.documentsReceived} />}
                            </ChartCard>
                            <ChartCard
                                title={t('dashboard.charts.documentsAutomated.title')}
                                helpText={t('dashboard.charts.documentsAutomated.helpText')}
                                loading={loading}
                                error={error}
                            >
                                {data.documentsAutomated != null && <TextChart value={data.documentsAutomated} />}
                            </ChartCard>
                            <ChartCard
                                title={t('dashboard.charts.timeSavedPerDocument.title')}
                                helpText={t('dashboard.charts.timeSavedPerDocument.helpText')}
                                loading={loading}
                                error={error}
                            >
                                {data.timeSavedPerDocument != null && (
                                    <TextChart
                                        value={Math.round(secondsToMinutes(data.timeSavedPerDocument) * 100) / 100}
                                        unit="min"
                                    />
                                )}
                            </ChartCard>
                            <ChartCard
                                title={t('dashboard.charts.totalTimeSaved.title')}
                                helpText={t('dashboard.charts.totalTimeSaved.helpText')}
                                loading={loading}
                                error={error}
                            >
                                {data.timeSavedTotal != null && (
                                    <TextChart
                                        value={Math.round(secondsToHours(data.timeSavedTotal) * 100) / 100}
                                        unit="h"
                                    />
                                )}
                            </ChartCard>
                        </div>

                        <div className="flex gap-4 w-full min-h-[20rem]">
                            <ChartCard
                                title={t('dashboard.charts.fieldExtractionRate.title')}
                                helpText={t('dashboard.charts.fieldExtractionRate.helpText')}
                                loading={loading}
                                error={error}
                            >
                                {data.fieldExtractionRate != null && data.fieldExtractionRate.length > 0 && (
                                    <FieldExtractionRateChart data={data.fieldExtractionRate} dateRange={dateRange} />
                                )}
                            </ChartCard>

                            <ChartCard
                                title={t('dashboard.charts.documentAssistanceTime.title')}
                                helpText={t('dashboard.charts.documentAssistanceTime.helpText')}
                                loading={loading}
                                error={error}
                            >
                                {data.documentAssistanceTime != null && data.documentAssistanceTime.length > 0 && (
                                    <DocumentAssistanceTimeChart
                                        data={data.documentAssistanceTime}
                                        dateRange={dateRange}
                                    />
                                )}
                            </ChartCard>
                        </div>

                        <div className="flex gap-4 w-full min-h-[20rem]">
                            <ChartCard
                                title={t('dashboard.charts.assistanceReasons.title')}
                                helpText={t('dashboard.charts.assistanceReasons.helpText')}
                                loading={loading}
                                error={error}
                            >
                                {data.assistanceReasons != null && data.assistanceReasons.length > 0 && (
                                    <AssistanceReasonsChart data={data.assistanceReasons} />
                                )}
                            </ChartCard>
                        </div>
                    </div>
                </Page.Content>
            </Page>
        </Layout>
    );
};

export default Dashboard;
