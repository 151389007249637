import { gql } from '@apollo/client';

export const FULL_CHANNEL = gql`
    fragment FullChannel on RfqProcessingChannelType {
        id
        name
        team {
            id
            name
        }
        emailAddress
        timePerDocumentBeforeWorkist

        xmlSerializerConfig {
            enabled
            serializerModule
            headerFields
            lineItemFields
            exportExternalDocumentLink
            exportReceivedBy
            exportWorkistUrl
            useShortExternalFileName
        }

        emailConfig {
            enabled
            inboxName
            allowSentByMatchByName
            enableEmailOnlyDocumentProcessing
            enableEmailThreadTruncating
            keepNTopmostEmailThreads
        }

        masterDataConfig {
            authToken

            clientLookupDefinitionId
            contactLookupDefinitionId
            emailLookupDefinitionId

            articleLookupDefinitionId
            debitorArticleLookupDefinitionId
            conversionFactorLookupDefinitionId
            frameworkContractLookupDefinitionId

            invoiceAddressLookupDefinitionId
            deliveryAddressLookupDefinitionId
            clientMappingLookupDefinitionId

            articleMappingLookupDefinitionId
            vatIdCheckMappingLookupDefinitionId

            invoiceAddressMappingLookupDefinitionId
            deliveryAddressMappingLookupDefinitionId
        }

        sftpConfig {
            sftpHost
            sftpHostKey
            sftpPort
            sftpUser
            sftpPassword
        }

        as2Config {
            senderAs2Name
            receiverUrl
            receiverAs2Name
            receiverVerifyCert
            receiverEncryptCert
            verifyReceiverCertificates
        }

        uploadConfig {
            enabled
            targetPathFileExport
            targetPathOriginalDocument
            sendOriginalDocument
            sendOriginalEmail
            deliveryMethod
        }

        documentConfig {
            options {
                name
                value
                valueType
                defaultValue
            }

            fields {
                name
                valueType
                position
                enabled
                options {
                    name
                    value
                    valueType
                    defaultValue
                    configurable
                }
            }
        }

        discardConfig {
            forwardingAddresses
            sendBack
        }
    }
`;

export const GET_CHANNEL_CONFIG = gql`
    ${FULL_CHANNEL}
    query ($id: UUID!) {
        rfqProcessingChannel(id: $id) {
            ...FullChannel
        }
    }
`;

export const CREATE_CONFIG = gql`
    mutation ($channelId: UUID!, $configName: String!) {
        createRfqProcessingChannelConfig(channelId: $channelId, configName: $configName) {
            success
        }
    }
`;

export const DELETE_CONFIG = gql`
    mutation ($channelId: UUID!, $configName: String!) {
        CreateRfqProcessingChannelConfigMutation(channelId: $channelId, configName: $configName) {
            success
        }
    }
`;

export const UPDATE_CHANNEL = gql`
    mutation (
        $channelId: UUID!
        $name: String
        $teamId: UUID
        $emailConfig: UpdateEmailConfigInput
        $masterDataConfig: UpdateMasterDataConfigInput
        $xmlSerializerConfig: UpdateXMLSerializerConfigInput
        $sftpConfig: UpdateSFTPConfigInput
        $as2Config: UpdateAS2ConfigInput
        $uploadConfig: UpdateUploadConfigInput
        $discardConfig: UpdateDiscardConfigInput
        $timePerDocumentBeforeWorkist: Int
    ) {
        updateRfqProcessingChannel(
            channelId: $channelId
            name: $name
            teamId: $teamId
            emailConfig: $emailConfig
            masterDataConfig: $masterDataConfig
            xmlSerializerConfig: $xmlSerializerConfig
            sftpConfig: $sftpConfig
            as2Config: $as2Config
            uploadConfig: $uploadConfig
            discardConfig: $discardConfig
            timePerDocumentBeforeWorkist: $timePerDocumentBeforeWorkist
        ) {
            success
        }
    }
`;

export const DELETE_MAPPINGS = gql`
    mutation ($channelId: UUID!, $beforeDate: Date!, $mappingType: String!) {
        rfqChannelDeleteMappings(channelId: $channelId, beforeDate: $beforeDate, mappingType: $mappingType) {
            deletedCount
            success
        }
    }
`;
