import * as React from 'react';
import { useTranslation } from 'react-i18next';

import ProgressButton from '../../../core/containers/ProgressButton';
import DropDown, { DropDownMenu, DropDownToggle } from '../../../core/containers/DropDown';
import Button from '../../../core/components/Button';
import MenuItem from '../../../core/components/MenuItem';

import './style.scss';
import Tooltip from '../../../core/components/Tooltip';

const AssistanceFinishButton = (props) => {
    const { disabled, onFinishAndContinue, onFinish, onReset, onDiscard, extraItems = undefined } = props;

    const { t } = useTranslation('assistance');

    return (
        <>
            <div className="finish-button">
                <Tooltip content={t('finishButton.finishNote')} placement="top-end" long className="tooltip--flex">
                    {onFinishAndContinue ? (
                        <ProgressButton
                            className="finish-button__button"
                            label={t('finishButton.finishAndContinueButton')}
                            onClick={onFinishAndContinue}
                            disabled={disabled}
                        />
                    ) : (
                        <ProgressButton
                            className="finish-button__button"
                            label={t('finishButton.finishButton')}
                            onClick={onFinish}
                            disabled={disabled}
                        />
                    )}
                </Tooltip>

                <DropDown menuOrientation="top" className="finish-button__drop-down">
                    <DropDownToggle className="finish-button__drop-down-handler">
                        <Button className="finish-button__toggle" />
                    </DropDownToggle>

                    <DropDownMenu className="finish-button__drop-down-menu">
                        {onFinishAndContinue && (
                            <MenuItem label={t('finishButton.finishButton')} onClick={onFinish} disabled={disabled} />
                        )}
                        <MenuItem
                            label={t('finishButton.resetButton')}
                            onClick={onReset}
                            className="menu-item--danger"
                        />
                        <MenuItem
                            label={t('finishButton.discardButton')}
                            onClick={onDiscard}
                            className="menu-item--danger"
                        />
                        {extraItems}
                    </DropDownMenu>
                </DropDown>
            </div>
        </>
    );
};

export default AssistanceFinishButton;
