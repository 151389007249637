import React, { ReactNode } from 'react';
import classnames from '../../../utils/classnames';
import withLoader from '../../../utils/withLoader';
import { useNavigationContext } from './NavigationContext';
import NavigationItemBadge from './NavigationItemBadge';
import { useMatch, useNavigate } from 'react-router-dom';
import { url } from '../../../../core/utils/link';
import NavigationButton from './NavigationButton';

interface NavigationChannelItemProps {
    icon?: ReactNode;
    label: string;
    disabled?: boolean;
    badgeNumber?: number;
    className?: string;
    children?: ReactNode;
    channelId: string;
    path?: string;
}

export const NavigationChannelItem = ({
    channelId,
    label,
    badgeNumber = undefined,
    className,
    path,
}: NavigationChannelItemProps) => {
    const navigate = useNavigate();
    const active = !!useMatch(channelId ? url(path, { channelId }) + '*' : '');

    const handleClick = () => {
        navigate(url(path, { channelId }));
    };

    return (
        <li className={className}>
            <NavigationButton active={active} onClick={handleClick} label={label} badgeNumber={badgeNumber} />
        </li>
    );
};

const randomInt = (max: number, min: number) => Math.round(Math.random() * (max - min)) + min;

export const NavigationChannelItemLoader = () => {
    const width = `${randomInt(8, 16)}ch`;

    return (
        <span
            className="my-1 h-4 flex-initial animate-pulse items-center gap-2 rounded-md bg-tertiary"
            style={{ width }}
        />
    );
};

export const NavigationChannelItemWithLoader = withLoader(NavigationChannelItem, NavigationChannelItemLoader);

export default NavigationChannelItem;
