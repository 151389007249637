import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

export interface IProps {
    id?: string;
    type?: string;
    name?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    onChange?: any;
    onClick?: any;
    onKeyDown?: any;
    value?: string;
    required?: boolean;
    readOnly?: boolean;

    // TODO: maybe inherit from HTMLInputProps
    step?: string;
    min?: number;
    max?: number;
    inputMode?: any;
    onBlur?: any;
    onFocus?: any;
}

const Input = React.forwardRef((props: any, ref: any) => {
    const {
        id,
        type,
        name,
        placeholder,
        className,
        disabled,
        onChange,
        onClick,
        value,
        required,
        readOnly,
        ...inputProps
    } = props;

    return (
        <input
            id={id}
            ref={ref}
            className={classnames('input', className)}
            name={name}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            disabled={disabled}
            value={value || ''}
            required={required}
            readOnly={readOnly}
            {...inputProps}
        />
    );
});

Input.defaultProps = {
    disabled: false,
    type: 'text',
    placeholder: '',
    value: '',
    required: false,
    readOnly: false,
};

export default Input;
