import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanField, useForm } from '../Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import ConfigurationCard from '../ConfigurationCard';
import ListField from '../ListField';

const DiscardConfiguration = ({ config, onActivate, onDeactivate = undefined, onSubmit }) => {
    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`discardConfig.fieldNames.${fieldName}`),
    });

    useEffect(() => {
        setFormData({
            forwardingAddresses: config?.forwardingAddresses || [],
            sendBack: config?.sendBack,
        });
    }, [config]);

    const handleSubmit = () => onSubmit(formData);
    const { t } = useTranslation('config');
    return (
        <ConfigurationCard
            title={t('discardConfig.title')}
            description={t('discardConfig.description')}
            active={!!config}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
        >
            <div className="form">
                <ListField {...getFieldProps('forwardingAddresses')} className="field--vertical" />
                <BooleanField {...getFieldProps('sendBack')} className="field--vertical" />

                <div>
                    <ProgressButton label={t('card.save')} onClick={handleSubmit} />
                </div>
            </div>
        </ConfigurationCard>
    );
};

export default DiscardConfiguration;
