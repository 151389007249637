import * as React from 'react';
import classnames from 'classnames/dedupe';

import './style.scss';

export interface IProps {
    children?: any;
    className?: string;
}

const Page = (props: IProps) => {
    const { children, className } = props;

    // TODO: remove class when global dark mode support
    return (
        <main className={classnames('page', 'dark-mode-ready', className)}>
            <div className="page__inner">{children}</div>
        </main>
    );
};

export default Page;
