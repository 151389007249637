import { useApplicationContext } from '../../../../core_updated/contexts/ApplicationContext';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { canSeeTestingDocuments } from '../../../../users/utils';
import ConfirmModal from '../../../../core/containers/ConfirmModal';
import classnames from '../../../../core_updated/utils/classnames';
import DropArea from '../../../../core/containers/DropArea';
import { SelectField } from '../../../../assistance/containers/Fields';
import Checkbox from '../../../../core/components/Checkbox';
import * as React from 'react';

const UploadFileModal = (props) => {
    const { activeChannel, channels, uploadFile, onUploadDone, onConfirm, onCancel, className, ...modalProps } = props;

    const { user } = useApplicationContext();

    const { t } = useTranslation('assistance');

    const channelChoices = channels?.map((channel) => [channel.id, channel.name]) || [];

    const [files, setFiles] = useState([]);
    const [channel, setChannel] = useState(
        activeChannel?.id || (channelChoices.length === 1 ? channelChoices[0][0] : '')
    );

    useEffect(() => {
        if (activeChannel) {
            setChannel(activeChannel.id);
        }
    }, [activeChannel?.id]);

    // For safety reasons, when support users upload a document on production it is marked as a
    // testing document per default.
    const [isTestDocument, setIsTestDocument] = useState(
        process.env.MARK_UPLOAD_DOCUMENT_AS_TEST == 'True' && canSeeTestingDocuments(user) ? true : false
    );

    const handleSubmitFiles = () => {
        return uploadFile({
            variables: {
                files: files,
                channelId: channel,
                isTestingDocument: isTestDocument,
            },
        }).then((res) => {
            setFiles([]);
            if (onUploadDone) return onUploadDone(isTestDocument);
        });
    };

    return (
        <ConfirmModal
            className={classnames('add-channel-modal', className)}
            title={t('overview.uploadFileModal.title')}
            onConfirm={handleSubmitFiles}
            onCancel={onCancel}
            labelConfirm={t('overview.uploadFileModal.confirm')}
            labelCancel={t('overview.uploadFileModal.cancel')}
            noHeader={false}
            confirmDisabled={!(files.length > 0 && channel)}
            {...modalProps}
        >
            <div className="form">
                <div className="overview-form">
                    <DropArea
                        placeholder={t('overview.uploadFileModal.dropPlaceholder')}
                        onFilesChange={setFiles}
                        className="overview-form__drop-area"
                        files={files}
                    />

                    <SelectField
                        label={t('overview.uploadFileModal.channelLabel')}
                        value={channel}
                        choices={channelChoices}
                        inputProps={{
                            placeholder: t('overview.uploadFileModal.channelPlaceholder'),
                            disabled: activeChannel,
                        }}
                        onUpdate={setChannel}
                        className="field--vertical"
                    />
                    {
                        // Only support users are allowed to upload documents for testing
                        canSeeTestingDocuments(user) && (
                            <Checkbox
                                label={t('overview.uploadFileModal.markAsTestButton')}
                                className="form__input"
                                checked={isTestDocument}
                                onChange={(e) => setIsTestDocument(e.target.checked)}
                            />
                        )
                    }
                </div>
            </div>
        </ConfirmModal>
    );
};

export default UploadFileModal;
