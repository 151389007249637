import * as React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { createContext, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { IconButton } from '../IconButton';
import { TOAST_DURATION } from '../../../../constants';
import classNames from 'classnames';

import './style.scss';

export interface ToastMessage {
    id?: number; // assigned automatically
    title?: string | React.ReactNode;
    description: string | React.ReactNode;
}

interface IToasterStore {
    toastMessages: Array<ToastMessage>;
    publishToast: (message: ToastMessage) => void;
}

const ToasterContext = createContext<IToasterStore>(null);

// Hook-based imperative API for publishing toasts
export const useToaster = () => useContext(ToasterContext);

export const Toast = (props) => {
    const { title, description, ...toastProps } = props;
    const [open, setOpen] = useState(true);

    return (
        <ToastPrimitive.Root className="toast" open={open} onOpenChange={setOpen} {...toastProps}>
            <div className="toast__text">
                {title && <ToastPrimitive.Title className="toast__text__title">{title}</ToastPrimitive.Title>}
                {description && (
                    <ToastPrimitive.Description className="toast__text__description">
                        {description}
                    </ToastPrimitive.Description>
                )}
            </div>
            <ToastPrimitive.Close className="toast__action" aria-label="Close">
                <IconButton icon="close" onClick={() => setOpen(false)} />
            </ToastPrimitive.Close>
        </ToastPrimitive.Root>
    );
};

/**
 * Provides:
 * 1. The Radix primitive provider which injects shared configuration into all toast components
 * 2. A custom context (ToasterContext) that holds the state & API for the imperative useToaster hook
 */
export const Provider = React.forwardRef((props: any, ref: any) => {
    const [toastMessages, setToastMessages] = useState<Array<ToastMessage>>([]);
    const [counter, setCounter] = useState(0);
    const maxBufferSize = 5;

    const publishToast = (toastMessage: ToastMessage) => {
        // Update the toast message buffer while respecting the max buffer size
        const keepToastMessages =
            toastMessages.length > maxBufferSize ? toastMessages.slice(1, toastMessages.length) : toastMessages;
        // Assign an incrementing ID used as the Radix component key
        setToastMessages([...keepToastMessages, { ...toastMessage, id: counter }]);
        setCounter(counter + 1);
    };

    return (
        <ToasterContext.Provider value={{ toastMessages, publishToast }}>
            <ToastPrimitive.Provider duration={TOAST_DURATION} swipeDirection={null} {...props} ref={ref} />
        </ToasterContext.Provider>
    );
});

/**
 * Renderer for all toasts (declarative and imperative ones). This does two things:
 * 1. It mounts the Radix-managed viewport into the modal root so all toasts are rendered there
 * 2. It renders the toasts published imperatively via the useToaster hook as components (which will be picked up by
 *    Radix & portaled into the Radix Viewport via 1. (Note: since visibility of toasts is managed by Radix, we
 *    always render a buffer of the latest published toasts here with a unique key per toast message)
 *
 * See https://www.radix-ui.com/primitives/docs/components/toast
 */
export const Viewport = React.forwardRef((props: any, ref: any) => {
    const { className, ...passThroughProps } = props;
    const { toastMessages } = useToaster();

    const modalRoot = document.getElementById('modal-root');
    return (
        <>
            {toastMessages.map((message: ToastMessage) => (
                <Toast key={message.id} {...message} />
            ))}
            {createPortal(
                <ToastPrimitive.Viewport
                    className={classNames('toaster__viewport', className)}
                    {...passThroughProps}
                    ref={ref}
                />,
                modalRoot
            )}
        </>
    );
});

export default {
    Toast,
    Provider,
    Viewport,
};
