import * as React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import MenuItem from '../../components/MenuItem';
import Select, { IProps } from '../Select';

import './style.scss';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const PAGE_PARAM = 'page';

export const useListControl = (key, fallbackValue = undefined) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [state, setState] = useState(searchParams.get(key) || fallbackValue);

    const onChange = (value) => {
        setState(value);

        // in case there is a page set we just remove it
        // TODO: not sure if best way but it's okay I think
        searchParams.delete(PAGE_PARAM);

        if (value) {
            searchParams.set(key, value.toString());
        } else {
            searchParams.delete(key);
        }

        navigate({
            pathname: location.pathname,
            search: '?' + searchParams.toString(),
        });
    };

    return [state, onChange];
};

export const ListControl = (props: IProps) => {
    const { className } = props;
    return <Select {...props} className={classnames('list-control', className)} />;
};

ListControl.displayName = 'ListControl';

const DATE_ITEMS = {
    today: 'core:components.dateListControl.today',
    week: 'core:components.dateListControl.week',
    month: 'core:components.dateListControl.month',
    year: 'core:components.dateListControl.year',
    '': 'core:components.dateListControl.all',
};

export const DateListControl = (props: IProps) => {
    const { t } = useTranslation('core');
    const children = Object.entries(DATE_ITEMS).map(([value, label]) => (
        <MenuItem key={value} value={value} label={t(label)} />
    ));
    return <ListControl {...props} children={children} />;
};

DateListControl.displayName = 'DateListControl';

export default ListControl;
