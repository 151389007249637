import * as React from 'react';

interface TroxOrderCodeContextValue {
    troxSelectedArticleField: string;
    onTroxSelectedArticleFieldChange: (fieldName: string) => void;
}

const TroxOrderCodeContext = React.createContext<TroxOrderCodeContextValue>({
    troxSelectedArticleField: 'orderCode',
    onTroxSelectedArticleFieldChange: (fieldName: string) => {},
});

export const TroxOrderCodeContextProvider = ({
    troxSelectedArticleField,
    onTroxSelectedArticleFieldChange,
    children,
}: TroxOrderCodeContextValue & { children: React.ReactNode }) => {
    return (
        <TroxOrderCodeContext.Provider value={{ troxSelectedArticleField, onTroxSelectedArticleFieldChange }}>
            {children}
        </TroxOrderCodeContext.Provider>
    );
};
export const useTroxOrderCodeContext = () => React.useContext(TroxOrderCodeContext);
