import * as React from 'react';
import classnames from '../../../utils/classnames';

interface PageContentProps {
    className?: string;
    children?: React.ReactNode;
    lowered?: boolean;
}

const PageContent = ({ className, children, lowered = false }: PageContentProps) => {
    return (
        <section className={classnames('w-full flex-1 p-7 overflow-auto', lowered && 'bg-secondary-light', className)}>
            {children}
        </section>
    );
};

export default PageContent;
