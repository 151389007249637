// For local development & testing
//
// Change the import path of the `customerModule` to load a different customer module for workist
// Note: You can also override the customer module to be loaded at runtime via customization key in the customer config
//
//import customerModule from '../leonhard_kurz';
import { IModuleProps } from '../../core/utils/modules';

export default {
    //...customerModule,
    name: 'workist',
    featureFlags: {
        assistanceEmailThreads: true,
    },
} satisfies IModuleProps;
