import DocumentConfiguration from '../../../generic_document/pages/Configuration';
import * as DocumentQueries from './queries';
import { GET_OVERVIEW_DATA } from '../../queries';
import { CHANNEL_PATH, OVERVIEW_PATH } from '../../constants';
import React from 'react';
import { DocumentType } from '../../../generic_document/constants';

const Configuration = (props) => {
    const documentConfiguration = {
        service: 'order_confirmations',
        documentType: DocumentType.OrderConfirmation,
        documentTypeName: 'orderConfirmation',
        activeTab: 'order-confirmation',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
