import * as React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import ListItem from '../ListItem';

import './style.scss';

interface INoResultsProps {
    children?: any;
    className?: string;
}

export const NoResults = (props: INoResultsProps) => {
    const { children, className } = props;
    return <div className={classnames('no-results', className)}>{children}</div>;
};

NoResults.displayName = 'NoResults';

export interface IProps {
    className?: string;
    children?: any;
    onChildrenReady?: (children) => void;
    filter?: (child) => boolean;
    sort?: (child, other) => number;
    begin?: number;
    end?: number;
}

export const List = (props: IProps) => {
    const { className, children, onChildrenReady, filter, sort = (child, other) => 0, begin, end } = props;

    const { t } = useTranslation('core');

    // get no results
    const noResults = children.find(
        (child: any, i) => child && child.type && child.type.displayName == 'NoResults'
    ) || <NoResults className="no-results--default">{t('components.list.no-results')}</NoResults>;

    // filter out invalid children
    let listItems = React.Children.toArray(children).filter((child: any, i) => {
        if (!child || !child.type || child.type.displayName != 'ListItem') return false;
        return !filter || filter(child);
    });

    // sorting after we filtered out invalid children
    listItems.sort(sort);

    // before slicing to get e.g. number of items in total
    if (onChildrenReady) onChildrenReady(listItems);

    // do slicing to allow pagination
    if (begin !== undefined || end !== undefined) {
        listItems = listItems.slice(begin !== undefined ? begin : 0, end !== undefined ? end : listItems.length);
    }

    return (
        <div className={classnames('list', className)}>
            {listItems.length
                ? listItems.map((child: any, i) => <child.type key={child.key} {...child.props} />)
                : noResults}
        </div>
    );
};

List.displayName = 'List';

export default List;
