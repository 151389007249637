// end is excluded
export const getRange = (start, end) => {
    return Array.from({ length: end - start }, (_, i) => i + start);
};

export const parseCron = (cron: String) => {
    // e.g. '0 8,10 * * 1-5'
    if (!cron) return {};

    try {
        const [minute, hour, dayOfMonth, month, dayOfWeek] = cron.split(' ');
        const hours = hour.split(',');
        const daily = dayOfWeek == '1-5';
        return {
            option: daily ? 'daily' : 'weekly',
            day: !daily ? dayOfWeek : '',
            time: hours.length > 0 ? hours[0] : '',
            additionalTime: hours.length > 1 ? hours[1] : '',
        };
    } catch (e) {
        console.error(e);
        return {};
    }
};

export const makeCron = (option, day, time, additionalTime) => {
    const daily = option == 'daily';
    const hours = [time, additionalTime].filter((i) => i);
    hours.sort();

    const parts = [];
    parts.push('0'); // minutes
    parts.push(hours.join(',')); // hour
    parts.push('*'); // dayOfMonth
    parts.push('*'); // month
    parts.push(daily ? '1-5' : day); // dayOfWeek
    return parts.join(' ');
};
