import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanField, IntegerField, StringField, useForm } from '../Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import ConfigurationCard from '../ConfigurationCard';

const EmailClientConfiguration = ({ config, onActivate, onDeactivate = undefined, onSubmit }) => {
    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`emailClientConfig.fieldNames.${fieldName}`),
    });

    useEffect(() => {
        setFormData({
            enabled: config?.enabled,
            inboxName: config?.inboxName || '',
            allowSentByMatchByName: config?.allowSentByMatchByName,
            enableEmailOnlyDocumentProcessing: config?.enableEmailOnlyDocumentProcessing,
            enableEmailThreadTruncating: config?.enableEmailThreadTruncating,
            keepNTopmostEmailThreads: config?.keepNTopmostEmailThreads || null,
        });
    }, [config]);

    const handleSubmit = () => onSubmit(formData);
    const { t } = useTranslation('config');

    return (
        <ConfigurationCard
            title={t('emailClientConfig.title')}
            description={t('emailClientConfig.description')}
            active={!!config}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
        >
            <div className="form">
                <BooleanField {...getFieldProps('enabled')} className="field--vertical" />
                <StringField {...getFieldProps('inboxName')} className="field--vertical" />
                <BooleanField {...getFieldProps('allowSentByMatchByName')} className="field--vertical" />
                <BooleanField {...getFieldProps('enableEmailOnlyDocumentProcessing')} className="field--vertical" />
                <BooleanField {...getFieldProps('enableEmailThreadTruncating')} className="field--vertical" />
                <IntegerField {...getFieldProps('keepNTopmostEmailThreads')} className="field--vertical" />

                <div>
                    <ProgressButton label={t('card.save')} onClick={handleSubmit} />
                </div>
            </div>
        </ConfigurationCard>
    );
};

export default EmailClientConfiguration;
