import * as React from 'react';
import classnames from '../utils/classnames';
import { useState } from 'react';
import { useControllableState } from '../utils/useControllableState';
import { Simulate } from 'react-dom/test-utils';
import load = Simulate.load;

interface BaseButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    loading?: boolean;
}

export const BaseButton = React.forwardRef<React.ElementRef<'button'>, BaseButtonProps>(
    ({ className, loading, onClick, disabled, ...props }, ref) => {
        const [isLoading, setIsLoading] = useControllableState(false, loading);

        const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
            setIsLoading(true);
            Promise.resolve(onClick?.(e)).finally(() => setIsLoading(false));
        };

        return (
            <button
                ref={ref}
                className={classnames((disabled || isLoading) && 'opacity-50 cursor-not-allowed', className)}
                onClick={handleClick}
                disabled={disabled || isLoading}
                {...props}
            />
        );
    }
);

const Button = React.forwardRef<
    React.ElementRef<'button'>,
    BaseButtonProps & {
        active?: boolean;
        variant?: 'outline' | 'ghost';
    }
>(({ className, active, variant = 'outline', ...props }, ref) => {
    return (
        <BaseButton
            ref={ref}
            className={classnames(
                'bg-primary text-primary rounded px-3 py-1.5 font-medium text-sm border border-solid outline-none',
                'hover:bg-secondary transition-all',
                'data-[state=open]:bg-brand data-[state=open]:text-brand',
                variant === 'outline' && 'bg-primary border-secondary shadow-sm data-[state=open]:border-brand',
                active && variant === 'outline' && '!border-brand',
                variant === 'ghost' && 'bg-transparent border-transparent',
                active && '!bg-brand !text-brand',
                className
            )}
            {...props}
        />
    );
});

export default Button;
