import DocumentAssistance, { ALL_TABS, TABS_OPTIONS } from '../../../generic_document/pages/Assistance';
import {
    DELETE_UNIVERSAL,
    DISCARD_UNIVERSAL,
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    UNIVERSAL_EXTRACT_TEXT,
    RE_UPLOAD_FILE,
    REOPEN_FOR_ASSISTANCE,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../../queries';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../../constants';
import { useMemo } from 'react';
import { DocumentType } from '../../../generic_document/constants';
import * as React from 'react';
import {
    UniversalFocusActionClickButton,
    UniversalHeaderDataFieldGroupControls,
    UniversalItemMoreActionsButton,
} from './customFieldGroupSections';

const UniversalAssistance = (props) => {
    const documentConfiguration = useMemo(
        () => ({
            documentType: DocumentType.Universal,
            documentTypeName: 'universal',
            GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
            GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
            RETRY_STEP: RETRY_STEP,
            DISCARD: DISCARD_UNIVERSAL,
            DELETE: DELETE_UNIVERSAL,
            REOPEN_FOR_ASSISTANCE: REOPEN_FOR_ASSISTANCE,
            ASSISTANCE_PATH: ASSISTANCE_PATH,
            ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
            OVERVIEW_PATH: OVERVIEW_PATH,
            OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
            OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
            CHANNEL_PATH: CHANNEL_PATH,
            CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
            CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
            RE_UPLOAD_FILE: RE_UPLOAD_FILE,
            SEND_TO_LABELING: SEND_TO_LABELING,
            EXTRACT_TEXT: UNIVERSAL_EXTRACT_TEXT,
            tabs: {
                [TABS_OPTIONS.Source]: ALL_TABS[TABS_OPTIONS.Source],
                [TABS_OPTIONS.Document]: ALL_TABS[TABS_OPTIONS.Document],
            },
            defaultTab: TABS_OPTIONS.Document,
            useDefaultItemTypes: false,
            components: {
                // Add custom components here
                HeaderDataFieldGroupControls: [
                    {
                        condition: () => true,
                        component: UniversalHeaderDataFieldGroupControls,
                    },
                ],
                FocusActionClickButton: [
                    {
                        condition: () => true,
                        component: UniversalFocusActionClickButton,
                    },
                ],
                ItemMoreActionsButton: [
                    {
                        condition: () => true,
                        component: UniversalItemMoreActionsButton,
                    },
                ],
            },
        }),
        []
    );

    return <DocumentAssistance documentConfiguration={documentConfiguration} props={props} />;
};

export default UniversalAssistance;
