import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { useAssistanceContext } from './AssistanceContext';
import { useTranslation } from 'react-i18next';
import { AssistanceField } from './AssistanceField';
import { withIcon } from '../../../core_updated/components/Icon';
import { faCheckCircle, faCrosshairs, faEraser, faMarker, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import AssistanceSection, { useAssistanceSectionContext } from './AssistanceSection';
import Tooltip from '../../../core/components/Tooltip';
import { IEventField, useAssistanceViewContext } from './AssistanceViewContext';
import { filterFastTrack } from './utils';
import { DEFAULT_VALUE_KEY, GROUP_SEPARATOR } from './useDocumentConfig';
import AssistanceSectionEmptyState from './AssistanceSectionEmptyState';
import { camelCase } from 'lodash';
import HeaderDataFieldGroup from './customizable/HeaderDataFieldGroup';
import { useConditionalComponent } from '../../../customizations/ConditionalComponentContext';
import useReselectEventListener, { ReselectEvent } from './useReselectEventListener';

const SCROLL_OFFSET = -20;

const FocusIcon = withIcon(faCrosshairs);
const ClearIcon = withIcon(faEraser);
const ReselectIcon = withIcon(faMarker);
const LoadingIcon = withIcon(faSpinnerThird);
const CompleteIcon = withIcon(faCheckCircle);

const HeaderDataSection = React.memo(
    ({ previewRef, formFieldGroups, focusField, hoverField, hoverItemIndex, updateField, reselectField }: any) => {
        const { t } = useTranslation('assistance');

        const [isOpen, setIsOpen] = useState(true);

        const { document } = useAssistanceContext();
        const { isFastTrackEnabled } = useAssistanceViewContext();

        const [filteredFormFieldGroups, { low: lowConfidenceFieldsCount, medium: mediumConfidenceFieldsCount }] =
            filterFastTrack(formFieldGroups);

        const displayFormFieldGroups = isFastTrackEnabled ? filteredFormFieldGroups : formFieldGroups;

        return (
            <AssistanceSection
                title={t('sections.header')}
                controls={
                    <HeaderDataSectionControls
                        isOpen={isOpen}
                        mediumConfidenceFieldsCount={mediumConfidenceFieldsCount}
                        lowConfidenceFieldsCount={lowConfidenceFieldsCount}
                        onOpenChange={setIsOpen}
                    />
                }
                open={isOpen}
                onOpenChange={setIsOpen}
            >
                <div className="flex flex-col gap-8">
                    {!document ? (
                        <HeaderDataSectionLoader />
                    ) : displayFormFieldGroups.length ? (
                        displayFormFieldGroups.map((fields, index) => (
                            <HeaderDataFormFieldGroup
                                key={index}
                                fields={fields}
                                previewRef={previewRef}
                                focusField={focusField}
                                hoverField={hoverField}
                                hoverItemIndex={hoverItemIndex}
                                updateField={updateField}
                                reselectField={reselectField}
                            />
                        ))
                    ) : (
                        <AssistanceSectionEmptyState />
                    )}
                </div>
            </AssistanceSection>
        );
    }
);

const HeaderDataSectionControls = ({
    isOpen,
    mediumConfidenceFieldsCount,
    lowConfidenceFieldsCount,
    onOpenChange,
}: any) => {
    const { t } = useTranslation('assistance');

    const {
        record,
        document,
        loading: isDocumentLoading,
        isFinished,
        readOnly,
        handlers: { showOriginalData },
    } = useAssistanceContext();
    const { onFieldAction } = useAssistanceViewContext();

    if (!document) {
        return null;
    }

    const isHeaderCorrect = record?.isHeaderCorrect;
    const canComplete = !!record?.canFinishHeaderAssistance && !readOnly;

    const correctBadge = (
        <span className="text-sm font-medium text-primary flex gap-1.5 items-center px-2">
            <CompleteIcon className="text-success" />
            {t('itemsView.actions.sectionCompleted')}
        </span>
    );

    return (
        <>
            {isHeaderCorrect && !showOriginalData
                ? correctBadge
                : (!isOpen || showOriginalData) && (
                      <div className="px-2 flex gap-2">
                          {mediumConfidenceFieldsCount === 0 && lowConfidenceFieldsCount === 0 && (
                              <div className="rounded-full w-7 h-7 text-primary text-sm font-medium flex justify-center items-center bg-secondary">
                                  0
                              </div>
                          )}
                          {mediumConfidenceFieldsCount > 0 && (
                              <div className="rounded-full w-7 h-7 text-sm font-medium flex justify-center items-center bg-confidence-medium">
                                  {mediumConfidenceFieldsCount}
                              </div>
                          )}
                          {lowConfidenceFieldsCount > 0 && (
                              <div className="rounded-full w-7 h-7 text-inverted text-sm font-medium flex justify-center items-center bg-confidence-low">
                                  {lowConfidenceFieldsCount}
                              </div>
                          )}
                      </div>
                  )}

            {!isFinished && !showOriginalData && isOpen && (
                <Tooltip
                    content={
                        !canComplete
                            ? t('itemsView.actions.completeSectionDisabled')
                            : t('itemsView.actions.completeSection')
                    }
                    placement="top"
                >
                    <AssistanceSection.IconButton
                        className="bg-brand-default hover:bg-brand-hover active:bg-brand-active text-inverted hover:text-inverted disabled:opacity-50 disabled:pointer-events-none"
                        onClick={() => {
                            onFieldAction?.({
                                action: 'action:mark_header_correct',
                            }).then(() => {
                                onOpenChange(false);
                            });
                        }}
                        disabled={!canComplete || isDocumentLoading}
                    >
                        {isDocumentLoading ? <LoadingIcon spin /> : <CompleteIcon />}
                    </AssistanceSection.IconButton>
                </Tooltip>
            )}
        </>
    );
};

const HeaderDataSectionLoader = () => (
    <>
        <AssistanceSection.FieldGroupLoadingSkeleton />
        <AssistanceSection.FieldGroupLoadingSkeleton />
        <AssistanceSection.FieldGroupLoadingSkeleton lines={1} />
        <AssistanceSection.FieldGroupLoadingSkeleton lines={1} />
    </>
);

export const HeaderDataFieldGroupControls = ({
    fields,
    fieldName,
    onClear,
    onFocus,
    onFieldFocus,
    onFieldReselect,
    focusField,
    hideFocus,
    hideClear,
    hideReselect,
}: any) => {
    const { t } = useTranslation('assistance');
    const { readOnly: documentReadOnly } = useAssistanceContext();

    const reselectButtonRef = useRef(null);

    useReselectEventListener(reselectButtonRef, (e: ReselectEvent) => {
        onFieldReselect({
            fieldName,
            bbox: e.detail.bbox,
            pageIndex: e.detail.pageIndex,
        });
    });

    const isFieldDisabled = fields?.length && fields?.length === 1 && fields[0]?.config?.disabled;
    const isActionDisabled = documentReadOnly || isFieldDisabled;

    return (
        <>
            {!hideFocus && onFocus && (
                <Tooltip content={t(`assistance:headerView.actions.focus`)} placement="top">
                    <AssistanceSection.FieldGroupIconButton onClick={onFocus}>
                        <FocusIcon />
                    </AssistanceSection.FieldGroupIconButton>
                </Tooltip>
            )}

            {!hideClear && onClear && fields?.length && fields?.length > 1 && !isActionDisabled && (
                <Tooltip content={t(`assistance:headerView.actions.clear`)} placement="top">
                    <AssistanceSection.FieldGroupIconButton onClick={onClear}>
                        <ClearIcon />
                    </AssistanceSection.FieldGroupIconButton>
                </Tooltip>
            )}

            {!hideReselect && onFieldFocus && !isActionDisabled && (
                <Tooltip content={t(`assistance:headerView.actions.reselect`)} placement="top">
                    <AssistanceSection.FieldGroupIconButton
                        onClick={() =>
                            onFieldFocus(
                                focusField?.fieldName === fieldName
                                    ? null
                                    : {
                                          fieldName,
                                          reselectTargetRef: reselectButtonRef,
                                      }
                            )
                        }
                        active={focusField?.fieldName === fieldName}
                        ref={reselectButtonRef}
                    >
                        <ReselectIcon />
                    </AssistanceSection.FieldGroupIconButton>
                </Tooltip>
            )}
        </>
    );
};

const MemoAssistanceField = React.memo(AssistanceField);

const HeaderDataFormFieldGroup = ({ fields, previewRef, focusField, updateField, reselectField }: any) => {
    const { t } = useTranslation('assistance');
    const { document, readOnly: documentReadOnly, loading: documentLoading } = useAssistanceContext();
    const {
        onFieldFocus,
        onFieldBlur,
        onFieldMouseEnter,
        onFieldMouseLeave,
        onFieldAction,
        onFieldReselect,
        onFieldUpdate,
        generalConfig,
    } = useAssistanceViewContext();
    const { viewMode } = useAssistanceSectionContext();

    const { ocr } = useAssistanceContext();

    const scrollToArea = useScrollToArea({
        previewRef: previewRef,
        pagesCount: ocr?.pagesCount,
    });
    const handleFocus = useCallback(() => scrollToArea(fields[0]?.bbox, fields[0]?.pageIndex), [fields, scrollToArea]);

    const handleClear = useCallback(
        () =>
            onFieldUpdate({
                fieldName,
                payload: Object.fromEntries(fields.map((field) => [field.valueKey, ''])),
            }),
        [onFieldUpdate, fields]
    );

    const handleFieldUpdate = useCallback(
        (props) => {
            // for header fields always send all the values back not only the changed one
            const prevPayload = Object.fromEntries(
                fields
                    .filter((field) => !!field.valueType)
                    .map((field) => [camelCase(field.valueKey), field.value ?? ''])
            );

            return onFieldUpdate({
                ...props,
                payload: {
                    ...prevPayload,
                    ...props.payload,
                },
            });
        },
        [onFieldUpdate, fields]
    );

    // e.g. for customer we only want to show the subfields
    const filterGroupField = (field) => !(field.path === fieldName && fields.length > 1);
    const fieldName = fields[0]?.fieldName;

    const FieldGroupComponent = useConditionalComponent(
        'HeaderDataFieldGroup',
        {
            fieldName,
        },
        HeaderDataFieldGroup
    );

    const FieldGroupControlsComponent = useConditionalComponent(
        'HeaderDataFieldGroupControls',
        {
            fieldName,
        },
        HeaderDataFieldGroupControls
    );

    return (
        <FieldGroupComponent
            fieldName={fieldName}
            fields={fields}
            controls={
                <FieldGroupControlsComponent
                    fields={fields}
                    fieldName={fieldName}
                    onClear={handleClear}
                    onFocus={handleFocus}
                    onFieldFocus={onFieldFocus}
                    onFieldReselect={onFieldReselect}
                    focusField={focusField}
                    reselectField={reselectField}
                    updateField={updateField}
                />
            }
        >
            {fields
                // don't filter as this e.g. filters out some readonly fields
                // ?.filter((field) => field.valueType)
                ?.filter(filterGroupField)
                .map((field, fieldIndex) => {
                    const fullFieldName =
                        field.valueKey && field.valueKey !== DEFAULT_VALUE_KEY
                            ? `${field.fieldName}${GROUP_SEPARATOR}${field.valueKey}`
                            : field.fieldName;

                    const getIsActive = (eventField: IEventField) => {
                        return (
                            (eventField?.fieldName === field.fieldName || eventField?.fieldName === fullFieldName) &&
                            eventField?.itemIndex === undefined
                        );
                    };

                    const assistanceField = (
                        <MemoAssistanceField
                            key={field.config?.name}
                            fieldName={field.fieldName}
                            fieldIndex={fieldIndex}
                            valueKey={field.valueKey}
                            value={field.value}
                            // view state
                            placeholder={
                                field.config?.fieldLabelName ?? t(`assistance:headerView.fieldNames.${fullFieldName}`)
                            }
                            isUpdateActive={getIsActive(updateField)}
                            isReselectActive={getIsActive(reselectField)}
                            isFocusActive={getIsActive(focusField)}
                            // by reference
                            data={field.data}
                            config={field.config}
                            generalConfig={generalConfig}
                            // more
                            onFocus={onFieldFocus}
                            onBlur={onFieldBlur}
                            onMouseEnter={onFieldMouseEnter}
                            onMouseLeave={onFieldMouseLeave}
                            onAction={onFieldAction}
                            onReselect={onFieldReselect}
                            onUpdate={handleFieldUpdate}
                            viewMode={viewMode}
                            documentLoading={documentLoading}
                            documentReadOnly={documentReadOnly}
                            clientPartitionId={document?.clientPartitionId}
                            matchingDocumentNumber={
                                document[camelCase(field.config?.documentNumberFieldName || '')]?.value
                            }
                        />
                    );

                    return (
                        <AssistanceSection.FieldLabel
                            label={
                                field.config?.fieldLabelName ?? t(`assistance:headerView.fieldNames.${fullFieldName}`)
                            }
                            required={field.config?.isRequired}
                            key={`${field.config?.name}-wrapper`}
                        >
                            {assistanceField}
                        </AssistanceSection.FieldLabel>
                    );
                })}
        </FieldGroupComponent>
    );
};

export const useScrollToArea =
    ({ previewRef, pagesCount }: any) =>
    (bbox: number[][], pageIndex = 0) => {
        const getTop = (bbox, pageIndex, pagesCount) => (bbox[0][1] + pageIndex) / pagesCount;

        const viewportWidth = previewRef.current?.getBoundingClientRect().width || 0;

        const svg = previewRef.current?.querySelector('svg');
        const svgHeight = svg?.getBoundingClientRect().height || 0;
        const svgWidth = svg?.getBoundingClientRect().width || 0;

        const scrollTop = bbox ? getTop(bbox, pageIndex, pagesCount) : 0;
        const spacingTop =
            svg.getBoundingClientRect().top -
            previewRef.current?.getBoundingClientRect().top +
            previewRef.current?.scrollTop;

        const spacingLeft =
            svg.getBoundingClientRect().left -
            previewRef.current?.getBoundingClientRect().left +
            previewRef.current?.scrollLeft;

        const relativeWordCenterX = bbox ? bbox[0][0] + (bbox[1][0] - bbox[0][0]) / 2 : 0;
        const alignLeft = relativeWordCenterX <= 0.5;

        // if word is on the right side of the screen, scroll to the right

        previewRef.current.scrollTo({
            top: scrollTop * svgHeight + spacingTop + SCROLL_OFFSET,
            left:
                svgWidth > viewportWidth
                    ? alignLeft
                        ? spacingLeft + SCROLL_OFFSET
                        : spacingLeft + svgWidth - viewportWidth - SCROLL_OFFSET
                    : undefined,
            behavior: 'smooth',
        });
    };

export default HeaderDataSection;
