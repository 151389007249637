import DocumentConfiguration, { CONFIG_OPTIONS, DEFAULT_CONFIGS } from '../../../generic_document/pages/Configuration';
import * as DocumentQueries from './queries';
import { GET_OVERVIEW_DATA } from '../../queries';
import { CHANNEL_PATH, OVERVIEW_PATH } from '../../constants';
import React from 'react';
import { DocumentType } from '../../../generic_document/constants';
import { UniversalDocumentConfig } from '../../../assistance/containers/UniversalDocumentConfig';

const Configuration = (props) => {
    const configOptions = {
        [CONFIG_OPTIONS.Document]: ({ channel, documentConfiguration, handlers, channelRefetch }) => {
            return (
                <UniversalDocumentConfig
                    documentTypeName={documentConfiguration.documentTypeName}
                    config={channel?.documentConfig}
                    refetch={channelRefetch}
                    channelId={channel?.id}
                />
            );
        },
        [CONFIG_OPTIONS.Email]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Email],
        [CONFIG_OPTIONS.MasterData]: DEFAULT_CONFIGS[CONFIG_OPTIONS.MasterData],
        [CONFIG_OPTIONS.SFTP]: DEFAULT_CONFIGS[CONFIG_OPTIONS.SFTP],
        [CONFIG_OPTIONS.AS2]: DEFAULT_CONFIGS[CONFIG_OPTIONS.AS2],
        [CONFIG_OPTIONS.Upload]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Upload],
        [CONFIG_OPTIONS.Discard]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Discard],
    };

    const documentConfiguration = {
        service: 'universal',
        documentType: DocumentType.Universal,
        documentTypeName: 'universal',
        activeTab: 'universal',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configOptions: configOptions,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
