import * as React from 'react';
import { useEffect, useState } from 'react';

import './style.scss';

export const FilterForm = (props) => {
    const { availableFilters = {}, initialFilterValues = [], onSubmit } = props;
    const [activeValues, setActiveValues] = useState(initialFilterValues);

    const clearActiveValue = (fieldName) => {
        handleSubmit({ ...activeValues, [fieldName]: '' });
    };

    const handleSubmit = (activeValues, isInitialSubmit = false) => {
        const nonNullFilters = Object.entries(activeValues)
            .filter(([key, value]) => value != null)
            .map(([key, value]) => ({
                name: key,
                value: value,
                expression: availableFilters.find(({ key: filterKey }) => filterKey == key)?.expression ?? 'eq',
            }))
            .map(
                (filter) =>
                    availableFilters.find(({ key: filterKey }) => filter.name == filterKey)?.mapFilter?.(filter) ??
                    filter
            );
        if (onSubmit) onSubmit(nonNullFilters, isInitialSubmit);
    };

    // Invoke submit callback for initial values to allow updating controlling state in parent
    useEffect(() => {
        handleSubmit(activeValues, true);
    }, []);

    return (
        <form
            className="filter-form"
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(activeValues);
            }}
        >
            {availableFilters.length > 0 && (
                <div className="filter-form__filters">
                    {availableFilters.map(({ key, render, label, placeholder, getDisplayValue, renderProps }) => {
                        const FieldComponent = render;
                        return (
                            <FieldComponent
                                key={key}
                                label={label}
                                inputProps={{ placeholder }}
                                value={activeValues[key]}
                                setValue={(value) => {
                                    setActiveValues({ ...activeValues, [key]: value });
                                }}
                                onUpdate={(value) => handleSubmit({ ...activeValues, [key]: value })}
                                className="filter-form__filter field--vertical"
                                onClear={renderProps?.onClear ? () => clearActiveValue(key) : null}
                                getDisplayValue={getDisplayValue}
                                {...(renderProps || {})}
                            />
                        );
                    })}
                </div>
            )}
        </form>
    );
};

export default FilterForm;
