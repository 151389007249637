import React from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';

import '../../core/components/Menu/style.scss';
import classnames from '../../core_updated/utils/classnames';

const DropdownMenuItem = React.forwardRef(
    ({ className = undefined, children = undefined, ...props }: any, ref: any) => {
        return (
            <DropdownMenuPrimitive.Item
                className={classnames(
                    'rounded outline-none bg-primary px-3 py-1.5 hover:bg-secondary focus:bg-secondary text-primary text-sm cursor-default',
                    className
                )}
                {...props}
                ref={ref}
            >
                {children}
            </DropdownMenuPrimitive.Item>
        );
    }
);

const DropdownMenuSeparator = React.forwardRef(({ className = undefined, ...props }: any, ref: any) => {
    return (
        <DropdownMenuPrimitive.Separator
            className={classnames('w-full border-b border-solid border-primary my-1', className)}
            {...props}
            ref={ref}
        />
    );
});

const DropdownMenuContent = React.forwardRef(
    ({ className = undefined, children = undefined, ...props }: any, ref: any) => {
        return (
            <DropdownMenuPrimitive.Portal>
                <DropdownMenuPrimitive.Content
                    className={classnames(
                        'bg-primary shadow-lg rounded-lg p-2 flex flex-col gap-0.5 border border-primary border-solid min-w-[200px] z-[100]',
                        className
                    )}
                    {...props}
                    ref={ref}
                >
                    {children}
                </DropdownMenuPrimitive.Content>
            </DropdownMenuPrimitive.Portal>
        );
    }
);

const DropdownMenuTrigger = React.forwardRef(({ className = undefined, ...props }: any, ref: any) => {
    return <DropdownMenuPrimitive.Trigger className={classnames(className)} {...props} ref={ref} asChild />;
});

const DropdownMenu = React.forwardRef((props: any, ref: any) => {
    return <DropdownMenuPrimitive.Root {...props} ref={ref} />;
});

export default Object.assign(DropdownMenu, {
    Item: DropdownMenuItem,
    Separator: DropdownMenuSeparator,
    Content: DropdownMenuContent,
    Trigger: DropdownMenuTrigger,
});
