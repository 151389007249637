import { useTranslation } from 'react-i18next';
import { default as React, useEffect, useState } from 'react';
import TextArea from '../../../core/components/TextArea';
import ConfirmModal from '../../../core/containers/ConfirmModal';

const DiscardModal = (props) => {
    const { onCancel, onConfirm, initialValue = '', ...modalProps } = props;
    const { t } = useTranslation('assistance');

    const textareaRef = React.useRef(null);

    const [reason, setReason] = useState(initialValue);
    useEffect(() => {
        if (!modalProps.visible) {
            return;
        }

        if (!reason) {
            setReason(initialValue);
        }

        // make sure the textarea is focused and the cursor is at the end of the text
        textareaRef?.current?.focus();
        setTimeout(() => {
            if (!textareaRef?.current) return;

            const val = textareaRef.current.value;
            textareaRef.current.value = '';
            textareaRef.current.value = val;
        }, 10);
    }, [modalProps.visible]);

    return (
        <ConfirmModal
            className="discard-modal"
            onConfirm={() => onConfirm(reason)}
            onCancel={onCancel}
            labelCancel={t('discardModal.cancel')}
            labelConfirm={t('discardModal.discardButton')}
            {...modalProps}
        >
            <p>{t('discardModal.reasonLabel')}</p>
            <TextArea
                ref={textareaRef}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder={t('discardModal.reasonPlaceHolder')}
                className="discard-modal__input"
            />
        </ConfirmModal>
    );
};

export default DiscardModal;
