import * as React from 'react';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';
import Checkbox from '../../../core/components/Checkbox';

export interface BooleanFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {}

const BooleanField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            controls,
            className,
            readOnly,
            disabled,
            inputRef = undefined,
            ...props
        }: BooleanFieldProps,
        ref: any
    ) => {
        const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);

        const handleChange = (e: any) => {
            setValue(e.target.checked);
            onChange?.(e);
        };

        return (
            <Field className={className} readOnly={readOnly} disabled={disabled} ref={ref}>
                <Field.Input>
                    <label className="w-full min-w-0 px-2 h-8 flex items-center">
                        <Checkbox
                            checked={value || ''}
                            onChange={handleChange}
                            readOnly={readOnly}
                            disabled={disabled}
                            {...props}
                            ref={inputRef}
                        />
                    </label>
                </Field.Input>
                <Field.Controls>{controls}</Field.Controls>
            </Field>
        );
    }
);

export default BooleanField;
