import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import i18n from 'i18next';

import Card from '../../../core/components/Card';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import Loader from '../../../core/components/Loader';
import { url } from '../../../core/utils/link';
import { SecondaryButton } from '../../../core/components/Button';
import EmailNotificationsModal from '../../containers/EmailNotificationsModal';
import {
    CHANGE_EMAIL_NOTIFICATION_SETTINGS,
    GET_COMPANY_DETAILS,
    UPDATE_CUSTOMER,
    UPDATE_SSO_CONFIG,
} from '../../queries';
import { parseCron } from '../../services';
import { EDIT_PATH } from '../../constants';
import SSOConfigModal from '../../containers/SSOConfigModal';

import './style.scss';
import CustomizationConfigModal from '../../containers/CustomizationConfigModal';
import { loadCustomizations } from '../../../customizations/framework';
import Layout from '../../../core_updated/components/Layout';

const getEmailNotificationDescriptionParts = (customer): any => {
    if (!customer.sendEmailNotification) {
        return ['overview.emailNotification.never', {}];
    }

    if (!customer.emailNotificationCron) {
        return ['overview.emailNotification.always', {}];
    }

    const cronParts = parseCron(customer.emailNotificationCron);

    if (cronParts.option === 'weekly') {
        return [
            'overview.emailNotification.weekly',
            {
                time: cronParts.time,
                day: moment().day(cronParts.day).format('dddd'),
            },
        ];
    } else if (cronParts.option === 'daily') {
        return [
            'overview.emailNotification.daily',
            {
                time: cronParts.time,
                additionalTime: cronParts.additionalTime || '-',
            },
        ];
    }

    return ['overview.emailNotification.fallback', {}];
};

const Overview = (props) => {
    const { user } = props;
    const { t } = useTranslation('customer');

    moment.locale(user?.language || i18n.language);

    const [showEmailNotificationsModal, setShowEmailNotificationsModal] = useState(false);
    const [showSSOConfigModal, setShowSSOConfigModal] = useState(false);
    const [showCustomizationConfigModal, setShowCustomizationConfigModal] = useState(false);

    const { data, error, loading, refetch } = useQuery(GET_COMPANY_DETAILS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });

    const [changeNotifications, { loading: notificationsLoading, error: notificationsError }] = useMutation(
        CHANGE_EMAIL_NOTIFICATION_SETTINGS
    );

    const handleChangeNotifications = (formData) => {
        return changeNotifications({
            variables: {
                sendEmailNotification: formData.sendEmailNotification,
                emailNotificationCron: formData.emailNotificationCron,
            },
        })
            .then(() => {
                refetch();
                setShowEmailNotificationsModal(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [updateSSOConfig] = useMutation(UPDATE_SSO_CONFIG);

    const handleUpdateSSOConfig = (formData) => {
        return updateSSOConfig({
            variables: {
                input: formData,
            },
        })
            .then(() => {
                refetch();
                setShowSSOConfigModal(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [updateCustomer, { loading: updateCustomerLoading, error: updateCustomerError }] =
        useMutation(UPDATE_CUSTOMER);

    const customer = data?.viewer?.customer;
    const price = ![undefined, null].includes(customer?.subscription?.price)
        ? customer?.subscription?.price
        : customer?.plan?.price;

    const handleUpdateCustomizationConfig = (formData) => {
        return updateCustomer({
            variables: {
                input: formData,
            },
        })
            .then(() => {
                refetch();
                setShowCustomizationConfigModal(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const customizationKey = customer?.customizationKey;
    useEffect(
        function reloadCustomizations() {
            loadCustomizations(customer);
        },
        [customizationKey]
    );

    if (loading || notificationsLoading) {
        return (
            <Page className="customer page--slim">
                <Loader />
            </Page>
        );
    }

    return (
        <Layout>
            <Page className="customer page--slim">
                <div className="customer__head">
                    <div className="customer__basic-info">
                        <div className="customer__title">{customer.name}</div>
                        <div className="customer__address">
                            {`${customer.streetAddress}, ${customer.postalCode} ${customer.city}, ${customer.countryCode}`}
                        </div>
                    </div>

                    <SecondaryButton label={t('overview.editLabel')} linkTo={url(EDIT_PATH)} />
                </div>

                <section className="customer__section">
                    <div className="customer__section-title">{t('overview.emailNotification.title')}</div>
                    <div className="customer__section-text">{t(...getEmailNotificationDescriptionParts(customer))}</div>
                    <div className="customer__section-buttons">
                        <SecondaryButton
                            className="customer__section-button"
                            label={t('overview.emailNotification.change')}
                            onClick={() => setShowEmailNotificationsModal(true)}
                        />
                    </div>

                    <EmailNotificationsModal
                        customer={customer}
                        onSubmit={handleChangeNotifications}
                        onCancel={() => setShowEmailNotificationsModal(false)}
                        visible={showEmailNotificationsModal}
                    />
                </section>

                <section className="customer__section">
                    <div className="customer__section-title">{t('overview.sso.title')}</div>
                    <div className="customer__section-text">{t('overview.sso.description')}</div>
                    <div className="customer__section-buttons">
                        <SecondaryButton
                            className="customer__section-button"
                            label={t('overview.sso.change')}
                            onClick={() => setShowSSOConfigModal(true)}
                        />
                    </div>

                    <SSOConfigModal
                        config={customer?.ssoConfig}
                        onSubmit={handleUpdateSSOConfig}
                        onCancel={() => setShowSSOConfigModal(false)}
                        visible={showSSOConfigModal}
                    />
                </section>

                <section className="customer__section">
                    <div className="customer__section-title">{t('overview.customizations.title')}</div>
                    <div className="customer__section-text">{t('overview.customizations.description')}</div>
                    <div className="customer__section-buttons">
                        <SecondaryButton
                            className="customer__section-button"
                            label={t('overview.sso.change')}
                            onClick={() => setShowCustomizationConfigModal(true)}
                        />
                    </div>

                    <CustomizationConfigModal
                        config={{ customizationKey: customer?.customizationKey }}
                        onSubmit={handleUpdateCustomizationConfig}
                        onCancel={() => setShowCustomizationConfigModal(false)}
                        visible={showCustomizationConfigModal}
                    />
                </section>

                {customer?.plan && (
                    <section className="customer__section">
                        <div className="customer__section-title">{t('overview.selectedPlan.title')}</div>

                        {customer?.quotaExceeded && (
                            <Alert severity="warning">
                                <AlertTitle>{t('overview.quota.warning')}</AlertTitle>
                            </Alert>
                        )}

                        {customer?.quota && (
                            <div className="quota customer__quota">
                                <div className="quota__description">
                                    <span className="quota__highlight">
                                        {customer?.usedQuota.toLocaleString()} {t('overview.quota.executions')}{' '}
                                    </span>
                                    <span>
                                        {t('overview.quota.of')}{' '}
                                        {customer?.quota == -1
                                            ? t('overview.quota.unlimited')
                                            : customer?.quota.toLocaleString()}
                                    </span>
                                </div>

                                <div className="quota__bar-background">
                                    {customer?.quota != -1 && (
                                        <div
                                            className="quota__bar"
                                            style={{ width: `${(customer?.usedQuota / customer?.quota) * 100}%` }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        <div className="customer__plan">
                            <Card className="customer__plan-card" level={1}>
                                <div className="customer__plan-title">{customer?.plan?.name}</div>
                                <div className="customer__plan-pricing">
                                    {t('plan.pricing', { quota: customer?.plan?.quota, price: price })}
                                </div>
                                {customer?.plan?.provider && (
                                    <div className="customer__plan-description">
                                        {t('overview.selectedPlan.managedBy')}
                                        <br />
                                        <a href={customer?.plan?.provider?.url} target="_blank">
                                            {customer?.plan?.provider?.name}
                                        </a>
                                        .
                                    </div>
                                )}
                            </Card>
                        </div>

                        <div className="customer__section-text">{t('overview.selectedPlan.changePlan')}</div>
                    </section>
                )}
            </Page>
        </Layout>
    );
};

export default Overview;
