import { gql } from '@apollo/client';

export const GET_USER_FIRST_STEPS = gql`
    query {
        viewer {
            id
            customer {
                id
                isActive
                name
                invoiceEmail
                streetAddress
                postalCode
                city
            }
        }
    }
`;
