import { StringField, useForm } from '../../../../assistance/containers/Fields';
import { useTranslation } from 'react-i18next';
import classnames from '../../../../core_updated/utils/classnames';
import * as React from 'react';
import Modal from '../../../../core/components/Modal';
import AutomationRateChart from '../../../containers/AutomationRateChart';
import { useQuery } from '@apollo/client';
import { AUTO_UPDATE_INTERVAL } from '../../../../../constants';
import { canUseDemoMode } from '../../../../users/utils';
import { useApplicationContext } from '../../../../core_updated/contexts/ApplicationContext';
import moment from 'moment';

const getDateRange = (startDate, endDate) => {
    let fromDate = moment(startDate);
    let toDate = moment(endDate);
    let diff = toDate.diff(fromDate, 'days');
    let range = [];
    for (let i = 0; i < diff; i++) {
        range.push(moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
    }
    return range;
};

const ChannelAutomationRateChart = React.memo((props: any) => {
    let { channelName, channelId, documentName, documentConfiguration } = props;

    const { user } = useApplicationContext();

    const now = new Date();
    const startDate = moment(now).subtract(30, 'days').format('YYYY-MM-DD');
    const endDate = moment(now).format('YYYY-MM-DD');

    const query = channelId
        ? documentConfiguration.GET_CHANNEL_AUTOMATION_RATIO
        : documentConfiguration.GET_CUSTOMER_AUTOMATION_RATIO;

    const {
        data,
        error,
        loading,
        refetch: tableRefetch,
    } = useQuery(query, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: false,
        pollInterval: AUTO_UPDATE_INTERVAL,
        variables: {
            channelId,
            startDate,
            endDate,
        },
    });

    let chartData = channelId
        ? data?.['channel' + documentName + 'AutomationRatio']
        : data?.['customer' + documentName + 'AutomationRatio'];

    if (canUseDemoMode(user)) {
        let demoDateRange = getDateRange(startDate, endDate);
        const weekOfDay = moment(now).isoWeekday();
        const demoAutomationRateBase = documentConfiguration.demoAutomationRatePath;
        let demoAutomationRate = JSON.parse(JSON.stringify(demoAutomationRateBase));
        // show automation rates until yesterday, with Saturday/Sunday dips at the correct location
        demoAutomationRate.automationDays.splice(0, weekOfDay - 1 + 5);
        const demoAutomationDays = demoDateRange.map((date, index) => {
            demoAutomationRate.automationDays[index].date = date;
            return demoAutomationRate.automationDays[index];
        });
        chartData = {
            automationDays: demoAutomationDays,
            last5DaysAutomationRatio: demoAutomationRate.last5DaysAutomationRatio,
        };
    }

    return (
        <AutomationRateChart
            label={channelName}
            data={chartData?.automationDays || []}
            automationRatio={chartData?.last5DaysAutomationRatio}
        />
    );
});

const AutomationRateModal = (props) => {
    const { t } = useTranslation('assistance');

    const { className, channelName, channelId, documentName, documentConfiguration, ...modalProps } = props;

    return (
        <Modal
            className={classnames('!max-w-[800px]', className)}
            title={t('overview.automationRateModal.title')}
            allowMaximize={true}
            {...modalProps}
        >
            <ChannelAutomationRateChart
                channelName={channelName}
                channelId={channelId}
                documentName={documentName}
                documentConfiguration={documentConfiguration}
            />
        </Modal>
    );
};

export default AutomationRateModal;
