import React, { useRef, useEffect, useState } from 'react';
import { HighlightedText, IHighlightedText } from './highlighting';
import Tooltip from '../Tooltip';
import classnames from 'classnames';

import './style.scss';

interface ITruncatedText extends React.HTMLAttributes<HTMLDivElement> {
    text: string | React.ReactNode;
    truncate?: boolean; // whether to truncate at all
    truncatedTooltip?: string; // shows the full text by default
    as?: React.ElementType;
}

type ITextProps = IHighlightedText & ITruncatedText & Record<any, any>;

/**
 * Base text component that renders the given text and optionally
 * - highlights the given substring pattern (if any)
 * - truncates the text it doesn't fit in the parent container & renders a tooltip with the full non-truncated text
 *   (or an alternative `tooltip` if given)
 */
export const Text: React.FC<ITextProps> = ({
    text,
    highlightPattern = null,
    truncate = true,
    truncatedTooltip = undefined,
    as: Component = 'div',
    ...props
}) => {
    const el = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);
    const { className, style, ...passThroughProps } = props;

    useEffect(() => {
        const currentElement = el.current;
        if (currentElement) {
            setIsTruncated(currentElement.offsetWidth < currentElement.scrollWidth);
        }
    }, [text]);

    // Add highlighting (if any)
    let textComponent;
    textComponent = <HighlightedText text={text} pattern={highlightPattern} />;

    // Truncate (if configured)
    if (!truncate) {
        return (
            <div {...passThroughProps} className={classnames('text', className)} style={style} ref={el}>
                {textComponent}
            </div>
        );
    }
    textComponent = (
        <Component
            {...passThroughProps}
            className={classnames('text', 'text__maybe-truncated', isTruncated && 'text__truncated', className)}
            style={{
                ...(style || {}),
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}
            ref={el}
        >
            {textComponent}
        </Component>
    );

    // Show full non-truncated text in tooltip (unless tooltip is explicitly disabled by setting it to null)
    if (truncatedTooltip === null || !isTruncated) {
        return textComponent;
    }
    return (
        <Tooltip
            content={truncatedTooltip === undefined ? text : truncatedTooltip}
            placement="bottom"
            className="tooltip--flex"
        >
            {textComponent}
        </Tooltip>
    );
};

export default Text;
