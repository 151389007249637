import * as React from 'react';

import { Icon, IconProps } from '../Icon';
import { IProps as IButtonProps, SecondaryButton } from '../Button';
import Tooltip from '../Tooltip';
import { AsPopupComponent } from '../Popup';
import classNames from 'classnames';

import './style.scss';

type _IProps = IconProps & IButtonProps;

interface IIconButtonProps extends _IProps {
    tooltip?: string;
    tooltipDisabled?: string;
    outlined?: boolean;
}

export const IconButton = (props) => {
    let { tooltip, tooltipDisabled, icon, outlined = true, ...buttonProps } = props;
    if (buttonProps.disabled && tooltipDisabled) {
        tooltip = tooltipDisabled;
    }

    const button = (
        <SecondaryButton
            {...buttonProps}
            className={classNames('button--icon', 'icon', outlined && 'button--icon--no-border')}
            label={
                <>
                    <Icon icon={icon} />
                    {buttonProps.label}
                </>
            }
        />
    );

    return tooltip != null ? (
        <Tooltip content={tooltip} placement={'bottom'}>
            {button}
        </Tooltip>
    ) : (
        button
    );
};

export const PopupIconButton = AsPopupComponent(IconButton);
