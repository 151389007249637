import * as React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import sanitize from 'sanitize-html';
import moment from 'moment';
import i18n from 'i18next';

import Frame from '../../../core/components/Frame';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../../App';

import contentStyle from '!!raw-loader!./content.css';

import '../../../core/components/Card/style.scss';
import './style.scss';

const HTML_SANITIZE_OPTIONS = {
    allowedTags: sanitize.defaults.allowedTags.concat(['img', 'style', 'html', 'head', 'meta', 'comment']),
    allowedAttributes: {
        a: ['href', 'name', 'target'],
        p: ['class'],
        img: ['src', 'alt', 'width', 'height', 'id'],
        '*': ['style'],
    },
    allowedSchemes: sanitize.defaults.allowedSchemes.concat(['cid', '<cid']),
    allowedSchemesByTag: false,
    allowVulnerableTags: true,
};

export interface EmailAddress {
    name: string;
    email: string;
}

export interface EmailAttachment {
    cid?: string;
    contentDisposition?: string;
    url?: string;
    type?: string;
    contentType?: string;
    name?: string;
}

export interface Email {
    sender?: EmailAddress;
    receivers: EmailAddress[];
    cc?: EmailAddress[];
    bcc?: EmailAddress[];
    subject: string;
    receivedAt?: Date;
    text?: string;
    html?: string;
    attachments?: EmailAttachment[];
}

export const useEmailBody = (email: Email) => {
    let attachmentsPerCid = Object.fromEntries(
        (email?.attachments || []).filter((a) => !!a.cid).map((a) => [a.cid, a])
    );
    const attachmentsWithoutCid = (email?.attachments || []).filter((a) => !a.cid);

    const isHtml = !!email.html;
    let emailContent =
        (isHtml ? sanitize(email.html, HTML_SANITIZE_OPTIONS) : sanitize(email.text, HTML_SANITIZE_OPTIONS)) || '';
    emailContent = emailContent.replaceAll(/src=['"](.*?)['"]/g, (src, match) => {
        let url = match;
        const cid = `<${match.replace('cid:', '')}>`;

        if (attachmentsPerCid[cid]) {
            url = attachmentsPerCid[cid].url;
            delete attachmentsPerCid[cid];
        }

        return `src="${url}"`;
    });

    const remainingAttachments = Object.values(attachmentsPerCid).concat(attachmentsWithoutCid);

    return {
        content: emailContent,
        attachments: remainingAttachments,
    };
};

const EmailPreview = (props) => {
    const { email, className } = props;
    const { t } = useTranslation('assistance');

    const isDarkMode = !!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY);

    const isHtml = !!email.html;
    const { content: emailContent, attachments: remainingAttachments } = useEmailBody(email);

    moment.locale(i18n.language);
    const date = email.receivedAt && moment(email.receivedAt);

    return (
        <div className={classnames('email', className)}>
            <div className="email__header">
                <div className="email__envelope">
                    <div className="email__subject">{email?.subject}</div>
                    <div className="email__sender">
                        {email?.sender?.name} &lt;{email?.sender?.email}&gt;
                    </div>
                    <div className="email__receiver">
                        {t('originalDataPreview.email.to')}:{' '}
                        {email?.receivers?.map((receiver) => receiver?.email).join(', ')}
                    </div>
                </div>
                <div className="email__meta">
                    <div className="email__date">{date?.format('LLL')}</div>
                    <div className="email__language">{email?.language}</div>
                </div>
            </div>

            <div className="email__body">
                <Frame>
                    <style>{contentStyle}</style>
                    <div
                        className={classnames(
                            'email__html',
                            !isHtml && 'email__html--plain',
                            isDarkMode && 'email__html--dark-mode'
                        )}
                        dangerouslySetInnerHTML={{ __html: emailContent }}
                    />
                </Frame>
            </div>

            {!!remainingAttachments.length && (
                <div className="email__attachments">
                    {remainingAttachments.map((attachment) => {
                        if (!attachment) {
                            console.error('Invalid attachment cannot be displayed.');
                            return;
                        }

                        const [mimeType, mimeSubtype] = attachment?.type?.split('/');
                        const icon = mimeType == 'image' ? 'image' : 'document';

                        return (
                            <a
                                className="email__attachment card card--link"
                                target="_blank"
                                href={attachment.url}
                                key={attachment.url}
                            >
                                <span className={`email__attachment-icon email__attachment-icon--${icon}`} />
                                <span className="email__attachment-name">{attachment.name}</span>
                                <span className="email__attachment-type">{attachment.type}</span>
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default EmailPreview;
