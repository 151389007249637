import * as React from 'react';
import { createContext, useContext } from 'react';

interface NavigationContextProps {
    collapsed?: boolean;
    children: any;
}

const NavigationContext = createContext<Omit<NavigationContextProps, 'children'>>({
    collapsed: false,
});

export const NavigationContextProvider = (props: NavigationContextProps) => {
    const { children, ...value } = props;
    return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export const useNavigationContext = () => {
    const context = useContext(NavigationContext);

    if (!context) {
        throw new Error('useNavigationContext must be used within a NavigationContextProvider');
    }

    return context;
};
