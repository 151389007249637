import * as React from 'react';
import { FieldWrapper } from '../Fields';
import { SimpleJsonInput } from '../../../core/containers/JsonInput';
import { useEffect, useState } from 'react';

const JSONField = ({ label, initialValue, onUpdate, inputProps = {}, ...props }) => {
    // comes in as object, goes out as object

    const [internalValue, setInternalValue] = useState<string>(JSON.stringify(initialValue, null, 2) || '');
    useEffect(() => setInternalValue(JSON.stringify(initialValue, null, 2) || ''), [initialValue]);

    const handleBlur = () => {
        // only update real value when blurred, so we don't try to parse intermediate values
        try {
            onUpdate(JSON.parse(internalValue));
        } catch (error) {
            // reset to initial value
            setInternalValue(JSON.stringify(initialValue, null, 4));
        }
    };

    return (
        <JSONStringField
            label={label}
            initialValue={internalValue}
            onUpdate={setInternalValue}
            inputProps={{ ...inputProps, onBlur: handleBlur }}
            {...props}
        />
    );
};

export const JSONStringField = ({ label, initialValue, onUpdate, inputProps = {}, onBlur = undefined, ...props }) => {
    // comes in as string, goes out as string
    return (
        <FieldWrapper label={label} className="field--vertical" {...props}>
            <SimpleJsonInput
                className="json-field form__input"
                value={initialValue}
                setValue={onUpdate}
                invalidSyntaxError="Invalid syntax"
                {...inputProps}
            />
        </FieldWrapper>
    );
};

export default JSONField;
