import { gql } from '@apollo/client';

export const GET_SUPPORT_USERS = gql`
    query {
        supportUsers {
            id
            email

            customer {
                id
                isActive
                name
            }
        }
    }
`;

export const SWITCH_SUPPORT_USER = gql`
    mutation ($supportUserId: Int, $recordId: UUID, $documentType: String) {
        switchSupportUser(supportUserId: $supportUserId, recordId: $recordId, documentType: $documentType) {
            supportUser {
                id
            }
            success
        }
    }
`;
