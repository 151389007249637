import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StringField, BooleanField, useForm } from '../../../../../assistance/containers/Fields';
import ProgressButton from '../../../../../core/containers/ProgressButton';
import ConfigurationCard from '../../../../../assistance/containers/ConfigurationCard';
import ListField from '../../../../../assistance/containers/ListField';

const DocumentXmlSerializerConfiguration = ({
    config,
    onActivate,
    onDeactivate = undefined,
    onSubmit,
    documentType,
}) => {
    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`${documentType + '.fieldNames'}.${fieldName}`),
    });
    useEffect(() => {
        setFormData({
            enabled: config?.enabled,
            serializerModule: config?.serializerModule || '',

            headerFields: config?.headerFields || [],
            lineItemFields: config?.lineItemFields || [],
            exportExternalDocumentLink: config?.exportExternalDocumentLink || false,
            exportReceivedBy: config?.exportReceivedBy || false,
            exportWorkistUrl: config?.exportWorkistUrl || false,
            overwritePosition: config?.overwritePosition || false,
            useShortExternalFileName: config?.useShortExternalFileName || false,
        });
    }, [config]);

    const handleSubmit = () => onSubmit(formData);
    const { t } = useTranslation('config');

    return (
        <ConfigurationCard
            title={t(documentType + '.title')}
            description={t(documentType + '.description')}
            active={!!config}
            onActivate={onActivate}
            onDeactivate={onDeactivate}
        >
            <div className="form">
                <BooleanField {...getFieldProps('enabled')} className="field--vertical" />
                <StringField {...getFieldProps('serializerModule')} className="field--vertical" />
                <ListField {...getFieldProps('headerFields')} className="field--vertical" />
                <ListField {...getFieldProps('lineItemFields')} className="field--vertical" />
                <BooleanField {...getFieldProps('exportExternalDocumentLink')} className="field--vertical" />
                <BooleanField {...getFieldProps('exportReceivedBy')} className="field--vertical" />
                <BooleanField {...getFieldProps('exportWorkistUrl')} className="field--vertical" />
                <BooleanField {...getFieldProps('overwritePosition')} className="field--vertical" />
                <BooleanField {...getFieldProps('useShortExternalFileName')} className="field--vertical" />

                <div>
                    <ProgressButton label={t('card.save')} onClick={handleSubmit} />
                </div>
            </div>
        </ConfigurationCard>
    );
};
export default DocumentXmlSerializerConfiguration;
