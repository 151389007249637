import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import Input from '../../../core/components/Input';
import Checkbox from '../../../core/components/Checkbox';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';
import Loader from '../../../core/components/Loader';
import ProgressButton from '../../../core/containers/ProgressButton';
import Select from '../../../core/containers/Select';
import MenuItem from '../../../core/components/MenuItem';
import { OVERVIEW_PATH } from '../../constants';
import { GET_COMPANY_DETAILS, UPDATE_CUSTOMER } from '../../queries';
import { COUNTRY_CODES } from '../../../utils/';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const COUNTRY_CODE = 'DE';

const EditForm = (props) => {
    const { customer, onSubmit } = props;
    const { t } = useTranslation('customer');

    // form fields
    const [name, setName] = useState(customer?.name || '');

    // shipping
    const [streetAddress, setStreetAddress] = useState(customer?.streetAddress || '');
    const [postalCode, setPostalCode] = useState(customer?.postalCode || '');
    const [city, setCity] = useState(customer?.city || '');
    const [countryCode, setCountryCode] = useState(customer?.countryCode || COUNTRY_CODE);

    // billing
    const [invoiceEmail, setInvoiceEmail] = useState(customer?.invoiceEmail || '');
    const [billingStreetAddress, setBillingStreetAddress] = useState(customer?.billingStreetAddress || '');
    const [billingPostalCode, setBillingPostalCode] = useState(customer?.billingPostalCode || '');
    const [billingCity, setBillingCity] = useState(customer?.billingCity || '');
    const [billingCountryCode, setBillingCountryCode] = useState(customer?.billingCountryCode || COUNTRY_CODE);

    const [isBillingSameAsShipping, setIsBillingSameAsShipping] = useState(customer?.isBillingSameAsShipping);

    const handleSubmit = () =>
        onSubmit({
            name,
            invoiceEmail,

            streetAddress,
            postalCode,
            city,
            countryCode,

            billingStreetAddress: isBillingSameAsShipping ? '' : billingStreetAddress,
            billingPostalCode: isBillingSameAsShipping ? '' : billingPostalCode,
            billingCity: isBillingSameAsShipping ? '' : billingCity,
            billingCountryCode: isBillingSameAsShipping ? '' : billingCountryCode,
        });

    return (
        <div className={classnames('form')}>
            <div className="form__group">
                <label className="form__label">{t('edit.name')}</label>
                <Input className="form__input" value={name} onChange={(e) => setName(e.target.value)} />
            </div>

            <div className="form__group">
                <label className="form__label">{t('edit.invoiceEmail')}</label>
                <Input className="form__input" value={invoiceEmail} onChange={(e) => setInvoiceEmail(e.target.value)} />
            </div>

            <div className="form__field-group form__field-group--address">
                <div className="form__group">
                    <label className="form__label">{t('edit.streetAddress')}</label>
                    <Input
                        className="form__input"
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                    />
                </div>

                <div className="form__field-group form__field-group--horizontal">
                    <div className="form__group">
                        <label className="form__label">{t('edit.postalCode')}</label>
                        <Input
                            className="form__input"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                        />
                    </div>

                    <div className="form__group">
                        <label className="form__label">{t('edit.city')}</label>
                        <Input className="form__input" value={city} onChange={(e) => setCity(e.target.value)} />
                    </div>

                    <div className="form__group">
                        <label className="form__label">{t('edit.countryCode')}</label>
                        <Select className="form__input" value={countryCode} onChange={(value) => setCountryCode(value)}>
                            {COUNTRY_CODES.map((code) => (
                                <MenuItem key={code} label={code} value={code} />
                            ))}
                        </Select>
                    </div>
                </div>
            </div>

            <div className="form__group">
                <label className="form__label">{t('edit.isBillingSameAsShipping')}</label>
                <Checkbox
                    className="form__input"
                    checked={isBillingSameAsShipping}
                    onChange={(e) => setIsBillingSameAsShipping(e.target.checked)}
                />
            </div>

            {!isBillingSameAsShipping && (
                <div className="form__group form__group--address">
                    <div className="form__group">
                        <label className="form__label">{t('edit.billingStreetAddress')}</label>
                        <Input
                            className="form__input"
                            value={billingStreetAddress}
                            onChange={(e) => setBillingStreetAddress(e.target.value)}
                        />
                    </div>

                    <div className="form__field-group form__field-group--horizontal">
                        <div className="form__group">
                            <label className="form__label">{t('edit.billingPostalCode')}</label>
                            <Input
                                className="form__input"
                                value={billingPostalCode}
                                onChange={(e) => setBillingPostalCode(e.target.value)}
                            />
                        </div>

                        <div className="form__group">
                            <label className="form__label">{t('edit.billingCity')}</label>
                            <Input
                                className="form__input"
                                value={billingCity}
                                onChange={(e) => setBillingCity(e.target.value)}
                            />
                        </div>

                        <div className="form__group">
                            <label className="form__label">{t('edit.billingCountryCode')}</label>
                            <Select
                                className="form__input"
                                value={billingCountryCode}
                                onChange={(value) => setBillingCountryCode(value)}
                            >
                                {COUNTRY_CODES.map((code) => (
                                    <MenuItem key={code} label={code} value={code} />
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
            )}

            <div className="form__group">
                <ProgressButton label={t('edit.submitLabel')} onClick={handleSubmit} />
            </div>
        </div>
    );
};

const Edit = (props) => {
    const { t } = useTranslation('customer');
    const navigate = useNavigate();

    const [updateCustomer, { loading: updateCustomerLoading, error: updateCustomerError }] =
        useMutation(UPDATE_CUSTOMER);

    const { data, error, loading } = useQuery(GET_COMPANY_DETAILS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });

    const customer = data?.viewer?.customer;

    const handleSubmit = (formData) => {
        return updateCustomer({
            variables: {
                input: formData,
            },
        })
            .then(() => {
                navigate(url(OVERVIEW_PATH));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Layout>
            <Page className="edit-customer page--slim">
                <div className="edit-customer__head">
                    <Breadcrumbs>
                        <Breadcrumb label={customer?.name || ''} linkTo={url(OVERVIEW_PATH)} />
                    </Breadcrumbs>
                    <div className="edit-customer__title">{t('edit.title')}</div>
                </div>

                {error && (
                    <Alert severity="error">
                        <AlertTitle>{error?.message}</AlertTitle>
                    </Alert>
                )}

                {updateCustomerError && (
                    <Alert severity="error">
                        <AlertTitle>{updateCustomerError?.message}</AlertTitle>
                    </Alert>
                )}

                {!customer || loading ? <Loader /> : <EditForm customer={customer} onSubmit={handleSubmit} />}
            </Page>
        </Layout>
    );
};

export default Edit;
