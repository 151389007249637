import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import UserForm from '../../containers/UserForm';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';

import { OVERVIEW_PATH } from '../../constants';
import { INVITE_USER } from '../../queries';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const Add = (props) => {
    const { t } = useTranslation('users');
    const navigate = useNavigate();

    const [inviteUser, { loading: inviteUserLoading, error: inviteUserError }] = useMutation(INVITE_USER);

    const handleSubmit = (formData) => {
        return inviteUser({
            variables: {
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                language: formData.language,
                groups: formData.roles,
                teams: formData.teams,
            },
        })
            .then((mutationResult: any) => {
                navigate(url(OVERVIEW_PATH));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Layout>
            <Page className="add-user page--slim">
                <div className="add-user__head">
                    <Breadcrumbs>
                        <Breadcrumb label={t('breadcrumbs.users')} linkTo={url(OVERVIEW_PATH)} />
                    </Breadcrumbs>
                    <div className="add-user__title">{t('add.title')}</div>
                </div>

                {inviteUserError && (
                    <Alert severity="error">
                        <AlertTitle>{inviteUserError?.message}</AlertTitle>
                    </Alert>
                )}

                <UserForm className="add-user__form" submitLabel={t('add.submitLabel')} onSubmit={handleSubmit} />
            </Page>
        </Layout>
    );
};

export default Add;
