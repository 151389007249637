import AssistanceSection from '../AssistanceSection';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface IHeaderDataFieldGroupProps {
    fieldName: string;
    fields: any[];
    children: React.ReactNode;
    controls: React.ReactNode;
    title?: string;
}

export const useHeaderDataFieldGroupProps = ({ fieldName, fields, controls }: IHeaderDataFieldGroupProps) => {
    const { t } = useTranslation('assistance');
    const groupConfigField = fields.find((field) => field.path === fieldName);
    return {
        title: groupConfigField?.config?.fieldLabelName ?? t(`assistance:headerView.fieldNames.${fieldName}`),
        required: groupConfigField
            ? groupConfigField.config?.isRequired
            : fields.every((field) => field.config?.isRequired),
        controls,
    };
};

export const HeaderDataFieldGroup = (props: IHeaderDataFieldGroupProps) => {
    const fieldGroupProps = useHeaderDataFieldGroupProps(props);
    return <AssistanceSection.FieldGroup {...fieldGroupProps}>{props.children}</AssistanceSection.FieldGroup>;
};

export default HeaderDataFieldGroup;
