import { useEffect, useState } from 'react';
import { useControllableState } from '../../core_updated/utils/useControllableState';
import { Option } from '../../core_updated/components/Fields/AutoCompleteField';

const useForceSelection = ({
    options = [],
    defaultValue,
    value: propsValue,
    onValueChange,
    forceSelection = false,
    resetInvalidOnBlur = false, // either reset value or clear it if invalid on blur
    onFocus,
    onBlur,
    onSelectOption,
}: any) => {
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

    const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);
    const [inputValue, setInputValue] = useState<string>(value);
    // remember with what value we started on focus, so we can reset to it if needed
    const [valueWhenFocused, setValueWhenFocused] = useState<string>(value);

    const updateValue = (updateValue: string) => {
        if (forceSelection) {
            // Basically if force selection is active we keep the external value at '' unless valid input is entered
            const option = options.find((option) => option.value === updateValue);
            const optionWhenFocused = options.find((option) => option.value === valueWhenFocused);

            if (!option) {
                setValue(resetInvalidOnBlur ? valueWhenFocused : '');
                onSelectOption?.(resetInvalidOnBlur ? optionWhenFocused : null); // reset potential external listeners
            } else if (option && option.value !== valueWhenFocused) {
                // prevent unnecessary updates by checking if the value actually changed from when we opened the dropdown
                setValue(option.value);
                onSelectOption?.(option);
            }
        } else {
            setValue(updateValue);
        }
    };

    const updateInputValue = (value: string) => {
        setInputValue(value);
    };

    useEffect(() => {
        // only listen to external value changes when the input is not focused
        if (!isInputFocused) {
            updateInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        // only update value while typing when the input is focused
        if (isInputFocused) {
            updateValue(inputValue);
        }
    }, [options.map((option: Option) => option.value).join(',')]);

    const handleFocus = (e) => {
        // store previous value, so we can play around with the external value but still remember its original state
        setIsInputFocused(true);
        setValueWhenFocused(value);
        onFocus?.(e);
    };

    const handleBlur = (e) => {
        setIsInputFocused(false);

        // reset input value to empty string if invalid value (e.g. user typed in a value that is not in the options)
        if (forceSelection && value !== inputValue) updateInputValue(resetInvalidOnBlur ? value : '');

        onBlur?.(e);
    };

    const handleSelectOption = (option: Option) => {
        // on select we know it's always a valid option
        updateInputValue(option.value);
        setValue(option.value);
        setValueWhenFocused(option.value);
        onSelectOption?.(option);
    };

    const handleValueChange = (value: string) => {
        updateInputValue(value);
        updateValue(value);
    };

    return {
        value: inputValue,
        onValueChange: handleValueChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onSelectOption: handleSelectOption,
    };
};

export default useForceSelection;
