import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';
import ProgressButton from '../../../core/containers/ProgressButton';
import Card from '../../../core/components/Card';
import { OVERVIEW_PATH } from '../../constants';
import { GET_PLANS, UPDATE_PLAN_SUBSCRIPTION } from '../../queries';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const PlanCard = (props) => {
    const { t } = useTranslation('customer');
    const { title, pricing, onSubmit } = props;
    return (
        <Card className="plan-card">
            <div className="plan-card__title">{title}</div>
            <div className="plan-card__pricing">{pricing}</div>
            <ProgressButton label={t('plan.buyNow')} onClick={onSubmit} />
        </Card>
    );
};

const Plan = (props) => {
    const { user } = props;

    const { t } = useTranslation('customer');
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(GET_PLANS);
    const [updatePlan] = useMutation(UPDATE_PLAN_SUBSCRIPTION);

    const handleSubmit = (planIdentifier) => {
        return updatePlan({
            variables: {
                input: {
                    customerId: user?.customer?.id,
                    planIdentifier,
                },
            },
        })
            .then((res) => {
                navigate(url('/'));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        if (user?.customer?.plan) {
            navigate(url(OVERVIEW_PATH));
        }
    }, [user]);

    const plans = data?.plans;

    return (
        <Layout>
            <Page className="plan page--slim">
                <div className="page__head">
                    <div className="page__title-group">
                        <Breadcrumbs className="page__breadcrumbs">
                            <Breadcrumb label={t('breadcrumbs.customer')} linkTo={url(OVERVIEW_PATH)} />
                        </Breadcrumbs>
                        <div className="page__title">{t('plan.title')}</div>
                    </div>
                </div>

                {error && (
                    <Alert severity="error">
                        <AlertTitle>{error?.message}</AlertTitle>
                    </Alert>
                )}

                <div className="form">
                    <div className="plan__cards">
                        {plans?.map((plan) => (
                            <PlanCard
                                key={plan.identifier}
                                onSubmit={() => handleSubmit(plan.identifier)}
                                title={plan.name}
                                pricing={t('plan.pricing', { quota: plan.quota, price: plan.price })}
                            />
                        ))}
                    </div>

                    <div className="form__group">
                        <div className="form__note plan__terms">{t('plan.terms')}</div>
                    </div>
                </div>
            </Page>
        </Layout>
    );
};

export default Plan;
