import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import classnames from '../../core_updated/utils/classnames';
import { withIcon } from '../../core_updated/components/Icon';
import { faChevronDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import moment from 'moment';
import DateField, { defaultDateFormat } from '../../core_updated/components/Fields/DateField';
import { FilterDropdownOption } from './Filter';
import { isoDateFormat } from '../../utils/dates';
import Calendar from '../../core_updated/components/Calendar';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../core_updated/components/DropdownMenu';
import Button from '../../core_updated/components/Button';

// we started collecting data around 01.01.2023
export const MIN_DATE = new Date(2023, 0, 1);
const TODAY = new Date();

const ClearIcon = withIcon(faXmark);
const ChevronDownIcon = withIcon(faChevronDown);

interface DateRange {
    from: Date;
    to?: Date;
}

interface DateRangeFilterDropdownProps {
    label?: string;
    options?: FilterDropdownOption[];
    range?: DateRange;
    onRangeChange?: (range: DateRange) => void;
    className?: string;
    dateFormat?: string;
    required?: boolean;
}

const getSelectedOption = (range: DateRange, options: FilterDropdownOption[]) => {
    // values come in like 'DDDD-MM-YYYY,DDDD-MM-YYYY' (start, end)
    const selectedValue =
        range?.to && range?.from
            ? `${moment(range.from).format(isoDateFormat)},${moment(range.to).format(isoDateFormat)}`
            : null;
    return options.find((option) => option.value === selectedValue);
};

const TriggerButton = React.forwardRef(
    ({ label: defaultLabel, range, options, className, dateFormat = defaultDateFormat, ...props }: any, ref: any) => {
        const { t } = useTranslation('analytics');

        const selectedOption = getSelectedOption(range, options);

        // in case no item is selected we show the default label
        let label: string = defaultLabel;
        if (selectedOption) {
            label = selectedOption.label;
        } else if (range?.from && range?.to) {
            if (moment(range.from).format(dateFormat) === moment(range.to).format(dateFormat)) {
                label = `${moment(range.from).format(dateFormat)}`;
            } else {
                label = `${moment(range.from).format(dateFormat)} - ${moment(range.to).format(dateFormat)}`;
            }
        } else if (range?.from && !range?.to) {
            label = t('dashboard.filters.dateRange.since', {
                date: moment(range.from).format(dateFormat),
            });
        } else if (range?.to) {
            label = t('dashboard.filters.dateRange.until', {
                date: moment(range.to).format(dateFormat),
            });
        }

        if (label !== defaultLabel) {
            label = `${defaultLabel} ${label}`;
        }

        return (
            <Button ref={ref} className={classnames('pr-8 ', className)} active={range?.from || range?.to} {...props}>
                <span>{label}</span>
            </Button>
        );
    }
);

const DateRangeForm = ({ range: initialRange, onSubmit, onCancel, dateFormat = defaultDateFormat }) => {
    const { t } = useTranslation('analytics');

    const fromInputRef = useRef(null);
    const toInputRef = useRef(null);

    const [range, setRange] = useState<DateRange>(initialRange ?? { from: null, to: null });

    const handleSubmit = () => {
        onSubmit(range);
    };

    const handleSelect = (range: DateRange) => {
        setRange(range ?? { from: null, to: null });
    };

    useEffect(() => {
        setTimeout(() => {
            // skip the current event loop
            if (range?.from && !range?.to) {
                toInputRef.current?.focus();
            } else {
                fromInputRef.current?.focus();
            }
        }, 0);
    }, [range?.from, range?.to]);

    useEffect(() => {
        setRange(initialRange ?? { from: null, to: null });
    }, [initialRange?.from, initialRange?.to]);

    const maxDate = new Date();
    const minDate = MIN_DATE;

    return (
        <div className="p-5 flex flex-col gap-4 w-[360px] border-l border-primary border-solid">
            <div className="flex gap-4 flex-1">
                <div className="flex flex-col gap-1 flex-1 min-w-0">
                    <label className="text-sm text-secondary">{t('dashboard.filters.dateRange.startDate')}</label>
                    <DateField
                        inputRef={fromInputRef}
                        disableDatePicker
                        placeholder={dateFormat}
                        value={range?.from ? moment(range?.from).format(isoDateFormat) : ''}
                        onValueChange={(value) =>
                            setRange({ ...range, from: value ? moment(value).toDate() : undefined })
                        }
                        format={dateFormat}
                        fromDate={minDate}
                        toDate={range?.to ?? maxDate}
                    />
                </div>

                <div className="flex flex-col gap-1 flex-1 min-w-0">
                    <label className="text-sm text-secondary">{t('dashboard.filters.dateRange.endDate')}</label>
                    <DateField
                        inputRef={toInputRef}
                        disableDatePicker
                        placeholder={dateFormat}
                        value={range?.to ? moment(range?.to).format(isoDateFormat) : ''}
                        onValueChange={(value) =>
                            setRange({ ...range, to: value ? moment(value).toDate() : undefined })
                        }
                        format={dateFormat}
                        fromDate={range?.from ?? minDate}
                        toDate={maxDate}
                    />
                </div>
            </div>

            <div>
                <Calendar
                    mode="range"
                    selected={range ? range : undefined}
                    onSelect={handleSelect}
                    disabled={{
                        after: TODAY,
                        before: MIN_DATE,
                    }}
                    fromYear={minDate.getFullYear()}
                    toYear={maxDate.getFullYear()}
                    defaultMonth={range?.from ?? maxDate}
                />
            </div>

            <div className="flex justify-end gap-2">
                <button
                    className={classnames(
                        'flex gap-2 items-center bg-primary text-primary shadow-sm rounded px-3 py-1.5 font-medium text-sm border border-solid border-secondary outline-none',
                        'hover:bg-secondary transition-colors'
                    )}
                    onClick={onCancel}
                >
                    {t('dashboard.filters.dateRange.cancel')}
                </button>

                <button
                    className="flex items-center justify-center px-3 py-1.5 bg-brand-default rounded text-inverted font-medium text-sm hover:enabled:bg-brand-hover transition-colors duration-200 focus:enabled:bg-brand-focus active:enabled:bg-brand-active disabled:opacity-50"
                    onClick={handleSubmit}
                    // disabled={!allowEmpty && (!range?.from || !range?.to)}
                >
                    {t('dashboard.filters.dateRange.apply')}
                </button>
            </div>
        </div>
    );
};

const DateRangeFilterDropdown = ({
    label: defaultLabel,
    options,
    range: initialRange,
    onRangeChange,
    className,
    dateFormat = defaultDateFormat,
    required,
}: DateRangeFilterDropdownProps) => {
    const { t } = useTranslation('analytics');

    const selectedOption = getSelectedOption(initialRange, options);

    const [isOpen, setIsOpen] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(!selectedOption);

    const handleSubmit = (range) => {
        onRangeChange?.(range);
        setIsOpen(false);
    };

    const handleCancel = () => {
        setIsOpen(false);
    };

    const handleClear = () => {
        onRangeChange?.({ from: null, to: null });
        setIsOpen(false);
    };

    const handleValueChange = (value: string) => {
        if (!value) return; // custom range selected

        const [startDate, endDate] = value.split(',');

        const newRange = {
            from: moment(startDate, isoDateFormat, true).toDate(),
            to: endDate ? moment(endDate, isoDateFormat, true).toDate() : null,
        };
        onRangeChange(newRange);
        setIsOpen(false);
    };

    const handleSelectCustom = (e) => {
        e.preventDefault();
        setIsFormOpen(true);
    };

    useEffect(() => {
        setIsFormOpen(initialRange && (initialRange.from || initialRange.to) && !selectedOption);
    }, [selectedOption, isOpen]);
    return (
        <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
            <div className="relative text-sm">
                <DropdownMenu.Trigger asChild>
                    <TriggerButton
                        label={defaultLabel}
                        range={initialRange}
                        options={options}
                        className={className}
                        dateFormat={dateFormat}
                    />
                </DropdownMenu.Trigger>

                {!required && (initialRange?.from || initialRange?.to) ? (
                    <button
                        onClick={handleClear}
                        className={classnames(
                            'absolute right-0 top-0 transform flex items-center justify-center h-full px-3 text-primary',
                            (initialRange?.from || initialRange?.to || isOpen) && 'text-brand'
                        )}
                    >
                        <ClearIcon />
                    </button>
                ) : (
                    <ChevronDownIcon
                        className={classnames(
                            'absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-primary',
                            (initialRange?.from || initialRange?.to || isOpen) && 'text-brand'
                        )}
                    />
                )}
            </div>
            <DropdownMenu.Portal>
                <DropdownMenu.Content align="start" className="p-0">
                    <div className="flex">
                        <DropdownMenu.RadioGroup
                            value={!isFormOpen ? selectedOption?.value : null}
                            onValueChange={(value) => handleValueChange?.(value)}
                            className="p-1 w-full"
                        >
                            {options?.map((option) => (
                                <DropdownMenu.RadioItem key={option.value} value={option.value}>
                                    {option.label}
                                </DropdownMenu.RadioItem>
                            ))}
                            <DropdownMenu.RadioItem key="custom" value={null} onSelect={handleSelectCustom}>
                                {t('dashboard.filters.dateRange.custom')}
                            </DropdownMenu.RadioItem>
                        </DropdownMenu.RadioGroup>

                        {isFormOpen && (
                            <DateRangeForm
                                range={initialRange}
                                onSubmit={handleSubmit}
                                onCancel={handleCancel}
                                dateFormat={dateFormat}
                            />
                        )}
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu>
    );
};

export default DateRangeFilterDropdown;
