import * as React from 'react';
import { Button, DayPicker, DayProps, useDayRender } from 'react-day-picker';
import { DayPickerProps } from 'react-day-picker';
import { useRef } from 'react';
import classnames from '../utils/classnames';

const Day = (props: DayProps): JSX.Element => {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);

    const classNames = [
        '!rounded-full cursor-pointer !h-8 !w-8 flex items-center justify-center !text-sm transition-all duration-200 !cursor-pointer',
    ];

    // add class names by priority, last ones will override the first ones
    if (dayRender.activeModifiers.today && !dayRender.activeModifiers.selected) {
        classNames.push('!border !border-solid !border-brand !text-brand !bg-brand');
    }
    if (dayRender.activeModifiers.selected) {
        classNames.push('!bg-brand-active hover:!bg-brand-active !text-inverted');
    }
    if (dayRender.activeModifiers.range_start) {
        classNames.push('!bg-brand-active hover:!bg-brand-active text-inverted');
    }
    if (dayRender.activeModifiers.range_end) {
        classNames.push('!bg-brand-active hover:!bg-brand-active text-inverted');
    }
    if (dayRender.activeModifiers.range_middle) {
        classNames.push('!bg-brand !text-brand hover:!bg-brand');
    }
    if (dayRender.activeModifiers.disabled) {
        classNames.push('opacity-50 pointer-events-none');
    }
    if (
        !dayRender.activeModifiers.range_start &&
        !dayRender.activeModifiers.range_end &&
        !dayRender.activeModifiers.range_middle
    ) {
        classNames.push('hover:bg-secondary');
    }

    if (dayRender.isHidden) {
        return <div role="gridcell"></div>;
    }
    if (!dayRender.isButton) {
        return <div {...dayRender.divProps} className={classnames(...classNames, dayRender.divProps.className)} />;
    }
    return (
        <Button
            name="day"
            ref={buttonRef}
            {...dayRender.buttonProps}
            className={classnames(...classNames, dayRender.buttonProps.className)}
        />
    );
};

const Calendar = (props: DayPickerProps) => {
    // This is mainly, so we can have a consistent style for the calendar across all components.
    return (
        <DayPicker
            captionLayout="dropdown-buttons"
            fromYear={2020}
            toYear={2030}
            initialFocus={false}
            weekStartsOn={1}
            showWeekNumber
            classNames={{
                root: 'relative',

                caption: 'relative h-8 flex items-center',
                caption_label: 'text-primary text-sm font-medium flex gap-1 items-center px-3 py-1',
                caption_dropdowns: 'absolute flex left-1/2 transform -translate-x-1/2 z-10',
                dropdown_month: 'rounded cursor-pointer hover:bg-secondary transition-colors relative',
                dropdown_year: 'rounded cursor-pointer hover:bg-secondary transition-colors relative',

                nav: 'w-full flex justify-between',
                button: 'rounded cursor-pointer hover:bg-secondary transition-colors',
                nav_button:
                    'rounded cursor-pointer hover:bg-secondary h-8 w-8 flex items-center justify-center opacity-50 hover:opacity-100 transition-all cursor-pointer',
                nav_icon: 'h-3 w-3',

                head_cell: 'text-tertiary text-sm h-8 w-8 font-medium align-middle',
                weeknumber:
                    'bg-secondary text-tertiary h-10 -mt-1 -mb-1 w-8 flex items-center justify-center text-sm group-first:rounded-t group-last:rounded-b font-medium',

                cell: 'align-middle p-1',
                row: 'group',
            }}
            components={{
                Day,
            }}
            {...props}
        />
    );
};

export default Calendar;
