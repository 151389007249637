import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

export interface IProps {
    name?: string;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    onChange?: any;
    onClick?: any;
    value?: string;
    required?: boolean;
    readOnly?: boolean;
    // TODO: maybe inherit from HTMLTextAreaProps
}

const TextArea = React.forwardRef((props: IProps & React.HTMLProps<HTMLTextAreaElement>, ref: any) => {
    const { name, placeholder, className, disabled, onChange, onClick, value, required, readOnly, ...textareaProps } =
        props;

    return (
        <textarea
            ref={ref}
            className={classnames('textarea', className)}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onClick={onClick}
            disabled={disabled}
            value={value}
            required={required}
            readOnly={readOnly}
            rows={4}
            {...textareaProps}
        />
    );
});

TextArea.defaultProps = {
    disabled: false,
    placeholder: '',
    value: '',
    required: false,
    readOnly: false,
};

export default TextArea;
