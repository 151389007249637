import * as React from 'react';
import classnames from 'classnames';

import Avatar from '../../../core/components/Avatar';

import './style.scss';
import { useTranslation } from 'react-i18next';

interface IProps {
    id: string;
    viewerId?: string;
    imageUrl?: string;
    firstName: string;
    lastName: string;
    email: string;
    roles?: string[];
    teams?: string[];
    className?: string;
    buttons?: any;
    onClick?: any;
}

const UserListItem = (props: IProps) => {
    const {
        id,
        viewerId,
        imageUrl,
        firstName,
        lastName,
        email,
        roles = [],
        teams = [],
        buttons,
        className,
        onClick,
    } = props;

    const { t } = useTranslation('users');

    const handleClick = () => {
        if (onClick) onClick();
    };

    return (
        <div className={classnames('user-list-item', className)} onClick={handleClick}>
            <div className="user-list-item__section user-list-item__section--avatar">
                <Avatar imageUrl={imageUrl} size={42} fallbackText={firstName[0]} />
            </div>
            <div className="user-list-item__section user-list-item__section--details">
                <div className="user-list-item__name">
                    {firstName} {lastName}
                    {id == viewerId && <span className="user-list-item__badge">{t('userListItem.you')}</span>}
                </div>
                <div className="user-list-item__email">
                    <a href={`mailto:${email}`}>{email}</a>
                </div>
            </div>
            <div className="user-list-item__section user-list-item__section--groups">
                {!!roles.length && <div className="user-list-item__roles">{roles.join(', ')}</div>}
                {!!teams.length && <div className="user-list-item__teams">{teams.join(', ')}</div>}
            </div>
            {buttons && <div className="user-list-item__section user-list-item__section--buttons">{buttons}</div>}
        </div>
    );
};

export default UserListItem;
