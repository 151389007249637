import * as React from 'react';
import * as Toast from './modules/core/components/Toast';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime';
import { merge } from 'lodash';
import { detect } from 'detect-browser';
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ApolloProvider } from '@apollo/client/react';
import * as Sentry from '@sentry/react';

import Loader from './modules/core/components/Loader';
import Page from './modules/core/components/Page';
import App from './App';
import { modules } from './modules/core/utils/modules';
import { initAppInsights } from './modules/core/analytics/applicationInsights';
import { client } from './modules/core/utils/authentication';

import analytics from './modules/analytics';
import login from './modules/login';
import invoices from './modules/invoices';
import orders from './modules/orders';
import orderConfirmations from './modules/order_confirmations';
import rfqs from './modules/rfqs';
import propertyBills from './modules/db_immo';
import delivery_notes from './modules/delivery_notes';
import universal from './modules/universal';
import list_of_services from './modules/list_of_services';
import teams from './modules/teams';
import users from './modules/users';
import customer from './modules/customer';
import assistance from './modules/assistance';
import onboarding from './modules/onboarding';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

// Initialize all modules: register routes, localizations, etc
modules.populate([
    // Documents
    invoices,
    orders,
    orderConfirmations,
    rfqs,
    delivery_notes,
    propertyBills, // customer-specific (DBImmo)
    universal,
    list_of_services,
    // Shared
    analytics,
    assistance,
    onboarding,
    // Account management
    login,
    teams,
    users,
    customer,
]);

const legacyLocaleResources = require('i18next-resource-store-loader!../locales/index.js');
const localeResources = merge(legacyLocaleResources, modules.getLocales());

void i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'de',
        supportedLngs: ['de', 'en', 'pl', 'ja'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        load: 'languageOnly',
        returnEmptyString: true,
        resources: localeResources,
    });

const browser = detect();

let browserSupported = false;
// handle the case where we don't detect the browser
switch (browser && browser.name) {
    case 'edge-chromium':
        browserSupported = true;
        break;
    case 'edge':
        browserSupported = true;
        break;
    case 'chrome':
        browserSupported = true;
        break;
    case 'firefox':
        browserSupported = true;
        break;
    case 'safari':
        browserSupported = true;
        break;
}

const InitializedApp = initAppInsights(({ browserName }) => {
    // only temporarily to figure out which lib to use
    useEffect(() => {
        // copied from https://developers.heap.io/docs/web
        window.heap = window.heap || [];
        window.heap.load = function (e, t = undefined) {
            window.heap.appid = e;
            window.heap.config = t = t || {};
            let r = document.createElement('script');
            r.type = 'text/javascript';
            r.async = true;
            r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js';
            let a = document.getElementsByTagName('script')[0];
            a.parentNode.insertBefore(r, a);
            let n = function (e) {
                return function () {
                    window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                };
            };
            let p = [
                'addEventProperties',
                'addUserProperties',
                'clearEventProperties',
                'identify',
                'resetIdentity',
                'removeEventProperty',
                'setEventProperties',
                'track',
                'unsetEventProperty',
            ];
            for (let o = 0; o < p.length; o++) window.heap[p[o]] = n(p[o]);
        };

        if (process.env.HEAP_APP_ID) {
            window.heap.load(process.env.HEAP_APP_ID);
        }
    }, []);

    return (
        <PostHogProvider
            apiKey={process.env.POSTHOG_KEY}
            options={{
                api_host: process.env.POSTHOG_HOST,
                persistence: 'memory',
                disable_persistence: true,
            }}
        >
            <ApolloProvider client={client}>
                <App browserName={browserName} />
            </ApolloProvider>
        </PostHogProvider>
    );
});

if (browserSupported) {
    if (process.env.RUN_ENV != 'development') {
        Sentry.init({
            dsn: 'https://cdeb31cc54d4ece8d3d688e4231c4c98@sentry.workist.com/7',
            environment: process.env.RUN_ENV,
        });
    }

    const root = createRoot(document.getElementById('root'));
    const modalRoot = document.getElementById('modal-root');

    root.render(
        <React.Suspense
            fallback={
                <Page className="page--slim">
                    <Loader />
                </Page>
            }
        >
            <Toast.Provider>
                <InitializedApp browserName={browser?.name} />
                <Toast.Viewport />
            </Toast.Provider>
        </React.Suspense>
    );
} else {
    document.write(
        '<div>Your browser is unfortunately currently not supported. Please download a current version of Chrome, Firefox, Edge or Safari.</div>'
    );
}
