import * as React from 'react';

// TODO: re-activate FontAwesome Pro license & install icon library so we can get remove this file again
export const faDatabaseOutlined = ({ width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 12} height={height ?? 12} viewBox="0 0 448 512">
            <path d="M224 512C100.3 512 0 476.2 0 432V80C0 35.82 100.3 0 224 0C347.7 0 448 35.82 448 80V432C448 476.2 347.7 512 224 512zM400 86.03L399.4 85.54C394.1 81.03 383.5 74.8 366.2 68.64C332.1 56.45 281.8 48 224 48C166.2 48 115.9 56.45 81.75 68.64C64.49 74.8 53.91 81.03 48.57 85.54L48 86.03V174.7C61.28 181.1 79.64 188.9 102.8 194.6C136.7 202.1 178.5 208 224 208C269.5 208 311.3 202.1 345.2 194.6C368.4 188.9 386.7 181.1 400 174.7L400 86.03zM400 227.6C386.7 232.9 372.1 237.4 356.7 241.2C318.5 250.6 272.8 256 224 256C175.2 256 129.5 250.6 91.31 241.2C75.93 237.4 61.32 232.9 48 227.6V302.7C61.28 309.1 79.64 316.9 102.8 322.6C136.7 330.1 178.5 336 224 336C269.5 336 311.3 330.1 345.2 322.6C368.4 316.9 386.7 309.1 400 302.7V227.6zM48.57 426.5C53.91 430.1 64.48 437.2 81.75 443.4C115.9 455.5 166.2 464 223.1 464C281.8 464 332.1 455.5 366.2 443.4C383.5 437.2 394.1 430.1 399.4 426.5L400 425.1V355.6C386.7 360.9 372.1 365.4 356.7 369.2C318.5 378.6 272.8 384 223.1 384C175.2 384 129.5 378.6 91.31 369.2C75.93 365.4 61.32 360.9 47.1 355.6V425.1L48.57 426.5zM402 423.9L401.9 424.1C402 423.1 402.1 423.9 402.1 423.9L402 423.9zM46.1 424.1C46 423.1 45.95 423.9 45.94 423.9L46.1 424.1zM46.1 87.9C45.98 88.04 45.94 88.11 45.94 88.11L46.1 87.9zM402.1 88.11C402.1 88.11 402 88.04 401.9 87.9z" />
        </svg>
    );
};
