import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

export interface IProps {
    label?: any;
    className?: string;
    disabled?: boolean;
    onClick?: any;
    linkTo?: string;
    linkExternal?: boolean;
    type?: any;
}

const Button = React.forwardRef((props: IProps, ref: any) => {
    const {
        label,
        className,
        disabled,
        onClick,
        linkTo,
        linkExternal = false,
        type = 'button',
        ...buttonProps
    } = props;
    const handleClick = (e, ...props) => {
        if (!linkTo && type !== 'submit' && onClick) e.preventDefault();
        onClick?.(e, ...props);
    };

    if (linkTo && linkExternal) {
        return (
            <a
                className={classnames('button', linkTo && disabled && 'button--disabled', className)}
                onClick={handleClick}
                href={linkTo}
                target={'_blank'}
            >
                {label}
            </a>
        );
    }

    const Component = linkTo ? Link : 'button';
    return (
        <Component
            className={classnames('button', linkTo && disabled && 'button--disabled', className)}
            onClick={handleClick}
            disabled={disabled}
            to={linkTo}
            type={type}
            {...buttonProps}
            ref={ref}
        >
            {label}
        </Component>
    );
});

Button.defaultProps = {
    disabled: false,
};

export const SecondaryButton = React.forwardRef((props: IProps, ref: any) => {
    const classNames = classnames('button', 'button--secondary', props.className);
    return <Button {...props} ref={ref} className={classNames} />;
});

export default Button;
