import { useEffect } from 'react';

export interface ReselectEvent extends CustomEvent {
    detail: {
        bbox: number[][];
        pageIndex: number;
        itemIndex?: number;
    };
}

const useReselectEventListener = (ref: any, callback: (event: ReselectEvent) => void) => {
    useEffect(() => {
        const listener = (event: ReselectEvent) => callback(event);

        ref.current?.addEventListener('reselect', listener);
        return () => {
            ref.current?.removeEventListener('reselect', listener);
        };
    }, [callback, ref]);
};

export default useReselectEventListener;
