import { useState } from 'react';
import { DARK_MODE_LOCAL_STORAGE_KEY } from '../../../App';

const useDarkMode = () => {
    const [active, setActive] = useState(!!localStorage.getItem(DARK_MODE_LOCAL_STORAGE_KEY));

    const toggle = () => {
        if (active) {
            deactivate();
        } else {
            activate();
        }
    };

    const activate = () => {
        document.body.classList.add('dark');
        localStorage.setItem(DARK_MODE_LOCAL_STORAGE_KEY, '1');
        setActive(true);
    };

    const deactivate = () => {
        document.body.classList.remove('dark');
        localStorage.removeItem(DARK_MODE_LOCAL_STORAGE_KEY);
        setActive(false);
    };

    return { isActive: active, toggle, activate, deactivate };
};

export default useDarkMode;
