import type { IUser } from './interfaces';
import type { IGroup } from '../teams/interfaces';

// NOTE: do not export these functions, they are only used in this file
//  Instead add a permission check function that uses these functions

const isCustomerPowerUser = (user: IUser) => {
    return user.groups.some((group: IGroup) => group.name === 'Customer Power User');
};

const isITUser = (user: IUser) => {
    return user.groups.some((group: IGroup) => group.name === 'Customer IT');
};
const isSupportUser = (user: IUser) => {
    return user.groups.some((group: IGroup) => group.name === 'Support');
};

const isStaffUser = (user: IUser) => {
    return user.isStaff;
};

const isDemoUser = (user: IUser) => {
    return user?.customer?.shortName === 'workist_demo' || user?.customer?.shortName === 'workist_demo_eng';
};

// We currently don't define permissions properly for roles in the backend.
// So instead we define check functions here based on what group the user is in

export const canManageCustomer = (user: IUser) => {
    return isCustomerPowerUser(user) || isSupportUser(user) || isStaffUser(user);
};

export const canManageIntegrations = (user: IUser) => {
    return isITUser(user) || isSupportUser(user) || isStaffUser(user);
};

export const canManageUsers = (user: IUser) => {
    return isCustomerPowerUser(user) || isSupportUser(user) || isStaffUser(user);
};

export const canManageChannels = (user: IUser) => {
    return isCustomerPowerUser(user) || isSupportUser(user) || isStaffUser(user);
};

export const canManageAllDocumentTypes = (user: IUser) => {
    return isSupportUser(user) || isStaffUser(user);
};

export const canSeeTestingDocuments = (user: IUser) => {
    return isSupportUser(user) || isStaffUser(user);
};

export const canSeeDeletedDocuments = (user: IUser) => {
    return isSupportUser(user) || isStaffUser(user);
};

export const canSeeAnalytics = (user: IUser) => {
    return isSupportUser(user) || isStaffUser(user) || isCustomerPowerUser(user);
};

export const canDownloadAnalytics = (user: IUser) => {
    return isSupportUser(user) || isStaffUser(user) || isCustomerPowerUser(user);
};

export const canUseSupportMode = (user) => isStaffUser(user) || isSupportUser(user);

export const canUseDemoMode = (user) => isDemoUser(user);
