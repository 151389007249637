import * as React from 'react';

import { RFQ_EXTRACT_TEXT } from '../../queries';
import {
    extractText,
    fieldConfigToLegacyConfigFormat,
    fieldToFormFieldConfig,
} from '../../../generic_document/pages/Assistance/utils';
import { merge } from 'lodash';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';

export const documentTypeName = 'rfq';

const fieldNameToGroupTypeMap = {
    customer: 'CustomerField',
    deliveryAddress: 'AddressField',
    invoiceAddress: 'AddressField',
    customerContact: 'ContactField',
};

export const getHeaderFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    headerFields,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const configs = {};

    fieldConfigToLegacyConfigFormat(fieldConfigs, configs, client, recordId, documentConfig, fieldNameToGroupTypeMap);

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        customer: {
            groupFieldProps: {
                customerNumber: {
                    hidden: false,
                },
            },
        },
        invoiceAddress: {
            groupFieldProps: {
                addressId: {},
                addressId2: {
                    required: false,
                    hidden: !headerFields.includes('invoiceAddress.addressId2'),
                },
            },
            groupInputProps: {
                misc: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                email: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                phone: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
            },
        },
        deliveryAddress: {
            groupFieldProps: {
                addressId: {},
                addressId2: {
                    // We currently use this catch-all field to differentiate between pickup & delivery addresses
                    // If this becomes a more common use case, we should use a dedicated field instead
                    required: false,
                    hidden: !headerFields.includes('deliveryAddress.addressId2'),
                },
            },
            groupInputProps: {
                misc: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                email: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                phone: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
            },
        },
        customerContact: {
            groupFieldProps: {
                contactId: {},
                email: {
                    hidden: !headerFields.includes('customerContact.email'),
                },
                phone: {
                    hidden: !headerFields.includes('customerContact.phone'),
                },
            },
        },
        dispatchContact: {
            groupFieldProps: {
                contactId: {
                    hidden: true,
                },
                email: {
                    hidden: !headerFields.includes('dispatchContact.email'),
                },
                phone: {
                    hidden: !headerFields.includes('dispatchContact.phone'),
                },
            },
        },
        frameworkContractNumber: {},
    };
    return merge(legacyConfig, configs);
};

export const getLineItemFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const { handlers, document, documentConfiguration } = useAssistanceContext();

    const configs = {};
    if (fieldConfigs) {
        fieldConfigs.forEach((field) => {
            configs[field.name] = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
        });
    }

    const interceptedOnReselect = ({ fieldName, bbox, pageIndex, itemIndex }) => {
        // This method extracts text within the selected bbox and triggers a simulated onChange event on
        // the AutocompleteField. This way we won't trigger onReselect with an invalid value.
        return extractText(client, recordId, bbox, pageIndex, RFQ_EXTRACT_TEXT, documentTypeName).then((text) => {
            const fieldNode: any = document.querySelector(`.item-${itemIndex} .field--${fieldName} input`);
            if (!fieldNode) return;

            fieldNode?.focus();

            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                'value'
            ).set;
            nativeInputValueSetter.call(fieldNode, text);

            const inputEvent = new Event('input', { bubbles: true });
            fieldNode.dispatchEvent(inputEvent);
        });
    };

    const legacyConfig = {
        articleNumber: {
            onReselect: interceptedOnReselect,
        },
        articleNumber2: {
            onReselect: interceptedOnReselect,
        },
        articleNumber3: {
            onReselect: interceptedOnReselect,
        },
        articlePartitionSpecificNumber: {
            onReselect: interceptedOnReselect,
        },
        customer: {
            onReselect: interceptedOnReselect,
            valueKey: 'customerNumber',
        },
        quantity: {
            fieldType: 'FloatField',
        },
        convertedQuantity: {
            fieldType: 'FloatField',
        },
        unitPrice: {
            fieldType: 'FloatField',
        },
        totalPrice: {
            fieldType: 'FloatField',
        },
    };

    return merge(legacyConfig, configs);
};
