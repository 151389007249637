import * as React from 'react';
import { useState } from 'react';
import classnames from '../../../../core_updated/utils/classnames';
import { withIcon } from '../../../../core_updated/components/Icon';
import { faArrowDownShortWide, faArrowUpWideShort, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../../core_updated/components/DropdownMenu';
import Button from '../../../../core_updated/components/Button';

const AscendingIcon = withIcon(faArrowDownShortWide);
const DescendingIcon = withIcon(faArrowUpWideShort);
const ChevronDownIcon = withIcon(faChevronDown);

interface OrderByDropdownOption {
    value: string;
    label: string;
    isDate?: boolean;
}

interface OrderByDropdownProps {
    label?: string;
    options?: OrderByDropdownOption[];
    selected?: string;
    onSelectedChange?: (selected: string) => void;
    className?: string;
}

const isDescending = (value: string) => value.startsWith('-');
const removeDirection = (value: string) => value.replace(/^-/, '');

const OrderByDropdown = ({
    label: defaultLabel,
    options,
    selected,
    onSelectedChange,
    className,
}: OrderByDropdownProps) => {
    const { t } = useTranslation('assistance');

    if (!selected) {
        selected = '';
    }

    const direction: 'asc' | 'desc' = isDescending(selected) ? 'desc' : 'asc';
    const selectedOption = options?.find((option) => option.value === removeDirection(selected));

    const [isOpen, setIsOpen] = useState(false);

    const handleDirectionChange = (direction: 'asc' | 'desc') => {
        const newSelected = direction === 'asc' ? selectedOption?.value : `-${selectedOption?.value}`;
        onSelectedChange(newSelected);
    };

    const handleValueChange = (value: string) => {
        onSelectedChange(value);
    };

    return (
        <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
            <div className={classnames('relative text-sm', className)}>
                <DropdownMenu.Trigger asChild>
                    <Button className="pr-8 border-none shadow-none flex gap-2 items-center">
                        {direction === 'asc' ? <AscendingIcon /> : <DescendingIcon />}
                        {selectedOption?.label || defaultLabel}
                    </Button>
                </DropdownMenu.Trigger>

                <ChevronDownIcon
                    className={classnames(
                        'absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none',
                        isOpen && 'text-brand'
                    )}
                />
            </div>
            <DropdownMenu.Portal>
                <DropdownMenu.Content align="end" className="p-0">
                    <div className="flex flex-col">
                        <div className="flex p-1 gap-1 border-b border-primary border-solid">
                            <button
                                className={classnames(
                                    'text-sm text-primary p-1 rounded px-3 py-1.5 flex gap-2 items-center justify-center w-40',
                                    direction === 'asc' && 'text-brand bg-brand'
                                )}
                                onClick={() => handleDirectionChange('asc')}
                            >
                                <AscendingIcon />{' '}
                                {selectedOption?.isDate
                                    ? t('overview.filters.orderBy.options.ascendingDate')
                                    : t('overview.filters.orderBy.options.ascending')}
                            </button>
                            <button
                                className={classnames(
                                    'text-sm text-primary p-1 rounded px-3 py-1.5 flex gap-2 items-center justify-center w-40',
                                    direction === 'desc' && 'text-brand bg-brand'
                                )}
                                onClick={() => handleDirectionChange('desc')}
                            >
                                <DescendingIcon />{' '}
                                {selectedOption?.isDate
                                    ? t('overview.filters.orderBy.options.descendingDate')
                                    : t('overview.filters.orderBy.options.descending')}
                            </button>
                        </div>
                        <DropdownMenu.RadioGroup
                            value={selectedOption?.value}
                            onValueChange={(value) => handleValueChange?.(value)}
                            className="p-1"
                        >
                            {options?.map((option) => (
                                <DropdownMenu.RadioItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={(e) => e.preventDefault()}
                                >
                                    {option.label}
                                </DropdownMenu.RadioItem>
                            ))}
                        </DropdownMenu.RadioGroup>
                    </div>
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu>
    );
};

export default OrderByDropdown;
