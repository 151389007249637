import DocumentConfiguration, { CONFIG_OPTIONS, DEFAULT_CONFIGS } from '../../../generic_document/pages/Configuration';
import * as DocumentQueries from './queries';
import { GET_OVERVIEW_DATA } from '../../queries';
import { CHANNEL_PATH, OVERVIEW_PATH } from '../../constants';
import React from 'react';
import { DocumentType } from '../../../generic_document/constants';
import { UPDATE_FIELDS_CONFIG } from './queries';

const Configuration = (props) => {
    const configOptions = {
        [CONFIG_OPTIONS.Document]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Document],
        [CONFIG_OPTIONS.Email]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Email],
        [CONFIG_OPTIONS.MasterData]: DEFAULT_CONFIGS[CONFIG_OPTIONS.MasterData],
        [CONFIG_OPTIONS.Discard]: DEFAULT_CONFIGS[CONFIG_OPTIONS.Discard],
    };

    const documentConfiguration = {
        service: 'db_immo',
        documentType: DocumentType.PropertyBill,
        documentTypeName: 'propertyBill',
        activeTab: 'property-bill',
        documentQueries: DocumentQueries,
        getOverviewData: GET_OVERVIEW_DATA,
        channelPath: CHANNEL_PATH,
        overviewPath: OVERVIEW_PATH,
        configOptions: configOptions,
        itemTypes: ['objekte', 'spitzabrechnungen', 'abschlagszahlungen'],
        updateMutation: UPDATE_FIELDS_CONFIG,
    };

    return <DocumentConfiguration documentConfiguration={documentConfiguration} {...props} />;
};

export default Configuration;
