import * as React from 'react';
import { IBaseModalProps } from '../../components/Modal';
import ConfirmModal from '../ConfirmModal';

import { SecondaryButton } from '../../components/Button';
import { useTranslation } from 'react-i18next';

import ProgressButton from '../ProgressButton';

import './style.scss';
import classnames from '../../../core_updated/utils/classnames';

export interface IProps extends IBaseModalProps {
    onSubmit: any;
    onCancel: any;
    readOnly?: boolean;
}

const EditFormModal = (props: IProps) => {
    const { className, children, onSubmit, onCancel, readOnly, ...modalProps } = props;

    const { t } = useTranslation();
    const buttons = readOnly
        ? [
              <SecondaryButton
                  key="button-confirm"
                  label={t('core:components.confirmModal.close')}
                  onClick={() => onCancel()}
              />,
          ]
        : [
              <SecondaryButton
                  key="button-reject"
                  className="button--danger"
                  label={t('assistance:reset')}
                  onClick={() => onCancel()}
              />,
              <ProgressButton
                  key="button-confirm"
                  label={t('core:components.confirmModal.ok')}
                  onClick={() => onSubmit()}
              />,
          ];

    return (
        <ConfirmModal
            className={classnames('edit-form-modal', className)}
            onConfirm={onSubmit}
            onCancel={onCancel}
            buttons={buttons}
            {...modalProps}
        >
            {children}
        </ConfirmModal>
    );
};

export default EditFormModal;
