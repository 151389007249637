import { OVERVIEW_PATH as INVOICE_OVERVIEW_PATH } from '../invoices/constants';
import { OVERVIEW_PATH as ORDER_OVERVIEW_PATH } from '../orders/constants';
import { OVERVIEW_PATH as ORDER_CONFIRMATION_OVERVIEW_PATH } from '../order_confirmations/constants';
import { OVERVIEW_PATH as RFQ_OVERVIEW_PATH } from '../rfqs/constants';
import { OVERVIEW_PATH as PROPERTY_BILL_OVERVIEW_PATH } from '../db_immo/constants';
import { OVERVIEW_PATH as DELIVERY_NOTE_OVERVIEW_PATH } from '../delivery_notes/constants';
import { OVERVIEW_PATH as UNIVERSAL_OVERVIEW_PATH } from '../universal/constants';
import { OVERVIEW_PATH as LIST_OF_SERVICES_OVERVIEW_PATH } from '../list_of_services/constants';
import { capitalizeFirstLetter } from '../utils/string';
import { camelCase } from 'lodash';

export enum DocumentType {
    Order,
    OrderConfirmation,
    Invoice,
    Rfq,
    PropertyBill,
    DeliveryNote,
    Universal,
    ListOfServices,
}

export const OVERVIEW_PATHS = {
    [DocumentType.Order]: ORDER_OVERVIEW_PATH,
    [DocumentType.OrderConfirmation]: ORDER_CONFIRMATION_OVERVIEW_PATH,
    [DocumentType.Invoice]: INVOICE_OVERVIEW_PATH,
    [DocumentType.Rfq]: RFQ_OVERVIEW_PATH,
    [DocumentType.PropertyBill]: PROPERTY_BILL_OVERVIEW_PATH,
    [DocumentType.DeliveryNote]: DELIVERY_NOTE_OVERVIEW_PATH,
    [DocumentType.Universal]: UNIVERSAL_OVERVIEW_PATH,
    [DocumentType.ListOfServices]: LIST_OF_SERVICES_OVERVIEW_PATH,
};

export const getDocumentType = (document?: any): DocumentType =>
    DocumentType[capitalizeFirstLetter(camelCase(document.__typename).replace('_', '')) as keyof typeof DocumentType];

export enum LabelingStatus {
    NotEligible,
    Eligible,
    Unknown,
}

export const getLabelingStatus = (record: any): LabelingStatus =>
    LabelingStatus[
        capitalizeFirstLetter(camelCase(record.labelingStatus).replace('_', '')) as keyof typeof LabelingStatus
    ];
