import * as React from 'react';
import classnames from 'classnames';
import { isInstanceOfEmailResult, isInstanceOfFile } from '../../types';
import EmailPreview from '../EmailPreview';
import DataViewer from '../DataViewer';

import './style.scss';

const InputDataPreview = ({ data, className = '' }) => {
    if (data === undefined) {
        return (
            <DataViewer data={data} className={classnames('input-data-preview input-data-preview--raw', className)} />
        );
    } else if (isInstanceOfEmailResult(data)) {
        return (
            <EmailPreview
                email={data}
                className={classnames('input-data-preview input-data-preview--email', className)}
            />
        );
    } else if (isInstanceOfFile(data)) {
        const [mimeType, mimeSubtype] = data?.type ? data.type.split('/') : [undefined, undefined];

        if (mimeType == 'image') {
            return (
                <img className={classnames('input-data-preview input-data-preview--image', className)} src={data.url} />
            );
        } else if (mimeType == 'application' && mimeSubtype === 'pdf') {
            return (
                <object
                    data={data.url}
                    type="application/pdf"
                    className={classnames('input-data-preview input-data-preview--pdf', className)}
                >
                    <iframe src={data.url} />
                </object>
            );
        } else {
            return (
                <DataViewer
                    data={data}
                    className={classnames('input-data-preview input-data-preview--raw', className)}
                />
            );
        }
    } else {
        return (
            <DataViewer data={data} className={classnames('input-data-preview input-data-preview--raw', className)} />
        );
    }
};

export default InputDataPreview;
