import * as React from 'react';
import classnames from '../utils/classnames';

const WINDOW = 3;

// end is excluded
const getRange = (start, end) => {
    return Array.from({ length: end - start }, (_, i) => i + start);
};

interface IProps {
    className?: string;
    onChange?: (page: number) => void;
    numItems: number;
    itemsPerPage: number;
    activePage?: number;
}

const Pagination = ({ className, onChange, numItems = 0, itemsPerPage = 10, activePage = 0 }: IProps) => {
    const handleClick = (page: number) => onChange?.(page);

    // prevent Nan
    const numPages = itemsPerPage ? Math.ceil(numItems / itemsPerPage) : 0;
    let pageGroups = [];

    // calculate valid start and endpoints around the activePage
    // (minStart ... WINDOW activePage WINDOW ... numPages)
    let start = activePage - WINDOW;
    let end = activePage + WINDOW + 1;

    if (start < 0) {
        end = end - start;
        start = 0;
    }

    if (end > numPages) {
        end = numPages;
        start = Math.max(end - WINDOW * 2, 1) - 1;
    }

    // add groups like [[1, 2, 3, 4], [100]] so that start and end is always reachable
    // as well as the window around the activePAge
    if (start > 0) pageGroups.push([0]);
    pageGroups.push(getRange(start, end));
    if (end < numPages) pageGroups.push([numPages - 1]);

    // draw all that
    return (
        <div className={classnames('flex gap-2', className)}>
            {pageGroups.flatMap((group, i) => {
                const result = group.map((page: number) => {
                    return (
                        <a
                            key={page}
                            className={classnames(
                                'bg-primary text-primary shadow-sm rounded px-3 py-1.5 font-medium text-sm border border-solid border-secondary outline-none',
                                'hover:bg-secondary hover:text-primary cursor-pointer transition-colors',
                                page == activePage && '!bg-brand !text-brand !border-brand'
                            )}
                            onClick={() => handleClick(page)}
                        >
                            {page + 1}
                        </a>
                    );
                });

                // make sure dots are only shown when a number is left out -> so we check:
                // 1. this is not the last group
                // 2. the last element of current group is not the direct neighbour of the first number of the next group
                if (i < pageGroups.length - 1 && group[group.length - 1] != pageGroups[i + 1][0] - 1) {
                    result.push(<span className="px-1 py-1.5 text-tertiary">...</span>);
                }

                return result;
            })}
        </div>
    );
};

Pagination.displayName = 'Pagination';

export default Pagination;
