import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

interface IProps {
    size: number;
    imageUrl?: string;
    className?: string;
    fallbackText?: string;
}

const Avatar = (props: IProps) => {
    const { size, imageUrl, className, fallbackText } = props;

    const style = {
        width: `${size}px`,
        height: `${size}px`,
        lineHeight: `${size}px`,
        fontSize: `${size * 0.4}px`,
        backgroundImage: `url(${imageUrl})`,
    };

    return (
        <span className={classnames('avatar', className)} style={style}>
            {imageUrl ? '' : fallbackText}
        </span>
    );
};

Avatar.defaultProps = {
    size: 36,
};

export default Avatar;
