import * as React from 'react';
import { useContext } from 'react';
import { CUSTOMIZATION_COMPONENTS } from './framework';

export interface IConditionalComponent {
    condition: (props: any) => boolean;
    component: React.FC;
}

export interface IConditionalComponents {
    [key: string]: IConditionalComponent[];
}

const ConditionalComponentContext = React.createContext<IConditionalComponents>({});
export const ConditionalComponentContextProvider = ({ components, children }) => (
    <ConditionalComponentContext.Provider value={components}>{children}</ConditionalComponentContext.Provider>
);
export const useConditionalComponent = <T extends any>(
    componentKey: string,
    conditionProps: any,
    defaultComponent: React.FC<T> = null
): React.FC<T> => {
    // CUSTOMIZATION_COMPONENTS is once filled initially as soon as we know the customer
    const customizationComponents = CUSTOMIZATION_COMPONENTS?.[componentKey] || [];
    const contextComponents = useContext(ConditionalComponentContext)?.[componentKey] || [];
    // always customizations first
    const conditionalComponents = [...customizationComponents, ...contextComponents];

    for (const conditionalComponent of conditionalComponents) {
        if (conditionalComponent.condition(conditionProps)) {
            return conditionalComponent.component;
        }
    }

    return defaultComponent;
};
