import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useControllableState } from '../../utils/useControllableState';
import { getDateLocalizations } from '../../../utils/localization';
import Popover from '../Popover';
import { isoDateFormat } from '../../../utils/dates';
import Field, { FieldProps } from './Field';
import { withIcon } from '../Icon';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import classnames from '../../utils/classnames';
import Calendar from '../Calendar';
import Tooltip from '../../../core/components/Tooltip';
import { mergeRefs } from '../../utils/mergeRefs';

const ClearIcon = withIcon(faXmark);

export interface DateFieldProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue'>,
        FieldProps {
    format?: string;
    fromYear?: number | string;
    toYear?: number | string;
    fromDate?: Date;
    toDate?: Date;
    side?: 'top' | 'bottom' | 'left' | 'right';
    align?: 'start' | 'center' | 'end';
    disableDatePicker?: boolean;
    disableValidation?: boolean;
}

export const defaultDateFormat = 'DD.MM.YYYY';

const DateField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onChange,
            onBlur,
            onFocus,
            readOnly,
            disabled,
            required,
            className,
            controls,
            inputRef: propsInputRef = undefined,
            // field specific props
            format: dateFormat = defaultDateFormat, // presentation format, use german date format by default
            fromYear = 2000,
            toYear = new Date(new Date().setFullYear(new Date().getFullYear() + 25)).getFullYear(), // in 25 years
            fromDate = undefined,
            toDate = undefined,
            disableDatePicker = false,
            disableValidation = false,
            // popover props
            side,
            align,
            ...props
        }: DateFieldProps,
        ref: any
    ) => {
        const { t } = useTranslation('assistance');

        const inputRef = useRef<HTMLInputElement>(null);

        const [isPickerOpen, setIsPickerOpen] = useState(false);
        const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);
        const [inputValue, setInputValue] = useState<string>(defaultValue);

        useEffect(() => {
            // while editing don't update input value on external value change
            if (isPickerOpen) return;

            const isValidDate = moment(value, isoDateFormat, true).isValid();
            setInputValue(isValidDate ? moment(value).format(dateFormat) : '');
        }, [value, isPickerOpen]);

        // validation
        toYear = typeof toYear === 'string' ? parseInt(toYear) : toYear;
        fromYear = typeof fromYear === 'string' ? parseInt(fromYear) : fromYear;
        const minDate = fromDate != null ? new Date(fromDate.setHours(0, 0, 0)) : new Date(fromYear, 0, 1);
        const maxDate = toDate != null ? new Date(toDate.setHours(0, 0, 0)) : new Date(toYear + 1, 0, 1);

        const isValidDate = (date: Date | moment.Moment) => {
            return moment(date).isValid() && moment(date).isBetween(minDate, maxDate, 'day', '[]');
        };

        const handleFocus = (e: any) => {
            setIsPickerOpen(true);
            if (!isPickerOpen) onFocus?.(e);
        };

        const handleBlur = (e: any) => {
            if (!isPickerOpen) onBlur?.(e);
        };

        const handleChange = (e: any) => {
            setInputValue(e.target.value);
            onChange?.(e);

            const parsedDate = moment(e.target.value, dateFormat, true);
            if (isValidDate(parsedDate) || e.target.value === '') {
                setValue(isValidDate(parsedDate) ? parsedDate.format(isoDateFormat) : null);
            } else {
                setValue(null);
            }
        };

        const handleClear = () => {
            setInputValue('');
            setValue(null);
            inputRef.current?.focus();
        };

        const handleDateSelect = (date: Date | undefined) => {
            setInputValue(date ? moment(date).format(dateFormat) : '');
            setValue(date ? moment(date).format(isoDateFormat) : null);
        };

        const handleOpenChange = (open: boolean) => {
            setIsPickerOpen(open);
            if (!open) {
                // reset if closed
                const parsedDate = moment(value, isoDateFormat, true).toDate();
                setInputValue(isValidDate(parsedDate) ? moment(value).format(dateFormat) : '');
                // needed to trigger update
                // TODO: maybe not liek this
                setTimeout(() => {
                    onBlur?.(null);
                }, 0);
            }
        };

        const isInputValueValid = inputValue ? moment(inputValue, dateFormat, true).isValid() : !required; // empty is okay if not required
        const parsedInputDate = isInputValueValid ? moment(inputValue, dateFormat, true) : undefined;
        const isDateOutsideRange =
            isInputValueValid &&
            parsedInputDate?.isValid() &&
            !parsedInputDate.isBetween(minDate, maxDate, 'day', '[]');

        const isValid = disableValidation ? true : !isDateOutsideRange && isInputValueValid;

        let tooltipContent = '';
        if (!isValid) {
            if (isDateOutsideRange) {
                tooltipContent = t('assistance:fields.dateField.invalid', {
                    minDate: moment(minDate).format(dateFormat),
                    maxDate: moment(maxDate).format(dateFormat),
                });
            } else if (!isInputValueValid) {
                tooltipContent = t('assistance:fields.dateField.invalidFormat', { format: dateFormat });
            }
        }

        const parsedDate = value && moment(value).isValid() ? moment(value).toDate() : undefined;

        return (
            <Popover open={isPickerOpen} onOpenChange={handleOpenChange}>
                <Popover.Trigger
                    className="w-full"
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    // @ts-ignore
                    type="text"
                    disabled={readOnly}
                    asChild
                >
                    <Tooltip
                        content={tooltipContent}
                        open={isPickerOpen && !isValid ? true : isValid ? false : undefined}
                    >
                        <Field
                            className={classnames(
                                !isValid &&
                                    'border-confidence-low hover:border-confidence-low focus-within:border-confidence-low focus-within:outline-error',
                                className
                            )}
                            readOnly={readOnly}
                            disabled={disabled}
                            ref={ref}
                        >
                            <Field.Input>
                                <input
                                    ref={mergeRefs(inputRef, propsInputRef)}
                                    type="text"
                                    value={inputValue || ''}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    required={required}
                                    className="px-2 py-1.5 flex-1 min-w-0 bg-transparent"
                                    {...props}
                                />
                            </Field.Input>
                            <Field.Controls>
                                <>
                                    {!readOnly && inputValue && (
                                        <Field.ControlButton onClick={handleClear}>
                                            <ClearIcon />
                                        </Field.ControlButton>
                                    )}
                                    {controls}
                                </>
                            </Field.Controls>
                        </Field>
                    </Tooltip>
                </Popover.Trigger>

                <Popover.Content
                    className={classnames('w-auto p-4', disableDatePicker ? 'hidden' : '')}
                    onOpenAutoFocus={(e) => {
                        e.preventDefault();
                    }}
                    side={side}
                    align={align}
                    style={{ minWidth: 'auto' }}
                >
                    <Calendar
                        mode="single"
                        defaultMonth={parsedDate}
                        selected={parsedDate}
                        onSelect={handleDateSelect}
                        onWeekNumberClick={undefined}
                        fromYear={fromDate != null ? fromDate.getFullYear() : fromYear}
                        toYear={toDate != null ? toDate.getFullYear() : toYear}
                        toDate={toDate}
                        locale={getDateLocalizations()}
                        disabled={{
                            before: minDate ? minDate : undefined,
                            after: maxDate ? maxDate : undefined,
                        }}
                        showWeekNumber
                        fixedWeeks
                        showOutsideDays
                    />
                </Popover.Content>
            </Popover>
        );
    }
);

export default DateField;
