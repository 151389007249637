import { gql } from '@apollo/client';

export const UPDATE_FIELDS_CONFIG = gql`
    mutation UpdateUniversalDocumentConfig(
        $channelId: UUID!
        $documentType: String!
        $documentConfig: DocumentConfigInput
        $fieldConfigs: [FieldConfigInput]
    ) {
        updateUniversalDocumentConfig(
            channelId: $channelId
            documentType: $documentType
            documentConfig: $documentConfig
            fieldConfigs: $fieldConfigs
        ) {
            success
        }
    }
`;
