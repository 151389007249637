import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import '../../../assistance/containers/Configuration/style.scss';
import { IntegerField, SelectField, StringField, useForm } from '../../../assistance/containers/Fields';
import ProgressButton from '../../../core/containers/ProgressButton';
import { url } from '../../../core/utils/link';
import { GET_TEAMS } from '../../../teams/queries';
import DiscardConfiguration from '../../../assistance/containers/DiscardConfiguration';
import EmailClientConfiguration from '../../../assistance/containers/EmailClientConfiguration';
import MasterDataConfiguration from '../../../assistance/containers/MasterDataConfiguration';
import SFTPConfiguration from '../../../assistance/containers/SFTPConfiguration';
import UploadConfiguration from '../../../assistance/containers/UploadConfiguration';
import AS2Configuration from '../../../assistance/containers/AS2Configuration';
import { appInsights } from '../../../core/analytics/applicationInsights';
import { updateChannelConfigEvent, updateChannelMetadataEvent } from '../../../core/analytics/customEvents';
import DocumentXMLSerializerConfiguration from './containers/DocumentXMLSerializerConfiguration';
import { DocumentConfig } from '../../../assistance/containers/DocumentConfig';
import { UPDATE_FIELDS_CONFIG } from '../../../assistance/containers/DocumentConfig/queries';

export enum CONFIG_OPTIONS {
    Document,
    XmlSerializer,
    Email,
    MasterData,
    SFTP,
    AS2,
    Upload,
    Discard,
}

export const DEFAULT_CONFIGS: { [key in CONFIG_OPTIONS]: any } = {
    [CONFIG_OPTIONS.Document]: ({ channel, documentConfiguration, handlers, channelRefetch }) => {
        return (
            <DocumentConfig
                key="document-config"
                documentTypeName={documentConfiguration.documentTypeName}
                config={channel?.documentConfig}
                refetch={channelRefetch}
                channelId={channel?.id}
                itemTypes={documentConfiguration?.itemTypes || ['line_items']}
                updateMutation={documentConfiguration?.updateMutation || UPDATE_FIELDS_CONFIG}
            />
        );
    },
    [CONFIG_OPTIONS.XmlSerializer]: ({ channel, documentConfiguration, handlers }) => {
        return (
            <DocumentXMLSerializerConfiguration
                config={channel?.xmlSerializerConfig}
                onActivate={() => handlers.onCreate('xml_serializer_config')}
                onDeactivate={() => handlers.onDelete('xml_serializer_config')}
                onSubmit={(formData) => handlers.onUpdate({ xmlSerializerConfig: formData })}
                documentType={documentConfiguration.documentTypeName + 'XmlSerializerConfig'}
            />
        );
    },
    [CONFIG_OPTIONS.Email]: ({ channel, handlers }) => {
        return (
            <EmailClientConfiguration
                config={channel?.emailConfig}
                onActivate={() => handlers.onCreate('email_config')}
                onDeactivate={() => handlers.onDelete('email_config')}
                onSubmit={(formData) => handlers.onUpdate({ emailConfig: formData })}
            />
        );
    },
    [CONFIG_OPTIONS.MasterData]: ({ channel, handlers, user, channelId, service }) => {
        return (
            <MasterDataConfiguration
                user={user}
                config={channel?.masterDataConfig}
                onActivate={() => handlers.onCreate('master_data_config')}
                // can't be deactivated
                onSubmit={(formData) => handlers.onUpdate({ masterDataConfig: formData })}
                channelId={channelId}
                service={service}
                onDeleteMappings={handlers.onDeleteMappings}
            />
        );
    },
    [CONFIG_OPTIONS.SFTP]: ({ channel, handlers }) => {
        return (
            <SFTPConfiguration
                config={channel?.sftpConfig}
                onActivate={() => handlers.onCreate('sftp_config')}
                onDeactivate={() => handlers.onDelete('sftp_config')}
                onSubmit={(formData) => handlers.onUpdate({ sftpConfig: formData })}
            />
        );
    },
    [CONFIG_OPTIONS.AS2]: ({ channel, handlers }) => {
        return (
            <AS2Configuration
                config={channel?.as2Config}
                onActivate={() => handlers.onCreate('as2_config')}
                onDeactivate={() => handlers.onDelete('as2_config')}
                onSubmit={(formData) => handlers.onUpdate({ as2Config: formData })}
            />
        );
    },
    [CONFIG_OPTIONS.Upload]: ({ channel, handlers }) => {
        return (
            <UploadConfiguration
                config={channel?.uploadConfig}
                onActivate={() => handlers.onCreate('upload_config')}
                onDeactivate={() => handlers.onDelete('upload_config')}
                onSubmit={(formData) => handlers.onUpdate({ uploadConfig: formData })}
            />
        );
    },
    [CONFIG_OPTIONS.Discard]: ({ channel, handlers }) => {
        return (
            <DiscardConfiguration
                config={channel?.discardConfig}
                onActivate={() => handlers.onCreate('discard_config')}
                onDeactivate={() => handlers.onDelete('discard_config')}
                onSubmit={(formData) => handlers.onUpdate({ discardConfig: formData })}
            />
        );
    },
};

const useConfigurationHandlers = ({ user, channelId, documentConfiguration, channelRefetch }) => {
    const [createConfig] = useMutation(documentConfiguration.documentQueries.CREATE_CONFIG);
    const handleCreateConfig = (configName) => {
        return createConfig({
            variables: {
                channelId,
                configName,
            },
        }).then(() => channelRefetch());
    };

    const [deleteConfig] = useMutation(documentConfiguration.documentQueries.DELETE_CONFIG);
    const handleDeleteConfig = (configName) => {
        return deleteConfig({
            variables: {
                channelId,
                configName,
            },
        }).then(() => channelRefetch());
    };

    const [updateChannel] = useMutation(documentConfiguration.documentQueries.UPDATE_CHANNEL);
    const handleUpdateChannel = (updateData, { isMetadata = false } = {}) => {
        if (isMetadata) {
            appInsights?.trackEvent(...updateChannelMetadataEvent(user, channelId, documentConfiguration.documentType));
        } else {
            appInsights?.trackEvent(
                ...updateChannelConfigEvent(user, channelId, documentConfiguration.documentType, {
                    configKey: Object.keys(updateData)[0],
                })
            );
        }
        return updateChannel({
            fetchPolicy: 'no-cache',
            variables: {
                channelId,
                ...updateData,
            },
        }).then(() => channelRefetch());
    };

    const [deleteMappings] = useMutation(documentConfiguration.documentQueries.DELETE_MAPPINGS);
    const handleDeleteMappings = (date, mappingType) => {
        return deleteMappings({
            fetchPolicy: 'no-cache',
            variables: {
                channelId,
                beforeDate: date,
                mappingType,
            },
        }).then(() => channelRefetch());
    };

    return {
        onCreate: handleCreateConfig,
        onDelete: handleDeleteConfig,
        onUpdate: handleUpdateChannel,
        onDeleteMappings: handleDeleteMappings,
    };
};

const DocumentConfiguration = (props) => {
    const { user, documentConfiguration } = props;

    const { channelId } = useParams();
    const { t } = useTranslation('config');

    const { data, error, loading } = useQuery(documentConfiguration.getOverviewData, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    const { data: channelData, refetch: channelRefetch } = useQuery(
        documentConfiguration.documentQueries.GET_CHANNEL_CONFIG,
        {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: false,
            variables: {
                id: channelId,
            },
        }
    );

    const channel = channelData?.[documentConfiguration?.documentTypeName + 'ProcessingChannel'];
    const service = documentConfiguration?.service;

    const channels = data?.[documentConfiguration?.documentTypeName + 'ProcessingChannels'] || [];
    const activeChannel = channels.find((channel) => channel.id === channelId);

    const channelMenuItems = (channels || []).map((channel) => ({
        label: channel.name,
        url: url(documentConfiguration.channelPath, { channelId: channel.id }),
        active: activeChannel?.id === channel.id,
    }));

    channelMenuItems.unshift({
        label: t('overview.navigation.allChannels'),
        url: url(documentConfiguration.overviewPath),
        active: channelMenuItems.every((item) => !item.active),
    });

    const {
        data: teamsData,
        error: teamsError,
        loading: teamsLoading,
    } = useQuery(GET_TEAMS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {},
    });

    const teams = teamsData?.teams.map((team) => [team.id, team.name]) || [];

    const { getFieldProps, formData, setFormData } = useForm({
        getLabel: (fieldName) => t(`configPage.${fieldName}`),
    });

    useEffect(() => {
        setFormData({
            name: channel?.name || '',
            teamId: channel?.team?.id || undefined,
            emailInbox: channel?.emailAddress || '',
            timePerDocumentBeforeWorkist: channel?.timePerDocumentBeforeWorkist || null,
        });
    }, [channel]);

    const [updateChannel] = useMutation(documentConfiguration.documentQueries.UPDATE_CHANNEL);
    const handleUpdateChannel = (updateData, { isMetadata = false } = {}) => {
        if ('timePerDocumentBeforeWorkist' in updateData && updateData['timePerDocumentBeforeWorkist']) {
            updateData['timePerDocumentBeforeWorkist'] = parseInt(updateData['timePerDocumentBeforeWorkist']);
        }
        if (isMetadata) {
            appInsights?.trackEvent(...updateChannelMetadataEvent(user, channelId, documentConfiguration.documentType));
        } else {
            appInsights?.trackEvent(
                ...updateChannelConfigEvent(user, channelId, documentConfiguration.documentType, {
                    configKey: Object.keys(updateData)[0],
                })
            );
        }
        return updateChannel({
            fetchPolicy: 'no-cache',
            variables: {
                channelId,
                ...updateData,
            },
        }).then(() => channelRefetch());
    };

    const handlers = useConfigurationHandlers({
        user,
        channelId,
        documentConfiguration,
        channelRefetch,
    });

    const configProps = {
        user,
        channel,
        channelId,
        documentConfiguration,
        handlers,
        service,
        channelRefetch,
    };

    // Display only the configuration options specified or use the default ones in case no information is given
    const configOptions =
        (documentConfiguration?.configOptions && Object.keys(documentConfiguration?.configOptions)) ||
        Object.keys(DEFAULT_CONFIGS);

    const ConfigComponents = configOptions.map((configOption) => {
        const ConfigComponent = documentConfiguration?.configOptions?.[configOption] || DEFAULT_CONFIGS?.[configOption];
        return ConfigComponent && <ConfigComponent {...configProps} />;
    });

    return (
        <div className="configuration dark-mode-ready">
            <main className="configuration__main">
                <div className="configuration__content">
                    <div className="configuration__form">
                        <StringField {...getFieldProps('name')} className="field--vertical" />

                        <SelectField {...getFieldProps('teamId')} choices={teams} className="field--vertical" />

                        <StringField
                            {...getFieldProps('emailInbox')}
                            className="field--vertical"
                            inputProps={{ disabled: true }}
                        />

                        <IntegerField
                            {...getFieldProps('timePerDocumentBeforeWorkist', null)}
                            className="field--vertical"
                        />

                        <div className="configuration__form-buttons">
                            <ProgressButton
                                label={t('configPage.edit.submitLabel')}
                                onClick={() => handleUpdateChannel(formData, { isMetadata: true })}
                            />
                        </div>
                    </div>
                    {ConfigComponents}
                </div>
            </main>

            <div className="configuration__spacer" />
        </div>
    );
};

export default DocumentConfiguration;
