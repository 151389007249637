import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import TeamForm from '../../containers/TeamForm';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import Page from '../../../core/components/Page';
import Breadcrumbs from '../../../core/components/Breadcrumbs';
import Breadcrumb from '../../../core/components/Breadcrumb';
import { url } from '../../../core/utils/link';
import Loader from '../../../core/components/Loader';

import { DETAIL_PATH, OVERVIEW_PATH } from '../../constants';
import { ADD_TEAM } from '../../queries';
import { GET_USERS } from '../../../users/queries';

import './style.scss';
import Layout from '../../../core_updated/components/Layout';

const Add = (props) => {
    const { user: viewer } = props;

    const { t } = useTranslation('teams');
    const navigate = useNavigate();

    const {
        data: usersData,
        error: usersErrors,
        loading: usersLoading,
    } = useQuery(GET_USERS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
            size: 1000, // we just want to show all users
        },
    });

    const [addTeam, { loading: addTeamLoading, error: addTeamError }] = useMutation(ADD_TEAM);

    const handleSubmit = (formData) => {
        return addTeam({
            variables: {
                name: formData.name,
                description: formData.description,
                users: formData.users,
            },
        })
            .then((result: any) => {
                navigate(url(DETAIL_PATH, { teamId: result?.data?.addTeam?.team?.id }));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Layout>
            <Page className="add-team page--slim">
                <div className="add-team__head">
                    <Breadcrumbs>
                        <Breadcrumb label={t('breadcrumbs.teams')} linkTo={url(OVERVIEW_PATH)} />
                    </Breadcrumbs>
                    <div className="add-team__title">{t('add.title')}</div>
                </div>

                {addTeamError && (
                    <Alert severity="error">
                        <AlertTitle>{addTeamError?.message}</AlertTitle>
                    </Alert>
                )}

                {usersLoading ? (
                    <Loader />
                ) : (
                    <TeamForm
                        viewer={viewer}
                        selectableUsers={usersData?.users?.users}
                        className="add-team__form"
                        submitLabel={t('add.submitLabel')}
                        onSubmit={handleSubmit}
                    />
                )}
            </Page>
        </Layout>
    );
};

export default Add;
