import { gql } from '@apollo/client';

export const GET_ANNOUNCEMENTS = gql`
    query {
        announcements {
            id
            announcementType
            title
            message
            titleDe
            messageDe
            externalLink
            startedAt
            resolvedAt
        }
    }
`;
