import { useMutation, useQuery } from '@apollo/client';

import { faEllipsisV, faExternalLink, faInbox, faPlus, faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import i18n from 'i18next';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringField, useForm } from '../../../assistance/containers/Fields';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import { SecondaryButton } from '../../../core/components/Button';
import Loader, { LoaderLine } from '../../../core/components/Loader';
import MenuItem from '../../../core/components/MenuItem';

import Page from '../../../core/components/Page';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import DropDown, { DropDownMenu, DropDownToggle } from '../../../core/containers/DropDown';
import ProgressButton from '../../../core/containers/ProgressButton';
import { withIcon } from '../../../core_updated/components/Icon';
import { GET_NAVIGATION_CHANNELS } from '../../../core_updated/components/Navigation/internal/queries';
import {
    CREATE_WECLAPP_INTEGRATION,
    DELETE_WECLAPP_INTEGRATION,
    GET_WECLAPP_INTEGRATIONS,
    TRIGGER_WECLAPP_INTEGRATION_MASTERDATA_UPDATE,
    UPDATE_WECLAPP_INTEGRATION,
} from '../../queries';
import { MANIFEST_URL } from './constants';
import { WorkistIcon } from '../../../core_updated/components/WorkistLogo';

const AddIntegrationModal = (props) => {
    const { className, children, onConfirm, onCancel, integrationToEdit, ...modalProps } = props;

    const { getFieldProps, formData } = useForm({
        initialData: {
            name: !!integrationToEdit ? integrationToEdit.name : '',
            apiKey: !!integrationToEdit ? integrationToEdit.apiKey : '',
            tenant: !!integrationToEdit ? integrationToEdit.tenant : '',
            workistTicketCategoryId: !!integrationToEdit ? integrationToEdit.workistTicketCategoryId : '',
            workistImportTicketStatusIds: !!integrationToEdit
                ? integrationToEdit.workistImportTicketStatusIds
                      .replaceAll(/\[/gi, '')
                      .replaceAll(/\]/gi, '')
                      .replaceAll(/"/gi, '')
                : '',
            workistInProgressTicketStatusId: !!integrationToEdit
                ? integrationToEdit.workistInProgressTicketStatusId
                : '',
            workistFinishedTicketStatusId: !!integrationToEdit ? integrationToEdit.workistFinishedTicketStatusId : '',
            workistFinishedTicketCategoryId: !!integrationToEdit
                ? integrationToEdit.workistFinishedTicketCategoryId
                : '',
        },
    });
    const { t } = useTranslation('customer');

    // TODO when new channel type available add them
    const [selectedChannels, setSelectedChannels] = useState(
        !!integrationToEdit ? integrationToEdit.orderChannels : []
    );

    const {
        data: channelData,
        error: channelError,
        loading: loading,
    } = useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: false,
    });
    // Get a flat list of channels, in a proper Format

    const toChannel = (channelFromOverview) => {
        return {
            id: channelFromOverview.id,
            name: channelFromOverview.name,
        };
    };
    // only allow order channels
    const availableChannels = channelData
        ? Object.keys(channelData)
              .map((key) => channelData[key])
              .flatMap((elem) => elem)
              .filter((elem) => elem.__typename === 'OrderProcessingChannelType')
              .map(toChannel)
        : [];

    const handleConfirm = () => {
        onConfirm({
            id: integrationToEdit?.id,
            name: formData['name'],
            apiKey: formData['apiKey'],
            tenant: formData['tenant'],
            workistTicketCategoryId: formData['workistTicketCategoryId'],
            workistImportTicketStatusIds: formData['workistImportTicketStatusIds'],
            workistInProgressTicketStatusId: formData['workistInProgressTicketStatusId'],
            workistFinishedTicketStatusId: formData['workistFinishedTicketStatusId'],
            workistFinishedTicketCategoryId: formData['workistFinishedTicketCategoryId'],
            orderChannels: selectedChannels.map((element) => element.id),
        });
    };

    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    const handleChannelSelect = (name, data) => {
        setSelectedChannels([...selectedChannels, data]);
        setShowDropdownMenu(false);
    };
    const handleChannelDeleteFromList = (channelToDelete) => {
        setSelectedChannels(selectedChannels.filter((channel) => channel.id !== channelToDelete.id));
    };

    const AddIcon = withIcon(faPlus);

    const DeleteIcon = withIcon(faXmarkCircle);
    const channelMenuItems = availableChannels.map((channel) => (
        <MenuItem
            key={channel.id}
            value={channel.name}
            label={channel.name}
            description=""
            onClick={() => handleChannelSelect(channel.id, channel)}
            disabled={selectedChannels.some((selectedChannel) => channel.id === selectedChannel.id)}
        />
    ));

    const channelSelectedListItems = selectedChannels.map((channel) => (
        <div className="flex justify-between items-center">
            <span>{channel.name}</span>
            <button className="px-3 py-2 hover:bg-secondary rounded cursor-pointer active:bg-tertiary">
                <DeleteIcon onClick={() => handleChannelDeleteFromList(channel)} />
            </button>
        </div>
    ));

    return (
        <ConfirmModal
            className={classnames('add-Integration-modal', className)}
            title={t('integrations.weclapp.addIntegrationModal.title')}
            onConfirm={handleConfirm}
            onCancel={onCancel}
            labelConfirm={t('integrations.weclapp.addIntegrationModal.confirm')}
            labelCancel={t('integrations.weclapp.addIntegrationModal.cancel')}
            noHeader={false}
            {...modalProps}
        >
            {loading ? (
                <Loader />
            ) : (
                <div className="form">
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">{t('integrations.weclapp.addIntegrationModal.name')}</label>
                        <StringField {...getFieldProps('name')} label="" className="w-full" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">{t('integrations.weclapp.addIntegrationModal.apiKey')}</label>
                        <StringField {...getFieldProps('apiKey')} label="" className="w-full" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">{t('integrations.weclapp.addIntegrationModal.tenant')}</label>
                        <StringField {...getFieldProps('tenant')} label="" className="w-full" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">
                            {t('integrations.weclapp.addIntegrationModal.workistTicketCategoryId')}
                        </label>
                        <StringField {...getFieldProps('workistTicketCategoryId')} label="" className="w-full" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">
                            {t('integrations.weclapp.addIntegrationModal.workistImportTicketStatusIds')}
                        </label>
                        <StringField {...getFieldProps('workistImportTicketStatusIds')} label="" className="w-full" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">
                            {t('integrations.weclapp.addIntegrationModal.workistInProgressTicketStatusId')}
                        </label>
                        <StringField
                            {...getFieldProps('workistInProgressTicketStatusId')}
                            label=""
                            className="w-full"
                        />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">
                            {t('integrations.weclapp.addIntegrationModal.workistFinishedTicketStatusId')}
                        </label>
                        <StringField {...getFieldProps('workistFinishedTicketStatusId')} label="" className="w-full" />
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="font-medium">
                            {t('integrations.weclapp.addIntegrationModal.workistFinishedTicketCategoryId')}
                        </label>
                        <StringField
                            {...getFieldProps('workistFinishedTicketCategoryId')}
                            label=""
                            className="w-full"
                        />
                    </div>
                    <div className="flex justify-between">
                        <label className="font-medium">{t('integrations.weclapp.addIntegrationModal.channel')}</label>
                        <DropDown
                            className=""
                            onCloseMenu={() => setShowDropdownMenu(false)}
                            menuOrientation="right"
                            menuVisible={showDropdownMenu}
                        >
                            <DropDownToggle>
                                <SecondaryButton
                                    label={
                                        <span className="flex gap-2 items-center">
                                            <AddIcon /> {t('integrations.weclapp.addIntegrationModal.addChannel')}
                                        </span>
                                    }
                                    onClick={() => setShowDropdownMenu(true)}
                                />
                            </DropDownToggle>
                            <DropDownMenu
                                className="menu--scroll"
                                children={channelMenuItems}
                                loading={loading}
                                emptyItemsContent={
                                    <MenuItem
                                        value=""
                                        label={t('integrations.weclapp.addIntegrationModal.noChannels')}
                                        disabled={true}
                                    ></MenuItem>
                                }
                            />
                        </DropDown>
                    </div>
                    <div>
                        {channelSelectedListItems.length ? (
                            channelSelectedListItems
                        ) : (
                            <div className="border border-primary w-full p-5 text-center border-solid flex items-center justify-center h-24 text-sm rounded">
                                {t('integrations.weclapp.addIntegrationModal.noChannelsConnected')}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ConfirmModal>
    );
};

const WeClappIntegration = (props) => {
    const { user } = props;

    const { t } = useTranslation('customer');

    const [addIntegrationModalVisible, setAddIntegrationModalVisible] = useState(false);

    const [integrationToEdit, setintegrationToEdit] = useState();

    moment.locale(user?.language || i18n.language);

    const DropdownToggleIcon = withIcon(faEllipsisV);
    const ExternalLinkIcon = withIcon(faExternalLink);
    const TitleLogo = withIcon(faInbox);

    const {
        data: data,
        error: IntegrationError,
        loading: loading,
        refetch: refetch,
    } = useQuery(GET_WECLAPP_INTEGRATIONS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
    });

    const Integrations = data?.weclappIntegrations || [];

    const [createIntegration, { loading: createIntegrationLoading, error: createIntegrationError }] =
        useMutation(CREATE_WECLAPP_INTEGRATION);

    const [updateIntegration, { loading: updateIntegrationLoading, error: updateIntegrationError }] =
        useMutation(UPDATE_WECLAPP_INTEGRATION);

    const handleCreateIntegration = (data) => {
        return createIntegration({ variables: { ...data } })
            .then((res) => {
                setAddIntegrationModalVisible(false);
                refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleUpdateIntegration = (data) => {
        return updateIntegration({ variables: { ...data } })
            .then((res) => {
                setintegrationToEdit(null);
                refetch();
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const [deleteIntegration, { loading: deleteIntegrationLoading, error: deleteIntegrationError }] =
        useMutation(DELETE_WECLAPP_INTEGRATION);
    const handleDeleteIntegration = (id) => {
        return deleteIntegration({ variables: { id } })
            .then((res) => void refetch())
            .catch((error) => {
                console.error(error);
            });
    };

    const [
        updateMasterDataIntegration,
        { loading: updateMasterDataIntegrationLoading, error: updateMasterDataIntegrationnError },
    ] = useMutation(TRIGGER_WECLAPP_INTEGRATION_MASTERDATA_UPDATE);
    const handleUpdateMasterDataIntegration = (id) => {
        return updateMasterDataIntegration({ variables: { id } })
            .then((res) => void refetch())
            .catch((error) => {
                console.error(error);
            });
    };

    const error = [IntegrationError, createIntegrationError, deleteIntegrationError].find((value) => value);

    return (
        <Page className="page overflow-visible">
            <div className="flex justify-between items-center">
                <div className="text-xl font-medium flex gap-4 items-center justify-start">
                    <div className="w-12 gap-1">
                        <img src="/static/images/external_logos/weclapp-vertikal-positive.png" />
                    </div>
                    <div className="gap-1">{t('integrations.weclapp.subTitle')}</div>
                </div>
                <div className="flex gap-4">
                    <a href={t('integrations.weclapp.documentationLink')} target={'_blank'}>
                        <SecondaryButton
                            label={
                                <span className="flex gap-2 items-center">
                                    {t('integrations.weclapp.documentation')}
                                    <ExternalLinkIcon />{' '}
                                </span>
                            }
                        />
                    </a>
                    <ProgressButton
                        label={t('integrations.weclapp.addButton')}
                        onClick={() => setAddIntegrationModalVisible(true)}
                    />
                </div>
            </div>
            {addIntegrationModalVisible && (
                <AddIntegrationModal
                    visible={addIntegrationModalVisible}
                    onConfirm={handleCreateIntegration}
                    integrationToEdit={integrationToEdit}
                    onCancel={() => setAddIntegrationModalVisible(false)}
                />
            )}
            {!!integrationToEdit && (
                <AddIntegrationModal
                    visible={!!integrationToEdit}
                    onConfirm={handleUpdateIntegration}
                    integrationToEdit={integrationToEdit}
                    onCancel={() => setintegrationToEdit(null)}
                />
            )}

            {error && (
                <Alert severity="error">
                    <AlertTitle>{error?.message}</AlertTitle>
                </Alert>
            )}

            {[loading, createIntegrationLoading, deleteIntegrationLoading, updateIntegrationLoading].some(
                (value) => value
            ) ? (
                <Loader />
            ) : (
                <div className="mt-6">
                    <div className="">
                        <div className="">
                            {Integrations.length ? (
                                Integrations.map((integration) => {
                                    return (
                                        <div className="border border-primary w-full p-5  border-solid flex items-center justify-between rounded">
                                            <div className="flex flex-col gap-3">
                                                <h3 className="font-semibold text-primary">{integration.name}</h3>
                                                <div className="flex gap-2 text-secondary">
                                                    <span>
                                                        {t('integrations.weclapp.channelCount', {
                                                            channelCount: integration.orderChannels.length,
                                                        })}
                                                    </span>
                                                    <span>&bull;</span>
                                                    <span>
                                                        {t('integrations.weclapp.lastMasterDataUpdate', {
                                                            updatedAt: moment(integration.lastMasterDataUpdate).format(
                                                                'Do MMMM YYYY, HH:mm'
                                                            ),
                                                        })}
                                                    </span>
                                                    {integration.activeMasterDataUpdate && (
                                                        <>
                                                            <span>&bull;</span>
                                                            <span>
                                                                {t('integrations.weclapp.masterDataUpdateInProgress')}
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="flex gap-2 items-center">
                                                {integration.activeMasterDataUpdate && (
                                                    <span>
                                                        <i className="fa-solid fa-circle-notch fa-spin" />
                                                    </span>
                                                )}
                                                <DropDown>
                                                    <DropDownToggle>
                                                        <button className="px-3 py-2 hover:bg-secondary rounded cursor-pointer active:bg-tertiary">
                                                            <DropdownToggleIcon />
                                                        </button>
                                                    </DropDownToggle>
                                                    <DropDownMenu>
                                                        <MenuItem
                                                            value={t('integrations.weclapp.editButton')}
                                                            onClick={() => setintegrationToEdit(integration)}
                                                        />
                                                        <MenuItem
                                                            value={t('integrations.weclapp.triggerUpdate')}
                                                            className="text-error"
                                                            onClick={() =>
                                                                handleUpdateMasterDataIntegration(integration.id)
                                                            }
                                                        />
                                                        <MenuItem
                                                            value={t('integrations.weclapp.deleteButton')}
                                                            className="text-error"
                                                            onClick={() => handleDeleteIntegration(integration.id)}
                                                        />
                                                    </DropDownMenu>
                                                </DropDown>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div className="border border-primary w-full p-5 text-center border-solid flex items-center justify-center h-48 text-sm rounded">
                                    {t('integrations.weclapp.noIntegrations')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Page>
    );
};

export default WeClappIntegration;
