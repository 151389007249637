import * as React from 'react';
import {
    fieldConfigToLegacyConfigFormat,
    fieldToFormFieldConfig,
} from '../../../generic_document/pages/Assistance/utils';
import { merge } from 'lodash';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';

export const documentTypeName = 'invoice';

const fieldNameToGroupTypeMap = {
    senderSupplier: 'CustomerField',
    recipientAddress: 'AddressField',
    recipientContact: 'ContactField',
    senderAddress: 'AddressField',
    senderContact: 'ContactField',
};

export const getHeaderFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    headerFields,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const configs = {};

    fieldConfigToLegacyConfigFormat(fieldConfigs, configs, client, recordId, documentConfig, fieldNameToGroupTypeMap);

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        senderSupplier: {
            groupFieldProps: {},
        },
        senderAddress: {
            groupFieldProps: {
                addressId2: {
                    required: false,
                    hidden: !headerFields.includes('senderAddress.addressId2'),
                },
            },
            groupInputProps: {
                misc: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                email: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
                phone: {
                    // currently part of always_optional - change when document configs are available
                    required: false,
                },
            },
        },
        recipientContact: {
            groupFieldProps: {
                contactId: {},
            },
        },
        senderContact: {
            groupFieldProps: {
                contactId: {},
            },
        },
    };
    return merge(legacyConfig, configs);
};

export const getLineItemFieldConfigs = (
    client,
    recordId,
    masterDataConfig,
    documentConfig = undefined,
    fieldConfigs = undefined
) => {
    const { handlers, document, documentConfiguration } = useAssistanceContext();

    const configs = {};
    if (fieldConfigs) {
        fieldConfigs.forEach((field) => {
            configs[field.name] = fieldToFormFieldConfig(field, {
                client,
                recordId,
                documentConfig,
                documentConfiguration,
                document,
                handlers,
            });
        });
    }

    // TODO: maybe this information can come from the backend to increase flexibility - for now it's fine though
    const legacyConfig = {
        articleNumber: {},
        articlePartitionSpecificNumber: {},
        quantity: {
            fieldType: 'FloatField',
        },
        unitPrice: {
            fieldType: 'FloatField',
        },
        totalPrice: {
            fieldType: 'FloatField',
        },
    };

    return merge(legacyConfig, configs);
};
