import { withIcon } from '../../../core_updated/components/Icon';
import {
    faCheckCircle,
    faCrosshairs,
    faEllipsisV,
    faList,
    faPlus,
    faSpinnerThird,
    faTableCells,
} from '@fortawesome/pro-regular-svg-icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAssistanceContext } from './AssistanceContext';
import { useTranslation } from 'react-i18next';
import { camelCase, snakeCase } from 'lodash';
import { AssistanceField } from './AssistanceField';
import AssistanceSection, { useAssistanceSectionContext } from './AssistanceSection';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import DropdownMenu from '../../../core_updated/components/DropdownMenu';
import classnames from 'classnames';
import { useLocalStorage } from '../../../core/utils/hooks/useLocalStorage';
import Tooltip from '../../../core/components/Tooltip';
import { TABLE_HEADER_SPLIT_PANEL_ID } from '../../../assistance/containers/ItemsView';
import { Pane, SplitPanes } from '../../../core/containers/SplitPanes';
import { IEventField, useAssistanceViewContext } from './AssistanceViewContext';
import { filterFastTrack } from './utils';
import { DEFAULT_VALUE_KEY, GROUP_SEPARATOR } from './useDocumentConfig';
import AssistanceSectionEmptyState from './AssistanceSectionEmptyState';
import ItemDataFieldGroup from './customizable/ItemDataFieldGroup';
import { useConditionalComponent } from '../../../customizations/ConditionalComponentContext';
import useReselectEventListener, { ReselectEvent } from './useReselectEventListener';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useTraceUpdate } from '../../../core/utils/hooks/useTraceUpdate';
import { capitalizeFirstLetter, titleCase } from '../../../utils/string';
import { HeaderDataFieldGroupControls } from './HeaderDataSection';

const ADD_LINE_ITEM_INDEX = -1;

const MoreIcon = withIcon(faEllipsisV);
const LoadingIcon = withIcon(faSpinnerThird);
const WarningIcon = withIcon(faExclamationTriangle);

const FocusIcon = withIcon(faCrosshairs);
const CompleteIcon = withIcon(faCheckCircle);
const AddItemIcon = withIcon(faPlus);
const ListViewIcon = withIcon(faList);
const TableViewIcon = withIcon(faTableCells);

const ItemDataSection = React.memo(({ itemType, formFieldGroups, updateField, reselectField, focusField }: any) => {
    const { t } = useTranslation('assistance');

    const { document } = useAssistanceContext();
    const { isFastTrackEnabled } = useAssistanceViewContext();

    const [filteredFormFieldGroups, { low: lowConfidenceFieldsCount, medium: mediumConfidenceFieldsCount }] =
        filterFastTrack(formFieldGroups);

    const [viewMode, setViewMode] = useLocalStorage('assistanceItemsViewMode', 'table');
    const [assistanceFormHeight, setAssistanceFormHeight] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    // resize columns
    const [columnWidths, setColumnWidths] = useState(null);
    const handleColumnWidthsChange = (widths) => {
        setColumnWidths(widths);
    };

    useEffect(() => {
        /*
        In table view the table should have a height so that the horizontal scrollbar is always visible.
        */

        const assistanceForm = window.document.querySelector('.js-assistance-form');

        if (!assistanceForm) {
            return;
        }

        const handleResize = () => {
            const newHeight = assistanceForm?.clientHeight;
            setAssistanceFormHeight(newHeight || 0);
        };

        const resizeObserver = new ResizeObserver(handleResize);
        resizeObserver.observe(assistanceForm);

        handleResize();

        return () => resizeObserver.disconnect();
    }, []);

    const displayFormFieldGroups = isFastTrackEnabled ? filteredFormFieldGroups : formFieldGroups;

    return (
        <AssistanceSection
            title={t(`sections.${camelCase(itemType)}`)}
            controls={
                <ItemDataSectionControls
                    isOpen={isOpen}
                    mediumConfidenceFieldsCount={mediumConfidenceFieldsCount}
                    lowConfidenceFieldsCount={lowConfidenceFieldsCount}
                    viewMode={viewMode}
                    onViewModeChange={setViewMode}
                    onOpenChange={setIsOpen}
                    focusField={focusField}
                    reselectField={reselectField}
                    updateField={updateField}
                    itemType={itemType}
                />
            }
            open={isOpen}
            onOpenChange={setIsOpen}
            viewMode={viewMode}
        >
            <div className="w-full">
                <div
                    className={classnames('relative flex flex-col', viewMode === 'list' ? 'gap-8' : 'overflow-auto')}
                    style={viewMode !== 'list' ? { maxHeight: `calc(${assistanceFormHeight}px - 108px)` } : undefined}
                >
                    <div className={classnames('relative flex flex-col', viewMode === 'list' ? 'gap-8' : 'gap-2 pb-2')}>
                        {!document ? (
                            <ItemDataSectionLoader />
                        ) : displayFormFieldGroups?.length > 0 ? (
                            <>
                                {viewMode === 'table' && (
                                    <TableHeader
                                        fieldGroups={displayFormFieldGroups}
                                        onColumnWidthChange={handleColumnWidthsChange}
                                    />
                                )}

                                {displayFormFieldGroups?.map((fields, itemIndex) => {
                                    return (
                                        <ItemDataFormFieldGroup
                                            // using the item index from first field prevents wrong updates when
                                            // fast track is enabled as that item index is stable from the data
                                            // NOTE: except for when a new item is added or removed
                                            key={fields[0]?.itemIndex}
                                            itemType={itemType}
                                            itemIndex={fields[0]?.itemIndex}
                                            fields={fields}
                                            columnWidths={columnWidths}
                                            updateField={updateField}
                                            reselectField={reselectField}
                                            focusField={focusField}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <AssistanceSectionEmptyState />
                        )}
                    </div>
                </div>
            </div>
        </AssistanceSection>
    );
});

const ItemDataSectionControls = ({
    isOpen,
    mediumConfidenceFieldsCount,
    lowConfidenceFieldsCount,
    viewMode,
    onViewModeChange,
    onOpenChange,
    focusField,
    reselectField,
    updateField,
    itemType,
}: any) => {
    const { t } = useTranslation('assistance');

    const {
        record,
        document,
        loading: isDocumentLoading,
        isFinished,
        readOnly,
        documentConfiguration,
        handlers: { showOriginalData },
    } = useAssistanceContext();
    const { onFieldAction, onFieldFocus, onFieldReselect } = useAssistanceViewContext();

    const addLineItemButtonRef = React.useRef(null);

    useReselectEventListener(addLineItemButtonRef, (e: ReselectEvent) => {
        onFieldReselect({
            itemIndex: ADD_LINE_ITEM_INDEX,
            itemType: itemType,
            bbox: e.detail.bbox,
            pageIndex: e.detail.pageIndex,
        });
    });

    if (!document) {
        return null;
    }

    const handleAddLineItem = () => {
        if (documentConfiguration?.onAddLineItem) {
            return documentConfiguration.onAddLineItem({
                onFieldAction,
                itemType,
                itemIndex: ADD_LINE_ITEM_INDEX,
            });
        }

        return onFieldFocus(
            focusField?.itemIndex === ADD_LINE_ITEM_INDEX ||
                reselectField?.itemIndex === ADD_LINE_ITEM_INDEX ||
                updateField?.itemIndex === ADD_LINE_ITEM_INDEX
                ? null
                : {
                      itemIndex: ADD_LINE_ITEM_INDEX,
                      reselectTargetRef: addLineItemButtonRef,
                  }
        );
    };

    const isLineItemsCorrect =
        record?.[`is${titleCase(itemType)}Correct`] ??
        (record?.isLineItemsCorrect && JSON.parse(record.isLineItemsCorrect)?.[snakeCase(itemType)]);

    const canFinishLineItemsAssistance =
        record?.[`canFinish${titleCase(itemType)}Assistance`] ??
        (record?.canFinishLineItemsAssistance &&
            JSON.parse(record?.canFinishLineItemsAssistance)?.[snakeCase(itemType)]);

    const canComplete = !!canFinishLineItemsAssistance && !readOnly;

    const correctBadge = (
        <span className="text-sm font-medium text-primary flex gap-1.5 items-center px-2">
            <CompleteIcon className="text-success" />
            {t('itemsView.actions.sectionCompleted')}
        </span>
    );

    return (
        <>
            {isOpen && (
                <>
                    <div className="flex gap-1">
                        <Tooltip content={t('itemsView.actions.listView')} placement="top">
                            <AssistanceSection.IconButton
                                active={viewMode === 'list'}
                                onClick={() => onViewModeChange('list')}
                            >
                                <ListViewIcon />
                            </AssistanceSection.IconButton>
                        </Tooltip>

                        <Tooltip content={t('itemsView.actions.tableView')} placement="top">
                            <AssistanceSection.IconButton
                                active={viewMode === 'table'}
                                onClick={() => onViewModeChange('table')}
                            >
                                <TableViewIcon />
                            </AssistanceSection.IconButton>
                        </Tooltip>
                    </div>

                    <AssistanceSection.ButtonSeparator />
                </>
            )}

            {!readOnly && isOpen && (
                <>
                    <Tooltip content={t('itemsView.actions.addArticle')} placement="top">
                        <AssistanceSection.IconButton
                            active={
                                focusField?.itemIndex === ADD_LINE_ITEM_INDEX ||
                                reselectField?.itemIndex === ADD_LINE_ITEM_INDEX ||
                                updateField?.itemIndex === ADD_LINE_ITEM_INDEX
                            }
                            onClick={handleAddLineItem}
                            disabled={isDocumentLoading || readOnly}
                            ref={addLineItemButtonRef}
                        >
                            {updateField?.itemIndex === ADD_LINE_ITEM_INDEX ? <LoadingIcon spin /> : <AddItemIcon />}
                        </AssistanceSection.IconButton>
                    </Tooltip>

                    <AssistanceSection.ButtonSeparator />
                </>
            )}

            {isLineItemsCorrect && !showOriginalData
                ? correctBadge
                : (!isOpen || showOriginalData) && (
                      <>
                          <div className="px-2 flex gap-2">
                              {mediumConfidenceFieldsCount === 0 && lowConfidenceFieldsCount === 0 && (
                                  <div className="rounded-full w-7 h-7 text-primary text-sm font-medium flex justify-center items-center bg-secondary">
                                      0
                                  </div>
                              )}
                              {mediumConfidenceFieldsCount > 0 && (
                                  <div className="rounded-full w-7 h-7 text-primary text-sm font-medium flex justify-center items-center bg-confidence-medium">
                                      {mediumConfidenceFieldsCount}
                                  </div>
                              )}
                              {lowConfidenceFieldsCount > 0 && (
                                  <div className="rounded-full w-7 h-7 text-inverted text-sm font-medium flex justify-center items-center bg-confidence-low">
                                      {lowConfidenceFieldsCount}
                                  </div>
                              )}
                          </div>
                      </>
                  )}

            {!isFinished && !showOriginalData && isOpen && (
                <Tooltip
                    content={
                        !canComplete || isDocumentLoading
                            ? t('itemsView.actions.completeSectionDisabled')
                            : t('itemsView.actions.completeSection')
                    }
                    placement="top"
                >
                    <AssistanceSection.IconButton
                        className="bg-brand-default hover:bg-brand-hover active:bg-brand-active text-inverted hover:text-inverted disabled:opacity-40 disabled:pointer-events-none"
                        onClick={() => {
                            onFieldAction?.({
                                action: 'action:mark_items_correct',
                                itemType: itemType,
                            }).then(() => {
                                onOpenChange(false);
                            });
                        }}
                        disabled={!canComplete || isDocumentLoading}
                    >
                        {isDocumentLoading ? <LoadingIcon spin /> : <CompleteIcon />}
                    </AssistanceSection.IconButton>
                </Tooltip>
            )}
        </>
    );
};

const ItemDataSectionLoader = () => (
    <>
        <AssistanceSection.FieldGroupLoadingSkeleton />
        <AssistanceSection.FieldGroupLoadingSkeleton />
        <AssistanceSection.FieldGroupLoadingSkeleton />
        <AssistanceSection.FieldGroupLoadingSkeleton />
    </>
);

const TableHeader = ({ fieldGroups, onColumnWidthChange }: any) => {
    const { t } = useTranslation('assistance');

    const firstFieldGroup = fieldGroups?.[0];

    return (
        <div className="flex sticky top-0 z-20 flex-none bg-primary mb-0.5">
            <div className="w-20 flex-none bg-primary sticky left-0 z-10" />
            <SplitPanes
                id={TABLE_HEADER_SPLIT_PANEL_ID}
                shrinkPanels={false}
                onUpdate={onColumnWidthChange}
                className="!w-auto bg-primary py-1"
                allowResizeLast={true}
            >
                {firstFieldGroup?.map((field) => (
                    <Pane
                        key={`items-table-header-${field.fieldName}`}
                        className="flex items-center !flex-row px-2 py-1 js-table-header-item box-border flex-none min-w-0 truncate"
                    >
                        <label className="text-xs text-secondary flex-none">
                            <span>
                                {field.config?.fieldLabelName ??
                                    t(`assistance:itemsView.fieldNames.${field.fieldName}`)}{' '}
                                {field.config?.isRequired && <span className="text-error">*</span>}
                            </span>
                        </label>
                    </Pane>
                ))}
            </SplitPanes>
        </div>
    );
};

const MemoAssistanceField = React.memo(AssistanceField);

export const FocusActionClickButton = ({ previewRef, viewMode, itemIndex, isButtonEnabled = true }: any) => {
    const { t } = useTranslation('assistance');

    const handleFocusActionClick = () => {
        const triggers = previewRef?.current.querySelectorAll('.image-map__area--row');
        const trigger = triggers?.[itemIndex];
        if (!trigger) return;

        const triggerPos = trigger.getBoundingClientRect();
        const parentPos = previewRef?.current.getBoundingClientRect();

        previewRef?.current.scrollTo({
            top: triggerPos.top + previewRef?.current.scrollTop - parentPos.top,
            behavior: 'smooth',
            block: 'start',
        });
    };

    return (
        <>
            {isButtonEnabled && handleFocusActionClick && (
                <Tooltip content={t(`assistance:itemsView.actions.focus`)} placement="top">
                    <AssistanceSection.FieldGroupIconButton
                        onClick={handleFocusActionClick}
                        className={classnames(viewMode !== 'list' && 'w-8 h-8 -mr-1')}
                    >
                        <FocusIcon />
                    </AssistanceSection.FieldGroupIconButton>
                </Tooltip>
            )}
        </>
    );
};

const ItemDataFormFieldGroup = ({
    itemType,
    fields,
    itemIndex,
    columnWidths,
    updateField,
    reselectField,
    focusField,
}: any) => {
    const { t } = useTranslation('assistance');
    const { document, loading: documentLoading, readOnly } = useAssistanceContext();
    const {
        generalConfig,
        onFieldAction,
        onFieldFocus,
        onFieldBlur,
        onFieldMouseEnter,
        onFieldMouseLeave,
        onFieldReselect,
        onFieldUpdate,
        previewRef,
    } = useAssistanceViewContext();
    const { viewMode } = useAssistanceSectionContext();

    // this is probably not the best way compared to using a AssistanceItemContext or similar
    const item = document?.[camelCase(itemType)]?.[itemIndex] || {};

    const handleActionClick = (action: string, payload = undefined) =>
        onFieldAction({ itemIndex, itemType, action: `action:${action}`, payload });

    const FieldGroupComponent = useConditionalComponent(
        'ItemDataFieldGroup',
        {
            itemIndex,
            fields,
        },
        ItemDataFieldGroup
    );

    const FocusActionClickComponent = useConditionalComponent(
        'FocusActionClickButton',
        {
            itemIndex,
            fields,
        },
        FocusActionClickButton
    );

    const MoreActionsButtonComponent = useConditionalComponent(
        'ItemMoreActionsButton',
        {
            itemIndex,
            fields,
        },
        ItemMoreActionsButton
    );

    return (
        <FieldGroupComponent
            itemIndex={itemIndex}
            title={
                <>
                    {`${t('itemsView.groupTitle')} #${itemIndex + 1}`}
                    {item?.isMatchingIgnored && (
                        <span className="text-secondary ml-3 inline-flex gap-1 items-baseline text-xs">
                            <WarningIcon className="translate-y-px text-tertiary" />
                            <span>{t('itemsView.matchingIgnored')}</span>
                        </span>
                    )}
                </>
            }
            fields={fields}
            controls={
                <>
                    <FocusActionClickComponent previewRef={previewRef} viewMode={viewMode} itemIndex={itemIndex} />

                    {!readOnly ? (
                        <MoreActionsButtonComponent
                            item={item}
                            onActionClick={handleActionClick}
                            itemIndex={itemIndex}
                            itemType={itemType}
                            fieldNames={fields.map((formField) => formField.fieldName)}
                            disabled={readOnly}
                        />
                    ) : (
                        viewMode !== 'list' && (
                            <span className="flex w-8 h-8 items-center justify-center text-sm font-medium ">
                                {itemIndex + 1}
                            </span>
                        )
                    )}
                </>
            }
        >
            {fields.map((field, fieldIndex) => {
                const fullFieldName =
                    field.valueKey && field.valueKey !== DEFAULT_VALUE_KEY
                        ? `${field.fieldName}${GROUP_SEPARATOR}${field.valueKey}`
                        : field.fieldName;

                const getIsActive = (eventField: IEventField) => {
                    return (
                        (eventField?.fieldName === field.fieldName || eventField?.fieldName === fullFieldName) &&
                        eventField?.itemIndex === itemIndex
                    );
                };

                return (
                    <AssistanceSection.FieldLabel
                        label={field.config?.fieldLabelName ?? t(`assistance:itemsView.fieldNames.${field.fieldName}`)}
                        required={field.config?.isRequired}
                        key={field.path}
                        style={viewMode !== 'list' ? { width: columnWidths?.[fieldIndex] } : undefined}
                    >
                        <MemoAssistanceField
                            fieldName={field.fieldName}
                            valueKey={field.valueKey}
                            value={field.value}
                            fieldIndex={0} // always 0, as we don't allow multiple fields in the same group for items
                            itemIndex={itemIndex}
                            itemType={itemType}
                            // view state
                            placeholder={
                                field.config?.fieldLabelName ?? t(`assistance:itemsView.fieldNames.${fullFieldName}`)
                            }
                            isUpdateActive={getIsActive(updateField)}
                            isReselectActive={getIsActive(reselectField)}
                            isFocusActive={getIsActive(focusField)}
                            // by reference
                            data={field.data}
                            config={field.config}
                            generalConfig={generalConfig}
                            // more
                            onFocus={onFieldFocus}
                            onBlur={onFieldBlur}
                            onMouseEnter={onFieldMouseEnter}
                            onMouseLeave={onFieldMouseLeave}
                            onAction={onFieldAction}
                            onReselect={onFieldReselect}
                            onUpdate={onFieldUpdate}
                            viewMode={viewMode}
                            documentLoading={documentLoading}
                            documentReadOnly={readOnly}
                            clientPartitionId={document?.clientPartitionId}
                            matchingDocumentNumber={
                                document[camelCase(field.config?.documentNumberFieldName || '')]?.value
                            }
                        />
                    </AssistanceSection.FieldLabel>
                );
            })}
        </FieldGroupComponent>
    );
};

export const ItemMoreActionsButton = ({
    item,
    onActionClick,
    itemIndex,
    fieldNames,
    children,
    className,
    ...props
}: any) => {
    const { t } = useTranslation('assistance');

    const { record } = useAssistanceContext();

    const { viewMode } = useAssistanceSectionContext();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [ignoreMatchingModalVisible, setIgnoreMatchingModalVisible] = useState(false);

    const handleConfirmIgnoreMatching = () => {
        onActionClick('document_matching_ignore_matching');
        setIgnoreMatchingModalVisible(false);
    };

    return (
        <>
            <DropdownMenu>
                <DropdownMenu.Trigger asChild>
                    <AssistanceSection.FieldGroupIconButton
                        onClick={() => ''}
                        className={classnames(
                            'group',
                            viewMode !== 'list' && 'data-[state=open]:bg-secondary w-8 h-8',
                            className
                        )}
                        {...props}
                    >
                        {viewMode !== 'list' && (
                            <span className="block group-data-[state=open]:hidden group-hover:hidden">
                                {itemIndex + 1}
                            </span>
                        )}
                        <span
                            className={classnames(
                                'group-data-[state=open]:block',
                                viewMode !== 'list' && 'hidden  group-hover:block'
                            )}
                        >
                            <MoreIcon />
                        </span>
                    </AssistanceSection.FieldGroupIconButton>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content align="end">
                    {children}

                    {!props?.isCorrectActionDisabled && (
                        <DropdownMenu.Item onClick={() => onActionClick('correct')}>
                            {t('itemsView.actions.correct')}
                        </DropdownMenu.Item>
                    )}

                    <DropdownMenu.Item onClick={() => onActionClick('duplicate', { itemIndex })}>
                        {t('itemsView.actions.duplicate')}
                    </DropdownMenu.Item>

                    {fieldNames
                        ?.filter((fieldName) => !fieldName.startsWith('articleNumber'))
                        .map((fieldName) => {
                            // action:copy does not make sense for article numbers due to the dependencies to allowed units, allowed variants, etc.
                            const label = t(`itemsView.fieldNames.${snakeCase(fieldName)}`);
                            return (
                                <DropdownMenu.Item key={fieldName} onClick={() => onActionClick('copy', { fieldName })}>
                                    {t('itemsView.actions.copy', { fieldName: label })}
                                </DropdownMenu.Item>
                            );
                        })}
                    {fieldNames && fieldNames.length > 0 ? <DropdownMenu.Separator /> : null}

                    {record?.isMatchingEnabled && !item?.isMatchingIgnored && (
                        <>
                            <DropdownMenu.Item
                                className="menu-item--danger"
                                onClick={() => setIgnoreMatchingModalVisible(true)}
                            >
                                {t('itemsView.actions.document_matching_ignore_matching')}
                            </DropdownMenu.Item>
                            <DropdownMenu.Separator />
                        </>
                    )}

                    {record?.isMatchingEnabled && item?.isMatchingIgnored && (
                        <>
                            <DropdownMenu.Item
                                className="menu-item--danger"
                                onClick={() => onActionClick('document_matching_restore_matching')}
                            >
                                {t('itemsView.actions.document_matching_restore_matching')}
                            </DropdownMenu.Item>
                            <DropdownMenu.Separator />
                        </>
                    )}

                    <DropdownMenu.Item className="menu-item--danger" onClick={() => onActionClick('delete')}>
                        {t('itemsView.actions.delete')}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item className="menu-item--danger" onClick={() => setDeleteModalVisible(true)}>
                        {t('itemsView.actions.bulk_delete')}
                    </DropdownMenu.Item>
                </DropdownMenu.Content>
            </DropdownMenu>

            <ConfirmModal
                title={t('itemsView.deleteModal.title')}
                labelConfirm={t('itemsView.deleteModal.confirmButton')}
                labelCancel={t('itemsView.deleteModal.cancelButton')}
                onConfirm={() => onActionClick('bulk_delete')} // Bulk delete
                onCancel={() => setDeleteModalVisible(false)}
                visible={deleteModalVisible}
                danger
            >
                <p>{t('itemsView.deleteModal.message')}</p>
            </ConfirmModal>

            <ConfirmModal
                title={t('itemsView.ignoreMatchingModal.title')}
                labelConfirm={t('itemsView.ignoreMatchingModal.confirmButton')}
                labelCancel={t('itemsView.ignoreMatchingModal.cancelButton')}
                onConfirm={handleConfirmIgnoreMatching}
                onCancel={() => setIgnoreMatchingModalVisible(false)}
                visible={ignoreMatchingModalVisible}
                danger
            >
                <p>{t('itemsView.ignoreMatchingModal.message')}</p>
            </ConfirmModal>
        </>
    );
};

export default ItemDataSection;
