import { useState, useEffect } from 'react';

/**
 * Similar to [useState] where the initial state for the given storageKey is read synchronously
 * from local storage and synced when the state is updated via [setState].
 *
 * @param storageKey
 * @param fallbackState
 */
export const useLocalStorage = (storageKey, fallbackState) => {
    const [value, setValue] = useState(JSON.parse(localStorage.getItem(storageKey)) ?? fallbackState);

    useEffect(() => {
        localStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);

    return [value, setValue];
};

export const useSessionStorage = (storageKey, fallbackState) => {
    const [value, setValue] = useState(JSON.parse(sessionStorage.getItem(storageKey)) ?? fallbackState);

    useEffect(() => {
        sessionStorage.setItem(storageKey, JSON.stringify(value));
    }, [value, storageKey]);

    return [value, setValue];
};
