import React from 'react';

export const Spacer = () => {
    return (
        <>
            <div></div>
            <div></div>
        </>
    );
};
