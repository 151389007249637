import classnames from 'classnames';
import isEqual from 'lodash/isEqual';
import uniqueId from 'lodash/uniqueId';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button, { SecondaryButton } from '../../../core/components/Button';
import Checkbox from '../../../core/components/Checkbox';
import MenuItem from '../../../core/components/MenuItem';
import Tooltip from '../../../core/components/Tooltip';
import ConfirmModal from '../../../core/containers/ConfirmModal';
import DropDown, { DropDownMenu, DropDownToggle, IMenuItem } from '../../../core/containers/DropDown';
import { useDebounce } from '../../../core/utils/hooks/useDebounce';

import { isObject, omit, snakeCase } from 'lodash';
import { getConfidenceClass } from '../../../core/components/ConfidenceIndicator';
import EditFormModal from '../../../core/containers/EditFormModal';
import { COUNTRY_CODES } from '../../../utils/index';
import { getDateLocalizations, resolveLocalizedString } from '../../../utils/localization';

import Alert, { AlertTitle } from '../../../core/components/Alert';
import Icon from '../../../core/components/Icon';
import { toMenuItems } from './utils';

import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import InputMask from 'react-input-mask';
import { usePopper } from 'react-popper';
import { isDateInRange, isoDateFormat, toISODateString } from '../../../utils/dates';
import { useFocusTrap } from '../../../core/utils/hooks/useLocation';
import { useToaster } from '../../../core/components/Toast';

import 'react-day-picker/dist/style.css';
import './style.scss';
import { useControllableState } from '../../../core_updated/utils/useControllableState';
import { appInsights } from '../../../core/analytics/applicationInsights';
import { showDefaultMDEvent, showMoreResultsEvent } from '../../../core/analytics/customEvents';
import { useApplicationContext } from '../../../core_updated/contexts/ApplicationContext';
import { useAssistanceContext } from '../../../generic_document/pages/Assistance/AssistanceContext';
import { SelectLabel } from '@radix-ui/react-select';
import { MenuDivider } from '../../../core/components/Menu';
import DOMPurify from 'dompurify';

export const DECIMAL_VALIDATION_REGEXP = /^\d+[.|,]?\d*$/;
export const INTEGER_VALIDATION_REGEXP = /^\d+$/;
export const TIME_VALIDATION_REGEXP =
    /^(?:[0-2]|[0-1][0-9]|2[0-3]|[0-1][0-9]:|2[0-3]:|[0-1][0-9]:[0-5]|2[0-3]:[0-5]|[0-1][0-9]:[0-5][0-9]|2[0-3]:[0-5][0-9])$/;

export const isEmptyValue = (v) => v === '' || v === undefined || v === null;

export const useForm = ({
    initialData = {},
    data: controlledData = undefined,
    onUpdate = undefined,
    getLabel = (fieldName) => fieldName,
} = {}) => {
    const [data, setData] = useControllableState(initialData, controlledData);

    const setField = (fieldName, value, quiet = false) => {
        const updated = { ...data, [fieldName]: value };
        setData({ ...data, [fieldName]: value });

        if (!quiet && onUpdate) onUpdate(updated);
    };

    const setFields = (fields, quiet = false) => {
        const updated = { ...fields };
        setData({ ...data, ...fields });
        if (!quiet && onUpdate) onUpdate(updated);
    };

    const getFieldProps = (fieldName, defaultValue = '') => ({
        id: fieldName,
        name: fieldName,
        label: getLabel(fieldName),

        initialValue: data?.[fieldName] || defaultValue,
        onUpdate: (value) => setField(fieldName, value),
    });

    return {
        formData: data,
        setFormData: setFields,

        setField,
        getFieldProps,
    };
};

export const FieldWrapper = (props) => {
    const {
        label,
        children,
        className,
        tooltip,
        tooltipPlacement = 'bottom-end',
        tooltipLong = true,
        required,
        disabled,
        id,
        empty,
        hidden,
        invalid = false,
        trailing,
        trailingFloating = false,
        decoration,
        tooltipTriggerRef = undefined,
        ...fieldProps
    } = props;
    if (hidden) return null;

    const { t } = useTranslation();

    const confidenceExplanationTooltip =
        tooltip?.translationKey && t(`assistance:tooltip.${tooltip.translationKey}`, tooltip.explanationDetails);

    const isExplanationOn = fieldProps?.explanationToggle;

    if (isExplanationOn) {
        return <FieldWithExplanation {...props} />;
    } else {
        return (
            <Tooltip
                content={confidenceExplanationTooltip}
                placement={tooltipPlacement}
                long={tooltipLong}
                triggerRef={tooltipTriggerRef}
            >
                <FieldWithExplanation {...props} />
            </Tooltip>
        );
    }
};

export const FieldWithExplanation = (props) => {
    const {
        label,
        children,
        className,
        tooltip,
        tooltipPlacement = 'top-end',
        required,
        disabled,
        id,
        empty,
        hidden,
        invalid = false,
        trailing,
        trailingFloating = false,
        decoration,
        ...fieldProps
    } = props;
    const { t } = useTranslation();
    const { publishToast } = useToaster();

    let confidenceExplanationString =
        tooltip?.translationKey && t(`assistance:${tooltip.translationKey}`, tooltip.explanationDetails);

    const snackbarTimestamp = tooltip?.explanationDetails?.snackbar_timestamp;

    // TODO: move this into a post-update handler that dispatches toasts based on the update diff payload
    useEffect(() => {
        if (snackbarTimestamp) {
            const snackbarTimestampValue = new Date(snackbarTimestamp).toUTCString();
            const currentTime = new Date().toUTCString();
            const timeDifferenceInSeconds = moment(currentTime).diff(snackbarTimestampValue, 'seconds');
            // Only display the snackbar control if a maximum of 8 seconds have passed since the backend sent the information
            if (timeDifferenceInSeconds < 8) {
                publishToast({
                    description: (
                        <Trans
                            t={t}
                            i18nKey={`assistance:snackbar:${tooltip?.explanationDetails.snackbar}`}
                            values={tooltip?.explanationDetails || {}}
                        />
                    ),
                });
            }
        }
    }, [snackbarTimestamp]);

    // SelectField has a different explanation when a default value is set
    if (
        fieldProps?.field?.__typename === 'SelectField' &&
        !empty &&
        tooltip?.translationKey === 'explanation.no_extracted_value'
    ) {
        confidenceExplanationString = t('assistance:explanation.no_extracted_value_select_field');
    }

    const isFieldActive = fieldProps?.activeFieldId === id;
    const [isFocused, setIsFocused] = React.useState(false);
    const [wasEmpty, setWasEmpty] = React.useState(empty); // to know if the field was empty before focus (to show it in red and not change the color while user is typing)

    const turnOnExplanationInfo = () => {
        if (fieldProps?.setActiveFieldId) {
            fieldProps.setActiveFieldId(id);
        } else {
            return; // If setActiveFieldId is not defined, the input field is not in the document assistance (e.g. the login)
        }

        // If another field is active and we hover over another icon, we want to blur the active field to avoid confusion
        if (document.activeElement.tagName === 'INPUT' && document.activeElement.id !== id) {
            // The blurring will trigger the useEffect below which turns off explanations,
            // therefore we set a brief timeout to turn on explanations again for the hovered field
            setTimeout(() => {
                fieldProps?.setActiveFieldId(id);
            }, 50);
        }
    };
    const turnOffExplanationInfo = () => {
        if (fieldProps?.setActiveFieldId) {
            fieldProps?.setActiveFieldId(null);
        } // If setActiveFieldId is not defined, the input field is not in the document assistance (e.g. the login)
    };
    const isExplanationOn = fieldProps?.explanationToggle;

    // Format time_stamp to date
    let details = Object.assign({}, tooltip?.explanationDetails);
    if (tooltip?.explanationDetails?.time_stamp) {
        // moment resolves the locale automatically
        details.time_stamp = moment(tooltip.explanationDetails.time_stamp).format(
            localStorage.getItem('i18nextLng') === 'de' ? 'Do MMMM, HH:mm' : 'MMMM Do, HH:mm'
        );
    }

    let hasDocumentMatching = tooltip?.explanationDetails?.document_matching_result !== undefined;

    // For nested fields (i.e. AddressField, CustomerField and ContactField) we only want to show the document matching info box for the respective id field
    if (
        fieldProps?.groupFieldName &&
        !['customerNumber', 'addressId', 'contactId'].includes(fieldProps.groupFieldName)
    ) {
        // Therefore we turn the document matching info box off for other subfields:
        hasDocumentMatching = false;
    }

    const matching_result = tooltip?.explanationDetails?.document_matching_result;
    const block_automation = tooltip?.explanationDetails?.block_automation;
    const threshold_configured = tooltip?.explanationDetails?.comparison_type !== 'exact_match';

    const getDocumentMatchingKind = () => {
        if (['no_document_found', 'no_document_item_found'].includes(matching_result)) {
            return tooltip?.explanationDetails?.document_matching_translation_key; // case 1,2
        }

        if (matching_result == 'ignore_matching') {
            return 'field_matching_required_or_optional_matching_ignored'; // case 23
        }

        // Field is mandatory
        if (required) {
            // field is empty
            if (empty && !isFocused && block_automation) {
                return 'field_mandatory__matching_required__field_empty'; // case 12
            }
            if (empty && !isFocused && !block_automation) {
                return 'field_mandatory__matching_optional__field_empty'; // case 13
            }

            // field isn't empty
            // matching mandatory
            if (block_automation && threshold_configured) {
                const mappingKindMap = {
                    values_match: 'field_mandatory__matching_required__threshold__field_not_empty__values_match', // case 6
                    value_in_range: 'field_mandatory__matching_required__threshold__field_not_empty__value_in_range', // case 7
                    values_not_match:
                        'field_mandatory__matching_required__threshold__field_not_empty__values_not_match', // case 8
                    deviation_accepted:
                        'field_mandatory__matching_required__threshold__field_not_empty__deviation_accepted', // case 9
                };
                details['threshold_unit'] = t(`assistance:explanation.${tooltip?.explanationDetails?.comparison_type}`);
                return mappingKindMap[matching_result];
            }
            if (block_automation && !threshold_configured) {
                const mappingKindMap = {
                    values_match: 'field_mandatory__matching_required__field_not_empty__values_match', // case 3
                    values_not_match: 'field_mandatory__matching_required__field_not_empty__values_not_match', // case 4
                    deviation_accepted: 'field_mandatory__matching_required__field_not_empty__deviation_accepted', // case 5
                };
                return mappingKindMap[matching_result];
            }
            //matching optional
            if (!block_automation && !threshold_configured) {
                const mappingKindMap = {
                    values_match: 'field_mandatory__matching_optional__field_not_empty__values_match', // case 10
                    values_not_match: 'field_mandatory__matching_optional__field_not_empty__values_not_match', // case 11
                    deviation_accepted: 'not_supported',
                };
                return mappingKindMap[matching_result];
            }
            if (!block_automation && threshold_configured) {
                return 'not_supported';
            }
        }

        // Field is optional
        if (!required) {
            // Field is empty
            if (empty && !isFocused) {
                return 'not_supported';
            }

            // Field isn't empty
            // Matching mandatory
            if (block_automation && threshold_configured) {
                const mappingKindMap = {
                    values_match: 'field_optional__matching_required__threshold__field_not_empty__values_match', // case 17
                    value_in_range: 'field_optional__matching_required__threshold__field_not_empty__value_in_range', // case 18
                    values_not_match: 'field_optional__matching_required__threshold__field_not_empty__values_not_match', // case 19
                    deviation_accepted:
                        'field_optional__matching_required__threshold__field_not_empty__deviation_accepted', // case 20
                };
                details['threshold_unit'] = t(`assistance:explanation.${tooltip?.explanationDetails?.comparison_type}`);
                return mappingKindMap[matching_result];
            }
            if (block_automation && !threshold_configured) {
                const mappingKindMap = {
                    values_match: 'field_optional__matching_required__field_not_empty__values_match', // case 14
                    values_not_match: 'field_optional__matching_required__field_not_empty__values_not_match', // case 15
                    deviation_accepted: 'field_optional__matching_required__field_not_empty__deviation_accepted', // case 16
                };
                return mappingKindMap[matching_result];
            }
            // Matching optional
            if (!block_automation && !threshold_configured) {
                const mappingKindMap = {
                    values_match: 'field_optional__matching_optional__field_not_empty__values_match', // case 21
                    values_not_match: 'field_optional__matching_optional__field_not_empty__values_not_match', // case 22
                    deviation_accepted: 'not_supported',
                };
                return mappingKindMap[matching_result];
            }
            if (!block_automation && threshold_configured) {
                return 'not_supported';
            }
        }
    };

    function getDocumentMatchingInformation() {
        const documentMatchingTextMessagesAndStyles = {
            no_order_found: ['error', 'OC-01'], // case 1
            no_order_item_found: ['error', 'OC-02'], // case 2
            field_mandatory__matching_required__field_empty: ['error', 'OC-10'], // case 12
            field_mandatory__matching_optional__field_empty: ['neutral', 'OC-10'], // case 13
            field_mandatory__matching_required__threshold__field_not_empty__values_match: ['success', 'OC-03'], // case 6
            field_mandatory__matching_required__threshold__field_not_empty__value_in_range: ['warning', 'OC-06'], // case 7
            field_mandatory__matching_required__threshold__field_not_empty__values_not_match: ['error', 'OC-11'], // case 8
            field_mandatory__matching_required__threshold__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 9
            field_mandatory__matching_required__field_not_empty__values_match: ['success', 'OC-03'], // case 3
            field_mandatory__matching_required__field_not_empty__values_not_match: ['error', 'OC-04'], // case 4
            field_mandatory__matching_required__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 5
            field_mandatory__matching_optional__field_not_empty__values_match: ['neutral', 'OC-07'], // case 10
            field_mandatory__matching_optional__field_not_empty__values_not_match: ['warning', 'OC-09'], // case 11
            field_optional__matching_required__threshold__field_not_empty__values_match: ['success', 'OC-03'], // case 17
            field_optional__matching_required__threshold__field_not_empty__value_in_range: ['warning', 'OC-06'], // case 18
            field_optional__matching_required__threshold__field_not_empty__values_not_match: ['error', 'OC-11'], // case 19
            field_optional__matching_required__threshold__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 20
            field_optional__matching_required__field_not_empty__values_match: ['success', 'OC-03'], // case 14
            field_optional__matching_required__field_not_empty__values_not_match: ['error', 'OC-04'], // case 15
            field_optional__matching_required__field_not_empty__deviation_accepted: ['success', 'OC-05'], // case 16
            field_optional__matching_optional__field_not_empty__values_match: ['neutral', 'OC-07'], // case 21
            field_optional__matching_optional__field_not_empty__values_not_match: ['neutral', 'OC-09'], // case 22
            field_matching_required_or_optional_matching_ignored: ['neutral', 'OC-12'], // case 23
            not_supported: '',
        };

        const documentMatchingActions = {
            field_mandatory__matching_required__field_not_empty__values_not_match: 'document_matching_accept_deviation', // case 4
            field_mandatory__matching_required__threshold__field_not_empty__value_in_range:
                'document_matching_accept_deviation', // case 7
            field_mandatory__matching_required__threshold__field_not_empty__values_not_match:
                'document_matching_accept_deviation', // case 8
            field_optional__matching_required__field_not_empty__values_not_match: 'document_matching_accept_deviation', // case 15
            field_optional__matching_required__threshold__field_not_empty__value_in_range:
                'document_matching_accept_deviation', // case 18
            field_optional__matching_required__threshold__field_not_empty__values_not_match:
                'document_matching_accept_deviation', // case 19
            field_matching_required_or_optional_matching_ignored: 'document_matching_restore_matching', // case 23
        };

        let documentMatchingKind = getDocumentMatchingKind();
        const documentMatchingTextMessageAndStyle = documentMatchingTextMessagesAndStyles?.[documentMatchingKind] || [
            'error',
            '',
        ];
        const documentMatchingStyle = documentMatchingTextMessageAndStyle[0];
        const showMatchingAction = documentMatchingActions?.[documentMatchingKind];
        let documentMatchingText = documentMatchingTextMessageAndStyle[1];
        documentMatchingText = documentMatchingText
            ? t(`assistance:explanation.${documentMatchingText}`, details)
            : documentMatchingText;
        return [documentMatchingText, documentMatchingStyle, showMatchingAction];
    }

    function getDocumentMatchingClass(colorStyleClass) {
        let dynamicClasses = '';

        // Check if the field info box should be shown or not and set the style accordingly
        if (
            isFieldActive &&
            isExplanationOn &&
            !(wasEmpty && !required) // Do not show info box if the field is empty+optional
        ) {
            dynamicClasses = `documentmatchinginfo documentmatching--info field__explanation_text field__info-box__open documentmatching--${colorStyleClass}`;
        } else {
            dynamicClasses = 'field__info-box__closed';
        }

        return dynamicClasses;
    }

    const documentMatchingInnerHtml = {};
    const [documentMatchingText, documentMatchingTextColor, showMatchingAction] = getDocumentMatchingInformation();
    const cleanedDocumentMatchingText = DOMPurify.sanitize(documentMatchingText);
    if (hasDocumentMatching && isFieldActive && !(wasEmpty && !required) && isExplanationOn) {
        // Only set innerHtml if the info box is expanded
        cleanedDocumentMatchingText &&
            (documentMatchingInnerHtml['dangerouslySetInnerHTML'] = { __html: cleanedDocumentMatchingText });
    }

    const DocumentMatchingFailed =
        hasDocumentMatching &&
        ['values_not_match', 'no_document_item_found', 'no_document_found'].includes(
            tooltip.explanationDetails.document_matching_result
        ) &&
        block_automation; // if this is set, it means that we are in the document matching case and we will overwrite the explanation box style

    const innerHtml = {};
    if (isFieldActive && !(wasEmpty && !required) && isExplanationOn) {
        // Only set innerHtml if the info box is expanded
        const cleanedConfidenceExplanationString = DOMPurify.sanitize(confidenceExplanationString.toString());
        innerHtml['dangerouslySetInnerHTML'] = { __html: cleanedConfidenceExplanationString };
    }

    // Logic to show loading icon and green background when field is updated
    const loading = fieldProps?.loading;
    const [wasFocused, setWasFocused] = React.useState(false);
    const [focusedAndLoading, setFocusedAndLoading] = React.useState(false);

    useEffect(() => {
        // Only the last focused field should be "wasFocused"
        if (document.activeElement.tagName === 'INPUT' && isFieldActive && !loading) setWasFocused(true);
        else if (document.activeElement.tagName === 'INPUT' && !isFocused && !loading) setWasFocused(false);
    }, [document.activeElement]);

    useEffect(() => {
        if (wasFocused && loading) {
            // Field is being updated
            setFocusedAndLoading(true);
        } else if (wasFocused && !loading) {
            // Field is updated (since loading changed to false)
            setTimeout(() => {
                setFocusedAndLoading(false);
                setFieldValueUpdated(false); // delay to avoid issues when other useEffect are triggered first
            }, 1200);
            setWasFocused(false);
        }
    }, [loading]);

    // Check if field_value changed
    const [fieldValueUpdated, setFieldValueUpdated] = React.useState(false);
    const prevFieldValue = useRef(tooltip?.explanationDetails?.field_value).current;
    useEffect(() => {
        if (prevFieldValue !== tooltip?.explanationDetails?.field_value) {
            setFieldValueUpdated(true);
        }
    }, [tooltip?.explanationDetails?.field_value]);

    // Check if a non-empty field gets cleared with clear button (we want to show the loading icon and green animation)
    const prevEmpty = useRef(empty).current;
    useEffect(() => {
        if (!prevEmpty) {
            // Show loading
            setWasFocused(true);
            // and green animation on success
            if (loading) setFocusedAndLoading(true);
        }
    }, [empty]);

    const [mouseOnComponent, setMouseOnComponent] = React.useState(false);
    // When clicking anywhere (-> field not focused anymore) and the mouse has left the component,
    // we want to turn off the explanations
    useEffect(() => {
        if (!mouseOnComponent && isFieldActive && !isFocused) {
            turnOffExplanationInfo();
        }
    }, [isFocused]);
    // ___________________________________________________________________________________________

    // filter field props that don't belong on a div
    const renderableFieldProps = omit(fieldProps, [
        'fieldName',
        'fieldConfigs',
        'channelId',
        'fieldType',
        'handleAcceptDeviation',
        'activeFieldId',
        'setActiveFieldId',
        'explanationToggle',
        'loading',
        'tooltipTriggerRef',
    ]);

    return (
        <div
            onMouseLeave={() => {
                // If the field is focused we don't want to turn off the explanation when hovering over this field's icon
                if (!isFocused && isFieldActive) {
                    turnOffExplanationInfo();
                }
                setMouseOnComponent(false);
            }}
            onMouseEnter={() => {
                setMouseOnComponent(true);
            }}
        >
            <div
                className={classnames(
                    isExplanationOn && 'field__explanations_wrapper',
                    isExplanationOn && wasFocused && loading && 'field__loading--icon',
                    !isExplanationOn && wasFocused && loading && 'field__explanations_wrapper--legacy'
                )}
            >
                <div
                    onFocus={() => {
                        setIsFocused(true);
                        turnOnExplanationInfo();
                        if (empty) setWasEmpty(true);
                    }}
                    onBlur={() => {
                        setIsFocused(false);
                        setWasFocused(true);
                        setWasEmpty(false);
                    }}
                    className={classnames(
                        'field',
                        required ? 'field--required' : 'field--optional',
                        empty && !isFocused && 'field--empty',
                        disabled && 'field--disabled',
                        trailing && 'field--has-trailing',
                        invalid && 'field--invalid',
                        ['explanation.decimal_quantity', 'explanation.out_of_range_validation_error'].includes(
                            tooltip?.translationKey
                        ) && 'field--validation-error',
                        focusedAndLoading &&
                            !loading &&
                            !(empty && required) &&
                            !DocumentMatchingFailed &&
                            fieldValueUpdated &&
                            'field__loading',
                        className,
                        // add document matching classes
                        DocumentMatchingFailed && 'documentmatching--fail',
                        `documentmatchingstyle--${documentMatchingTextColor}`
                    )}
                    {...renderableFieldProps}
                >
                    {label && (
                        <label className="field__label" htmlFor={id}>
                            {label}
                        </label>
                    )}

                    <div className="field__content">{children}</div>

                    {trailing && (
                        <div className={classnames('field__trailing', trailingFloating && 'field__trailing--floating')}>
                            {trailing}
                        </div>
                    )}
                </div>
                {decoration && (
                    <div className={classnames('field__decoration', className)}>
                        <div className="field__decoration--spacer">{label}</div>
                        <div className="field__decoration--message">{decoration}</div>
                    </div>
                )}
                <span
                    onMouseEnter={turnOnExplanationInfo}
                    className={classnames(
                        isFieldActive && 'field__explanations--active',
                        isExplanationOn && 'field__explanations',
                        isExplanationOn &&
                            [
                                'explanation.master_data_match',
                                'explanation.extracted_article_number_and_description',
                                'explanation.extracted_article_description',
                                'explanation.extracted_article_number',
                                'explanation.multiple_good_results',
                            ].includes(tooltip?.translationKey) &&
                            !(
                                hasDocumentMatching &&
                                ['values_not_match', 'no_document_item_found', 'no_document_found'].includes(
                                    tooltip.explanationDetails.document_matching_result
                                )
                            ) &&
                            'field__source_database',
                        isExplanationOn &&
                            tooltip?.translationKey === 'explanation.historical_matches' &&
                            !DocumentMatchingFailed &&
                            'field__source_magic',
                        isExplanationOn &&
                            tooltip?.translationKey === 'explanation.user_correction' &&
                            !DocumentMatchingFailed &&
                            'field__source_user',
                        isExplanationOn &&
                            tooltip?.translationKey === 'explanation.extracted_value' &&
                            !DocumentMatchingFailed &&
                            'field__source_document',
                        isExplanationOn &&
                            ['explanation.decimal_quantity', 'explanation.out_of_range_validation_error'].includes(
                                tooltip?.translationKey
                            ) &&
                            !DocumentMatchingFailed &&
                            'field__error_icon',
                        isExplanationOn &&
                            tooltip?.translationKey === 'explanation.customization_info' &&
                            !DocumentMatchingFailed &&
                            'field__source_customization'
                    )}
                ></span>
            </div>

            {isExplanationOn && confidenceExplanationString && (
                <div
                    className={classnames(
                        // expand info box if isFieldActive, explanations are turned on and it is not empty+optional
                        isFieldActive && !(wasEmpty && !required) && 'field',
                        isFieldActive && !(wasEmpty && !required) && 'field__explanation--info',
                        isFieldActive && !(wasEmpty && !required) && 'field__explanation_text',
                        isFieldActive && !(wasEmpty && !required) && 'field__info-box__open',
                        isFieldActive &&
                            !(wasEmpty && !required) &&
                            ['explanation.decimal_quantity', 'explanation.out_of_range_validation_error'].includes(
                                tooltip?.translationKey
                            ) &&
                            'field__explanation--validation-check',
                        !isFieldActive && !(wasEmpty && !required) && 'field__info-box__closed'
                    )}
                    {...innerHtml}
                ></div>
            )}

            {hasDocumentMatching && isExplanationOn && documentMatchingText && (
                <div // Contains the document matching tooltip
                    className={classnames(`${getDocumentMatchingClass(documentMatchingTextColor)}`)}
                >
                    <div {...documentMatchingInnerHtml}></div>
                    {showMatchingAction ? (
                        <a
                            className={classnames('documentmatching--accept')}
                            onMouseDown={(e) => {
                                fieldProps?.handleMatchingAction?.(showMatchingAction);
                                setTimeout(() => {
                                    turnOnExplanationInfo();
                                }, 5);
                            }}
                        >
                            {t(`assistance:${showMatchingAction}`)}
                        </a>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export const TextField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        autoResize = false,
        onUpdate,
        inputProps = {},
        onClear = undefined,
        maxLength = null,
        ...fieldProps
    } = props;

    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        setValue(event.target.value);
        if (inputProps?.onChange) inputProps?.onChange(event, ...eventProps);
    };

    const handleBlur = (event, ...eventProps) => {
        if (inputProps?.onBlur) inputProps?.onBlur(event, ...eventProps);
        if (onUpdate && initialValue !== value) onUpdate(value);
    };

    const disabled = inputProps?.disabled || inputProps?.readOnly;

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={disabled}
        >
            {autoResize ? (
                // See https://css-tricks.com/auto-growing-inputs-textareas/
                <span
                    role="textbox"
                    contentEditable={false}
                    className="field__input field__input--text-resize"
                    id={fieldId}
                    {...inputProps}
                    onChange={handleChange}
                    onBlur={handleBlur}
                >
                    {value || ''}
                </span>
            ) : (
                <textarea
                    className="field__input field__input--text"
                    id={fieldId}
                    {...inputProps}
                    value={value || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={maxLength}
                />
            )}
            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
            {value && maxLength && <div className="field__input--text-counter">{`${value.length}/${maxLength}`}</div>}
        </FieldWrapper>
    );
};

export const ArrayField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        onUpdate,
        inputProps = {},
        onClear = undefined,
        ...fieldProps
    } = props;

    /* ArrayField expects the initialValue on the record to be a string that is a JSON array e.g.: '["a", "b", "c"]'
     * If this is not the case, there will be no parsing and the raw value will be displayed.
     *
     * When the user changes or adds an element, each element of the elements are expected to be on separate lines and separated by a semicolon
     * (tailing semicolon will be added automatically to the last element).
     * This means in particular that an element can span multiple lines if the individual lines are not separated by a semicolon.
     */

    const arrayToString = (s) => {
        try {
            s = JSON.parse(s);
            if (s.length === 0 || (s.length === 1 && s[0] === '')) {
                // We want to show an empty field if the array is empty
                return '';
            }
            s = s.join(';\n') + ';';
            return s;
        } catch (SyntaxError) {
            // Not a JSON array - continue with initial value
            return s;
        }
    };

    const stringToArray = (s) => {
        let newArray = s.split(';\n');
        newArray = JSON.stringify(newArray);
        if (value.slice(-1) === ';') {
            return JSON.stringify(value.slice(0, -1).split(';\n'));
        } else {
            return JSON.stringify(value.split(';\n'));
        }
    };

    const displayArray = arrayToString(initialValue); // Array as a string with semicolons and newlines that is displayed
    const [valueArray, setValueArray] = useState(null); // Array that is send to the backend
    const [value, setValue] = useControllableState(displayArray, controlledValue, setControlledValue);

    useEffect(() => {
        // bring new value into JSON array format for onBlur event
        setValueArray(stringToArray(value));
    }, [value]);
    useEffect(() => {
        // Update value if loading ended: i.e. add a trailing semicolon if not present
        if (!fieldProps.loading) {
            setValue(displayArray);
        }
    }, [fieldProps.loading]);

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        setValue(event.target.value);
        if (inputProps?.onChange) {
            inputProps?.onChange(event, ...eventProps);
        }
    };

    const handleBlur = (event, ...eventProps) => {
        if (inputProps?.onBlur) {
            inputProps?.onBlur(event, ...eventProps);
        }
        if (onUpdate && initialValue !== value) {
            // use valueArray instead of value
            onUpdate(valueArray);
        }
    };

    const disabled = inputProps?.disabled || inputProps?.readOnly;

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={disabled}
        >
            <textarea
                className="field__input field__input--text"
                id={fieldId}
                {...inputProps}
                value={value || ''}
                onChange={handleChange}
                onBlur={handleBlur}
            />

            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
        </FieldWrapper>
    );
};

export const ReadOnlyField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        autoResize = false,
        onUpdate,
        inputProps = {},
        onClear = undefined,
        ...fieldProps
    } = props;

    const { current: fieldId } = useRef(uniqueId('field-'));
    inputProps.readOnly = true;
    inputProps.disabled = true;
    inputProps.required = false; // just to make sure
    fieldProps.tooltip = undefined; // we do not want to show any explanantions/icons for read only fields
    return (
        <FieldWrapper label={label} id={fieldId} {...fieldProps} empty={false} required={false} disabled={true}>
            {
                <span
                    role="textbox"
                    contentEditable={false}
                    className="field__input field__input--text-resize"
                    id={fieldId}
                    {...inputProps}
                >
                    {fieldProps.value || initialValue || ''}
                </span>
            }
        </FieldWrapper>
    );
};

export type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input = (props: InputProps) => {
    const { ...passThroughProps } = props;

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            props.onSubmit?.(event.target.value);
        }
        props.onKeyDown?.(event);
    };

    return <input {...passThroughProps} onKeyDown={handleKeyDown} />;
};

export const TimeField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        onUpdate,
        inputProps = {},
        locale = undefined,
        onClear = undefined,
        ...fieldProps
    } = props;

    const [value, setValue]: [string, any] = useControllableState(initialValue, controlledValue, setControlledValue);

    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        // Check if the value matches the time format regex
        if (event.target.value && !TIME_VALIDATION_REGEXP.test(event.target.value)) {
            event.preventDefault();
            return;
        }
        if (event.target.value.length == 2 && value.length !== 3) {
            // If a second number is being added, automatically add a colon
            setValue(event.target.value + ':');
        } else {
            // Otherwise set it as is
            setValue(event.target.value);
        }
        if (inputProps?.onChange) inputProps?.onChange(event, ...eventProps);
        inputProps.placeholder = 'hh:mm';
    };

    const handleBlur = (event, ...eventProps) => {
        let complete_value = value;
        if (inputProps?.onBlur) inputProps?.onBlur(event, ...eventProps);
        // Complete the string with the missing characters if it's not complete (i.e. '02:' or '02:4')
        if (value.length < 5) {
            complete_value = value + '00:00'.substring(value.length);
            setValue(complete_value);
        }
        if (onUpdate && initialValue !== value) onUpdate(complete_value);
    };

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={inputProps?.disabled || inputProps?.readOnly}
        >
            <input
                className="field__input field__input--time"
                id={fieldId}
                {...inputProps}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
        </FieldWrapper>
    );
};

export const StringField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        onUpdate, // TODO: this should be called onBlur
        onSubmit, // TODO: make this available for all input controls
        inputProps = {},
        onClear = undefined,
        maxLength = null,
        ...fieldProps
    } = props;
    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        setValue(event.target.value);
        if (inputProps?.onChange) inputProps?.onChange(event, ...eventProps);
    };

    const handleBlur = (event, ...eventProps) => {
        if (inputProps?.onBlur) inputProps?.onBlur(event, ...eventProps);
        if (onUpdate && initialValue !== value) onUpdate(value || '');
    };

    const handleFocus = (event, ...eventProps) => {
        if (inputProps?.onFocus) inputProps?.onFocus(event, ...eventProps);
    };

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={inputProps?.disabled || inputProps?.readOnly}
        >
            <Input
                className="field__input field__input--string"
                id={fieldId}
                {...inputProps}
                value={value || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onSubmit={onSubmit}
                maxLength={maxLength}
            />
            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
        </FieldWrapper>
    );
};

export const DecimalField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        onUpdate,
        inputProps = {},
        locale = undefined,
        decimalSeparator = undefined,
        onClear = undefined,
        ...fieldProps
    } = props;

    const localizeDecimal = (value) => {
        if (!decimalSeparator || !value) return value;

        value = value.toString();

        if (value.includes('.')) {
            return value.replace('.', decimalSeparator);
        } else if (value.includes(',')) {
            return value.replace(',', decimalSeparator);
        } else {
            return value;
        }
    };

    const [value, setValue]: [string, any] = useControllableState(
        localizeDecimal(initialValue),
        controlledValue,
        setControlledValue
    );

    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () =>
            void (initialValue !== undefined && initialValue !== value
                ? setValue(localizeDecimal(initialValue))
                : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        // last char if still typing
        const re = decimalSeparator ? new RegExp(`^\\d+[.|,|${decimalSeparator}]?\\d*$`) : DECIMAL_VALIDATION_REGEXP;
        if (event.target.value && !re.test(event.target.value)) {
            event.preventDefault();
            return;
        }
        setValue(event.target.value);
        if (inputProps?.onChange) inputProps?.onChange(event, ...eventProps);
    };

    const handleBlur = (event, ...eventProps) => {
        const nextValue = localizeDecimal(value);
        if (value !== nextValue) setValue(nextValue);
        if (inputProps?.onBlur) inputProps?.onBlur(event, ...eventProps);
        if (onUpdate && initialValue !== nextValue)
            onUpdate(nextValue !== undefined && nextValue !== '' ? nextValue : null);
    };

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={inputProps?.disabled || inputProps?.readOnly}
        >
            <input
                className="field__input field__input--decimal"
                id={fieldId}
                {...inputProps}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
        </FieldWrapper>
    );
};

export const IntegerField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        onUpdate,
        inputProps = {},
        onClear = undefined,
        ...fieldProps
    } = props;

    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        if (event.target.value && !DECIMAL_VALIDATION_REGEXP.test(event.target.value)) {
            event.preventDefault();
            return;
        }
        setValue(event.target.value);
        if (inputProps?.onChange) inputProps?.onChange(event, ...eventProps);
    };

    const handleBlur = (event, ...eventProps) => {
        if (inputProps?.onBlur) inputProps?.onBlur(event, ...eventProps);
        if (onUpdate && initialValue !== value) onUpdate(value !== undefined ? value : null);
    };

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={inputProps?.disabled || inputProps?.readOnly}
        >
            <input
                className="field__input field__input--integer"
                type="number"
                inputMode="numeric"
                step="1"
                id={fieldId}
                {...inputProps}
                value={value || ''}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
        </FieldWrapper>
    );
};

export const BooleanField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        onUpdate,
        inputProps = {},
        isWrapped = true,
        onClear = undefined,
        ...fieldProps
    } = props;

    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));

    const handleChange = (event, ...eventProps) => {
        const newValue = event.target.checked;
        setValue(newValue);
        if (inputProps?.onChange) inputProps?.onChange(event, ...eventProps);
        if (onUpdate) onUpdate(newValue);
    };

    if (!isWrapped) {
        return (
            <Checkbox
                className="field__input field__input--boolean"
                id={fieldId}
                {...{ ...inputProps, label }}
                checked={value || ''}
                onChange={handleChange}
            />
        );
    }

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={inputProps?.disabled || inputProps?.readOnly}
        >
            <Checkbox
                className="field__input field__input--boolean"
                id={fieldId}
                {...inputProps}
                checked={value}
                onChange={handleChange}
            />
            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
        </FieldWrapper>
    );
};

/**
 * An input control for dates combining a masked input field with a docked calendar.
 *
 * Dates are synced 2-way between the masked input & the calendar component. The calendar is positioned dynamically
 * to fit into the parent container / viewport (using popper), defaulting to the bottom-right of the input (if there
 * is sufficient space).
 *
 * Input values are expected to be given in ISO date format ('YYYY-MM-DD'), and value updates are propagated in ISO
 * date format as well. The presentation date format can be customized via the `format` prop, which affects the
 * internal state + presentation (i.e. how the date is rendered to the user), but not the input/output format.
 */
export const DateField = (props) => {
    const defaultDateFormat = 'DD.MM.YYYY';
    let {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        onUpdate,
        inputProps = {},
        onClear = undefined,
        format: dateFormat = defaultDateFormat, // presentation format, use german date format by default
        fromYear = 2000,
        toYear = new Date(new Date().setFullYear(new Date().getFullYear() + 25)).getFullYear(), // in 25 years
        toDate = undefined,
        ...fieldProps
    } = props;

    if (Array.isArray(controlledValue)) {
        console.warn(`Array-type 'DateField.props.value' may result in incorrect behaviour.`);
        controlledValue = controlledValue[0];
    }
    if (dateFormat == null) {
        console.warn('`DateField.props.format` cannot be null, defaulting to ISO format for presentation');
        dateFormat ??= isoDateFormat;
    }
    dateFormat = dateFormat.toUpperCase(); // ensure uppercase date format

    toYear = typeof toYear === 'string' ? parseInt(toYear) : toYear;
    fromYear = typeof fromYear === 'string' ? parseInt(fromYear) : fromYear;
    const minDate = new Date(fromYear, 0, 1);
    const maxDate = toDate != null ? toDate : new Date(toYear + 1, 0, 1);

    const inputMaskPlaceholder = dateFormat.toLowerCase();
    const inputMask = dateFormat.replaceAll(/[ymd]/gi, '9'); // for react-input-mask, e.g. "YYYY/MM/DD" => "9999/99/99"
    const isoMask = (isoDateFormat as any).replaceAll(/[ymd]/gi, '9');

    const isCompleteDate = (dateString: string, mask = inputMask) =>
        dateString != null && dateString != '' && (dateString as any).replaceAll(/[0-9]/g, '9') == mask;
    const isIncompleteDate = (dateString: string, mask = inputMask) => !isCompleteDate(dateString, mask);

    const isAllowedDate = (dateString: string) =>
        isCompleteDate(dateString) &&
        isDateInRange(moment(dateString, dateFormat).toDate(), minDate, maxDate, dateFormat);

    const { t } = useTranslation('assistance');
    const { current: fieldId } = useRef(uniqueId('field-'));

    // Ensure input values (`initialValue`, `controlledValue`) are ISO date strings & convert them to the
    // internal / presentation date format (`dateFormat`)
    // Note: when using a controlled value, we cannot always expect an ISO-formatted input value, so we need
    // to convert back & forth here (`controlledValue` = `inputValue`, a masked string in the presentation format)
    const initialValueFormatted =
        initialValue != null && initialValue != '' && isCompleteDate(initialValue, isoMask)
            ? moment(toISODateString(initialValue, [dateFormat]), isoDateFormat).format(dateFormat)
            : initialValue;
    const controlledValueFormatted =
        controlledValue != null && controlledValue != '' && isCompleteDate(controlledValue, isoMask)
            ? moment(toISODateString(controlledValue, [dateFormat]), isoDateFormat).format(dateFormat)
            : controlledValue;

    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () =>
            void (initialValueFormatted !== undefined && initialValueFormatted !== inputValue
                ? setInputValue(initialValueFormatted)
                : undefined),
        [initialValueFormatted]
    );

    const [inputValue, _setInputValue] = useControllableState(
        initialValueFormatted,
        controlledValueFormatted,
        setControlledValue
    );
    // Note: Wrap inputValue setter here to prevent setting the placeholder mask itself as an input value
    const setInputValue = (value) => value != inputMaskPlaceholder && _setInputValue(value);
    const defaultViewedDate = !!(controlledValue ?? initialValue)
        ? moment(controlledValue ?? initialValue, isoDateFormat).toDate()
        : new Date();
    const defaultSelectedDate = !!(controlledValue ?? initialValue)
        ? moment(controlledValue ?? initialValue, isoDateFormat).toDate()
        : null;
    const [viewedMonth, setViewedMonth] = useState<Date>(defaultViewedDate);
    const [selectedDate, _setSelectedDate] = useState<Date>(defaultSelectedDate);
    const setSelectedDate = (date: Date) => {
        // Ensure that if a date is selected (e.g. by typing in a complete & valid date), we force the calendar view
        // to the selected month
        setViewedMonth(date);
        _setSelectedDate(date);
    };
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    // Focus Management
    const [isInputFocused, setIsInputFocused] = useState(true);
    const [isPickerFocused, setIsPickerFocused] = useState(false);

    useEffect(() => {
        // Close the picker when neither the picker nor the input are focused (e.g. outside click, tab away - when
        // the user wants to stop interacting with the date field)
        if (!isInputFocused && !isPickerFocused) {
            handleFieldBlur();
        }
    }, [isInputFocused, isPickerFocused]);

    const popperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [pickerElement, setPickerElement] = useState<HTMLDivElement | null>(null);

    const popper = usePopper(popperRef.current, pickerElement, {
        // Set popper config so that we always try to open to the bottom (if there is space in the parent container /
        // viewport), otherwise popper will position dynamically
        placement: 'bottom-start',
        modifiers: [{ name: 'preventOverflow', enabled: true, options: { altAxis: true } }],
    });

    const handleSubmit = (value: string, { clearIncomplete = true, clearFocus = true } = {}) => {
        if (value == null || value == '' || (isCompleteDate(value) && isAllowedDate(value))) {
            setInputValue(value);
            if (value == null || value == '') {
                setSelectedDate(null);
            } else {
                setSelectedDate(moment(value, dateFormat).toDate());
            }
            propagateUpdate(value);
            if (clearFocus) {
                forceClearFocus();
            }
        } else if (isIncompleteDate(value)) {
            if (clearIncomplete) {
                handleClear();
            }
        }
    };

    const forceClearFocus = () => {
        inputRef.current.blur();
        setIsInputFocused(false);
        setIsPickerFocused(false);
    };

    const handleFieldBlur = () => {
        // Close the picker & submit the value
        // The intended behaviour here is as follows:
        // 1. Only trigger the update mutation when the date is complete & valid
        // 2. When the field is incomplete, we always clear when losing focus
        // 3. When the field is complete but invalid, we retain the invalid value with some indicator to inform the
        //    user but don't trigger the update mutation
        handleSubmit(inputValue, { clearFocus: false });
        setIsPickerOpen(false);
        if (inputProps?.onBlur) inputProps?.onBlur(null);
    };

    const propagateUpdate = (newDate: Date | string) => {
        if (typeof newDate !== 'string') {
            newDate = moment(newDate).format(dateFormat);
        }
        // Convert from internal / presentational `dateFormat` to output date format (ISO string)
        newDate = toISODateString(newDate, [dateFormat]) as string;
        if (onUpdate && initialValue !== newDate) onUpdate(newDate || null);
    };

    const handleInputChange = (e, ...eventProps) => {
        let newInputValue = e.target.value;

        // Autofill zeros when entering days or months that wouldn't make sense otherwise
        if (newInputValue.length > 0 && newInputValue.length <= dateFormat.length) {
            const currentMaskPosition = newInputValue.length - 1;
            const currentMaskCharacter = dateFormat[currentMaskPosition];
            const isDatePartComplete =
                newInputValue.length == dateFormat.length ||
                dateFormat[currentMaskPosition + 1] != currentMaskCharacter;

            if (!isDatePartComplete) {
                const newCharacter = newInputValue[newInputValue.length - 1];
                if (currentMaskCharacter.toLowerCase() == 'd') {
                    if (newCharacter >= 4) {
                        newInputValue =
                            newInputValue.substring(0, Math.max(newInputValue.length - 2, 0)) + '0' + newCharacter;
                    }
                }
                if (currentMaskCharacter.toLowerCase() == 'm') {
                    if (newCharacter >= 2) {
                        newInputValue =
                            newInputValue.substring(0, Math.max(newInputValue.length - 2, 0)) + '0' + newCharacter;
                    }
                }
            }
        }

        setInputValue(newInputValue);

        // Auto-select in calendar when typing a complete & valid date
        if (isCompleteDate(newInputValue) && isAllowedDate(newInputValue)) {
            setSelectedDate(moment(newInputValue, dateFormat).toDate()); // update picker's internal state
        }

        if (newInputValue == null || newInputValue == '') {
            handleClear();
        }

        if (inputProps?.onChange) inputProps?.onChange(event, ...eventProps);
    };

    const handleClear = (...args) => {
        // Reset to today when clearing input
        setInputValue(null);
        setSelectedDate(null);
        setViewedMonth(new Date());
        handleSubmit(null);
        onClear && onClear(...args);
    };

    const handleInputFocus = (event) => {
        setIsPickerOpen(true);
        setIsInputFocused(true);
        if (inputProps?.onFocus) inputProps?.onFocus(event);
    };

    const handlePickerFocus = () => {
        setIsPickerFocused(true);
        if (inputProps?.onFocus) inputProps?.onFocus(null);
    };

    const handleInputBlur = (event, ...eventProps) => {
        setIsInputFocused(false);
    };

    const handleDateSelect = (date: Date) => {
        if (date == null) {
            // when clicking on the already selected date
            forceClearFocus();
            return;
        }
        handleSubmit(moment(date).format(dateFormat));
        setIsPickerFocused(false);

        if (inputProps?.onChange) inputProps?.onChange(undefined);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(inputValue, { clearIncomplete: false });
        }
    };

    const hasUserInput = inputValue && inputValue != inputMaskPlaceholder;
    const isInvalid = isCompleteDate(inputValue) && !isAllowedDate(inputValue);

    // Fix cursor position to always be at the end of the input field (when we inject additional zeros when entering
    // days/months)
    if (inputRef.current != null && isInputFocused) {
        inputRef.current.selectionStart = inputRef.current.value.length;
        inputRef.current.selectionEnd = inputRef.current.value.length;
    }

    // Convert some tooltip values (containing dates) to the custom date format
    const explanationDetails = fieldProps?.tooltip?.explanationDetails;
    const dateFieldNames = ['field_value', 'expected_value', 'obtained_value'];
    for (const dateFieldName of dateFieldNames) {
        if (
            explanationDetails?.[dateFieldName] &&
            moment(explanationDetails?.[dateFieldName], isoDateFormat, true).isValid()
        ) {
            explanationDetails[dateFieldName] = moment(explanationDetails[dateFieldName]).format(dateFormat);
        }
    }

    return (
        <>
            <div className="popper-container legacy-date-field" ref={popperRef}>
                <FieldWrapper
                    label={dateFormat != defaultDateFormat ? `${label} (${dateFormat})` : label}
                    id={fieldId}
                    {...fieldProps}
                    empty={isEmptyValue(inputValue)}
                    required={inputProps?.required}
                    disabled={inputProps?.disabled || inputProps?.readOnly}
                    invalid={isInvalid}
                >
                    <InputMask
                        ref={inputRef}
                        id={fieldId}
                        className="field__input field__input--date"
                        {...inputProps}
                        placeholder={inputMaskPlaceholder}
                        mask={inputMask}
                        maskPlaceholder={null} // placeholder when clicking into the field
                        always
                        value={inputValue || ''}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        onFocus={handleInputFocus}
                        onKeyDown={handleKeyDown}
                    />
                    {hasUserInput && <a className="field__clear" onClick={handleClear} />}
                </FieldWrapper>
                {isPickerOpen && (
                    // Trap focus within this DOM subtree so that
                    // 1. when the user hits TAB it cycles through DOM nodes in the docker picker
                    // 2. when the user interacts with other controls (e.g. year/month dropdown), we don't lose focus &
                    //    close the docked picker
                    <FocusTrap
                        focusTrapOptions={{
                            initialFocus: false,
                            allowOutsideClick: true,
                            clickOutsideDeactivates: true,
                            returnFocusOnDeactivate: false,
                            fallbackFocus: inputRef.current,
                            onActivate: handlePickerFocus,
                            onDeactivate: () => setIsPickerFocused(false),
                        }}
                    >
                        <div
                            ref={setPickerElement}
                            role="dialog"
                            className="dialog-sheet"
                            // Inject popper's positioning
                            style={popper.styles.popper}
                            {...popper.attributes.popper}
                        >
                            <DayPicker
                                initialFocus={false}
                                mode="single"
                                month={viewedMonth ?? selectedDate ?? defaultViewedDate}
                                onMonthChange={setViewedMonth}
                                selected={selectedDate}
                                onSelect={handleDateSelect}
                                showWeekNumber={true}
                                onWeekNumberClick={undefined}
                                fromYear={fromYear}
                                toYear={toDate != null ? null : toYear}
                                toDate={toDate}
                                captionLayout="dropdown"
                                locale={getDateLocalizations()}
                                footer={
                                    isInvalid && (
                                        <Alert severity="error" className="alert-modal__alert alert--no-margin">
                                            {t('assistance:fields.dateField.invalid', {
                                                minDate: moment(minDate).format(dateFormat),
                                                maxDate: moment(maxDate).format(dateFormat),
                                            })}
                                        </Alert>
                                    )
                                }
                            />
                        </div>
                    </FocusTrap>
                )}
            </div>
        </>
    );
};

/**
 * Field components that renders a dropdown with menu items.
 *
 * Note:
 *     Select field options can be provided either as a list of plain strings (`choices`) or objects (`options`).
 *     When providing options as plain strings, the string is used both as a label & value for the menu item.
 *
 *     Where possible, prefer using option objects over plain string. Plain strings are still supported for
 *     backward-compatibility and will be deprecated in the future.
 */
export const SelectField = (props) => {
    let {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,

        choices,
        options,
        getDisplayValue,

        onUpdate,
        inputProps = {},
        menuOrientation = 'left',
        onClear = undefined,
        ...fieldProps
    } = props;

    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (initialValue !== undefined && initialValue !== value ? setValue(initialValue) : undefined),
        [initialValue]
    );

    const { current: fieldId } = useRef(uniqueId('field-'));
    const inputRef = useRef(null);

    options ??= value?.options || [];
    choices ??= value?.choices || [];

    const menuItems = toMenuItems(choices, options);

    const handleMenuItemClick = (itemValue, data) => {
        const newValue = itemValue;
        setValue(newValue);
        onUpdate(newValue, data);
    };

    // Display the label for the currently selected value on the input element
    let inputValue = value || '';
    if (getDisplayValue && value) {
        inputValue = getDisplayValue(value);
    } else if (value) {
        for (let menuItem of menuItems) {
            if (value == menuItem.value) {
                inputValue = menuItem.label;
            }
        }
    }

    const isFieldDisabled = inputProps?.disabled || inputProps?.readOnly;

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={isFieldDisabled}
            tooltipTriggerRef={inputRef}
        >
            {isFieldDisabled ? (
                <input
                    className="field__input field__input--select"
                    id={fieldId}
                    {...inputProps}
                    value={inputValue}
                    readOnly={true}
                />
            ) : (
                <DropDown className={classnames('field__autocomplete')} menuOrientation={menuOrientation}>
                    <DropDownToggle>
                        <input
                            className="field__input field__input--select"
                            id={fieldId}
                            {...inputProps}
                            value={inputValue}
                            readOnly={true}
                            ref={inputRef}
                        />
                        <div className="field__select-icon" />
                    </DropDownToggle>

                    <DropDownMenu className="menu--scroll" activeValue={value}>
                        {!inputProps?.required && (
                            <MenuItem
                                key="empty"
                                value=""
                                label="&nbsp;"
                                onClick={() => handleMenuItemClick('', undefined)}
                            />
                        )}
                        {menuItems.map(({ value, label, description, data, disabled: isItemDisabled }) => (
                            <MenuItem
                                key={value}
                                value={value}
                                label={label}
                                description={description}
                                onClick={() => handleMenuItemClick(value, data)}
                                forceOnClick={true}
                                disabled={isFieldDisabled ?? isItemDisabled ?? false}
                            />
                        ))}
                    </DropDownMenu>
                </DropDown>
            )}
            {value && onClear && <a className="field__clear" onClick={(e) => onClear(e)} />}
        </FieldWrapper>
    );
};

/**
 * Field component that renders an auto-completed text input.
 *
 * Configurable behaviour:
 *
 *     - Fixed list of items vs Async callback. The items that will be shown to the user can be provided either via
 *       an async callback that returns the suggestions matching the current input, or as a fixed list (via `options`
 *       or `choices`) that is filtered via a matching function to generate the suggestions (by default, items are
 *       matched on both their label & value). When providing a complete & fixed list of items, this field also
 *       functions like a SelectField, meaning that all options are shown when clicking on the down arrow (can be
 *       disabled via `allowShowAllItems`).
 *
 *     - Freetext vs Force Selection. In freetext mode, the input accepts any user value even if the value is not
 *       included in the data / items that are backing the field (i.e. the options are merely suggestions for helping /
 *       guiding the user). If force selection is enabled, the input values must be included in the data backing
 *       the field (i.e. they are validated against the list of items).
 *
 * @param props
 * @constructor
 */
export const AutocompleteField = (props) => {
    const {
        label,
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = undefined,
        forceSelection = true, // whether the input value must be included in the list of items (if any)
        defaultActiveFirstOption = true, // whether the first option in the suggestions will be auto-selected

        getItems = undefined, // callback for fetching items asynchronously
        choices, // fixed list of items
        options, // fixed list of items
        matchOptions = null, // optional callback for generating matching suggestions from the fixed list of items
        allowShowAllItems = true, // whether to add a selectbox-style icon that allows showing the fixed list of items
        resetInvalidToPreviousValue = false, // whether to reset the input value to the previous valid value

        maxItemsShown = null, // show all results by default
        onShowMore, // action callback for when there are more items available than `maxItemsShown`
        onShowMoreDefault, // action callback for when we want to show the option to search in the entire master data
        dropdownMessageBuilder = null, // for displaying custom messages in the dropdown
        searchOnEmptyValue = false, // Determine if an empty value should fetch new items

        onUpdate,
        inputProps = {},
        ...fieldProps
    } = props;

    const items = toMenuItems(choices, options);
    if (items != null && getItems !== undefined) {
        throw new Error(
            'Must provide either a fixed list of items (as choices / options prop) or ' +
                'an asynchronous callback to AutocompleteField, but not both.'
        );
    }

    const { t } = useTranslation('assistance');
    const { publishToast } = useToaster();
    const { current: fieldId } = useRef(uniqueId('field-'));
    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);
    const inputRef = useRef(null);

    // The context is only used to provide user and record info to the AppInsights telemetry
    const assistanceContext = useAssistanceContext();
    const applicationContext = useApplicationContext();

    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(() => {
        if (initialValue === undefined || initialValue === value) return;
        setValue(initialValue);
        setValidatedValue(initialValue);
        setInputValue(initialValue);
    }, [initialValue]);

    // validatedValue remembers the last "initialValue" so we can reset if needed
    const [validatedValue, setValidatedValue] = useState(value);
    const [inputValue, setInputValue] = useState(value);
    const [showDropdownMenu, setShowDropdownMenu] = useState(false);
    let [loading, setLoading] = useState(false);
    const [isInDebounceWindow, setIsInDebounceWindow] = useState(false);
    loading = loading || isInDebounceWindow; // show a fake loading state if we are typing rapidly

    const [isInputFocused, setInputFocused] = useState(false);
    let [menuItems, setMenuItems] = useState(items || []);
    const clearMenuItems = () => setMenuItems([]);

    // for temporarily disabling commit-on-blur behaviour in certain scenarios (e.g. when onShowMore opens a modal)
    const [disabledBlurBehaviour, setDisabledBlurBehaviour] = useState(false);

    // if controlled input, this way internal state gets updated
    useEffect(() => setInputValue(value), [value]);

    // Flag indicating whether to filter items in-memory or use the async callback
    const hasLocalItems = getItems == null && items != null;

    // Custom dropdown message
    const dropdownMessage = dropdownMessageBuilder?.(inputValue, menuItems);
    const hasDropdownMessage = dropdownMessage != null;

    // Case-insensitive match against both label & value by default
    // (only relevant when filtering a fixed list of items in-memory)
    const defaultFilterFunc = (searchString, searchItems) =>
        searchItems.filter(
            (item) =>
                item.label.toString().toLowerCase().startsWith(searchString.toString().toLowerCase()) ||
                item.value.toString().toLowerCase().startsWith(searchString.toString().toLowerCase())
        );
    const filterFunc = matchOptions ?? defaultFilterFunc;

    /**
     * Returns a promise of the matching items for the given input value by either
     *   1. delegating to the async callback or
     *   2. searching the fixed list of options in-memory & wrapping the result in a promise
     */
    const fetchItemSuggestions = (value): Promise<Array<IMenuItem>> => {
        setLoading(true);

        const resolveSuggestions = (value) => {
            // Uses either the async callback or in-memory filter to resolve suggestions
            const suggestionsPromise = !hasLocalItems ? getItems(value) : Promise.resolve(filterFunc(value, items));
            return suggestionsPromise?.finally(() => setLoading(false));
        };

        return resolveSuggestions(value);
    };

    const _fetchAndSetSuggestions = (value) => {
        fetchItemSuggestions(value).then((items) => {
            setMenuItems(items);
        });
    };
    const _debouncedFetchAndSetSuggestions = useCallback(
        useDebounce(
            (value) => {
                setIsInDebounceWindow(false);
                fetchItemSuggestions(value).then((items) => {
                    setMenuItems(items);
                });
            },
            300,
            { leading: true }
        ),
        [getItems]
    );
    const getSuggestions = (value) => {
        // use non-debounced fetch for in-memory search
        const isDebounced = !hasLocalItems;
        const fetchSuggestions = isDebounced ? _debouncedFetchAndSetSuggestions : _fetchAndSetSuggestions;
        if (isDebounced) {
            setIsInDebounceWindow(true);
        }
        return fetchSuggestions(value);
    };

    const getExactMatchingMenuItem = (value, { matchLabel = false }) => {
        for (let menuItem of menuItems) {
            let isMatchingItem = menuItem.value == inputValue;
            if (matchLabel) {
                isMatchingItem ||= menuItem.label == inputValue;
            }
            if (isMatchingItem) {
                return menuItem;
            }
        }
        return null;
    };

    const handleMenuItemSelect = ({ value: itemValue, data, disabled = false }) => {
        if (disabled) {
            return;
        }
        setInputValue(itemValue);
        setShowDropdownMenu(false);
        setDisabledBlurBehaviour(true); // make sure we don't execute onBlur after manually selecting

        // valid update through list selection
        const newValue = itemValue;
        setValue(newValue);
        setValidatedValue(newValue);
        onUpdate?.(newValue, data);
    };

    const handleChange = (event, ...eventProps) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);

        // Condition to determine if an empty value should fetch new results or not.
        const doNothingOnEmptyValue = newInputValue == '' && !searchOnEmptyValue;

        if (newInputValue == null || doNothingOnEmptyValue) {
            setShowDropdownMenu(hasDropdownMessage);
            clearMenuItems();
        } else {
            if (document.activeElement.id === fieldId) {
                setShowDropdownMenu(true);
            }
            getSuggestions(newInputValue);
        }
    };

    const handleFocus = (event, ...eventProps) => {
        setDisabledBlurBehaviour(false); // restore default commit behaviour when focused

        setInputFocused(true);
        setShowDropdownMenu(false);
        clearMenuItems();

        // Condition to determine if new results should be fetch or not.
        const fetchValues = inputValue != '' || searchOnEmptyValue;

        if (inputValue != null && fetchValues) {
            setShowDropdownMenu(true);
            getSuggestions(inputValue);
        }

        if (hasDropdownMessage) {
            setShowDropdownMenu(true);
        }

        inputProps?.onFocus?.(event, ...eventProps);
    };

    const handleBlur = (event, ...eventProps) => {
        const inputValueOnBlur = event.target.value;

        if (disabledBlurBehaviour) {
            return;
        }
        setInputFocused(false);
        setShowDropdownMenu(false);

        // We consider a value to be valid when it is contained in the list of results (`validatedValue` is set
        // to the most recently selected item). The input value's validation status is used in two ways:
        // 1. We can auto-select valid input values when the user only types into the field but doesn't
        //    select from the dropdown
        // 2. When force selection is enabled, we reset any input values that are invalid
        let isValidInputValue = validatedValue == inputValueOnBlur;

        // When losing focus without having selected an item from the dropdown (e.g. when the user is tabbing
        // through fields), we auto-select the item that is an exact match for the current input value (if any).
        // This way we enable the user to type the correct value without having to press enter to confirm the selection.
        if (inputValueOnBlur != null && inputValueOnBlur != '') {
            const matchingMenuItem = getExactMatchingMenuItem(inputValue, { matchLabel: true });
            const hasValueChanged = validatedValue != matchingMenuItem?.value;
            if (matchingMenuItem != null && hasValueChanged) {
                handleMenuItemSelect(matchingMenuItem);
                return;
            }
        }

        clearMenuItems();

        // Determine what the input value should be after losing focus (i.e. whether to keep the user's input)
        let newValue;
        let didForceReset = false;
        if (forceSelection) {
            if (isValidInputValue) {
                newValue = inputValueOnBlur;
            } else {
                newValue = resetInvalidToPreviousValue ? validatedValue : ''; // undefined does not work here since then no update will be triggered
                didForceReset = true;
            }
        } else {
            newValue = inputValue; // allow any value from typing
        }

        setValue(newValue);
        setValidatedValue(newValue);
        setInputValue(newValue);

        /* when should onBlur lead to an update:
           in case of forceSelection, when the user types an invalid value:
              (value !== newValue) === true
           in case of forceSelection, when the user empties the field:
              (value !== newValue) === false  // since both are ''
              (validatedValue !== ''  && value === '')  === true
         */
        let promise;
        if (onUpdate && (value !== newValue || (validatedValue !== '' && value === ''))) {
            promise = onUpdate(newValue); // potentially intercepted (e.g. confirmation dialog))
        } else {
            promise = Promise.resolve();
        }
        promise?.then(() => {
            if (didForceReset) {
                publishToast({ description: t(`assistance:fields.autocompleteField.valueResetFromForceSelection`) });
            }
        });

        inputProps?.onBlur?.(event, ...eventProps);
    };

    const showAllItems = () => {
        if (!hasLocalItems) {
            return; // never show the full ist of items when fetching async
        }
        setMenuItems(items);
        setShowDropdownMenu(true);
    };

    const addSearchDefaultMDMenuItem = () => {
        // Add the menu item option to search in the default master data

        let items = [];
        items.push(<MenuDivider />);
        items.push(
            <MenuItem
                value=""
                label={t(`masterdata:browser.searchEntireData`)}
                description=""
                disabled={false}
                onClick={() => {
                    setDisabledBlurBehaviour(true);
                    trapFocus(inputRef);
                    appInsights?.trackEvent(
                        ...showDefaultMDEvent(applicationContext.user, assistanceContext.record, document)
                    );
                    // queue up callback immediately after the state update (because useState doesn't have a promise
                    // to attach to...)
                    setTimeout(() => {
                        onShowMoreDefault({ ...props, value: visibleInputValue || '' });
                    }, 0); // might lose focus here because of onShowMore (i.e trigger blur)
                }}
            />
        );

        return items;
    };

    const addLookupNameLabel = () => {
        // Adds a menu item in the dropdown indicating the lookup name where the data comes from

        const lookupTypeLocalizationKey = snakeCase(props.lookupType?.replace('Type', '') || '').toUpperCase();
        const labelName = t(`masterdata:browser.dropDownTitle.` + lookupTypeLocalizationKey);

        return <MenuItem value="" label={labelName} description="" disabled={true} />;
    };

    const obtainMenuItems = (items) => {
        // Generate a list of menu items to be displayed in the dropdown menu.
        // If onShowMoreDefault is provided:
        // - Includes a label indicating the lookup name where the message comes from
        // - Add the option to open the browsable master data

        if (items != null) {
            let children = [];
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const { value, label, description, disabled, onClick, ...menuItemProps } = item;
                children.push(
                    <MenuItem
                        key={i}
                        value={value}
                        label={label}
                        description={description}
                        onClick={onClick ?? (() => handleMenuItemSelect(item))}
                        disabled={disabled ?? false}
                        {...menuItemProps}
                    />
                );
            }

            // Add the menu item option to search in the default master data
            if (onShowMoreDefault != null) {
                children.unshift(addLookupNameLabel());
                children.push(...addSearchDefaultMDMenuItem());
            }

            return children;
        } else {
            return []; // Return null if items is null
        }
    };

    const obtainEmptyDropDownItems = (dropdownMessage) => {
        // Generate the menu item containing an informative message to be displayed in the dropdown menu.
        // If onShowMoreDefault is provided:
        // - Includes a label indicating the lookup name where the message comes from
        // - Add the option to open the browsable master data

        let children = [];
        children.push(<MenuItem value="" label={dropdownMessage} disabled={true} />);

        // Add the menu item option to search in the default master data
        if (onShowMoreDefault != null) {
            children.unshift(addLookupNameLabel());
            children.push(...addSearchDefaultMDMenuItem());
        }

        return children;
    };

    // Determine the currently active suggestion in the dropdown's list of suggestions.
    // By order of priority:
    // 1. The suggestion corresponding to the current input value (by value or label)
    // 2. The suggestion corresponding to the currently selected value (can be different from input value)
    // 3. Otherwise, default to the first entry in the list of suggestions
    let activeValue;
    if (menuItems != null) {
        // Default to first entry in the list (if desired)
        if (defaultActiveFirstOption && menuItems.length > 0) {
            activeValue = menuItems[0].value;
        }
        let matchingMenuItem;
        // Override if current value matches any item
        matchingMenuItem = getExactMatchingMenuItem(value, { matchLabel: false });
        if (matchingMenuItem != null) {
            activeValue = matchingMenuItem.value;
        }
        // Override if current input matches any item
        matchingMenuItem = getExactMatchingMenuItem(inputValue, { matchLabel: true });
        if (matchingMenuItem != null) {
            activeValue = matchingMenuItem.value;
        }
    }

    // Display the label for the currently selected value on the input element
    let visibleInputValue = inputValue;
    for (let menuItem of menuItems) {
        if (inputValue == menuItem.value && menuItem.label != null && menuItem.label != '') {
            visibleInputValue = menuItem.label;
        }
    }

    // Fallback to regular input field
    if (getItems == null && items == null) {
        return (
            <StringField
                label={label}
                id={fieldId}
                {...fieldProps}
                inputProps={{
                    ...inputProps,
                    disabled: forceSelection,
                }}
                onUpdate={onUpdate}
                tooltip={
                    forceSelection && !inputProps?.readOnly && !inputProps?.disabled
                        ? t(`assistance:fields.autocompleteField.noDataForceSelection`)
                        : null
                }
            />
        );
    }

    const { trapFocus } = useFocusTrap();

    const hasMore = maxItemsShown != null && menuItems.length > maxItemsShown;
    if (onShowMore != null && hasMore) {
        menuItems = [
            ...menuItems.slice(0, maxItemsShown),
            {
                label: t(`masterdata:browser.openBrowser`),
                description: '',
                value: '',
                disabled: false,
                data: null,
                onClick: () => {
                    setDisabledBlurBehaviour(true);
                    trapFocus(inputRef);
                    appInsights?.trackEvent(
                        ...showMoreResultsEvent(applicationContext.user, assistanceContext.record, document)
                    );
                    // queue up callback immediately after the state update (because useState doesn't have a promise
                    // to attach to...)
                    setTimeout(() => {
                        onShowMore({ ...props, value: visibleInputValue || '' });
                    }, 0); // might lose focus here because of onShowMore (i.e trigger blur)
                },
            },
        ];
    }

    return (
        <FieldWrapper
            label={label}
            id={fieldId}
            {...fieldProps}
            empty={isEmptyValue(value)}
            required={inputProps?.required}
            disabled={inputProps?.disabled || inputProps?.readOnly}
            trailing={hasLocalItems && allowShowAllItems ? <Icon icon="arrowDown" onClick={showAllItems} /> : null}
            trailingFloating={true}
            tooltipTriggerRef={inputRef}
        >
            <DropDown
                className={classnames('field__autocomplete')}
                onCloseMenu={() => setShowDropdownMenu(false)}
                menuOrientation="left"
                menuVisible={showDropdownMenu}
            >
                <DropDownToggle>
                    <input
                        className="field__input field__input--string"
                        id={fieldId}
                        {...inputProps}
                        value={visibleInputValue || ''}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={inputRef}
                    />
                </DropDownToggle>

                {hasDropdownMessage ? (
                    // "fake" dropdown to render a custom info message
                    <DropDownMenu
                        className="menu--scroll"
                        items={[]}
                        // don't override the loading state (so that we don't need to handle it in the message builder)
                        loading={loading}
                        emptyItemsContent={obtainEmptyDropDownItems(dropdownMessage)}
                    />
                ) : (
                    // regular dropdown with result items
                    <DropDownMenu
                        className="menu--scroll"
                        activeValue={activeValue}
                        loading={loading}
                        onItemClick={(item) => handleMenuItemSelect(item)}
                        emptyItemsContent={!!inputValue ? obtainEmptyDropDownItems(dropdownMessage) : null}
                    >
                        {obtainMenuItems(menuItems)}
                    </DropDownMenu>
                )}
            </DropDown>
        </FieldWrapper>
    );
};

// TODO: consolidate this with other props-building functions (or remove since it's only used by hardcoded composite fields)
const useFields = (props) => {
    const {
        value: controlledValue,
        setValue: setControlledValue,
        initialValue = {},
        onUpdate,
        groupInputProps = {},
        groupFieldProps = {},
    } = props;

    const [value, setValue] = useControllableState(initialValue, controlledValue, setControlledValue);

    const getUpdatedValue = (fieldName, fieldValue) => ({ ...value, [fieldName]: fieldValue });

    const handleFieldChange = (fieldName, event, ...eventProps) => {
        setValue(getUpdatedValue(fieldName, event.target.value));
        if (groupInputProps?.[fieldName]?.onChange) groupInputProps?.[fieldName]?.onChange(event, ...eventProps);
    };

    const handleUpdate = (fieldName, fieldValue, data = undefined) => {
        const newValue = getUpdatedValue(fieldName, fieldValue);
        setValue(newValue);
        // TODO: this doesnt work if we want to force an update (e.g. for masterdata entity selections) even if the
        //  value hasn't changed
        if (onUpdate && !isEqual(initialValue, newValue)) {
            return onUpdate?.(newValue, data);
        }
    };

    const getFieldProps = (fieldName) => ({
        // conversion from value => {value: value} and back

        value: value?.[fieldName],
        groupFieldName: fieldName,
        setValue: (fieldValue) => setValue(getUpdatedValue(fieldName, fieldValue)),
        onUpdate: (fieldValue, data = undefined) => handleUpdate(fieldName, fieldValue, data),

        ...(groupFieldProps?.[fieldName] || {}),
        inputProps: {
            ...(groupInputProps?.[fieldName] || {}),
            onChange: (event, ...eventProps) => handleFieldChange(fieldName, event, ...eventProps),
        },
    });

    return { getFieldProps, value, setValue };
};

export const AddressFields = (props) => {
    const initialValue = {
        addressId: undefined,
        addressId2: undefined,
        name: undefined,
        companyName: undefined,
        streetAndNr: undefined,
        postcode: undefined,
        city: undefined,
        country: undefined,
        email: undefined,
        phone: undefined,
        misc: undefined,
        ...props.initialValue,
    };

    let { getFieldProps, value, setValue } = useFields({ ...props, initialValue });

    // in case initialValue gets changed from the outside we adjust internal state

    useEffect(() => {
        if (!isEqual(initialValue, value)) setValue(initialValue);
    }, [
        initialValue.addressId,
        initialValue.addressId2,
        initialValue.name,
        initialValue.companyName,
        initialValue.streetAndNr,
        initialValue.postcode,
        initialValue.city,
        initialValue.country,
        initialValue.email,
        initialValue.phone,
        initialValue.misc,
    ]);

    // Get all field props and store them:
    const addressIdProps = getFieldProps('addressId');
    const addressId2Props = getFieldProps('addressId2');
    const nameProps = getFieldProps('name');
    const companyNameProps = getFieldProps('companyName');
    const streetAndNrProps = getFieldProps('streetAndNr');
    const postcodeProps = getFieldProps('postcode');
    const cityProps = getFieldProps('city');
    const countryProps = getFieldProps('country');
    const emailProps = getFieldProps('email');
    const phoneProps = getFieldProps('phone');
    const miscProps = getFieldProps('misc');

    // Set the required state of all fields (we need the state for when the user is actively editing a field)
    const [addressIdRequired, setAddressIdRequired] = useState(addressIdProps.inputProps.required);
    const [addressId2Required, setAddressId2Required] = useState(addressId2Props.inputProps.required);
    const [nameRequired, setNameRequired] = useState(nameProps.inputProps.required);
    const [companyNameRequired, setCompanyNameRequired] = useState(companyNameProps.inputProps.required);
    const [streetAndNrRequired, setStreetAndNrRequired] = useState(streetAndNrProps.inputProps.required);
    const [postcodeRequired, setPostcodeRequired] = useState(postcodeProps.inputProps.required);
    const [cityRequired, setCityRequired] = useState(cityProps.inputProps.required);
    const [countryRequired, setCountryRequired] = useState(countryProps.inputProps.required);
    const [emailRequired, setEmailRequired] = useState(emailProps.inputProps.required);
    const [phoneRequired, setPhoneRequired] = useState(phoneProps.inputProps.required);
    const [miscRequired, setMiscRequired] = useState(miscProps.inputProps.required);

    // Function to check if all subfields are empty or undefined
    const allSubfieldsEmpty = () => {
        return (
            (addressIdProps.hidden || !addressIdProps.value || addressIdProps.value === '') &&
            (addressId2Props.hidden || !addressId2Props.value || addressId2Props.value === '') &&
            (nameProps.hidden || !nameProps.value || nameProps.value === '') &&
            (companyNameProps.hidden || !companyNameProps.value || companyNameProps.value === '') &&
            (streetAndNrProps.hidden || !streetAndNrProps.value || streetAndNrProps.value === '') &&
            (postcodeProps.hidden || !postcodeProps.value || postcodeProps.value === '') &&
            (cityProps.hidden || !cityProps.value || cityProps.value === '') &&
            (countryProps.hidden || !countryProps.value || countryProps.value === '') &&
            (emailProps.hidden || !emailProps.value || emailProps.value === '') &&
            (phoneProps.hidden || !phoneProps.value || phoneProps.value === '') &&
            (miscProps.hidden || !miscProps.value || miscProps.value === '')
        );
    };

    useEffect(() => {
        if (!props.activeFieldId && !props.required && allSubfieldsEmpty()) {
            // Overwrite all props to not be required (if parent is optional, all subfields are empty and the user is not actively editing a field)
            setAddressIdRequired(false);
            setAddressId2Required(false);
            setNameRequired(false);
            setCompanyNameRequired(false);
            setStreetAndNrRequired(false);
            setPostcodeRequired(false);
            setCityRequired(false);
            setCountryRequired(false);
            setEmailRequired(false);
            setPhoneRequired(false);
            setMiscRequired(false);
        } else if (!props.activeFieldId && !props.required && !allSubfieldsEmpty()) {
            // After a field has been edited and not all fields are empty anymore, we reset the required state to the initial state
            setAddressIdRequired(addressIdProps.required);
            setAddressId2Required(addressId2Props.required);
            setNameRequired(nameProps.required);
            setCompanyNameRequired(companyNameProps.required);
            setStreetAndNrRequired(streetAndNrProps.required);
            setPostcodeRequired(postcodeProps.required);
            setCityRequired(cityProps.required);
            setCountryRequired(countryProps.required);
            setEmailRequired(emailProps.required);
            setPhoneRequired(phoneProps.required);
            setMiscRequired(miscProps.required);
        }
    }, [props.activeFieldId]);

    return (
        <>
            {[
                [addressIdProps, addressIdRequired],
                [addressId2Props, addressId2Required],
                [nameProps, nameRequired],
                [companyNameProps, companyNameRequired],
                [streetAndNrProps, streetAndNrRequired],
                [postcodeProps, postcodeRequired],
                [cityProps, cityRequired],
                [countryProps, countryRequired],
                [emailProps, emailRequired],
                [phoneProps, phoneRequired],
                [miscProps, miscRequired],
            ].map(([fieldProps, required]) => {
                let fieldTypeName = fieldProps?.fieldType || 'StringField';

                // Special case for addressId: AutocompleteField
                if (fieldProps === addressIdProps) {
                    fieldTypeName = 'AutocompleteField';
                }

                // Special case for country: Selectfield with COUNTRY_CODES choices
                if (fieldProps === countryProps) {
                    fieldProps.choices = COUNTRY_CODES;
                    fieldTypeName = 'SelectField';
                }

                const Field = FIELDS[fieldTypeName];

                return (
                    <Field
                        {...{
                            ...fieldProps,
                            inputProps: { ...fieldProps.inputProps, required },
                        }}
                    />
                );
            })}
        </>
    );
};

// TODO streamline field callbacks & standardize APIs
export const CustomerFields = (props) => {
    const { onUpdate, useUpdateWarning = true } = props;
    const { t } = useTranslation('assistance');

    // TODO: extract logic to intercept/confirm updates into a reusable hook
    const [updateWarningVisible, setUpdateWarningVisible] = useState(false);
    const [updateWarningValue, setUpdateWarningValue] = useState(undefined);
    const [onUpdatePromise, setOnUpdatePromise] = useState(undefined);

    const handleUpdate = (value, data) => {
        if (useUpdateWarning) {
            setUpdateWarningVisible(true);
            setUpdateWarningValue([value, data]);
            // Create a placeholder promise that resolves when the update's actual promise resolves
            let newPromise;
            newPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    newPromise.resolve = resolve;
                    newPromise.reject = reject;
                }, 0);
            });
            setOnUpdatePromise(newPromise);
            return newPromise;
        } else {
            return onUpdate(value, data);
        }
    };

    const initialValue = {
        ...props.initialValue,
        customerNumber: props.initialValue?.customerNumber,
        name: props.initialValue?.name || '',
        address: props.initialValue?.address,
    };

    const { getFieldProps, value, setValue } = useFields({ ...props, initialValue, onUpdate: handleUpdate });
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (!isEqual(initialValue, value) ? setValue(initialValue) : undefined),
        [initialValue.customerNumber, initialValue.name]
    );

    const updateWarningConfirm = () => {
        onUpdate(...updateWarningValue)
            .then((result) => onUpdatePromise.resolve(result))
            .catch((error) => onUpdatePromise.reject(error))
            .finally(() => setOnUpdatePromise(null));
        setUpdateWarningVisible(false);
        setUpdateWarningValue(undefined);
    };

    const updateWarningReject = () => {
        setValue(initialValue);
        setUpdateWarningVisible(false);
        setUpdateWarningValue(undefined);
    };

    // TODO: use a collapsed composite field with a backend-controlled summary value & remove this
    const joinAddress = (address) => {
        return (
            [
                address.name || '',
                address.companyName || '',
                address.streetAndNr || '',
                address.misc || '',
                `${address.postcode || ''} ${address.city || ''}`.trim(),
                address.country || '',
            ]
                .filter((i) => i)
                .join('\n') || ''
        );
    };
    const address = value.address ? joinAddress(value.address) : '';

    // Get all field props and store them:
    const customerNumberProps = getFieldProps('customerNumber');
    const nameProps = getFieldProps('name');
    const addressProps = getFieldProps('address');

    // Set the required state of all fields (we need the state for when the user is actively editing a field)
    const [customerNumberRequired, setCustomerNumberRequired] = useState(customerNumberProps.inputProps.required);
    const [nameRequired, setNameRequired] = useState(nameProps.inputProps.required);

    // Function to check if all subfields are empty or undefined
    const allSubfieldsEmpty = () => {
        return (
            (customerNumberProps.hidden || !customerNumberProps.value || customerNumberProps.value === '') &&
            (nameProps.hidden || !nameProps.value || nameProps.value === '') &&
            // Address is readonly, so we don't need to set the required state. But we use it in the backend to determine if the parentfield is empty, so we need to do the same in the FE
            (addressProps.hidden || !addressProps.value || joinAddress(addressProps.value) === '')
        );
    };

    useEffect(() => {
        if (!props.activeFieldId && !props.required && allSubfieldsEmpty()) {
            // Overwrite all props to not be required (if parent is optional, all subfields are empty and the user is not actively editing a field)
            setCustomerNumberRequired(false);
            setNameRequired(false);
        } else if (!props.activeFieldId && !props.required && !allSubfieldsEmpty()) {
            // After a field has been edited and not all fields are empty anymore, we reset the required state to the initial state
            setCustomerNumberRequired(customerNumberProps.required);
            setNameRequired(nameProps.required);
        }
    }, [props.activeFieldId]);

    const NameField = FIELDS[nameProps?.fieldType || 'StringField'];

    return (
        <>
            <AutocompleteField
                {...{
                    ...customerNumberProps,
                    inputProps: { ...customerNumberProps.inputProps, required: customerNumberRequired },
                }}
            />
            <NameField
                {...{
                    ...nameProps,
                    inputProps: { ...nameProps.inputProps, required: nameRequired },
                }}
            />
            <TextField {...getFieldProps('address')} inputProps={{ disabled: true }} value={address} />
            {value.internalNote && <TextField {...getFieldProps('internalNote')} inputProps={{ disabled: true }} />}
            <ConfirmModal
                onConfirm={updateWarningConfirm}
                onCancel={updateWarningReject}
                visible={updateWarningVisible}
            >
                <p>{t('fields.customerField.modal')}</p>
            </ConfirmModal>

            <div className="field-group__note">{t('fields.customerField.note')}</div>
        </>
    );
};

export const ContactFields = (props) => {
    const initialValue = { contactId: undefined, name: undefined, ...props.initialValue };
    const { getFieldProps, value, setValue } = useFields({ ...props, initialValue });
    // in case initialValue gets changed from the outside we adjust internal state
    useEffect(
        () => void (!isEqual(initialValue, value) ? setValue(initialValue) : undefined),
        [initialValue.contactId, initialValue.name, initialValue.phone, initialValue.email]
    );

    // Get all field props and store them (address field is disabled / readonly):
    const contactIdProps = getFieldProps('contactId');
    const nameProps = getFieldProps('name');
    const phoneProps = getFieldProps('phone');
    const emailProps = getFieldProps('email');

    // Set the required state of all fields (we need the state for when the user is actively editing a field)
    const [contactIdRequired, setContactIdRequired] = useState(contactIdProps.inputProps.required);
    const [nameRequired, setNameRequired] = useState(nameProps.inputProps.required);
    const [phoneRequired, setPhoneRequired] = useState(phoneProps.inputProps.required);
    const [emailRequired, setEmailRequired] = useState(emailProps.inputProps.required);

    // Function to check if all subfields are empty or undefined
    const allSubfieldsEmpty = () => {
        return (
            (contactIdProps.hidden || !contactIdProps.value || contactIdProps.value === '') &&
            (nameProps.hidden || !nameProps.value || nameProps.value === '') &&
            (phoneProps.hidden || !phoneProps.value || phoneProps.value === '') &&
            (emailProps.hidden || !emailProps.value || emailProps.value === '')
        );
    };

    useEffect(() => {
        if (!props.activeFieldId && !props.required && allSubfieldsEmpty()) {
            // Overwrite all props to not be required (if parent is optional, all subfields are empty and the user is not actively editing a field)
            setContactIdRequired(false);
            setNameRequired(false);
            setPhoneRequired(false);
            setEmailRequired(false);
        } else if (!props.activeFieldId && !props.required && !allSubfieldsEmpty()) {
            // After a field has been edited and not all fields are empty anymore, we reset the required state to the initial state
            setContactIdRequired(contactIdProps.required);
            setNameRequired(nameProps.required);
            setPhoneRequired(phoneProps.required);
            setEmailRequired(emailProps.required);
        }
    }, [props.activeFieldId]);

    return (
        <>
            <AutocompleteField
                {...{
                    ...contactIdProps,
                    inputProps: { ...contactIdProps.inputProps, required: contactIdRequired },
                }}
            />
            <StringField
                {...{
                    ...nameProps,
                    inputProps: { ...nameProps.inputProps, required: nameRequired },
                }}
            />
            <StringField
                {...{
                    ...phoneProps,
                    inputProps: { ...phoneProps.inputProps, required: phoneRequired },
                }}
            />
            <StringField
                {...{
                    ...emailProps,
                    inputProps: { ...emailProps.inputProps, required: emailRequired },
                }}
            />
        </>
    );
};

export const InvalidField = (props) => {
    const { label } = props;
    const { t } = useTranslation();
    return (
        <TextField
            label={label}
            inputProps={{ disabled: true }}
            value={t('assistance:itemsView.invalidField')}
            className={classnames('field--unknown')}
            autoResize={true}
        />
    );
};

/**
 * Generic component that renders any composite field.
 *
 * The composite field is rendered as a collapsed, read-only field showing a summary value for the field's content
 * (to represent the content of all subfields). Subfields can be accessed via a modal that opens when clicking on
 * the field.
 */
export const CollapsedCompositeField = ({ field, fieldName, fieldConfigs, onUpdate, ...props }) => {
    const { t } = useTranslation();

    // this is kind of a hack - probably should be passed in or so, but tbh most of those fields here are more of a hack anyway
    const modalContainerRef = useRef(document.querySelector('.assistance-view__form-modal-root'));
    useEffect(() => {
        modalContainerRef.current = document.querySelector('.assistance-view__form-modal-root');
    }, []);

    const [isSubfieldModalVisible, setSubfieldModalVisible] = useState(false);
    const openSubfieldModal = () => setSubfieldModalVisible(true);
    const closeSubfieldModal = () => setSubfieldModalVisible(false);

    const channelId = props?.channelId;

    // Build props for the collapsed read-only field representing the composite structure
    const fieldConfig = fieldConfigs?.[fieldName];
    const fieldProps = { ...(fieldConfig?.fieldProps ?? {}) };
    fieldProps.explanationToggle = props?.explanationToggle ?? false;
    fieldProps.activeFieldId = props?.activeFieldId;
    fieldProps.setActiveFieldId = props?.setActiveFieldId;
    fieldProps.style = props?.style;

    const label = t(`assistance:itemsView.fieldNames.${snakeCase(fieldName)}`);

    const hasOrderCode = fieldName.includes('orderCode');

    if (!hasOrderCode) {
        props.inputProps['onFocus'] = null; // disable reselection of composite fields (for now)
    }

    const handleClear = () => {
        let payload = { code_string: '' };
        onUpdate(fieldName, { [fieldName]: fieldName, ...payload }, { forceRefetchRecord: true });
        closeSubfieldModal();
    };

    const inputProps = {
        ...props.inputProps, // e.g. readOnly, required, disabled
        ...fieldConfig?.inputProps,
        placeholder: label,
        tooltip: t(`assistance:fields.compositeField.view`),
    };
    const isEditable = !inputProps.readOnly && !inputProps.disabled;
    const tooltip = field?.confidenceExplanation;
    const confidence = Math.floor((field.predictionConfidence ?? 0) * 100);
    const resetField = () => props.inputProps?.onReset(fieldName);

    // Collect & build subfields
    // This will work recursively as long as the field render function is able to return a CompositeField component
    const subfields = collectSubfields(field);
    const SubfieldComponents = Object.entries(subfields).map(([subfieldName, subfield], _) => {
        const handleSubfieldUpdate = (value, data = {}) => {
            onUpdate(fieldName, { [subfieldName]: value, ...data }, { forceRefetchRecord: true });
        };

        // Inherit readonly / disabled status to subfields
        let subfieldConfig = fieldConfig || {};
        subfieldConfig[subfieldName] ??= {};
        subfieldConfig[subfieldName].inputProps = {
            ...(subfieldConfig[subfieldName]?.inputProps || {}),
            readOnly: inputProps?.readOnly,
            disabled: inputProps?.disabled,
        };

        const Field = renderField;
        return (
            <Field
                key={subfieldName}
                {...{
                    t,
                    field: subfield,
                    fieldName: subfieldName,
                    fieldConfigs: subfieldConfig,
                    channelId,
                    onUpdate: handleSubfieldUpdate,
                    handlerOnUpdate: onUpdate,
                    parentField: field,
                    parentFieldName: fieldName,
                    activeFieldId: props?.activeFieldId,
                    setActiveFieldId: props?.setActiveFieldId,
                    explanationToggle: props?.explanationToggle,
                    loading: props?.loading, // loading is used for explanantions icon here
                }}
            />
        );
    });

    return (
        <>
            <EditFormModal
                title={field['summaryValue']}
                visible={isSubfieldModalVisible}
                onSubmit={closeSubfieldModal}
                onCancel={() => {
                    isEditable && resetField();
                    closeSubfieldModal();
                }}
                readOnly={!isEditable}
                containerRef={modalContainerRef}
            >
                <div key={`modal-${fieldName}`} className={classnames('field-group')}>
                    <div className="field-group__header">
                        <div className="field-group__title">
                            {label}
                            <span
                                className={classnames(
                                    'field-group__confidence',
                                    `field-group__confidence--${getConfidenceClass(field.predictionConfidence ?? 0)}`
                                )}
                            >
                                {`${confidence} %`}
                            </span>
                        </div>
                        {hasOrderCode && (
                            <div className="field-group__actions">
                                <SecondaryButton
                                    label={t('assistance:itemsView.actions.clear')}
                                    className="field-group__action-button field-group__action-button--clear"
                                    onClick={handleClear}
                                />
                            </div>
                        )}
                    </div>
                    {SubfieldComponents}
                    {field.errorMessage && (
                        <Alert severity="error" className="alert--no-margin alert--multiline modal__error_message">
                            <AlertTitle>{field.errorMessage}</AlertTitle>
                        </Alert>
                    )}
                </div>
            </EditFormModal>

            <StringField // the collapsed read-only field representing the composite structure with a summary string
                key={fieldName}
                field={field}
                fieldName={fieldName}
                fieldConfigs={fieldConfigs}
                label={label}
                value={field['summaryValue']}
                tooltip={tooltip}
                inputProps={inputProps}
                {...fieldProps}
                className={classnames(
                    fieldProps['className'],
                    `field--${fieldName}`,
                    `field--${snakeCase(fieldName)}`,
                    `field--confidence-${getConfidenceClass(field.predictionConfidence, false)}`
                )}
                trailing={<Icon icon="settings" onClick={openSubfieldModal} />}
            />
        </>
    );
};

export const FIELD_KEYS = {
    IS_COMPOSITE: 'isComposite',
    TYPENAME: '__typename',
};

/**
 * Helper that collects all subfield objects on the given composite field object (direct children only)
 *
 * @returns mapping from subfield keys to subfield objects
 */
export const collectSubfields = (fieldObj: any): { [key: string]: any } => {
    const subfields = {};

    for (let key in fieldObj) {
        const potentialSubfield = fieldObj[key];
        if (!isObject(potentialSubfield)) {
            continue; // skip non-object attributes (i.e. not a field)
        }
        if (!potentialSubfield.hasOwnProperty(FIELD_KEYS.TYPENAME)) {
            continue; // skip object attributes that are not part of the graphene schema
        }
        const subfield = potentialSubfield;
        const subfieldTypeName = subfield[FIELD_KEYS.TYPENAME];
        const isFieldType = subfieldTypeName in FIELDS || subfield[FIELD_KEYS.IS_COMPOSITE];
        if (!isFieldType) {
            continue; // skip subfields that are of unknown type
        }
        subfields[key] = subfield;
    }
    return subfields;
};

/**
 * Helper that renders the field component for a given field object.
 */
export const renderField = ({
    t,
    field,
    fieldName,
    fieldConfigs,
    channelId,
    groupFieldProps = {},
    groupInputProps = {},
    onUpdate,
    handlerOnUpdate,
    onMouseEnter = null,
    onMouseLeave = null,
    parentField = null,
    parentFieldName = null,
    activeFieldId,
    setActiveFieldId,
    explanationToggle,
    loading,
}) => {
    // Determine field name using nested path info
    const nestedFieldSeparator = '__';
    const fieldPathComponents = fieldName.split(nestedFieldSeparator);
    const fromRootComponents = fieldPathComponents.slice(0, fieldPathComponents.length - 1);
    const newFieldPathComponents = [
        ...fromRootComponents,
        parentFieldName != null ? parentFieldName + nestedFieldSeparator : '', // prepend current parent name (if any)
        fieldPathComponents[fieldPathComponents.length - 1],
    ];
    const nestedFieldName = newFieldPathComponents.join('');

    let fieldConfig = fieldConfigs?.[fieldName]; // TODO this is not the FieldConfig django model
    let fieldTypeName = fieldConfig?.fieldType || field?.__typename;
    if (field?.['choices'] != null || field?.['options'] != null) {
        fieldTypeName = 'SelectField';
    }

    const isComposite = field?.[FIELD_KEYS.IS_COMPOSITE];
    const fieldTypeConfig = fieldConfigs?.[fieldTypeName] || {};
    fieldConfig = Object.assign(fieldTypeConfig, fieldConfig);

    // Determine field component to render
    let FieldComponent = FIELDS[fieldTypeName];
    if (!FieldComponent) {
        // Fallback to generic field component if there is no dedicated one for the concrete field type
        if (isComposite) {
            FieldComponent = CollapsedCompositeField;
        }
    }

    if (!field || !FieldComponent) {
        // only render invalid fields hints in the context of a specific channel
        // (i.e. not when looking at all documents, otherwise fieldNames will be a superset of all channel configs)
        return channelId != null ? <InvalidField key={nestedFieldName} label={nestedFieldName} /> : <></>;
    }

    // Determine the value for the input field
    let valueKey = fieldConfig?.valueKey;
    if (!valueKey) {
        valueKey = isComposite ? 'summaryValue' : 'value';
    }
    const value = field[valueKey];

    const handleMatchingAction = (actionType) => {
        handlerOnUpdate({
            fieldName: fieldName,
            action: 'action:' + actionType,
        });
    };

    const fieldProps = {
        channelId,
        ...fieldConfig?.fieldProps,
        ...groupFieldProps?.[fieldName],
        handleMatchingAction: handleMatchingAction,
    };

    let handleUpdate;
    if (isComposite) {
        handleUpdate = onUpdate; // just pass through update handler from the parent
    } else {
        if (parentFieldName != null) {
            // for primitive fields nested inside another field, we specify the parent name as the field to be updated
            // (instead of the name of the field itself), and provide the name & value of the primitive field in the
            // payload
            //
            // Note: this only works for 2 levels of nesting, the backend does not support updates for arbitrary levels
            // of nesting (yet)
            handleUpdate = (value, data = {}, options = {}) =>
                onUpdate(parentFieldName, { [fieldName]: value, ...data }, options);
        } else {
            // for primitive fields that are not nested inside another field, we specify the name of the field itself
            // as the field to be updated and provide the new value in the payload
            handleUpdate = (value, data = {}, options = {}) =>
                onUpdate(fieldName, { [valueKey]: value, ...data }, options);
        }
    }

    const readOnly = fieldConfig?.readOnly;
    const label =
        resolveLocalizedString(field.label) || t(`assistance:itemsView.fieldNames.${snakeCase(nestedFieldName)}`);
    const inputProps = {
        placeholder: label,
        readOnly,
        ...fieldConfig?.inputProps,
        ...groupInputProps?.[fieldName],
    };

    return (
        <FieldComponent
            key={fieldName}
            field={field}
            fieldName={fieldName}
            fieldConfigs={fieldConfigs}
            onUpdate={handleUpdate}
            label={label}
            initialValue={value}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            choices={field['choices']} // for SelectField
            options={field['options']} // for SelectField
            {...fieldProps}
            tooltip={field?.confidenceExplanation}
            inputProps={inputProps}
            className={classnames(
                fieldProps['className'],
                `field--${fieldName}`,
                `field--${snakeCase(fieldName)}`,
                `field--confidence-${getConfidenceClass(field.predictionConfidence, false)}`
            )}
            activeFieldId={activeFieldId}
            setActiveFieldId={setActiveFieldId}
            explanationToggle={explanationToggle}
            loading={loading}
        />
    );
};

export const FIELDS = {
    BooleanField: BooleanField,
    FloatField: DecimalField,
    DecimalField: DecimalField,
    IntegerField: IntegerField,
    DateField: DateField,
    TimeField: TimeField,
    StringField: StringField,
    ReadOnlyField: ReadOnlyField,
    ArrayField: ArrayField,
    ListField: ArrayField,
    SelectField: SelectField,
    AutocompleteField: AutocompleteField,

    TextField: TextField,
    JSONField: TextField,

    AddressField: AddressFields,
    CustomerField: CustomerFields,
    ContactField: ContactFields,
    // Note: composite fields without a type registered here are automatically rendered as CollapsedCompositeField
};
