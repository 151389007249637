import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

interface IProps {
    prefix?: string;
    label: string;
    deletable?: boolean;
    onDelete?: any;
    onClick?: any;
    className?: string;
}

const Chip = (props: IProps) => {
    const { prefix, label, deletable, onDelete, onClick, className } = props;

    return (
        <div className={classnames('chip', className)} onClick={onClick}>
            {prefix && <span className="chip__prefix">{prefix}</span>}
            <span className="chip__label">{label}</span>
            {deletable && <a className="chip__delete" onClick={onDelete} />}
        </div>
    );
};

export default Chip;
