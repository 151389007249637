export const ValidationCheckResultStatus = {
    PASSED: 'check_passed',
    INCOMPLETE: 'data_incomplete',
    FAILED: 'check_failed',
};

export const PROCESSING_STATUS_COLOR_CLASS = {
    PENDING: '',
    ON_HOLD: '',
    ASSISTANCE_NEEDED: 'warning',
    ASSISTED: '',
    FINISHED: 'success',
    DELIVERED: 'success',
    IMPORTED: 'success',
    FAILED: 'error',
    DISCARDED: '',
    REJECTED: 'error',
    FINISHED_TESTING: 'success',
    DELETED: '',
};
