import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { appInsights } from '../analytics/applicationInsights';
import { applyFiltersEvent, searchEvent } from '../analytics/customEvents';
import { DocumentType } from '../../generic_document/constants';
import { useLocalStorage } from './hooks/useLocalStorage';
import { useChannelStore } from '../../stores';

// COPIED FROM ML-STUDIO

const GROUP_DELIMITER = /(?:[^\s"]+|"[^"]*")+/g;
const PAIR_DELIMITER = /(?:(-?)([a-z_]+?):)?(.+)/;

const COMPARISON_VALUE = /^(<|<=|>|>=)(.+)$/;
const RANGE_VALUE = /^(.+)\.\.(.+)$/;

export const SEARCH_FILTER_KEY = 'search';
export const ORDER_BY_FILTER_KEY = 'order_by';

// following https://datatracker.ietf.org/doc/html/rfc7644#section-3.4.2.2
const EXPRESSION_MAPPING = {
    '<': 'lt',
    '<=': 'le',
    '>': 'gt',
    '>=': 'ge',
};

const splitPair = (str) => {
    const [match, prefix, name, value] = str.match(PAIR_DELIMITER) || [undefined, undefined, undefined, undefined];
    return {
        name,
        value, //: value.replace(/['"]+/g, ''),  // remove quotation marks
        exclude: prefix == '-',
        expression: undefined,
    };
};

const removeQuotes = (value) => value.replace(/"+/g, '');

const parseRange = (value) => {
    value = removeQuotes(value);
    const [match, rangeFrom, rangeTo] = value.match(RANGE_VALUE) || [undefined, undefined, undefined];
    return match
        ? [
              ['ge', [rangeFrom]],
              ['le', [rangeTo]],
          ]
        : [];
};

const parseComparison = (value) => {
    value = removeQuotes(value);
    const [match, expression, num] = value.match(COMPARISON_VALUE) || [undefined, undefined, undefined];
    return match ? [[EXPRESSION_MAPPING[expression], [num]]] : [];
};

const parseEqual = (value) => {
    // split by comma but prevent commas inside of quotes
    const values = value.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/).map((v) => removeQuotes(v));
    return [['eq', values]];
};

export const parseFilterQuery = (query, searchField = SEARCH_FILTER_KEY, validators = undefined) => {
    const groups = query.match(GROUP_DELIMITER) || [];
    const pairs = groups.map(splitPair);

    const parsedPairs = [];
    const searchTerms = pairs.filter((pair) => !pair.name).map((pair) => pair.value);

    for (const pair of pairs.filter((pair) => pair.name)) {
        // validators: object where key is pairName and value a method that validates one value e.g. date, number, ...
        const validator = validators?.[pair.name];
        const isRange = pair.value?.includes('..');
        const isComparison = Object.keys(EXPRESSION_MAPPING).some((key) => pair.value?.startsWith(key));

        let parsedValues;
        if (isRange) parsedValues = parseRange(pair.value);
        else if (isComparison) parsedValues = parseComparison(pair.value);
        else parsedValues = parseEqual(pair.value);

        // range returns multiple values - we want to flatten them
        // into separate pairs as we AND pairs together anyway
        for (const [expression, value] of parsedValues) {
            if (!validator || validator(value)) {
                parsedPairs.push({ ...pair, expression, value });
            }
        }
    }

    const searchTerm = searchTerms.join(' ');
    if (searchTerm && searchField) {
        parsedPairs.push({ name: searchField, value: [searchTerm], expression: 'eq', exclude: false });
    }

    return parsedPairs;
};

const buildRange = (pairs) => {
    return `${pairs[0].exclude ? '-' : ''}${pairs[0].name}:${pairs[0].value?.[0]}..${pairs[1].value?.[0]}`;
};

const buildComparison = (pair) => {
    const expression = Object.keys(EXPRESSION_MAPPING).find((key) => EXPRESSION_MAPPING[key] === pair.expression);
    return `${pair.exclude ? '-' : ''}${pair.name}:${expression}${pair.value?.[0]}`;
};

const buildEqual = (pair) => {
    let values = Array.isArray(pair.value) ? pair.value : [pair.value];
    values = values.map((value) => (value.includes(' ') ? `"${value}"` : value));
    return `${pair.exclude ? '-' : ''}${pair.name}:${values.join(',')}`;
};

export const groupBy = (xs, key) =>
    xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});

export const buildFilterQuery = (pairs, searchField = SEARCH_FILTER_KEY) => {
    // pairs like {expression: "", name: "", value: "", exclude: ""}
    const stringParts = [];

    const searchPair = pairs.find((p) => p.name === searchField);
    if (searchPair) {
        stringParts.push(searchPair.value);
    }
    const groups = groupBy(
        pairs.filter((p) => p.name !== searchField),
        'name'
    );

    for (const group of Object.values(groups)) {
        const isRange = (group as any).length > 1;
        const isComparison = Object.values(EXPRESSION_MAPPING).includes(group[0].expression);

        if (isRange) stringParts.push(buildRange(group));
        else if (isComparison) stringParts.push(buildComparison(group[0]));
        else stringParts.push(buildEqual(group[0]));
    }

    return stringParts.join(' ');
};

export interface IFilter {
    name: string;
    value: any;
    expression: string;
    exclude?: boolean;
}

/**
 * Factory for different query filters used in the task list
 * @param user
 */
export const taskQueryFilters = {
    finishedTasks: (finished) => {
        return {
            name: 'finished',
            value: 'true',
            expression: 'eq',
            exclude: !finished,
        };
    },
    testingTasks: (testing) => {
        return {
            name: 'is_test_document',
            value: 'true',
            expression: 'eq',
            exclude: !testing,
        };
    },
    channel: (channelId) => {
        return {
            name: 'channel_id',
            value: channelId,
            expression: 'eq',
            exclude: false,
        };
    },
    sortByCreatedAt: () => {
        return {
            name: ORDER_BY_FILTER_KEY,
            value: '-created_at',
            expression: 'eq',
            exclude: false,
        };
    },
};

/**
 * Returns the [targetFilter] from the given [filters] (if any, null otherwise)
 * @param filters
 * @param targetFilter
 */
export const getFilter = (filters, targetFilter) => {
    const idx = filters.findIndex((f) => isEqual(f, targetFilter));
    return idx != -1 ? filters[idx] : null;
};

/**
 * Custom hook for managing the active filters.
 * @param user
 */
export const useTaskFilters = ({ user, channelId, finished, testing }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Read filter params from URL
    const urlParamKey = 'query';
    const searchParams = new URLSearchParams(location.search);
    const routeFilters = parseFilterQuery(searchParams.get(urlParamKey) || '');

    // Read filter params from local storage
    const [persistedFilters, setPersistedFilters] = useLocalStorage('userFilters', []);

    // Initialize userFilters state
    const initialUserFilters = routeFilters.length > 0 ? routeFilters : persistedFilters;
    const [userFilters, setUserFilters] = useState(initialUserFilters);

    // Initialize queryFilters state
    const defaultQueryFilters = [
        // By default filter testing documents
        taskQueryFilters.testingTasks(testing || false),
    ];

    if (!userFilters.some((f) => f.name === ORDER_BY_FILTER_KEY)) {
        defaultQueryFilters.push(taskQueryFilters.sortByCreatedAt());
    }

    // only filter by finished tasks if not testing as testing should show all tasks
    if (testing === false) {
        defaultQueryFilters.push(taskQueryFilters.finishedTasks(finished));
    }

    const queryFilters = [...defaultQueryFilters, ...userFilters].map((f) => ({
        ...f,
        // NOTE: this is a little hacky - we can improve it when refactoring the filters
        value: f.name === 'execution_status' && f.value === 'OPEN' ? ['PENDING', 'SCHEDULED', 'RUNNING'] : f.value,
    }));

    if (channelId != null) {
        queryFilters.push(taskQueryFilters.channel(channelId));
    }

    // Make filters available in store
    const setActiveFilters = useChannelStore((state) => state.setActiveUserFilters);
    function _syncUserFiltersToStore() {
        setActiveFilters(userFilters);
    }

    function _syncPersistedFilters() {
        setPersistedFilters(userFilters);
    }

    function _updateQueryInUrl() {
        searchParams.set(urlParamKey, buildFilterQuery(userFilters));
        if (!userFilters.length) {
            searchParams.delete(urlParamKey);
        }
        navigate({ pathname: location.pathname, search: '?' + searchParams });
    }

    const deleteUserFilter = (filterIndex) => {
        userFilters.splice(filterIndex, 1);
        setUserFilters([...userFilters]);
    };

    useEffect(_syncUserFiltersToStore, [userFilters]);
    useEffect(_syncPersistedFilters, [userFilters]);
    useEffect(_updateQueryInUrl, [userFilters]);

    return [
        { queryFilters, userFilters },
        { setUserFilters, deleteUserFilter },
    ];
};
