import * as React from 'react';
import classnames from 'classnames';

export const Badge = (props) => {
    const { colorClass, children, className } = props;

    return <span className={classnames(className, 'status-badge', `status-badge--${colorClass}`)}>{children}</span>;
};

export default Badge;
