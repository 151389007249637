import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Logo from '../../../core/components/Logo';
import Card from '../../../core/components/Card';
import Input from '../../../core/components/Input';
import Alert, { AlertTitle } from '../../../core/components/Alert';
import ProgressButton from '../../../core/containers/ProgressButton';
import LoginWrapper from '../../components/LoginWrapper';
import { FORGOT_PASSWORD } from '../../queries';
import { LOGIN_PATH } from '../../constants';
import { StringField } from '../../../assistance/containers/Fields';
import { url } from '../../../core/utils/link';

const ForgotPassword = (props) => {
    const { loggedIn, user } = props;

    const { t } = useTranslation('user');
    const navigate = useNavigate();

    useEffect(() => {
        if (loggedIn && user) {
            navigate(LOGIN_PATH);
        }
    }, [loggedIn, user]);

    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const [forgotPassword, { loading, error }] = useMutation(FORGOT_PASSWORD);

    const handleSubmit = () => {
        return forgotPassword({
            variables: { email },
        })
            .then((mutationResult: any) => {
                setSubmitted(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <LoginWrapper>
            <div className="login__logo-wrapper">
                <Link to={url(LOGIN_PATH)}>
                    <Logo className="login__logo" />
                </Link>
            </div>

            <Card level={3} className="login__card">
                <h1 className="login__title">{t('resetPassword.title')}</h1>

                {submitted ? (
                    <Alert className="alert--no-margin">
                        <AlertTitle>{t('forgotPassword.successMessage')}</AlertTitle>
                    </Alert>
                ) : (
                    <div className="login__form">
                        {error && (
                            <Alert className="alert--no-margin" severity="error">
                                <AlertTitle>{error?.message}</AlertTitle>
                            </Alert>
                        )}

                        <div className="login__input-group">
                            <StringField
                                label={t('forgotPassword.emailLabel')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="field--vertical"
                                inputProps={{
                                    placeholder: t('forgotPassword.emailPlaceholder'),
                                    name: 'username',
                                    type: 'email',
                                }}
                            />
                        </div>

                        <ProgressButton label={t('forgotPassword.send')} disabled={!email} onClick={handleSubmit} />
                    </div>
                )}

                <div className="login__below-card">
                    <Link to={LOGIN_PATH}>{t('forgotPassword.back')}</Link>
                </div>
            </Card>
        </LoginWrapper>
    );
};

export default ForgotPassword;
