import { createBrowserHistory } from 'history';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes, SeverityLevel } from '@microsoft/applicationinsights-web';

// Global reference to the appInsights client
export let appInsights;
export let AI;

export const initAppInsights = (app) => {
    if (process.env.RUN_ENV != 'development' && !process.env.AZURE_INSTRUMENTATION_KEY) {
        return app;
    }

    const browserHistory = createBrowserHistory();
    const reactPlugin = new ReactPlugin();
    const ai = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.AZURE_INSTRUMENTATION_KEY,
            distributedTracingMode: DistributedTracingModes.W3C,
            enableCorsCorrelation: true,
            disableCorrelationHeaders: false,
            maxBatchInterval: 0,
            disableFetchTracking: true,
            disableAjaxTracking: true,
            disableCookiesUsage: true,
            autoTrackPageVisitTime: true,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory },
            },
        },
    });
    const shouldTrackToConsole = process.env.RUN_ENV == 'development' && !process.env.AZURE_INSTRUMENTATION_KEY;
    if (!shouldTrackToConsole) {
        ai.loadAppInsights();
    }

    ai.appInsights.addTelemetryInitializer((envelope) => {
        envelope.tags['ai.cloud.role'] = 'Frontend';
    });

    // intstrumentalize the calls to console after initializing application insights
    // TODO: not sure if we want to debug stuff below warning but the ticket said so :)
    const instrumentalizeConsole = (method, severityLevel) => {
        return (message) => {
            ai.trackTrace({ message, severityLevel });
            return method(message);
        };
    };

    if (shouldTrackToConsole) {
        const logToConsole = (telemetryObject, customProperties) =>
            console.debug({ ...telemetryObject, customProperties });
        ai.appInsights.trackPageView = logToConsole;
        ai.appInsights.trackTrace = logToConsole;
        ai.appInsights.trackEvent = logToConsole;
    } else {
        window.console.log = instrumentalizeConsole(window.console.log, SeverityLevel.Information);
        window.console.info = instrumentalizeConsole(window.console.info, SeverityLevel.Information);
        window.console.warn = instrumentalizeConsole(window.console.warn, SeverityLevel.Warning);
        window.console.error = instrumentalizeConsole(window.console.error, SeverityLevel.Error);
    }

    // Make available the app insights client in the global namespace for use in components
    appInsights = ai.appInsights;
    AI = ai;

    return withAITracking(reactPlugin, app);
};
