import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { isoDateFormat } from '../../../../utils/dates';
import DateRangeFilter from '../../../../analytics/components/DateRangeFilter';
import * as React from 'react';

const CreatedAtFilter = ({ filters, onFiltersChange }) => {
    const { t } = useTranslation('assistance');

    const createdAtFromValue = filters.find((f) => f.name === 'created_at' && f.expression === 'ge')?.value?.[0];
    const createdAtToValue = filters.find((f) => f.name === 'created_at' && f.expression === 'le')?.value?.[0];
    const createAtRange = {
        from: moment(createdAtFromValue, isoDateFormat).isValid()
            ? moment(createdAtFromValue, isoDateFormat).toDate()
            : null,
        to: moment(createdAtToValue, isoDateFormat).isValid() ? moment(createdAtToValue, isoDateFormat).toDate() : null,
    };

    const today = moment();
    const dateRangeOptions = [
        {
            label: t('overview.filters.createdAt.options.today'),
            value: [today.format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.yesterday'),
            value: [
                today.clone().subtract(1, 'day').format(isoDateFormat),
                today.clone().subtract(1, 'day').format(isoDateFormat),
            ].join(','),
        },

        {
            label: t('overview.filters.createdAt.options.lastWeek'),
            value: [today.clone().subtract(1, 'week').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastMonth'),
            value: [today.clone().subtract(1, 'month').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.last3Months'),
            value: [today.clone().subtract(90, 'days').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.last6Months'),
            value: [today.clone().subtract(6, 'months').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
        {
            label: t('overview.filters.createdAt.options.lastYear'),
            value: [today.clone().subtract(1, 'year').format(isoDateFormat), today.format(isoDateFormat)].join(','),
        },
    ];

    const handleCreatedAtChange = (range) => {
        const newFilters = filters.filter((f) => f.name !== 'created_at');

        if (range.from) {
            newFilters.push({
                name: 'created_at',
                value: [moment(range.from).format(isoDateFormat)],
                expression: 'ge',
                exclude: false,
            });
        }

        if (range.to) {
            newFilters.push({
                name: 'created_at',
                value: [moment(range.to).format(isoDateFormat)],
                expression: 'le',
                exclude: false,
            });
        }

        onFiltersChange(newFilters);
    };

    return (
        <DateRangeFilter
            options={dateRangeOptions}
            label={t('overview.filters.createdAt.label')}
            range={createAtRange}
            onRangeChange={handleCreatedAtChange}
        />
    );
};

export default CreatedAtFilter;
