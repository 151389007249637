import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '../../components/Page';
import { SecondaryButton } from '../../components/Button';

import './style.scss';

const NotFound = () => {
    const { t } = useTranslation('errors');

    return (
        <Page className="page-not-found">
            <div className="page-not-found__stretch">
                <div className="page-not-found__wrapper">
                    <h2 className="page-not-found__title">{t('notFound.title')}</h2>
                    <p className="page-not-found__text">{t('notFound.text')}</p>
                    <SecondaryButton label={t('notFound.button')} className="page-not-found__button" linkTo="/" />
                </div>
            </div>
        </Page>
    );
};

export default NotFound;
