import React from 'react';
import { IModuleProps } from '../../core/utils/modules';
import TroxItemFieldGroup from './components/TroxItemFieldGroup';
import { useTroxOrderCodeContext } from './components/TroxOrderCodeContext';
import locales from './locales';
import { ArticleNumberField } from '../../generic_document/components/ArticleNumberField';
import CompositeField from '../components/CompositeField';

const TroxOrderCodeField = React.memo((props: any) => {
    const { troxSelectedArticleField } = useTroxOrderCodeContext();
    return <CompositeField {...props} disabled={troxSelectedArticleField !== 'orderCode'} />;
});

const TroxArticleNumberField = React.memo((props: any) => {
    const { troxSelectedArticleField } = useTroxOrderCodeContext();
    return (
        <ArticleNumberField
            {...props}
            disabled={troxSelectedArticleField !== 'articleNumber'}
            readOnly={troxSelectedArticleField !== 'articleNumber'}
        />
    );
});

export default {
    name: 'trox',
    locales,
    components: {
        // Add custom components here
        ItemDataFieldGroup: [
            {
                condition: ({ itemIndex, fields }) => true,
                component: TroxItemFieldGroup,
            },
        ],
        Field: [
            {
                condition: ({ fieldName }) => fieldName === 'order_code',
                component: TroxOrderCodeField,
            },
            {
                condition: ({ fieldName, documentTypeName }) =>
                    documentTypeName === 'listOfServices' && fieldName === 'orderCode',
                component: TroxOrderCodeField,
            },
            {
                condition: ({ fieldName }) => fieldName === 'article_number',
                component: TroxArticleNumberField,
            },
        ],
    },
} as IModuleProps;
