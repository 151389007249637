import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../core_updated/components/Button';
import './EmptyState.scss';

const EmptyState = ({ onAddField }) => {
    const { t } = useTranslation('config');

    return (
        <div className="flex flex-col gap-10 items-center justify-center empty_state">
            <div className="text-center flex flex-col gap-1 text-base">
                <h2 className="font-medium text-primary">{t('documentConfig.emptyUniversalConfigState.title')}</h2>
                <p className="text-gray-600 mt-2 text-secondary text-base">
                    {t('documentConfig.emptyUniversalConfigState.description')}
                </p>
            </div>

            <Button className="add_button" onClick={onAddField}>
                {t('documentConfig.emptyUniversalConfigState.button')}
            </Button>
        </div>
    );
};

export default EmptyState;
