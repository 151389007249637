import React, { useEffect, useRef, useState } from 'react';
import classnames from '../../../utils/classnames';
import { WorkistIcon, WorkistLogo } from '../../WorkistLogo';
import { NavigationContextProvider } from './NavigationContext';
import NavigationDocumentTypeItem from './NavigationDocumentTypeItem';
import NavigationDropDownItem from './NavigationDropDownItem';
import { useApplicationContext } from '../../../contexts/ApplicationContext';
import { useQuery } from '@apollo/client';
import { GET_NAVIGATION_CHANNELS } from './queries';
import { Link, useNavigate } from 'react-router-dom';
import DropdownMenu from '../../DropdownMenu';
import useDarkMode from '../../../utils/useDarkMode';
import { navigationDocumentTypes } from './useNavigation';
import { useTranslation } from 'react-i18next';
import { getHelpCenterUrl, getPrivacyUrl } from '../../../../support/constants';
import i18n from 'i18next';
import { withIcon } from '../../Icon';
import {
    faArrowLeftToLine,
    faArrowRightToLine,
    faArrowUpRightFromSquare,
    faChartColumn,
    faGear,
    faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { logout } from '../../../../core/utils/authentication';
import { canManageAllDocumentTypes, canSeeAnalytics } from '../../../../users/utils';
import NavigationItem from './NavigationItem';
import { useControllableState } from '../../../utils/useControllableState';
import { url } from '../../../../core/utils/link';

interface NavigationProps {
    className?: string;
    initialActiveItem?: [string, string | null];
    activeItem?: [string, string | null];
    onSelect?: (item: [string, string | null]) => void;
    initialCollapsed?: boolean;
    collapsed?: boolean;
    onCollapsedChange?: (collapsed: boolean) => void;
}

const useNavigationChannels = (...params) =>
    useQuery(GET_NAVIGATION_CHANNELS, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: false,
        ...params,
    });

const AnalyticsIcon = withIcon(faChartColumn);
const SettingsIcon = withIcon(faGear);
const UserIcon = withIcon(faUser);
const PanelRightCloseIcon = withIcon(faArrowRightToLine);
const PanelRightOpenIcon = withIcon(faArrowLeftToLine);
const ExternalLinkIcon = withIcon(faArrowUpRightFromSquare);

const Navigation = ({
    initialActiveItem = null,
    activeItem: propsActiveItem,
    onSelect: propsOnSelect,
    initialCollapsed = false,
    collapsed: propsCollapsed,
    onCollapsedChange: propsOnCollapsedChange,
    className,
}: NavigationProps) => {
    const { t } = useTranslation('assistance');
    const navigate = useNavigate();

    const { user, loading: applicationLoading } = useApplicationContext();

    const customer = user?.customer;
    const customerIsActive = customer.isActive;

    const { toggle: toggleDarkMode, isActive: isDarkModeActive } = useDarkMode();

    // active item
    const [activeItem, setActiveItem] = useControllableState(initialActiveItem, propsActiveItem, propsOnSelect);

    // those states are mainly used to figure out if sidebar should be collapsed
    const [manuallyCollapsed, setManuallyCollapsed] = useControllableState(
        initialCollapsed,
        propsCollapsed,
        propsOnCollapsedChange
    );
    const [mouseOver, setMouseOver] = useState(false);
    const [focused, setFocused] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    // button clicked will force collapse, it resets automatically after 200ms
    const [collapseButtonClicked, setCollapseButtonClicked] = useState(false);

    const collapsed = collapseButtonClicked || (!mouseOver && !focused && !dropdownOpen && manuallyCollapsed);

    // document types
    const {
        data: channelsData,
        previousData: channelsPreviousData, // prevent loader from showing if external refetch is triggered
        loading: channelsLoading,
    } = useNavigationChannels();

    // Scroll shadow behavior

    const [showShadow, setShowShadow] = useState([false, false]);
    const listRef = useRef<HTMLUListElement | null>(null);

    const updateScroll = (container: HTMLUListElement) => {
        const { scrollTop, scrollHeight, clientHeight } = container;
        const isBottom = scrollTop + clientHeight >= scrollHeight;
        const isTop = scrollTop === 0;

        setShowShadow([!isTop, !isBottom]);
    };

    const handleScroll = (e: any) => {
        updateScroll(e.target);
    };

    // update shadows when resizing
    useEffect(() => {
        if (!listRef?.current) return;

        const handleResize = () => {
            // @ts-ignore
            updateScroll(listRef?.current);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!listRef?.current) return;

        if (!collapsed) {
            updateScroll(listRef?.current);
        }
    }, [collapsed]);

    return (
        <NavigationContextProvider collapsed={collapsed}>
            <div
                // this div is used to prevent the content from jumping when the sidebar is collapsed
                className={classnames(
                    'w-72 flex-none h-full z-10 transition-[width] duration-200 ease-in-out',
                    manuallyCollapsed && 'w-[74px]'
                )}
            />
            <aside
                className={classnames(
                    'max-w-72 flex flex-none h-full w-72 select-none flex-col items-stretch bg-primary border-r border-solid border-r-primary',
                    'transition-[width] duration-200 ease-in-out overflow-hidden absolute top-0 bottom-0 left-0 z-50',
                    collapsed && 'w-[74px]',
                    className
                )}
                onMouseEnter={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onMouseUp={() => setFocused(false)}
            >
                <div className="flex flex-none items-center p-4">
                    {!collapsed ? (
                        <>
                            <Link to="/">
                                <WorkistLogo className="ml-1 h-7 w-auto text-primary" />
                            </Link>

                            <button
                                className="ml-auto rounded-md p-2 text-primary hover:bg-secondary"
                                onClick={() => {
                                    if (!manuallyCollapsed) {
                                        setCollapseButtonClicked(true);
                                        setTimeout(() => setCollapseButtonClicked(false), 200);
                                    }
                                    setManuallyCollapsed(!manuallyCollapsed);
                                }}
                            >
                                {manuallyCollapsed ? <PanelRightCloseIcon /> : <PanelRightOpenIcon />}
                            </button>
                        </>
                    ) : (
                        <Link to="/">
                            <WorkistIcon className="mt-0.5 ml-1 mb-0.5 h-7 w-auto text-primary" />
                        </Link>
                    )}
                </div>

                <nav className="flex min-h-0 flex-auto flex-col items-stretch relative">
                    <div
                        className={classnames(
                            'to-transparent absolute left-0 top-0 z-10 h-2 w-full flex-none bg-gradient-to-b from-tertiary opacity-0 transition-opacity duration-200',
                            showShadow[0] && 'opacity-100'
                        )}
                    />
                    <ul
                        className="flex min-h-0 flex-auto flex-col justify-start py-2 overflow-y-auto overflow-x-hidden"
                        onScroll={handleScroll}
                        ref={listRef}
                    >
                        {Object.entries(navigationDocumentTypes)
                            .filter(([documentTypeName, documentType]) => {
                                const enabled =
                                    (customerIsActive && customer[documentType.enabledKey]) ||
                                    canManageAllDocumentTypes(user);
                                return enabled || !documentType.hideIfDisabled;
                            })
                            .map(([documentTypeName, documentType], idx) => {
                                const channels = channelsData?.[`${documentTypeName}ProcessingChannels`];
                                const loading =
                                    (channelsLoading && channelsPreviousData === undefined) || applicationLoading;
                                const onSelect = (event, subItem) => {
                                    if (event?.metaKey || event?.ctrlKey) {
                                        // if cmd (Mac) or ctrl (Windows/Linux) is pressed during click, open in a new tab
                                        const paths = documentType?.paths;
                                        if (paths) {
                                            const itemUrl = subItem
                                                ? url(paths.channelPath, { channelId: subItem })
                                                : url(paths.overviewPath);
                                            const win = window.open(itemUrl, '_blank');
                                            win?.focus();
                                        }
                                    } else {
                                        // Otherwise activate the item in current tab
                                        setActiveItem([documentTypeName, subItem]);
                                    }
                                };

                                const enabled =
                                    (customerIsActive && customer[documentType.enabledKey]) ||
                                    canManageAllDocumentTypes(user);
                                const active = activeItem?.[0] === documentTypeName ? activeItem[1] || true : false;

                                return (
                                    <NavigationDocumentTypeItem
                                        key={idx}
                                        label={t(documentType.label)}
                                        icon={<documentType.icon />}
                                        channels={channels}
                                        loading={loading}
                                        active={active}
                                        onSelect={onSelect}
                                        disabled={!enabled}
                                    />
                                );
                            })}
                    </ul>
                    <div
                        className={classnames(
                            'to-transparent absolute bottom-0 left-0 z-10 h-2 w-full flex-none bg-gradient-to-t from-tertiary opacity-0 transition-opacity duration-200',
                            showShadow[1] && 'opacity-100'
                        )}
                    />
                </nav>

                <nav className="flex flex-none flex-col items-stretch border-t border-solid border-primary py-2">
                    {canSeeAnalytics(user) && (
                        <NavigationItem
                            label={t('overview.navigation.analytics')}
                            icon={<AnalyticsIcon />}
                            onSelect={() => navigate('/analytics')}
                            active={activeItem[0] === 'analytics'}
                        />
                    )}

                    <NavigationDropDownItem
                        label={t('overview.navigation.settings')}
                        icon={<SettingsIcon />}
                        onOpenChange={setDropdownOpen}
                        loading={applicationLoading}
                        // active={activeItem[0] === 'settings'}
                    >
                        <DropdownMenu.Item onSelect={() => navigate('/customer')}>
                            {t('user:userTag.companyLink')}
                        </DropdownMenu.Item>
                        <DropdownMenu.Item onSelect={() => navigate('/users')}>
                            {t('user:userTag.usersLink')}
                        </DropdownMenu.Item>
                        <DropdownMenu.Item onSelect={() => navigate('/teams')}>
                            {t('user:userTag.teamsLink')}
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item onSelect={() => navigate('/customer/integrations')}>
                            {t('user:userTag.integrationsLink')}
                        </DropdownMenu.Item>
                    </NavigationDropDownItem>

                    <NavigationDropDownItem
                        label={user && `${user?.firstName} ${user?.lastName}`}
                        icon={<UserIcon />}
                        onOpenChange={setDropdownOpen}
                        loading={applicationLoading}
                        // active={activeItem[0] === 'user'}
                    >
                        <div className="px-3 py-2 text-sm flex flex-col">
                            <span className="font-medium">{user && `${user?.firstName} ${user?.lastName}`}</span>
                            <span>{user && user.email}</span>
                        </div>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item onSelect={() => navigate('/profile')}>
                            {t('user:userTag.profileLink')}
                        </DropdownMenu.Item>
                        <DropdownMenu.Item onSelect={toggleDarkMode}>
                            {t(`core:components.header.darkMode${isDarkModeActive ? 'Off' : 'On'}`)}
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item className="flex text-primary hover:text-primary" asChild>
                            <a href={getHelpCenterUrl(i18n.language)} target="_blank">
                                {t(`overview.navigation.helpCenter`)}
                                <ExternalLinkIcon className="ml-auto" />
                            </a>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item className="flex text-primary hover:text-primary" asChild>
                            <a href={getPrivacyUrl(i18n.language)} target="_blank">
                                {t(`overview.navigation.privacyPolicy`)}
                                <ExternalLinkIcon className="ml-auto" />
                            </a>
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator />
                        <DropdownMenu.Item className="text-error hover:!bg-error" onClick={logout}>
                            {t('overview.navigation.logout')}
                        </DropdownMenu.Item>
                    </NavigationDropDownItem>
                </nav>
            </aside>
        </NavigationContextProvider>
    );
};

export default Navigation;
