import React from 'react';
import AutoCompleteField from '../../core_updated/components/Fields/AutoCompleteField';
import { useAssistanceFieldContext } from '../pages/Assistance/AssistanceFieldContext';
import { renderContactOption } from './useLookup';
import { FieldProps, useFieldProps } from '../pages/Assistance/customizable/Field';
import { IConditionalComponent } from '../../customizations/ConditionalComponentContext';
import useForceSelectionLookupProps, { useForceSelectionReselectEventListener } from './useForceSelectionLookupProps';

export const ContactIdField = (props: FieldProps) => {
    const { clientPartitionId, config } = useAssistanceFieldContext();
    const { lookupDefinitionId, lookupFieldName, allowInvalidIds } = config;

    const fieldProps = useFieldProps(props);
    const { value, onValueChange, onFocus, onBlur } = fieldProps;

    const forceSelectionLookupProps = useForceSelectionLookupProps({
        lookupType: 'ContactLookupType',
        lookupDefinitionId,
        lookupFieldName,
        partitionId: clientPartitionId,
        allowInvalidIds,
        value,
        onValueChange,
        onUpdatePayloadChange: props.onUpdatePayloadChange,
        onFocus,
        onBlur,
    });

    useForceSelectionReselectEventListener(props.inputRef, forceSelectionLookupProps.onValueChange);

    return <AutoCompleteField {...fieldProps} {...forceSelectionLookupProps} renderOption={renderContactOption} />;
};

export const getContactFieldConditionalComponents = (contactFieldKey: string): IConditionalComponent[] => [
    {
        condition: ({ fieldName }) => fieldName === `${contactFieldKey}__contact_id`,
        component: ContactIdField,
    },
];
