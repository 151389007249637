import * as React from 'react';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DataGrid from 'react-data-grid';
import classnames from 'classnames';
import moment from 'moment';
import { Icon } from '../../../core/components/Icon';
import { getCurrentLocale } from '../../../utils/localization';
import { BooleanField, SelectField } from '../../../assistance/containers/Fields';
import { useQuery } from '@apollo/client';
import { GET_PAGINATED_DATA_IMPORT_RUNS } from '../../queries';
import { ChannelContext } from '../../../stores';
import { flatten } from 'lodash';
import { isValidHttpUrl } from '../../../utils/validation';
import { AsPopupComponent, createPopupGroupContext } from '../../../core/components/Popup';
import { AlertBar, AlertContent, AlertTitle } from '../../../core/components/Alert';
import Loader from '../../../core/components/Loader';
import Badge from '../../../core/components/Badge';
import FilterForm from '../../../core/containers/FilterForm';
import { MasterdataImportRun } from '../../types';
import { validate as isValidUUID } from 'uuid';
import { useQueryParams } from '../../../utils/router';
import { IconButton } from '../../../core/components/IconButton';
import Tooltip from '../../../core/components/Tooltip';
import Text from '../../../core/components/Text';

import 'react-data-grid/lib/styles.css';
import './style.scss';
import { canUseSupportMode } from '../../../users/utils';

const MASTERDATA_IMPORT_STATUS_COLOR_CLASS = {
    PENDING: '',
    RUNNING: '',
    CANCELED: 'warning',
    SUCCESS: 'success',
    FAILED: 'error',
};

const getLookupDefinitions = (masterdataConfig) => {
    const lookupDefinitionIdByConcreteType = [];
    if (masterdataConfig == null) {
        return {};
    }
    for (const [key, value] of Object.entries(masterdataConfig)) {
        if (key.includes('LookupDefinitionId') && !key.includes('MappingLookupDefinitionId')) {
            const concreteLookupType = key.replace('LookupDefinitionId', '');
            lookupDefinitionIdByConcreteType[concreteLookupType] = value || null;
        }
    }
    return lookupDefinitionIdByConcreteType;
};

export const useImportDateRenderer = (t) => {
    const getCalendarCustomFormats = (dateComponent: string) => ({
        sameDay: t(`importLog.dateFormat.${dateComponent}.sameDay`),
        lastDay: t(`importLog.dateFormat.${dateComponent}.lastDay`),
        lastWeek: t(`importLog.dateFormat.${dateComponent}.lastWeek`),
        sameElse: t(`importLog.dateFormat.${dateComponent}.sameElse`),
    });

    const renderDatetime =
        (key: string, predicate = null) =>
        ({ row }) => {
            const dateValue = row[key];
            const dayComponent = dateValue
                ? moment(new Date(dateValue)).calendar(null, getCalendarCustomFormats('dayPart'))
                : null;
            const timeComponent = dateValue
                ? moment(new Date(dateValue)).calendar(null, getCalendarCustomFormats('timePart'))
                : null;

            if (predicate != null && !predicate(row)) {
                return null;
            }

            return (
                <span className="import__timestamp">
                    <span className="import__timestamp__day">{dayComponent}</span>
                    <span className="import__timestamp__hm"> {timeComponent}</span>
                </span>
            );
        };

    return { getCalendarCustomFormats, renderDatetime };
};

export const MasterdataImportLog = ({ user }) => {
    const translationNamespace = 'masterdata';
    const { t, i18n } = useTranslation(translationNamespace);

    const queryParams = useQueryParams();
    const initialImportId = queryParams.get('importId');
    const initialLookupDefinitionId = queryParams.get('lookupDefinitionId');

    const [queryFilters, setQueryFilters] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);
    const goToNextPage = () => setPage(page + 1);
    const goToPreviousPage = () => setPage(page - 1);

    const offset = page * pageSize;
    let hasPreviousPage = page != 0;

    const { activeChannel } = useContext(ChannelContext);
    const { data, error, loading, refetch } = useQuery(GET_PAGINATED_DATA_IMPORT_RUNS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            filters: queryFilters,
            offset: offset,
            first: pageSize,
        },
    });
    const rows = (data?.dataImportRuns.edges.map((node) => node.node) as MasterdataImportRun[]) || [];
    const rowsCount = data?.dataImportRuns.totalCount ?? 0;
    const lookupDefinitionsByConcreteType = getLookupDefinitions(activeChannel?.masterDataConfig);
    const allLookupDefinitionIds = flatten(Object.values(lookupDefinitionsByConcreteType)).filter(
        (value) => value != null
    );

    const hasNextPage = offset + pageSize < rowsCount;

    const nullValue = '__null'; // needed so the null value option shows up as selected
    const initialFilterValues = {
        lookup_definition_id: initialLookupDefinitionId || nullValue,
        status: nullValue, // all status
        created_at: moment().subtract(30, 'd').startOf('day').format('YYYY-MM-DD'), // 30 days ago
    };

    const getConcreteLookupType = (row) => {
        for (let [concreteLookupType, lookupDefinitionId] of Object.entries(lookupDefinitionsByConcreteType)) {
            if (Array.isArray(lookupDefinitionId) && lookupDefinitionId.includes(row.lookupDefinitionId)) {
                return concreteLookupType;
            }
            if (row.lookupDefinitionId == lookupDefinitionId) {
                return concreteLookupType;
            }
        }
        return 'unknown';
    };

    const renderConcreteLookupType = ({ row }) => {
        // If we cannot determine the concrete lookup type based on the masterdata config, the lookup definition
        // is no longer in use
        const concreteLookupType = getConcreteLookupType(row);

        return (
            <div className="import__entity-type">
                <Text
                    text={
                        concreteLookupType != null
                            ? t(`importRun.concreteLookupType.${concreteLookupType}`)
                            : t(`importRun.lookupType.${row.lookupType}`)
                    }
                    truncatedTooltip={null}
                />
                {row.isActiveVersion && (
                    <Tooltip content={t(`importRun.isActiveVersion`)}>
                        <Icon icon="checkmark" className="status-badge--success" />
                    </Tooltip>
                )}
            </div>
        );
    };

    // Shared context for all popups in this table
    const PopupGroupContext = createPopupGroupContext();

    const renderStatus = ({ row }) => {
        const PopupBadge = AsPopupComponent(Badge);
        let errorTitle, errorMessage;

        if (row.errorType) {
            if (i18n.exists(`${translationNamespace}:importRun.error.${row.errorType}.message`)) {
                errorTitle = `importRun.error.${row.errorType}.title`;
                errorMessage = `importRun.error.${row.errorType}.message`;
            } else {
                errorTitle = `importRun.error.other.title`;
                errorMessage = `importRun.error.other.message`;
            }
        }

        const statusBadge = (
            <PopupBadge
                popupId={row.id}
                popupGroupContext={PopupGroupContext}
                className="import__status"
                colorClass={MASTERDATA_IMPORT_STATUS_COLOR_CLASS[row.status]}
                popup={
                    row.errorType && (
                        <AlertBar severity={'error'}>
                            {errorTitle && <AlertTitle>{t(errorTitle)}</AlertTitle>}
                            <AlertContent>
                                <span>
                                    <Trans
                                        t={t}
                                        i18nKey={errorMessage}
                                        values={
                                            row.errorMessageKwargs != null ? JSON.parse(row.errorMessageKwargs) : {}
                                        }
                                    />
                                </span>
                            </AlertContent>
                        </AlertBar>
                    )
                }
                tooltip={'hello world'}
            >
                {t(`importRun.status.${row.status}`)}
            </PopupBadge>
        );

        if (i18n.exists(`${translationNamespace}:importRun.statusExplanation.${row.status}`)) {
            return <Tooltip content={t(`importRun.statusExplanation.${row.status}`)}>{statusBadge}</Tooltip>;
        } else {
            return statusBadge;
        }
    };

    const renderRuntime = ({ row }) => {
        moment.updateLocale(getCurrentLocale(), {
            relativeTime: {
                m: t(`importLog.runtimeFormat.m`),
                mm: t(`importLog.runtimeFormat.mm`),
                h: t(`importLog.runtimeFormat.h`),
                hh: t(`importLog.runtimeFormat.hh`),
                d: t(`importLog.runtimeFormat.d`),
                dd: t(`importLog.runtimeFormat.dd`),
                M: t(`importLog.runtimeFormat.M`),
                MM: t(`importLog.runtimeFormat.MM`),
                y: t(`importLog.runtimeFormat.y`),
                yy: t(`importLog.runtimeFormat.yy`),
            },
        });

        const start = row.createdAt ? moment(row.createdAt) : null;
        const end = row.finishedAt ? moment(row.finishedAt) : null;
        let runtime;
        if (end) {
            let diff = end.diff(start, 'milliseconds');
            // clip humanized format at minute-resolution, i.e. anything less than a minute will show as 1 minute
            diff = Math.max(1000 * 60, diff);
            runtime = moment.duration(diff).humanize();
        }
        return (
            <div className="runtime-container">
                <span className={classnames('arrow-right', !end && 'arrow-right-unfinished')}></span>
                <span className="runtime-text">{end && <span className="runtime-text__inner">{runtime}</span>}</span>
            </div>
        );
    };

    const renderFiles =
        (keys: string[]) =>
        ({ row }) => {
            let inputFiles = [];
            for (let key of keys) {
                if (row[key + 'Url']) {
                    inputFiles.push([row[key + 'Name'] ?? row[key + 'Url'].split('/').pop(), row[key + 'Url']]);
                }
            }

            return (
                <>
                    {inputFiles.map(([inputFileName, fileUrl]) => (
                        <>
                            {!!fileUrl && isValidHttpUrl(fileUrl) ? (
                                <Text
                                    as="a"
                                    key={inputFileName}
                                    href={fileUrl}
                                    className="import__file-download-link"
                                    text={
                                        <>
                                            <Icon icon="fileDownload" /> {inputFileName ?? 'untitled'}
                                        </>
                                    }
                                    truncatedTooltip={inputFileName ?? 'untitled'}
                                />
                            ) : (
                                <Text text={inputFileName ?? 'untitled'} />
                            )}
                        </>
                    ))}
                </>
            );
        };

    const formatFileSize = (value) => {
        const fileSizeConversionFactors = [
            // same as macOS finder file sizes (1000 instead of 1024)
            [1.0, t(`importLog.fileSizeFormat.B`)],
            [1000.0, t(`importLog.fileSizeFormat.KB`)],
            [1000.0 ** 2, t(`importLog.fileSizeFormat.MB`)],
            [1000.0 ** 3, t(`importLog.fileSizeFormat.GB`)],
        ];

        let maxConversionFactor = fileSizeConversionFactors[0][0];
        let maxConversionTargetUnit = fileSizeConversionFactors[0][1];
        let convertedValue = value;

        for (let [conversionFactor, conversionTargetUnit] of fileSizeConversionFactors) {
            if (value / (conversionFactor as number) >= 1.0) {
                maxConversionFactor = conversionFactor;
                maxConversionTargetUnit = conversionTargetUnit;
                convertedValue = value / (conversionFactor as number);
            } else {
                break;
            }
        }

        return `${parseFloat(convertedValue.toFixed(2))} ${maxConversionTargetUnit}`;
    };

    const renderFileInfo =
        ({ fileKeys, fileInfoKey, emptyValue = '-', formatValue = null }) =>
        ({ row }) => {
            let fileInfos = [];
            for (let key of fileKeys) {
                const value = row[key + fileInfoKey];
                if (value) {
                    if (formatValue != null) {
                        fileInfos.push(formatValue(value));
                    } else {
                        fileInfos.push(value);
                    }
                }
            }
            if (fileInfos.length == 0) {
                fileInfos.push(emptyValue);
            }
            return (
                <>
                    {fileInfos.map((info) => (
                        <span key={info}>{info}</span>
                    ))}
                </>
            );
        };

    const renderDjangoAdminUrl = ({ row }) => {
        return (
            <IconButton
                linkTo={row.djangoAdminUrl}
                linkExternal
                icon="openExternalUrl"
                tooltip={t(`importLog.openInDjangoAdmin`)}
            />
        );
    };

    const { getCalendarCustomFormats, renderDatetime } = useImportDateRenderer(t);

    // Workaround to dynamically size the leftmost column
    const localizedConcreteTypes = rows.map(
        (row) => t(`importRun.concreteLookupType.${getConcreteLookupType(row)}`).length
    );
    const maxlenLocalizedConcreteType = Math.max(...localizedConcreteTypes);

    const columns = [
        {
            key: 'lookupDefinitionId',
            name: t('importLog.columns.concreteLookupType'),
            frozen: rows.length != 0,
            renderCell: renderConcreteLookupType,
            width: maxlenLocalizedConcreteType * 12,
            minWidth: 130,
            maxWidth: 210,
        },
        {
            key: 'djangoAdminUrl',
            name: '',
            renderCell: renderDjangoAdminUrl,
            hidden: !canUseSupportMode(user),
        },
        {
            key: 'status',
            name: t('importLog.columns.status'),
            renderCell: renderStatus,
            cellClass: 'import__status',
            minWidth: 135,
        },
        {
            key: 'createdAt',
            name: t('importLog.columns.importedAt'),
            renderCell: renderDatetime('createdAt'),
            minWidth: 120,
            maxWidth: 170,
        },
        {
            key: 'startedAt',
            name: '',
            renderCell: renderRuntime,
            cellClass: 'import__runtime',
            width: 120,
        },
        {
            key: 'finishedAt',
            name: '',
            renderCell: renderDatetime('finishedAt', (row) => row.status == 'SUCCESS'),
            minWidth: 120,
            maxWidth: 170,
        },
        {
            key: 'trigger',
            name: t('importLog.columns.trigger'),
            renderCell: ({ row }) =>
                row.trigger ? <Text text={t(`importRun.trigger.${row.trigger}`)} /> : row.trigger,
            minWidth: 110,
            maxWidth: 170,
        },
        {
            key: 'inputFileUrl',
            name: t('importLog.columns.inputFiles'),
            renderCell: renderFiles(['inputFile', 'inputFile2']),
            headerCellClass: 'rdg-cell-group',
            cellClass: 'rdg-cell-group',
            minWidth: 200,
            maxWidth: 300,
        },
        {
            key: 'inputFileNumRows',
            name: t('importLog.columns.numRows'),
            renderCell: renderFileInfo({
                fileKeys: ['inputFile', 'inputFile2'],
                fileInfoKey: 'NumRows',
            }),
            minWidth: 85,
        },
        {
            key: 'inputFileSize',
            name: t('importLog.columns.fileSize'),
            renderCell: renderFileInfo({
                fileKeys: ['inputFile', 'inputFile2'],
                fileInfoKey: 'Size',
                formatValue: formatFileSize,
            }),
            minWidth: 100,
        },
        {
            key: 'ingestionFileUrl',
            name: t('importLog.columns.ingestionFile'),
            renderCell: renderFiles(['ingestionFile']),
            headerCellClass: 'rdg-cell-group',
            cellClass: 'rdg-cell-group',
            minWidth: 200,
            maxWidth: 300,
        },
        {
            key: 'ingestionFileNumRows',
            name: t('importLog.columns.numRows'),
            renderCell: renderFileInfo({
                fileKeys: ['ingestionFile'],
                fileInfoKey: 'NumRows',
            }),
            minWidth: 85,
        },
        {
            key: 'ingestionFileSize',
            name: t('importLog.columns.fileSize'),
            renderCell: renderFileInfo({
                fileKeys: ['ingestionFile'],
                fileInfoKey: 'Size',
                formatValue: formatFileSize,
            }),
            minWidth: 100,
        },
    ].filter((column) => column.hidden == null || !column.hidden);

    const handleSubmitFilters = (filters, isInitialSubmit) => {
        const newFilters = [];

        for (let filter of filters) {
            if (filter.name == 'lookup_definition_id' && filter.value == nullValue) {
                newFilters.push({
                    ...filter,
                    value: allLookupDefinitionIds,
                });
            } else {
                newFilters.push(filter);
            }
        }

        // If `initialImportId` is given, include an initial filter that is discarded on subsequent submits
        if (initialImportId != null && isValidUUID(initialImportId) && isInitialSubmit) {
            newFilters.push({
                name: 'id',
                value: [initialImportId],
                expression: 'eq',
            });
        }

        // If `initialLookupDefinitionId` is given, include an initial filter that is discarded on subsequent submits
        if (initialLookupDefinitionId != null && isValidUUID(initialLookupDefinitionId) && isInitialSubmit) {
            newFilters.push({
                name: 'lookup_definition_id',
                value: [initialLookupDefinitionId],
                expression: 'eq',
            });
        }

        setQueryFilters(newFilters.filter(({ value }) => value != nullValue));
        setPage(0);
        refetch();
    };

    // - Filters

    moment.updateLocale(getCurrentLocale(), {
        relativeTime: {
            past: t(`importLog.filters.createdAt.format.past`),
            dd: t(`importLog.filters.createdAt.format.dd`),
            M: t(`importLog.filters.createdAt.format.M`),
            MM: t(`importLog.filters.createdAt.format.MM`),
            y: t(`importLog.filters.createdAt.format.y`),
            yy: t(`importLog.filters.createdAt.format.yy`),
        },
    });

    const filters = [
        {
            key: 'lookup_definition_id',
            render: SelectField,
            renderProps: {
                options: [
                    {
                        value: nullValue,
                        label: t(`importLog.filters.concreteLookupType.all`),
                    },
                    ...Object.entries(lookupDefinitionsByConcreteType).map(
                        ([conreteLookupType, lookupDefinitionId]) => ({
                            value: lookupDefinitionId,
                            label: t(`importRun.concreteLookupType.${conreteLookupType}`),
                            disabled: !lookupDefinitionId,
                        })
                    ),
                ],
                inputProps: {
                    required: true,
                },
            },
            mapFilter: (filter) => filter,
        },
        {
            key: 'status',
            render: SelectField,
            renderProps: {
                options: [
                    {
                        value: nullValue,
                        label: t(`importLog.filters.status.all`),
                    },
                    ...Object.keys(MASTERDATA_IMPORT_STATUS_COLOR_CLASS).map((status) => ({
                        value: status,
                        label: t(`importRun.status.${status}`),
                    })),
                ],
                inputProps: {
                    required: true,
                },
            },
        },
        {
            key: 'created_at',
            render: SelectField,
            renderProps: {
                options: [
                    {
                        value: moment().subtract(0, 'd').startOf('day').format('YYYY-MM-DD'),
                        label: moment().subtract(0, 'd').calendar(getCalendarCustomFormats('dayPart')),
                    },
                    {
                        value: moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD'),
                        label: moment().subtract(1, 'd').calendar(getCalendarCustomFormats('dayPart')),
                    },
                    {
                        value: moment().subtract(7, 'd').startOf('day').format('YYYY-MM-DD'),
                        label: moment().subtract(7, 'd').fromNow(),
                    },
                    {
                        value: moment().subtract(15, 'd').startOf('day').format('YYYY-MM-DD'),
                        label: moment().subtract(15, 'd').fromNow(),
                    },
                    {
                        value: moment().subtract(30, 'd').startOf('day').format('YYYY-MM-DD'),
                        label: moment().subtract(30, 'd').fromNow(),
                    },
                    {
                        value: moment().subtract(3, 'months').startOf('day').format('YYYY-MM-DD'),
                        label: moment().subtract(3, 'months').fromNow(),
                    },
                    {
                        value: moment().subtract(12, 'months').startOf('day').format('YYYY-MM-DD'),
                        label: moment().subtract(12, 'months').fromNow(),
                    },
                ],
                inputProps: {
                    required: true,
                },
            },
            expression: 'gte',
        },
        {
            key: 'is_active_version',
            render: BooleanField,
            renderProps: {
                isWrapped: false,
                label: t(`importLog.filters.isActiveVersion`),
            },
            mapFilter: (filter) => ({
                ...filter,
                // when deselected, show both active and inactive versions
                value: filter.value == true ? 'true' : ['true', 'false'],
            }),
        },
    ];

    return (
        <div className="masterdata-import-log">
            <div className="masterdata-import-log__filters">
                <span>{t('importLog.filterSectionPrefix')}</span>
                <FilterForm
                    availableFilters={filters}
                    initialFilterValues={initialFilterValues}
                    onSubmit={handleSubmitFilters}
                />
            </div>
            <div className="masterdata-import-log__table">
                <DataGrid
                    headerRowHeight={45}
                    rowHeight={45}
                    columns={columns}
                    rows={rows}
                    rowKeyGetter={(row) => row.id ?? row.createdAt}
                />
                {rows.length == 0 && (
                    <div
                        className={classnames(
                            'masterdata-import-log__table__no-content',
                            loading && 'masterdata-import-log__table__loading',
                            !loading && 'masterdata-import-log__table__empty'
                        )}
                    >
                        {loading && (
                            <>
                                <Loader />
                                <Loader />
                                <Loader />
                                <Loader />
                            </>
                        )}
                        {!loading && (
                            <AlertBar severity="info">
                                <AlertTitle>{t(`importLog.noData.title`)}</AlertTitle>
                                <AlertContent>{t(`importLog.noData.message`)}</AlertContent>
                            </AlertBar>
                        )}
                    </div>
                )}
            </div>
            <div className="rdg-footer">
                {rowsCount > 0 && (
                    <>
                        <span>
                            <Trans
                                t={t}
                                i18nKey="importLog.offsetPaginationInfo"
                                values={{
                                    offsetStart: offset + 1,
                                    offsetEnd: Math.min(offset + pageSize, rowsCount),
                                    totalResults: rowsCount,
                                }}
                            />
                        </span>
                        <div className="pagination">
                            <div
                                className={classnames(
                                    'pagination__item',
                                    'secondary-action-button',
                                    !hasPreviousPage && 'secondary-action-button__disabled'
                                )}
                                onClick={hasPreviousPage ? goToPreviousPage : null}
                            >
                                <span>{'<'}</span>
                            </div>
                            <div
                                className={classnames(
                                    'pagination__item',
                                    'secondary-action-button',
                                    !hasNextPage && 'secondary-action-button__disabled'
                                )}
                                onClick={hasNextPage ? goToNextPage : null}
                            >
                                <span>{'>'}</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default MasterdataImportLog;
