import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_PAGINATED_DATA_IMPORT_RUNS } from '../../queries';
import { MasterdataImportRun } from '../../types';
import { useImportDateRenderer } from '../MasterdataImportLog';
import { LoaderLine } from '../../../core/components/Loader';
import Badge from '../../../core/components/Badge';
import { IconButton } from '../../../core/components/IconButton';
import { generatePath } from '../../../utils/router';
import { NavigationContext } from '../../../generic_document/routes';
import { useContext } from 'react';

import '../MasterdataImportLog/style.scss';
import './style.scss';

interface ILookupVersionInfo {
    lookup_type: string;
    lookup_definition_id: string;
    version_id: string;
    importedAt?: string; // fetched from network for the given version_id
    isActiveVersion?: boolean; // fetched from network for the given version_id
}

interface IProps {
    versionInfo: ILookupVersionInfo[];
}

export const MasterdataVersionSummary = ({ versionInfo = [] }: IProps) => {
    if (versionInfo == null) {
        return null;
    }

    const { t } = useTranslation('masterdata');
    const { paths, channelId } = useContext(NavigationContext);

    // Ensure info is always displayed in the same order
    let versionInfoItems = [...versionInfo].sort((a, b) => (a.lookup_type < b.lookup_type ? -1 : 0));
    const dataImportRunIds = versionInfo.reduce((acc, infoItem) => [...acc, infoItem.version_id], []);

    const { data, loading } = useQuery(GET_PAGINATED_DATA_IMPORT_RUNS, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        variables: {
            // fetch all the referenced versions (= import runs)
            filters: [{ name: 'id', expression: 'eq', value: dataImportRunIds }],
            offset: 0,
            first: versionInfo.length,
        },
    });
    const dataImportRuns = (data?.dataImportRuns.edges.map((node) => node.node) as MasterdataImportRun[]) || [];

    // Enrich version info with data from fetched import runs (if any)
    if (dataImportRuns.length > 0) {
        let dataImportRunsById = {};
        for (let dataImportRun of dataImportRuns) {
            dataImportRunsById[dataImportRun.id] = dataImportRun;
        }

        versionInfoItems = versionInfoItems.map((infoItem) => ({
            ...infoItem,
            importedAt: dataImportRunsById[infoItem.version_id]?.finishedAt,
            isActiveVersion: dataImportRunsById[infoItem.version_id]?.isActiveVersion,
        }));
    }

    const { renderDatetime } = useImportDateRenderer(t);

    const renderRow = (versionInfo: ILookupVersionInfo) => {
        return (
            <tr className="table__row">
                <td className="table__cell">
                    {t(`importRun.concreteLookupType.${camelCase(versionInfo.lookup_type)}`)}
                </td>
                <td className="table__cell version">
                    {loading && <LoaderLine />}
                    {!loading &&
                        versionInfo?.isActiveVersion != null &&
                        (versionInfo.isActiveVersion ? (
                            <Badge colorClass="success" onClick={null}>
                                {t(`versionSummary.versionStatus.active`)}
                            </Badge>
                        ) : (
                            <Badge colorClass="">{t(`versionSummary.versionStatus.outdated`)}</Badge>
                        ))}
                </td>
                <td className="table__cell imported-at">
                    {loading && <LoaderLine />}
                    {!loading && versionInfo && renderDatetime('importedAt')({ row: versionInfo })}
                </td>
                <td className="table__cell">
                    <IconButton
                        linkTo={generatePath(
                            paths.channelMasterdata,
                            { channelId },
                            { importId: versionInfo.version_id }
                        )}
                        icon={'openExternalUrl'}
                        tooltip={t(`versionSummary.importDetailsTooltip`)}
                        linkExternal
                    />
                </td>
            </tr>
        );
    };

    return (
        <div className="masterdata-version-summary">
            <span>{t('versionSummary.description')}</span>
            <table className="table__table">
                <thead className="table__thead">
                    <tr>
                        <th className="table__cell">{t('versionSummary.columns.masterdata')}</th>
                        <th className="table__cell version">{t('versionSummary.columns.version')}</th>
                        <th className="table__cell imported-at">{t('versionSummary.columns.importedAt')}</th>
                        <th className="table__cell"></th>
                    </tr>
                </thead>
                <tbody className="table__tbody">{versionInfoItems.map(renderRow)}</tbody>
            </table>
        </div>
    );
};
