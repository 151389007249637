import * as React from 'react';
import OutlookAddIn from './pages/OutlookAddIn';
import Overview from './pages/Overview';
import Edit from './pages/Edit';
import Invoices from './pages/Invoices';
import Plan from './pages/Plan';
import Integrations from './pages/Integrations';

import { canManageCustomer, canManageIntegrations } from '../users/utils';

import {
    APPS_PATH,
    EDIT_PATH,
    INTEGRATIONS_CALLBACK_PATH,
    INTEGRATIONS_PATH,
    INVOICES_PATH,
    OVERVIEW_PATH,
    PLAN_PATH,
} from './constants';

export default [
    {
        path: OVERVIEW_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: Overview,
    },
    {
        path: EDIT_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: Edit,
    },
    {
        path: INVOICES_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: Invoices,
    },
    {
        path: INTEGRATIONS_PATH,
        condition: ({ user }) => canManageIntegrations(user) || canManageCustomer(user),
        component: Integrations,
    },
    {
        path: INTEGRATIONS_CALLBACK_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: (props) => <Integrations providerAuthorized={true} {...props} />,
    },
    {
        path: APPS_PATH,
        condition: ({ user }) => canManageIntegrations(user),
        component: OutlookAddIn,
    },
    {
        path: PLAN_PATH,
        condition: ({ user }) => canManageCustomer(user),
        component: Plan,
    },
];
